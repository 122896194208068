import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, DataType, initialState, reducer } from '../reducers/clinic';

const ClinicContext = createContext<{ clinicState: DataType; clinicDispatch: Dispatch<Action> }>({
  clinicState: initialState,
  clinicDispatch: () => null,
});

const ClinicProvider: FC<PropsWithChildren> = ({ children }) => {
  const [clinicState, clinicDispatch] = useReducer(reducer, initialState);
  return (
    <ClinicContext.Provider value={{ clinicState, clinicDispatch }}>
      {children}
    </ClinicContext.Provider>
  );
};

export { ClinicContext, ClinicProvider };
