import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import { Loading } from 'components/elements';
import Icon from 'components/icon';
import moment from 'moment';
import { ReviewListLegacyClient } from 'node-api/reviewList/reviewlistLegacyClient';
import { ScheduleAppointment } from 'node-api/schedule/Appointments.types';
import { ChangeEvent, useEffect, useState } from 'react';
import { getDateTimeFromString, getDateWithUTCFormatFromString } from 'utils/dateHelpers';
import { debug } from 'utils/helpers';
import { Action, Content, Details, Item } from './style';

const rowsPerPageOptions = [10, 15, 25, 100];

export const Appointments = ({ type, memberId }: { type: string; memberId: number }) => {
  const [appointments, setAppointments] = useState<ScheduleAppointment[]>([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterAppointments = (data: ScheduleAppointment[]) => {
    const now = moment().tz('UTC');
    return data.filter((appointmentItem: ScheduleAppointment) =>
      type === 'completed'
        ? getDateWithUTCFormatFromString(appointmentItem.start_at) < now
        : getDateWithUTCFormatFromString(appointmentItem.start_at) > now
    );
  };

  const fetchAppointments = async () => {
    setLoading(true);

    try {
      const response = await new ReviewListLegacyClient().getAppointmentByMemberId(memberId);
      if (response) {
        const filterByStatus = filterAppointments(response);
        setTotalRows(filterByStatus.length);
        setAppointments(filterByStatus);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (!axios.isCancel(error)) {
        debug(error as string);
      }
    }
  };

  const LoadData = () => {
    const source = axios.CancelToken.source();
    fetchAppointments();
    return () => {
      source.cancel();
    };
  };

  useEffect(() => {
    LoadData();
  }, []);

  return (
    <div className='appointments'>
      {loading && <Loading />}
      {!loading && appointments?.length === 0 && <h5>No appointments</h5>}
      {!loading && appointments?.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Detail</TableCell>
                  <TableCell>Place Of Service</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointments
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((appointment: ScheduleAppointment) => {
                    return (
                      <TableRow key={`appointment-${appointment.id}`} component='tr'>
                        <TableCell>
                          <Item>
                            <Icon
                              icon='done'
                              color='white'
                              background={appointment.is_editable ? 'teal' : 'gray'}
                              size={20}
                            />

                            <Content>
                              <Details>
                                <strong>{appointment.appointment_type}</strong>
                                <br />
                                <span>
                                  <strong>{appointment.patient_email}</strong>
                                  <br />
                                  Start at: {getDateTimeFromString(appointment.start_at)}
                                  <br />
                                  End at: {getDateTimeFromString(appointment.end_at)}
                                </span>
                              </Details>
                            </Content>
                          </Item>
                        </TableCell>
                        <TableCell>
                          <Action>{appointment.place_of_service}</Action>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};
