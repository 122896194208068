import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${(props) => (props.selected ? props.theme.white : 'transparent')};
  border-right: 1px solid ${({ theme }) => theme.grayLight};
  color: ${({ theme }) => theme.teal};
  cursor: ${(props) => (props.selected ? 'auto' : 'pointer')};
  display: inherit;
  font: ${(props) => (props.selected ? 900 : 300)} 18px/18px ${({ theme }) => theme.primaryFont};
  justify-content: center;
  min-height: 48px;
  min-width: 96px;
  padding: 0px 18px;
  text-decoration: ${(props) => (props.selected ? 'underline' : 'auto')};
`;

export const Counter = styled.div`
  background-color: ${({ theme }) => theme.turquoise};
  border-radius: 4px;
  color: ${({ theme }) => theme.white};
  font: 17px/27px ${({ theme }) => theme.primaryFont};
  height: 26px;
  margin-left: 16px;
  pointer-events: none;
  text-align: center;
  width: 26px;
`;
