import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { css } from 'glamor';

import Theme from 'styles/theme';

import { Icon } from './style';

const bodyClassName = css({
  alignItems: 'center',
  display: 'flex',
  font: `16px ${({ theme }) => theme.primaryFont}`,
});

const className = css({
  background: `${Theme.teal}`,
  borderRadius: '8px',
  color: `${Theme.white}`,
  margin: '0 auto',
  maxWidth: '400px',
  padding: '15px 10px',
  width: '100%',
});

const progressClassName = css({
  background: `${Theme.white}`,
});

const Message = ({ type, message }) => (
  <>
    {type === 'success' && <Icon className='material-icons'>check_circle</Icon>}
    {type === 'info' && <Icon className='material-icons'>info</Icon>}
    {type === 'error' && <Icon className='material-icons'>warning</Icon>}
    {message}
  </>
);

export default {
  show(type, msg, options = {}) {
    return toast(<Message type={type} message={msg} />, {
      closeButton: false,
      type,
      className,
      bodyClassName,
      progressClassName,
      ...options,
    });
  },
};

Message.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};
