import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import 'config/moment';
import 'font-proxima-nova/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/global';
import Theme, { MuiTheme } from 'styles/theme';
import App from './components/App';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={Theme}>
    <MuiThemeProvider theme={MuiTheme}>
      <GlobalStyle />
      <ToastContainer
        position='top-center'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
      <App />
    </MuiThemeProvider>
  </ThemeProvider>
);
