import Loading from 'components/elements/loading/Loader';
import { CardData, MetricRawValue } from 'components/main/metrics/types';
import { FC, useState } from 'react';
import { Conversion } from 'utils/constants';
import { formatCompactNumber } from 'utils/helpers';
import { roundDecimalToNDigits } from 'utils/math';
import {
  CardContainer,
  ComparisonContainer,
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  HeaderTitleContainer,
  MainContainer,
  Metric,
  MetricContainer,
  MetricDescription,
  MetricEmptyBottomLabel,
  MetricEmptyContainer,
  MetricEmptyLabel,
  MetricSecondDescription,
  MetricValue,
} from './style';

interface MetricCardProps {
  cardData: CardData;
  width?: string;
  height?: string;
  isLoading: boolean;
  rawData: MetricRawValue;
  membersAmount: number;
}

const MetricCardElement: FC<MetricCardProps> = ({
  cardData,
  width = '240px',
  height = '180px',
  isLoading,
  rawData,
  membersAmount,
}) => {
  const [hoverStyle, setHoverStyle] = useState({ display: 'none', fontSize: 11 });

  const renderArrowBasedOnValue = (value: number) => {
    switch (true) {
      case value > 0:
        return '↑';
      case value < 0:
        return '↓';
      default:
        return '';
    }
  };

  const parseComparisonValue = (value: number) => {
    const DIGITS = 0;
    return (roundDecimalToNDigits(Math.abs(value), 2) * 100).toFixed(DIGITS);
  };

  const formatNumber = (value: number) => {
    if (cardData.type === 'Weight') {
      return formatCompactNumber(value, Conversion.GramsToLibs);
    }

    return formatCompactNumber(value);
  };

  const renderMetric = () => {
    if (!rawData.self && (rawData.comparisonAverage?.clinicRole || 0) === 0) {
      return (
        <MetricEmptyContainer>
          <MetricEmptyLabel>No Data</MetricEmptyLabel>
          <MetricEmptyBottomLabel>Data is updated nightly</MetricEmptyBottomLabel>
        </MetricEmptyContainer>
      );
    }

    return (
      <>
        <MetricContainer>
          <Metric>
            <MetricValue>{formatNumber(rawData.self?.averageByMember || 0)}</MetricValue>
            <MetricDescription>{cardData.descriptionAvgFirstLine}</MetricDescription>
            <MetricSecondDescription>{cardData.descriptionAvg}</MetricSecondDescription>

            <ComparisonContainer isPositive={(rawData.comparisonAverage?.clinicRole || 0) >= 0}>
              <div>
                <span>{renderArrowBasedOnValue(rawData.comparisonAverage?.clinicRole || 0)}</span>
                {`${parseComparisonValue(rawData.comparisonAverage?.clinicRole || 0)}%`}
              </div>
            </ComparisonContainer>
          </Metric>
          <Metric>
            <MetricValue>{formatNumber(rawData.self?.total || 0)}</MetricValue>
            <MetricDescription>{cardData.totalFirstLine}</MetricDescription>
            <MetricSecondDescription>
              {cardData.renderDescriptionTotal(membersAmount)}
            </MetricSecondDescription>

            <ComparisonContainer isPositive={(rawData.comparisonTotal?.clinicRole || 0) >= 0}>
              <div>
                <span>{renderArrowBasedOnValue(rawData.comparisonTotal?.clinicRole || 0)}</span>
                {`${parseComparisonValue(rawData.comparisonTotal?.clinicRole || 0)}%`}
              </div>
            </ComparisonContainer>
          </Metric>
        </MetricContainer>
        <div style={{ height: 15 }}>
          <span style={hoverStyle}>{cardData.hoverMessage}</span>
        </div>
      </>
    );
  };

  return (
    <CardContainer
      width={width}
      height={height}
      isLoading={isLoading}
      onMouseEnter={() => {
        setHoverStyle({ display: 'block', fontSize: 11 });
      }}
      onMouseLeave={() => {
        setHoverStyle({ display: 'none', fontSize: 11 });
      }}>
      <HeaderContainer isLoading={isLoading}>
        <HeaderTitleContainer>
          <HeaderTitle>{cardData.title}</HeaderTitle>
        </HeaderTitleContainer>
        <HeaderSubtitle>{cardData.subtitle ?? ''}</HeaderSubtitle>
      </HeaderContainer>

      <MainContainer>{isLoading ? <Loading /> : renderMetric()}</MainContainer>
    </CardContainer>
  );
};

export default MetricCardElement;
