import styled from 'styled-components';

export const Wrapper = styled.label`
  align-items: center;
  display: flex;

  & input:checked + .slider {
    background-color: ${({ theme }) => theme.teal};
  }

  & input:focus + .slider {
    box-shadow: 0 0 1px ${({ theme }) => theme.white};
  }

  & input:checked + .slider:before {
    transform: translateX(34px);
  }
`;

export const Switch = styled.label`
  display: inline-block;
  height: 20px;
  position: relative;
  width: 50px;

  & input {
    height: 0;
    opacity: 0;
    width: 0;
  }
`;

export const Slider = styled.span`
  background-color: ${({ theme }) => theme.gray};
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.1s;

  &:before {
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    content: '';
    height: 26px;
    left: -4px;
    position: absolute;
    top: -4px;
    transition: 0.1s;
    width: 26px;
  }
`;

export const Label = styled.label`
  margin-left: 1rem;
`;
