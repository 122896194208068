import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ChartContext } from 'state/contexts/chart';
import Theme from 'styles/theme';

import { ChartWrapper } from '../../style';

const Charts = React.memo(() => {
  const { chartState } = useContext(ChartContext);
  const [data, setData] = useState(chartState.data);

  useEffect(() => {
    setData(chartState.data);
  }, [chartState.data]);

  return (
    <>
      <ChartWrapper>
        <Chart
          options={{
            chart: {
              fontFamily: Theme.primaryFont,
              stacked: false,
              animations: {
                enabled: true,
              },
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleXaxis: true,
              },
              toolbar: {
                autoSelected: 'zoom',
              },
              type: 'bar',
            },
            plotOptions: {
              bar: {
                vertical: true,
              },
            },
            stroke: {
              width: 2,
            },
            colors: [
              Theme.series.blue,
              Theme.series.red,
              Theme.series.yellow,
              Theme.series.green,
              Theme.series.blueDark,
              Theme.series.programs,
              Theme.series.purple,
              Theme.series.turquoise,
              Theme.series.heartrate_average,
              Theme.series.heartrate_max,
            ],
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              type: 'category',
              labels: {
                formatter: (val) => val,
                style: {
                  fontFamily: Theme.primaryFont,
                  fontSize: '18px',
                },
              },
            },
            yaxis: {
              min: 0,
              max: 100,
              labels: {
                formatter: (val) => `${val} %`,
              },
            },
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 410,
              offsetY: 410,
            },
            legend: {
              position: 'bottom',
              fontFamily: Theme.primaryFont,
              fontSize: '18px',
            },
          }}
          series={data.providers.values.map((card) => ({
            name: card.name,
            type: 'bar',
            data: card.card_completion_pct.map((point) => ({
              x: point.period,
              y: point.value,
            })),
          }))}
          type='bar'
          height='100%'
        />
      </ChartWrapper>
    </>
  );
});

Charts.propTypes = {};
Charts.defaultProps = {};

export default Charts;
