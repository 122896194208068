import api from 'api';
import { CancelToken } from 'axios';
import { IPictureNotifications, IUnreviewedPictures } from 'interfaces/pictures';

const PictureService = {
  async unreviewedFoodPictures(
    { memberId }: { memberId: number },
    cancelToken?: CancelToken
  ): Promise<IUnreviewedPictures | null> {
    const response = await api({ version: 'v2' }).get(
      `/members/${memberId}/picture_notifications`,
      {
        cancelToken,
      }
    );

    return response.data;
  },

  async pictureNotificationsSummary(
    { memberId }: { memberId: number },
    cancelToken?: CancelToken
  ): Promise<IPictureNotifications | null> {
    const response = await api({ version: 'v2' }).get(
      `/members/${memberId}/picture_notifications/summary`,
      {
        cancelToken,
      }
    );

    return response.data;
  },
};

export default PictureService;
