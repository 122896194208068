export enum ReviewListSlugs {
  // Review Lists 1
  IncompleteTasks = 'incomplete_tasks',
  MentionedInTeamChat = 'mentioned',
  PatientAssigned = 'patient_assigned',
  PatientsDueForEngagementByMe = 'not_engaged_by_me',
  PrivateMessages = 'private_messages',

  // Review Lists 2
  CardCompleted = 'cardcompleted',
  CardEngagement = 'cardengagement',
  CardsNotCompleted = 'cardsnotcompleted',
  Disengaging = 'disengaging',
  DueForEngagementByRD = 'dueforengagementByRD',
  FoodPictures = 'foodpictures',
  PictureCommentReplies = 'foodpicturecomments',
  WeightRegainWarning = 'weightregain',

  // Review Lists 4
  ExerciseLogs = 'exercise-logs',
  FoodPictureComments = 'food-picture-comments',
  NotEngagedByMe = 'not-engaged-by-me',
  NotEngagedByMeForMD = 'not-engaged-by-me-for-md',
  ReEngagementAlert = 're-engagement-alert',
  VitalsAndBodyComp = 'vitals-and-body-comp',
  WeightRegain = 'weight-regain',
}
