import axios from 'axios';
import { Loading, Tabs } from 'components/elements';
import MainProfileActions from 'components/main';
import Toast from 'components/toast';
import { AppointmentTypeCategory } from 'node-api/schedule/Appointments.types';
import AppointmentsClient from 'node-api/schedule/AppointmentsClient';
import { FunctionComponent, useEffect, useState } from 'react';
import ProviderCategoryTab from './Category/ProviderCategoryTab';
import { Text } from './style';
import { ScheduleComponentProps } from './types';

const CalendarTab: FunctionComponent<ScheduleComponentProps> = ({ patient }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [appointmentTypesData, setAppointmentTypesData] = useState<AppointmentTypeCategory[]>();
  const { clinic_id: clinicId } = patient;

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      const message = `Couldn't load the appointments data!`;
      try {
        const data = await new AppointmentsClient().appointmentTypes({ clinicId }, source.token);
        if (data.success !== true) {
          throw new Error(message);
        } else if (data.categories?.length === 0) {
          throw new Error(message);
        }
        const filteredCategories = data.categories
          .filter(({ visible }) => visible)
          .sort((a, b) => a.order - b.order);
        setAppointmentTypesData(filteredCategories);
      } catch (err) {
        if (!axios.isCancel(err)) {
          Toast.show('error', message);
        }
      }
      setLoading(false);
    };

    getData();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      {/* activate all buttons from profile page */}
      <MainProfileActions key={`main-profile-buttons-${patient.id}`} />

      {loading ? (
        <Loading />
      ) : appointmentTypesData?.length ? (
        <Tabs
          data={appointmentTypesData.map((e, index) => ({ ...e, id: index + 1, title: e.name }))}
          renderTabLabel={(data) => <Text> {data.title} </Text>}
          renderTabContent={(data) => (
            <ProviderCategoryTab data={data} key={`category-${data.id}`} patient={patient} />
          )}
        />
      ) : null}
    </>
  );
};

export default CalendarTab;
