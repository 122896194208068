import React from 'react';
import { IconContent } from '../style';

const UserPlus = () => (
  <IconContent fill='currentColor' viewBox='0 0 22 22'>
    <g>
      <path d='M8.76 11.955h.146c1.334-.023 2.412-.488 3.209-1.377 1.752-1.96 1.46-5.318 1.429-5.638-.114-2.406-1.26-3.558-2.207-4.095C10.63.443 9.807.227 8.888.21h-.077c-.505 0-1.497.081-2.448.618-.956.537-2.12 1.689-2.235 4.113-.032.32-.323 3.679 1.43 5.638.791.89 1.87 1.354 3.203 1.377zM5.344 5.053c0-.014.005-.028.005-.037.15-3.236 2.466-3.584 3.458-3.584H8.861c1.229.027 3.317.524 3.458 3.584 0 .014 0 .028.005.037.005.031.323 3.1-1.124 4.717-.573.641-1.338.957-2.344.966h-.045c-1.001-.009-1.77-.325-2.34-.966C5.03 8.163 5.34 5.08 5.344 5.053z' />
      <path d='M18.325 17.397v-.013c0-.035-.005-.07-.005-.11-.026-.866-.083-2.892-1.988-3.54l-.044-.013c-1.98-.503-3.626-1.641-3.644-1.654a.593.593 0 0 0-.825.144.59.59 0 0 0 .145.823c.075.053 1.822 1.265 4.008 1.825 1.022.363 1.137 1.453 1.167 2.45 0 .04 0 .075.005.11.004.394-.022 1.003-.092 1.353-.712.402-3.499 1.794-7.74 1.794-4.222 0-7.027-1.396-7.743-1.799-.07-.35-.1-.958-.092-1.352 0-.035.005-.07.005-.11.03-.997.144-2.087 1.167-2.45 2.186-.56 3.933-1.777 4.008-1.825a.59.59 0 0 0 .145-.823.593.593 0 0 0-.825-.145c-.018.014-1.655 1.151-3.644 1.655a.554.554 0 0 0-.044.013C.384 14.382.327 16.408.301 17.27c0 .04 0 .075-.005.11v.013c-.004.228-.008 1.396.224 1.983.044.113.123.21.228.275.132.088 3.288 2.092 8.569 2.092 5.28 0 8.437-2.008 8.569-2.092a.586.586 0 0 0 .228-.275c.22-.582.215-1.751.21-1.979zM17.893 4c.374 0 .677.316.677.706v3.088h2.752c.343 0 .627.266.672.61L22 8.5c0 .39-.303.706-.678.706l-2.752-.001v3.09a.697.697 0 0 1-.585.699l-.092.006a.692.692 0 0 1-.678-.706V9.205h-2.537a.688.688 0 0 1-.672-.61L14 8.5c0-.39.303-.706.678-.706h2.537V4.706c0-.358.255-.653.586-.7z' />
    </g>
  </IconContent>
);

export default UserPlus;
