import { CancelToken } from 'axios';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  ReviewList4Response,
  ReviewList4SummaryResponse,
  ReviewLists4Response,
} from './reviewlist.types';

export class ReviewListClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.rl3_url);
  }

  async getReviewLists4(cancelToken?: CancelToken) {
    const { data }: { data: ReviewLists4Response } = await this.axiosClient.get(
      `/review-lists/v4/providers/me/review-lists`,
      {
        cancelToken,
      }
    );
    return data;
  }

  async getReviewList4Summary(
    { reviewListName }: { reviewListName: string },
    cancelToken?: CancelToken
  ) {
    const { data }: { data: ReviewList4SummaryResponse } = await this.axiosClient(
      `/v4/providers/me/review-lists/${reviewListName}/summary`,
      {
        cancelToken,
      }
    );
    return data;
  }

  async getReviewList4({ reviewListName }: { reviewListName: string }, cancelToken?: CancelToken) {
    const { data }: { data: ReviewList4Response } = await this.axiosClient(
      `/v4/providers/me/review-lists/${reviewListName}`,
      {
        cancelToken,
      }
    );
    return data;
  }

  async reviewMemberInReviewList4({
    reviewListName,
    memberId,
  }: {
    reviewListName: string;
    memberId: number;
  }) {
    const { data } = await this.axiosClient.post(
      `review-lists/v4/providers/me/review-lists/${reviewListName}/members/${memberId}/review`
    );
    return data;
  }
}
