import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/chat';

const ChatContext = createContext<{ chatState: State; chatDispatch: Dispatch<Action> }>({
  chatState: initialState,
  chatDispatch: () => null,
});

const ChatProvider: FC<PropsWithChildren> = ({ children }) => {
  const [chatState, chatDispatch] = useReducer(reducer, initialState);
  return (
    <ChatContext.Provider value={{ chatState, chatDispatch }}>{children}</ChatContext.Provider>
  );
};

export { ChatContext, ChatProvider };
