import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import { AttentionDimension, AttentionResponse } from './AttentionApi.types';

export class AttentionClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.rl3_url);
  }

  /**
  Mark given members as tended to by the current provider (authenticated user) for a specific dimension
 * @returns 
 */
  async markMemberAsTendedToForDimension({
    memberIds,
    dimensionKey,
  }: {
    memberIds: number[];
    dimensionKey: AttentionDimension;
  }) {
    await this.axiosClient.put<void>(
      `/v1/members/${memberIds.join(',')}/dimension/${dimensionKey}/attention/tendTo`
    );
  }

  async getMembersAttentionNeed({ memberIds }: { memberIds: number[] }) {
    const {
      data: { attention },
    } = await this.axiosClient.get<AttentionResponse>(
      `/v1/members/${memberIds.join(',')}/attention`
    );
    return attention;
  }
}
