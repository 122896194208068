import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:not(:first-child) {
    margin-left: 20px;
  }

  textarea {
    margin-right: 36px;
  }
`;

export const TaskIndex = styled.div`
  align-items: center;
  border-radius: 14px;
  display: flex;
  font: 17px ${({ theme }) => theme.primaryFont};
  padding: 8px 15px;
  color: ${({ theme }) => theme.teal};
  background: ${({ theme }) => theme.turquoise};
  margin-bottom: 35px;
  margin-top: 40px;
`;

Container.defaultProps = {
  'data-id': 'Container',
};

TaskIndex.defaultProps = {
  'data-id': 'TaskIndex',
};
