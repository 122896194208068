import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/chart';

const ChartContext = createContext<{ chartState: State; chartDispatch: Dispatch<Action> }>({
  chartState: initialState,
  chartDispatch: () => null,
});

const ChartProvider: FC<PropsWithChildren> = ({ children }) => {
  const [chartState, chartDispatch] = useReducer(reducer, initialState);
  return (
    <ChartContext.Provider value={{ chartState, chartDispatch }}>{children}</ChartContext.Provider>
  );
};

export { ChartContext, ChartProvider };
