import { NOTIFICATION_SOUND } from 'config';
import {
  Conversion,
  GRAMS_PER_POUND,
  ORIGINAL_USER_KEY,
  SEND_GRID_MISSED_APPOINTMENTS_PARAM,
} from './constants';

/**
 * Toast
 * Show a message on the upper right viewport corner
 */
export { toast } from 'react-toastify';

/**
 * Change Cases
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Debug
 * Log on console if not in Production
 * @param {String} message
 * @param @optional {String} label
 */
export function debug(message, label = undefined) {
  // should be !ENVIRONMENT.isProd
  if (process.env.REACT_APP_ENV !== 'production') {
    // eslint-disable-next-line no-console
    return label ? console.log(message, label) : console.log(message);
  }
  return false;
}

/**
 * Format number as Number with decimals
 * @param {Number} num Amount
 * @param {Number} decimals Decimals you want, default is 0
 */
export function numericFormat(num, decimals) {
  const d = decimals || 0;
  let value = num;

  if (num instanceof Number) value = num.toFixed(d);
  else value = Number(num).toFixed(d);

  return `${value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

/**
 * Format number as Percentage
 * @param {Number} num Amount
 * @param {Number} decimals Decimals you want, default is 0
 */
export function percentageFormat(num, decimals) {
  return `${numericFormat(num, decimals)}%`;
}

/**
 * Convert the number to compact format like 1.8K or 1M
 * @param {Number} number to be formatted
 * @param {Conversion} conversion Optional value if is needed pre process the number before format it
 */
export function formatCompactNumber(number, conversion = Conversion.Default) {
  let nValue = number;
  if (conversion === Conversion.GramsToLibs) {
    nValue = number / GRAMS_PER_POUND;
  }

  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(nValue);
}

/**
 * Return number with the weight indicator
 * @param {Number} num Amount
 * @param {Number} decimals Decimals you want, default is 0
 */
export function weightFormat(num, decimals) {
  return `${numericFormat(num, decimals)}lbs`;
}

/**
 * Save on local storage
 * @param {string} name Store name
 * @param {object} data Data to store inside
 */
export function saveOnLocalStorage(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}

/**
 * Get item from local storage
 */
export function getItemFromLocalStorage(key) {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item);
}

/**
 * Get Item from Session Storage
 * @param {string} name of the variable to retrieve from the storage.
 */
export function getItemFromSessionStorage(item) {
  const storageItem = JSON.parse(sessionStorage.getItem(item)) || null;
  return storageItem;
}

/**
 * Escape the HTML data based in a regex.
 *
 * @param {*} data html string to process.
 */
export const escapeHTMLData = (data) => {
  const regex = /<\s*script[^>]*>(.*?)<\s*\/\s*script>|(&lt;script)(.*?)(script&gt;)/gi;
  return data.match(regex) ? data.replace(regex, '') : data;
};

export const imageExist = (url) => {
  const img = new Image();
  img.src = url;

  return img.height !== 0;
};

export const getAppVersion = (appVersion) => {
  return (appVersion && capitalize(appVersion.replaceAll('_', ' ')).replace('Ios', 'iOS')) || 'NA';
};

export const getCurrentProviderState = (userState, providerState) => {
  return providerState?.data?.find((p) => p.id === userState?.id);
};

export const isCurrentProviderHidden = (currentProviderState) => {
  return currentProviderState?.status === 'hidden';
};

export const setFavicon = (newMsg = false) => {
  const favicon = document.getElementById('favicon');
  const fileName = newMsg ? 'favicon3.ico' : 'favicon.ico';

  favicon.href = `${favicon.href.substring(0, favicon.href.lastIndexOf('/'))}/${fileName}`;
};

export const playSoundMessageReceived = () => {
  try {
    new Audio(NOTIFICATION_SOUND.url).play();
  } catch (e) {
    debug(e);
  }
};

export const getProviderClinicIds = (clinics) => {
  return new Set(clinics?.clinics?.filter(({ assigned }) => assigned).map(({ id }) => id));
};

const getOriginalUserData = () => {
  return localStorage.getItem(ORIGINAL_USER_KEY);
};

export const getOriginalUserFullName = () => {
  return getOriginalUserData()?.split('|')[1] || null;
};

export const getOriginalUserUUID = () => {
  return getOriginalUserData()?.split('|')[0] || null;
};

export const hasValues = (array) => Array.isArray(array) && array.length;

export const isFromSendGridMissedAppointments = (locationSearch) => {
  return (
    new URLSearchParams(locationSearch).get(SEND_GRID_MISSED_APPOINTMENTS_PARAM.name) ===
    SEND_GRID_MISSED_APPOINTMENTS_PARAM.value
  );
};
