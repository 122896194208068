import { Ability, AbilityBuilder } from '@casl/ability';

class CASLAbilities {
  constructor() {
    localStorage.setItem(
      'roles',
      JSON.stringify({
        name: 'User',
        role: 'admin',
      })
    );

    this.ability = new Ability(this.set(JSON.parse(localStorage.getItem('roles')) || undefined));
  }

  update() {
    this.ability.update(this.set(JSON.parse(localStorage.getItem('roles'))));
  }

  clear() {
    this.ability.update([]);
  }

  get() {
    return this.ability;
  }

  set(user = {}) {
    const { can, rules } = AbilityBuilder.extract();

    switch (user.role) {
      case 'admin':
        can('enter', ['Dashboard', 'Patients', 'Financials']);
        break;

      case 'provider':
        can('enter', ['Dashboard', 'Patients', 'Financials']);
        break;

      case 'patient':
        can('enter', ['Dashboard', 'Patients', 'Financials']);
        break;

      default:
        break;
    }

    return rules;
  }
}

export default new CASLAbilities();
