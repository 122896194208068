import moment from 'moment';
import momentTimezone from 'moment-timezone';

type AppointmentTypeCodes = ['FU' | 'TR', 'NS' | 'MD' | 'ES', 'OL' | 'IP', string];

const appointmentHasAppointmentTypeCode = (appointmentType: string) =>
  appointmentType.includes('[') && appointmentType.includes(']');

export const formatAppointmentStartEnd = (
  appointment: { startAt: string; endAt: string },
  timezone: string
) => {
  const start = momentTimezone(appointment.startAt).tz(timezone).format('h:mm A');
  const endAndTimezone = momentTimezone(appointment.endAt).tz(timezone).format('h:mm A z');
  const timesAsText = `${start} – ${endAndTimezone}`;
  return timesAsText;
};

export const getAppointmentTypeCodes = (
  appointmentType?: string | null
): AppointmentTypeCodes | [] => {
  if (!appointmentType || !appointmentHasAppointmentTypeCode(appointmentType)) {
    return [];
  }

  return appointmentType
    .toUpperCase()
    .split('[')[1]
    .split(']')[0]
    .split('-') as AppointmentTypeCodes;
};
export const getAppointmentName = (appointmentType: string): string =>
  appointmentType?.split('[')[0] || '';

export const getAppointmentSimplifiedName = (appointmentType: string): string =>
  getAppointmentName(appointmentType).replaceAll('Appointment', '').replaceAll('  ', ' ').trim() ||
  '';

export const getAppointmentWeeklyWindow = (
  appointments: { startAt: string }[],
  timezone: string
) => {
  const minEventStart = appointments.reduce((min, appointment) => {
    const appointmentStart = moment(appointment.startAt).tz(timezone);
    const roundedToHourStart = appointmentStart.minute(0).second(0);
    return roundedToHourStart.format('HH:mm') < min.format('HH:mm') ? roundedToHourStart : min;
  }, moment(appointments[0].startAt));
  const maxEventStart = appointments.reduce((max, appointment) => {
    const appointmentStart = moment(appointment.startAt).tz(timezone);
    const roundedToHourStart = appointmentStart.minute(0).second(0).add(1, 'hour');
    return roundedToHourStart.format('HH:mm') > max.format('HH:mm') ? roundedToHourStart : max;
  }, moment(appointments[0].startAt));
  const hiddenDays = [0, 6].filter(
    (day) => !appointments.find((appointment) => moment(appointment.startAt).weekday() === day)
  );
  const startTime = minEventStart.format('HH:mm');
  const endTime = maxEventStart.format('HH:mm');
  return {
    startTime,
    endTime,
    hiddenDays,
  };
};
