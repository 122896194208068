import MainProfileActions from 'components/main';
import useLastProgram from 'hooks/useLastProgram';
import { Patient } from 'lib/types';
import { FC, useContext, useEffect } from 'react';
import { PatientContext } from 'state/contexts/patient';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import { ChartsBox } from './charts-box/patient-chart-box';
import TasksBox from './tasks-box/TasksBox';

export const ProfileTabComponent: FC<{
  patient: Patient;
  setProfileTabUnreviewed: (unreviewed: boolean) => void;
}> = ({ patient, setProfileTabUnreviewed }) => {
  const { patientDispatch } = useContext(PatientContext);

  const currentProgram = useLastProgram();

  useEffect(() => {
    if (!currentProgram) {
      return;
    }

    patientDispatch({ type: PatientActionTypes.SET_PROGRAM, payload: currentProgram });
  }, [currentProgram]);

  return (
    <>
      <ChartsBox
        patient={patient}
        key={`chart-${patient}`}
        setProfileTabUnreviewed={setProfileTabUnreviewed}
      />

      <TasksBox patientId={patient.id} key={`task-${patient.id}`} />

      {/* activate all buttons */}
      <MainProfileActions key={`main-profile-buttons-${patient.id}`} />
    </>
  );
};
