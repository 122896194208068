export enum AttentionDimension {
  CardComments = 'card-comments',
  Exercise = 'exercise',
  Food = 'food',
  FoodComments = 'food-comments',
  MemberChat = 'member-chat',
  TeamChat = 'team-chat',
  Vitals = 'vitals',
  Weights = 'weights',
  /* Disabled or pending deletion */
  // 'Weights (Old)' = 'weights-old',
  // ExerciseComments = 'exercise-comments',
}

export enum AttentionStatus {
  NeedsAttention = 'needs-attention',
  TendedTo = 'tended-to',
}
export type AttentionResponseEntry = {
  dimensionKey: AttentionDimension;
  providerId: number;
  status: AttentionStatus;
  updatedAt: string;
  lastTendedToAt: string;
  statusChangeAt: string;
};

export type AttentionResponse = {
  attention: {
    [memberId: number]: AttentionResponseEntry[];
  };
};
