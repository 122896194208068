import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import Icon from 'components/icon';
import { Wrapper, Backdrop, Content, Main, CloseButton } from './style';

/**
 * Modal
 *
 * @param {*} children Content to show inside the modal
 * @param {*} header Modal header
 * @param {*} footer Modal footer
 * @param {boolean} showClose Show close button
 * @param {function} onClose Callback to execute on modal close
 *
 * Example: <Modal onClose={() => console.log("Close modal")}>Lipsum</Modal>
 */
const Modal = (props) => {
  const { children, showClose, onClose } = props;
  const modalRoot = document.querySelector('#modal');
  const appRoot = document.querySelector('#root');

  const backdropAnim = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  useEffect(() => {
    // componentDidMount
    appRoot.classList.add('modalBg');

    // componentDidUnmount
    return () => {
      appRoot.classList.remove('modalBg');
    };
  });

  return ReactDOM.createPortal(
    <Backdrop onClick={onClose} style={backdropAnim}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Content>
          {children && <Main> {children} </Main>}
          {showClose && (
            <CloseButton onClick={onClose}>
              <Icon icon='close' />
            </CloseButton>
          )}
        </Content>
      </Wrapper>
    </Backdrop>,
    modalRoot
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  footer: PropTypes.shape({}),
  showClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  onClose() {
    // eslint-disable-next-line no-console
    console.log('You should handle onClose with a callback function...');
  },
  showClose: true,
};

export default Modal;
