import styled from 'styled-components';

// export const Content = styled.section`
//   @media (max-width: ${({ theme }) => theme.md}) {
//     width: 500px;
//   }
// `;

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;
  & h2,
  p {
    color: ${({ theme }) => theme.grayDark};
  }
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & button {
    width: 50%;
  }
  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const PinEvent = styled.div`
  margin-bottom: 16px;

  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
  }
`;

export const CalendarIcon = styled.div`
  i {
    top: 47px;
    right: 35px;
    float: right;
  }
`;
export const Display = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:not(:first-child) {
    margin-left: 20px;
  }

  textarea {
    margin-right: 36px;
  }
`;
