import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Actions, initialState, InitialStateType, reducer } from '../reducers/reviewLists4';

const ReviewLists4Context = createContext<{
  reviewLists4State: InitialStateType;
  reviewLists4Dispatch: Dispatch<Actions>;
}>({
  reviewLists4State: initialState,
  reviewLists4Dispatch: () => null,
});

const ReviewLists4Provider: FC<PropsWithChildren> = ({ children }) => {
  const [reviewLists4State, reviewLists4Dispatch] = useReducer(reducer, initialState);
  return (
    <ReviewLists4Context.Provider value={{ reviewLists4State, reviewLists4Dispatch }}>
      {children}
    </ReviewLists4Context.Provider>
  );
};
export { ReviewLists4Context, ReviewLists4Provider };
