/* eslint-disable */
import axios from 'axios';
import { ENV, NODE_API, PLATFORM_ENVIRONMENT } from 'config';
import AuthService from 'services/auth-service';

/**
 * Axios NODE_API Instance
 */
const node_api = (configParams?: { url?: string; contentType?: string }) => {
  const contentType = configParams?.contentType || 'application/json';
  const baseURL = configParams?.url || NODE_API.legacy_url || '';
  const customAxios = axios.create({
    baseURL,
    headers: {
      Accept: `${contentType}`,
      'Content-Type': `${contentType}`,
    },
    mode: 'cors',
  } as any);

  customAxios.interceptors.response.use((response) => response);

  customAxios.interceptors.request.use((config) => {
    const { Authorization } = AuthService.getAuth() || {};
    let { data } = config;

    let params = { env: ENV, 'fe-env': PLATFORM_ENVIRONMENT, authToken: undefined };
    if (Authorization?.token) {
      config.headers.Authorization = `Bearer ${Authorization.token}`;

      if (config.data && Object.prototype.hasOwnProperty.call(config.data, 'auth_token')) {
        data = {
          ...config.data,
          authToken: config.data.auth_token.replace('AUTH_TOKEN', Authorization.token),
          env: ENV,
        };
      }

      if (config.params) {
        params = { ...params, ...config.params };
        if (Object.prototype.hasOwnProperty.call(config.params, 'auth_token')) {
          params = {
            ...params,
            authToken: config.params.auth_token.replace('AUTH_TOKEN', Authorization.token),
          };
        }
      }
    }

    return {
      ...config,
      data,
      params,
      url:
        Authorization && config.url
          ? config.url
              .replace('AUTH_TOKEN', Authorization.token)
              .replace('AUTH_ID', `${Authorization.id}`)
          : config.url,
    };
  });
  return customAxios;
};

export default node_api;
