import { NODE_API } from 'config';
import moment from 'moment';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  TypeformAnswerTypes,
  TypeformDigestedRecordContent,
  TypeformEventTypes,
  TypeformWebhookEvent,
} from './HealthRecordsClient.pvq.types';
import {
  RecordReport,
  RecordReportResponse,
  RetrieveRecordsResponse,
  VitalsResponse,
} from './HealthRecordsClient.types';

const HEALTH_RECORDS_MANUAL_PVQ_SOURCE = 'provider-app_manual-pvq';
export const EXTERNAL_URL_FIELD_REF = 'manual_form-question-0000-external-pvq-url';

export class HealthRecordsClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.healthRecords);
  }

  public async getLatestEntry<T>(memberId: number, type: string): Promise<T | null> {
    const { data } = await this.axiosClient.get<RetrieveRecordsResponse<T>>(
      `/v1/members/${memberId}/types/${type}/latest`
    );
    return data.records[0]?.recordedContent;
  }

  public getLatestPvqEntry = (memberId: number) =>
    this.getLatestEntry<TypeformDigestedRecordContent>(memberId, TypeformEventTypes.FormSubmitted);

  public async markPvqAsComplete(
    memberId: number,
    params?: {
      externalUrl?: string;
    }
  ): Promise<void> {
    const manualFormResponse: TypeformWebhookEvent['form_response'] = {
      form_id: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
      token: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
      landed_at: new Date().toISOString(),
      submitted_at: new Date().toISOString(),
      hidden: {
        email: '',
        first_name: '',
        id: memberId.toString(),
        last_name: '',
        phone: '',
      },
      definition: {
        id: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
        ref: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
        title: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
        fields: params?.externalUrl
          ? [
              {
                id: EXTERNAL_URL_FIELD_REF,
                ref: EXTERNAL_URL_FIELD_REF,
                type: TypeformAnswerTypes.Url,
                title: 'External URL',
              },
            ]
          : [],
      },
      answers: params?.externalUrl
        ? [
            {
              field: {
                id: EXTERNAL_URL_FIELD_REF,
                ref: EXTERNAL_URL_FIELD_REF,
                type: TypeformAnswerTypes.Url,
              },
              type: TypeformAnswerTypes.Url,
              url: params.externalUrl,
            },
          ]
        : [],
    };
    const manualEvent: TypeformWebhookEvent = {
      event_id: `${HEALTH_RECORDS_MANUAL_PVQ_SOURCE}-${Date.now()}`,
      event_type: HEALTH_RECORDS_MANUAL_PVQ_SOURCE,
      form_response: manualFormResponse,
    };

    await this.axiosClient.post<void>(
      `/v1/sources/${HEALTH_RECORDS_MANUAL_PVQ_SOURCE}/types/${TypeformEventTypes.FormSubmitted}/events`,
      manualEvent
    );
  }

  public async getMemberBodyCompositionReport(memberId: string): Promise<RecordReport[] | null> {
    const currentDate = new Date();
    // TODO: Improve this using correctly moment library
    const formattedCurrentDate = moment(currentDate).format('YYYY-MM-DD');

    const fromDate = new Date();
    fromDate.setFullYear(fromDate.getFullYear() - 6);
    const formattedFromDate = moment(fromDate).format('YYYY-MM-DD');

    const { data } = await this.axiosClient.get<RecordReportResponse>(
      `/v1/members/${memberId}/types/body-composition-report?fromDate=${formattedFromDate}&toDate=${formattedCurrentDate}`
    );

    if (!data.success) {
      return null;
    }

    return data.records;
  }

  public async getMemberVitals(memberId: number | string): Promise<VitalsResponse['vitals']> {
    const response = (await this.axiosClient.get<VitalsResponse>(`/v1/members/${memberId}/vitals`))
      .data;

    const { vitals } = response;

    return vitals;
  }
}
