import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 490px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0px 20px;
  width: 100%;
  max-height: 448px;

  .react-datepicker {
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: 1.1rem;
  }

  .react-datepicker__current-month {
    font-size: 1.3rem;
  }

  .react-datepicker__day-name {
    margin: 0.8rem;
  }

  .react-datepicker__day {
    line-height: 1.8rem;
    margin: 0.8rem;
  }

  .react-datepicker__day--selected {
    background: transparent;
    border-radius: 8px;
  }

  .react-datepicker__day--highlighted-red {
    background: ${({ theme }) => theme.salmon};
    border-radius: 8px;
  }

  .react-datepicker__day--highlighted-green {
    background: ${({ theme }) => theme.tealLight};
    border-radius: 8px;
  }

  .react-datepicker__day--highlighted-black {
    background: ${({ theme }) => theme.gray};
    border-radius: 7px;
  }
`;

export const DayCards = styled.div`
  width: calc(100% - 420px);
  text-align: start;
  padding: 15px 10px;
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.turquoise};
  overflow-y: auto;
`;

export const CardStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const CardTitle = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.turquoise};
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: ${({ theme }) => theme.teal};
  cursor: pointer;
`;

export const Filter = styled.div`
  margin-bottom: 20px;
  margin-left: 10px;
`;

export const CardOwner = styled.div`
  color: ${({ theme }) => theme.gray};
  font: 600 12px ${({ theme }) => theme.primaryFont};
  margin: 0 46px -9px;
`;
