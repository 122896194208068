import { createTheme } from '@mui/material/styles';

const Theme = {
  teal: '#1A667A',
  tealLight: '#0091A7',
  turquoise: '#DCF0EF',
  turquoiseDark: '#A8D9D6',
  salmon: '#F37962',
  salmonDark: '#DB6C57',
  red: '#FF1A00',
  cinnabar: '#E25035',
  linen: '#FFEBE7',
  grey: '#4b4846',
  lightWarning: '#fbf5e5',
  warning: '#e4d16b',
  green: '#0F921E',

  mainBackground: '#fcfcfe',
  railsBackground: '#eceff1',
  navBackground: '#eeeeee',
  calendarBackground: '#cbcbcb',
  inputBackground: '#f1f3f4',
  bubbleBackground: '#bbbbbb',

  white: '#fff',

  grayLighter: '#f1f3f4',
  grayLight: '#DDDDDD',
  gray: '#85868D',
  grayDark: '#60667f',
  grayDarker: '#323648',
  black: '#000',

  series: {
    weights: '#1A667A',
    programs: '#F37962',
    events: '#323648',
    appointments: '#4A68B1',
    steps: '#1A667A',
    systolic: '#CCDB2A',
    diastolic: '#1A667A',
    heartrate_min: '#1A667A',
    heartrate_average: '#CCDB2A',
    heartrate_max: '#4A68B1',
    glucose: '#1A667A',
    blue: '#6AC6F0',
    blueDark: '#0F709D',
    red: '#FF1A00',
    yellow: '#FBFD00',
    green: '#0F921E',
    tealLight: '#0091A7',
    turquoise: '#DCF0EF',
    purple: '#775DD0',
  },
  dashboard: {
    gray: '#D9D9D9',
  },

  error: '#cc0000',

  fonts: "'Proxima Nova Rg', 'Proxima Nova', 'Source Serif Pro'",
  basePrimaryFont: "'Proxima Nova', 'Proxima Nova Rg'",
  primaryFont: "'Proxima Nova Rg'",
  secondaryFont: "'Source Serif Pro'",

  // Breakpoints
  xs: '0px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

export const MuiTheme = createTheme({
  overrides: {
    // Calendar
    MuiPickersBasePicker: {
      pickerView: {
        margin: '0 auto',
        maxWidth: '350px',
        minWidth: '150px',
        minHeight: '150px',
        width: '-webkit-fill-available',
      },
    },

    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: '100px',
      },
    },

    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: '225px',

        '@media (max-width:300px)': {
          minHeight: '180px',
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Theme.teal,
      },
    },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        margin: 0,
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '0 20px',
      },
      transitionContainer: {
        // color: StyledTheme.gray,
        color: Theme.teal,
        font: '600 16px "Proxima Nova Rg"',
      },
      daysHeader: {
        backgroundColor: Theme.teal,
        borderRadius: '10px',
        margin: '0 20px',
        padding: '20px',
      },
      dayLabel: {
        color: Theme.white,
        font: '600 15px "Proxima Nova Rg"',

        '@media (max-width:400px)': {
          font: '500 13px "Proxima Nova Rg"',
        },
      },
    },

    MuiPickersDay: {
      day: {
        borderRadius: '6px',

        '@media (max-width:370px)': {
          width: '10vw',
          height: '10vw',
        },
      },
      daySelected: {
        backgroundColor: Theme.teal,

        '&:hover': {
          backgroundColor: Theme.teal,
        },
      },
      dayDisabled: {},
      current: {},
    },

    MuiPickersModal: {
      dialogAction: {
        color: 'brown',
      },
    },
  },
});

export default Theme;
