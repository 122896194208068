export enum EventNames {
  BulkActionsClickBulkMessage = 'bulk-actions: click bulk messages button',
  BulkMessageMarkAsReviewed = 'bulk-message: mark as reviewed',
  BulkMessageSendMessage = 'bulk-message: send message',
  CardDetailClickCommentButton = 'cards/card-detail: click comment button',
  CardDetailClickReviewButton = 'cards/card-detail: click review button',
  CardsChangeCardSequenceDate = 'Changed Card Sequence Date',
  CardsClickCardCategory = 'Clicked Card Category',
  CardsClickCardSubcategory = 'Clicked Card Subcategory',
  CardsClickPostCardSequence = 'Clicked Post Card Sequence',
  CardsClickStartCurriculum = 'Clicked Start Curriculum',
  CardsClickTab = 'cards: click tab',
  CardWithPendingCommentsClickCommentButton = 'cards/cards-with-pending-comments: click comment button',
  CardWithPendingCommentsClickReviewButton = 'cards/cards-with-pending-comments: click review button',
  ChatReceiveMessage = 'Received a message',
  DashboardChangeSection = 'Changed section',
  FeatureRatingClick = 'feature-rating: click',
  FeatureRatingComment = 'feature-rating: comment',
  HomeChangeSection = 'Changed section',
  HomeClickInitialDashboard = 'Clicked Initial Dashboard',
  HomeClickPerformanceInsightsDashboard = 'Clicked Performance Insights Dashboard',
  HomeAppointmentsChangeDisplayMode = 'home/appointments: change display mode',
  MemberCallJoin = 'member-call/join',
  MemberCallJoinAppointmentSummary = 'member-call/appointment-summary/video-call: join call',
  MemberCallPatient = 'member-call/member-profile/video-call: join call',
  MemberCallChatLink = 'member-call/chat/video-call: click link to join call ',
  MemberGetCallLink = 'member-call/service/video-call: creating meeting',
  MemberProfileAddWeightOpenModal = 'member-profile/add-weight: open modal',
  MemberProfileBulkDeleteWeightsDeleteWeights = 'member-profile/bulk-delete-weights: delete weights',
  MemberProfileBulkDeleteWeightsOpenModal = 'member-profile/bulk-delete-weights: open modal',
  MemberProfileBulkDeleteWeightsSelectWeight = 'member-profile/bulk-delete-weights: select weight',
  MemberProfileFromEmailClickScheduleButton = 'member-profile-from-email/calendar: click schedule button',
  MemberProfileOpen = 'member-profile/open',
  MemberScheduleViewOpenEventDetail = 'member-schedule:event-detail',
  MemberProfileNewVideoCall = 'video-call/config: update video call version',
  MetricsCompleteDashboardView = 'metrics/complete-dashboard:view',
  MetricsFilterClinicChange = 'metrics/filter/clinic:change',
  MetricsFilterPanelChange = 'metrics/filter/panel:change',
  MetricsFilterProgramChange = 'metrics/filter/program:change',
  ModalClose = 'modal/close',
  MyPanelFilterPatient = 'Filtered patient',
  MyPanelSortPatient = 'Sorted patient',
  NotificationsCheckDisplayReviewedNotifications = 'Checked display reviewed notifications',
  NotificationsReviewAll = 'Reviewed all notifications',
  NotificationsReviewOne = 'Reviewed notification',
  NotificationsUncheckDisplayReviewedNotifications = 'Unchecked display reviewed notifications',
  NavbarClickSchedulingCalendar = 'Clicked Acuity Calendar',
  PanelActionDrawerToggle = 'panel-table:drawer-toggle',
  PanelBulkDismissAttentionApply = 'panel-table:bulk-dismiss-attention:apply',
  PanelBulkDismissAttentionCancelModal = 'panel-table:bulk-dismiss-attention:cancel',
  PanelBulkDismissAttentionOpenModal = 'panel-table:bulk-dismiss-attention:open',
  PanelFiltersReset = 'panel-table:filters:reset',
  PanelFiltersSave = 'panel-table:filters:save-default',
  PanelFiltersUpdate = 'panel-table:filters:update',
  PanelKeyboardNavigation = 'panel-table:keyboard-navigation',
  PanelListSelect = 'panel-table:list-select',
  PanelSettingsCancelModal = 'panel-table:settings:cancel',
  PanelSettingsConfirm = 'panel-table:settings:save',
  PanelSettingsOpenModal = 'panel-table:settings:open',
  PanelSortUpdate = 'panel-table:sort:update',
  ProfileClickTab = 'Clicked Tab',
  ProfileCloseAddPatientModal = 'Closed add patient modal',
  ProfileCreatePatient = 'Created patient',
  RecentCardEdit = 'recent-card-edit: click edit button',
  ReviewListsClickInfoButton = 'review-lists: click info button',
  ReviewListsClickMarkAsReviewed = 'Clicked Mark as reviewed',
  ReviewListsClickReviewList = 'Clicked review list',
  ScheduleClickAppointmentType = 'Clicked Appointment Type',
  ScheduleClickAppointmentTypeNone = 'Clicked Appointment Type (None)',
  ScheduleClickScheduleButton = 'Clicked Schedule Button',
  SchedulePickProvider = 'Clicked Pick a Provider',
  ScheduleTypeAppointmentType = 'Typed Appointment Type',
  SessionLogIn = 'Login',
  SessionLogOut = 'Logout',
  WeeklyViewPreviewEvent = 'weekly-view:event-preview',
  WeeklyViewRotateTimezone = 'weekly-view:timezone-rotate',
}
