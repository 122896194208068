/* eslint-disable no-unused-expressions */
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ReactTooltip from 'react-tooltip';
import { ChartContext } from 'state/contexts/chart';
import Theme from 'styles/theme';
import { hasValues } from 'utils/helpers';
import { getTooltipContent } from '../../common';

import { ChartWrapper, Serie, SeriesWrappers, Tooltip } from '../../style';

const Charts = React.memo(() => {
  const { chartState } = useContext(ChartContext);
  const [data, setData] = useState(chartState.data);
  const [toggle, setToggle] = useState({
    programs: true,
    events: true,
    appointments: true,
    heartrate_max: true,
    heartrate_average: true,
    heartrate_min: true,
  });

  const series = [
    'programs',
    'events',
    'heartrate_min',
    'heartrate_average',
    'heartrate_max',
    'appointments',
  ];

  const renderTooltips = () => {
    const tooltips = {
      programs: [],
      events: [],
      appointments: [],
    };

    if (
      data.programs.values &&
      data.programs.values[0] &&
      data.healthkits.values.heartrate_average[0]
    ) {
      if (
        moment(data.healthkits.values.heartrate_average[0].date).format('YYYY-MM-DD') >
        moment(data.programs.values[0].start_date).format('YYYY-MM-DD')
      ) {
        if (
          moment(data.healthkits.values.heartrate_max[0].date).format('YYYY-MM-DD') >
          moment(data.programs.values[0].start_date).format('YYYY-MM-DD')
        ) {
          if (
            moment(data.healthkits.values.heartrate_min[0].date).format('YYYY-MM-DD') >
            moment(data.programs.values[0].start_date).format('YYYY-MM-DD')
          ) {
            const dummyPoint = {
              date: data.programs.values[0].start_date,
              value: data.healthkits.values.heartrate_min[0].value,
            };
            data.healthkits.values.heartrate_min.unshift(dummyPoint);
          }
          const dummyPoint = {
            date: data.programs.values[0].start_date,
            value: data.healthkits.values.heartrate_max[0].value,
          };
          data.healthkits.values.heartrate_max.unshift(dummyPoint);
        }
        const dummyPoint = {
          date: data.programs.values[0].start_date,
          value: data.healthkits.values.heartrate_average[0].value,
        };
        data.healthkits.values.heartrate_average.unshift(dummyPoint);
      }

      const programs_start_dates = [];
      for (const i of data.programs.values) i.start_date && programs_start_dates.push(i.start_date);

      const temp_1 = [];
      const temp_2 = [];
      const temp_3 = [];

      for (const i of data.healthkits.values.heartrate_average)
        (i.value || (i.value === 0 && programs_start_dates.includes(i.date))) && temp_1.push(i); // copy each non-empty value to the 'temp' array

      data.healthkits.values.heartrate_average = temp_1;

      for (const i of data.healthkits.values.heartrate_min)
        (i.value || (i.value === 0 && programs_start_dates.includes(i.date))) && temp_2.push(i); // copy each non-empty value to the 'temp' array

      data.healthkits.values.heartrate_min = temp_2;

      for (const i of data.healthkits.values.heartrate_max)
        (i.value || (i.value === 0 && programs_start_dates.includes(i.date))) && temp_3.push(i); // copy each non-empty value to the 'temp' array

      data.healthkits.values.heartrate_max = temp_3;
    }

    series.forEach((serie) => {
      if (
        !serie.startsWith('heartrate_') &&
        data[serie] &&
        data[serie].values &&
        hasValues(data[serie].values)
      ) {
        tooltips[serie] = data[serie].values.map((annotation, i) => (
          <ReactTooltip
            id={`${serie}-${i}`}
            key={`${serie}-${annotation.start_date}`}
            delayHide={200}
            className='custom'
            effect='solid'
            clickable>
            <Tooltip type={serie}>{getTooltipContent(serie, annotation)}</Tooltip>
          </ReactTooltip>
        ));
      }
    });

    return [...tooltips.programs, ...tooltips.events, ...tooltips.appointments];
  };

  const addTooltipAttributes = (timeout) => {
    setTimeout(() => {
      series.forEach((serie) => {
        if (
          !serie.startsWith('heartrate_') &&
          data[serie] &&
          data[serie].values &&
          hasValues(data[serie].values)
        ) {
          data[serie].values.forEach((annotation, i) => {
            const a = document.getElementsByClassName(`${serie}-${i}-data`)[0];
            if (a) {
              a.setAttribute('data-for', `${serie}-${i}`);
              a.setAttribute('data-tip', '');
            }
          });
        }
      });
      ReactTooltip.rebuild();
    }, timeout);
  };

  const getAnottations = () => {
    const annotattions = {
      programs: [],
      events: [],
      appointments: [],
    };

    series.forEach((serie) => {
      if (
        !serie.startsWith('heartrate_') &&
        data[serie] &&
        data[serie].values &&
        hasValues(data[serie].values) &&
        toggle[serie]
      ) {
        annotattions[serie] = data[serie].values.map((annotation, i) => ({
          x: new Date(moment(annotation.start_date).format()).getTime(),
          borderColor: Theme.series[serie],
          label: {
            borderColor: Theme.series[serie],
            style: {
              color: Theme.white,
              background: Theme.series[serie],
              cssClass: `apexchart-annotations apexcharts-yaxis-annotation-${serie} ${serie}-${i}-data`,
            },
            text: annotation.value ? annotation.value : annotation.name,
          },
        }));
      }
    });

    return [...annotattions.programs, ...annotattions.events, ...annotattions.appointments];
  };

  useEffect(() => {
    setData(chartState.data);
  }, [chartState.data]);

  return (
    <>
      <ChartWrapper>
        {renderTooltips()}

        <Chart
          options={{
            chart: {
              fontFamily: Theme.primaryFont,
              stacked: false,
              animations: {
                enabled: true,
              },
              zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true,
              },
              toolbar: {
                autoSelected: 'zoom',
              },
              events: {
                mounted() {
                  addTooltipAttributes(1000);
                },
                zoomed() {
                  addTooltipAttributes(0);
                },
                updated() {
                  addTooltipAttributes(0);
                },
              },
            },
            plotOptions: {
              line: {
                curve: 'smooth',
              },
            },
            stroke: {
              width: [2],
              curve: 'smooth',
            },
            colors: [
              Theme.series.heartrate_min,
              Theme.series.heartrate_average,
              Theme.series.heartrate_max,
            ],
            legend: {
              show: false,
            },
            markers: {
              size: [2],
              strokeWidth: 0,
              hover: {
                sizeOffset: 0,
              },
            },
            xaxis: {
              type: 'datetime',
              labels: {
                formatter: (val) => moment(val).format('YYYY-MM-DD'),
              },
            },
            yaxis: {
              min: 0,
              max: 200,
              labels: {
                // formatter: (val) => `${val}`
                formatter: (val) => `${val.toFixed(0)}`,
              },
            },
            annotations: {
              xaxis: getAnottations(),
            },
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 410,
              offsetY: 410,
            },
          }}
          series={[
            {
              name: 'heartrate_min',
              type: 'line',
              data: data.healthkits.values.heartrate_min.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
              })),
            },
            {
              name: 'heartrate_average',
              type: 'line',
              data: data.healthkits.values.heartrate_average.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
              })),
            },
            {
              name: 'heartrate_max',
              type: 'line',
              data: data.healthkits.values.heartrate_max.map((point) => ({
                x: new Date(moment(point.date).format()).getTime(),
                y: point.value,
              })),
            },
          ]}
          type='line'
          height='100%'
        />
      </ChartWrapper>

      <SeriesWrappers>
        {series.map(
          (serie, index) =>
            ((!serie.startsWith('heartrate_') &&
              data[serie] &&
              data[serie].values &&
              hasValues(data[serie].values) > 0) ||
              (serie === 'heartrate_min' && hasValues(data.healthkits.values.heartrate_min) > 0) ||
              (serie === 'heartrate_average' &&
                hasValues(data.healthkits.values.heartrate_average) > 0) ||
              (serie === 'heartrate_max' &&
                hasValues(data.healthkits.values.heartrate_max) > 0)) && (
              <Serie
                key={`${serie}-serie`}
                type={serie}
                visible={toggle[serie]}
                onClick={() =>
                  !serie.startsWith('heartrate_') &&
                  setToggle((prev) => ({
                    ...prev,
                    [serie]: !prev[serie],
                  }))
                }>
                <i className={`serie_${index}`} />
                {capitalize(serie)}
              </Serie>
            )
        )}
      </SeriesWrappers>
    </>
  );
});

Charts.propTypes = {};
Charts.defaultProps = {};

export default Charts;
