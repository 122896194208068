import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, FeaturesState, initialState, reducer } from '../reducers/features';

const FeaturesContext = createContext<{
  featuresState: FeaturesState;
  featuresDispatch: Dispatch<Action>;
}>({
  featuresState: initialState,
  featuresDispatch: () => null,
});

const FeaturesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [featuresState, featuresDispatch] = useReducer(reducer, initialState);
  return (
    <FeaturesContext.Provider value={{ featuresState, featuresDispatch }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export { FeaturesContext, FeaturesProvider };
