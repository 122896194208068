import ChatIcon from '@mui/icons-material/Chat';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import MmsIcon from '@mui/icons-material/Mms';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';
import Divider from '@mui/material/Divider';
import { GridFilterModel } from '@mui/x-data-grid';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { FC, useEffect, useState } from 'react';
import { PanelAssistant } from '../PanelAssistant';
import { PanelListType, PanelListTypeIterator, PanelRow, PanelSettings } from '../types';
import { PanelSideBarIcon } from './PanelSideBarIcon';

type Props = {
  panelRows: PanelRow[];
  selectedList: PanelListType;
  onSelectList: (list: PanelListType) => void;
  settings: PanelSettings;
  filterModel: GridFilterModel;
};

export const PanelSideBar: FC<Props> = ({
  panelRows,
  filterModel,
  settings,
  selectedList,
  onSelectList,
}) => {
  const [filteredRowsCount, setFilteredRowsCount] = useState<{ [key in PanelListType]?: number }>(
    {}
  );
  const [unfilteredRowsCount, setUnfilteredRowsCount] = useState<{
    [key in PanelListType]?: number;
  }>({});

  const showFood = settings?.activeDimensions?.food !== false;

  useEffect(() => {
    // Let's compute filtering by `filterModel` once, then filter by other lists
    const preFilteredPanelRows = PanelAssistant.filterPanelRows({
      filterModel,
      listType: 'panel',
      panelRows,
      settings,
    });

    const updatedFilteredCount = {} as { [key in PanelListType]?: number };
    const updatedUnfilteredCount = {} as { [key in PanelListType]?: number };

    // Compute filtered and unfiltered counts for each list. This could be optimized by caching these results!
    for (const list of PanelListTypeIterator) {
      // Current filtered panel, filtered by `list`
      updatedFilteredCount[list] = PanelAssistant.filterPanelRows({
        filterModel: { items: [] },
        listType: list,
        panelRows: preFilteredPanelRows,
        settings,
      }).length;
      // Entire panel, filtered by `list`
      updatedUnfilteredCount[list] = PanelAssistant.filterPanelRows({
        filterModel: { items: [] },
        listType: list,
        panelRows,
        settings,
      }).length;
    }
    setFilteredRowsCount(updatedFilteredCount);
    setUnfilteredRowsCount(updatedUnfilteredCount);
  }, [settings, panelRows, filterModel]);

  const renderIcon = (list: PanelListType, title: string, icon: JSX.Element) => {
    // If there's no data for the list, disable the icon (unless it's the entire panel list)
    const disabled =
      list !== 'panel' && filteredRowsCount[list] === 0 && unfilteredRowsCount[list] === 0;

    // If the filtered rows > 0, show badge with filtered count, otherwise show badge with unfiltered count (and show grayed out)
    const badgeCount =
      list !== 'panel' ? filteredRowsCount[list] || unfilteredRowsCount[list] || 0 : 0;
    const badgeColor = filteredRowsCount[list] ? 'secondary' : 'info';
    // If there are no filtered rows, show info tooltip with the number of unfiltered rows hidden by filters
    const tooltipInfo = filteredRowsCount[list]
      ? undefined
      : unfilteredRowsCount[list]
      ? `${unfilteredRowsCount[list]} hidden by filters`
      : undefined;

    return (
      <PanelSideBarIcon
        list={list}
        disabled={disabled}
        // eslint-disable-next-line dot-notation
        badgeCount={badgeCount}
        badgeColor={badgeColor}
        info={tooltipInfo}
        icon={icon}
        title={title}
        selectedList={selectedList}
        onSelectList={onSelectList}
      />
    );
  };

  return (
    <div className='panel-sidebar'>
      {renderIcon('panel', 'My Panel', <PeopleIcon />)}
      {renderIcon('any-attention', 'Need Attention', <NotificationsIcon />)}

      <Divider />

      {renderIcon(AttentionDimension.MemberChat, 'Member Chat', <ChatIcon />)}
      {renderIcon(AttentionDimension.TeamChat, 'Team Chat', <Diversity3Icon />)}

      <Divider />

      {renderIcon(AttentionDimension.Weights, 'Weights', <MonitorWeightIcon />)}
      {renderIcon(AttentionDimension.Vitals, 'Vitals & Labs', <MonitorHeartIcon />)}

      {showFood ? (
        <>
          <Divider />
          {renderIcon(AttentionDimension.Food, 'Food Pictures', <DinnerDiningIcon />)}
          {renderIcon(AttentionDimension.FoodComments, 'Food Comments', <MmsIcon />)}
        </>
      ) : null}
    </div>
  );
};
