// .App {
//   font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
//     monospace;
//   text-align: center;
//   height: 500px;
// }

import styled from 'styled-components';

export const FiltersActions = styled.div`
  padding: 20px;
  border: 2px solid ${({ theme }) => theme.gray};
  border-radius: 8px;
  display: ${(props) => (props.showFilters ? 'default' : 'none')};
`;

export const FilterTitleContainer = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    padding: 4px;
    top: 11px;
  }
`;

export const FilterTitle = styled.div`
  color: ${({ theme }) => theme.grayDarker};
  font: 22px/24px ${({ theme }) => theme.primaryFont};
  margin: 10px 10px 10px 0;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Separator = styled.div`
  width: 10px;
`;
export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  & button {
    width: 180px;
  }

  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const Record = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
`;

export const Item = styled.div``;

export const Data = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
`;
export const Treemap = styled.div`
  text-align: center;
  height: 540px;
  width: 70%;
`;
export const Lifespan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 540px;
  width: 30%;
  color: ${({ theme }) => theme.grayDarker};
  font: 40px/46px ${({ theme }) => theme.primaryFont};
`;
