import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { MixpanelFeedbackClick } from 'components/feature-feedback/MixpanelFeedback';
import { HealthRecordsClient } from 'node-api/health-records/HealthRecordsClient';
import { DrChronoMedicationHistoryRecordContent } from 'node-api/health-records/HealthRecordsClient.medication-history.types';
import { FC, useEffect, useRef, useState } from 'react';

export const MedicationHistoryChart: FC<{
  patientId: number;
  visible: boolean;
}> = ({ patientId, visible }) => {
  const [status, setStatus] = useState<'pending' | 'not-found' | 'error' | 'success'>('pending');
  const [medicationHistory, setMedicationHistory] = useState<
    DrChronoMedicationHistoryRecordContent['medicationHistory']
  >([]);

  const [filters, setFilters] = useState({
    status: 'active',
  });

  const healthRecordsClient = useRef(new HealthRecordsClient());

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchMedicationHistory = async () => {
      try {
        const medicationHistoryData =
          await healthRecordsClient.current.getLatestEntry<DrChronoMedicationHistoryRecordContent>(
            patientId,
            'ehr-medication-history'
          );
        if (medicationHistoryData) {
          setMedicationHistory(
            medicationHistoryData.medicationHistory.sort((a, b) => {
              return (
                new Date(b.dateStartedTaking).getTime() - new Date(a.dateStartedTaking).getTime()
              );
            })
          );
          setStatus('success');
        } else {
          setStatus('not-found');
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          setStatus('error');
        } else {
          setStatus('pending');
        }
      }
    };
    fetchMedicationHistory();

    return () => {
      source.cancel();
    };
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <>
      <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box sx={{ fontWeight: 'bold', m: 1 }}>Filters</Box>
        <FormControlLabel
          style={{ margin: 1 }}
          control={
            <Switch
              checked={filters.status === 'active'}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.checked ? 'active' : 'all' })
              }
              size='small'
            />
          }
          label={filters.status === 'active' ? 'Active' : 'All'}
        />
        <div style={{ flexGrow: 1 }} />
        <MixpanelFeedbackClick label='Feedback' source='chart-medication_history' openForm />
      </FormGroup>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Medication</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                Prescribed
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                Started
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                Stopped
              </TableCell>
              {filters.status !== 'active' && (
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Status
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {status === 'success' &&
              medicationHistory
                .filter((entry) => (filters.status === 'active' ? entry.status === 'active' : true))
                .map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component='th' scope='row'>
                      {row.medicationDescription}
                    </TableCell>
                    <TableCell align='right'>{row.datePrescribed}</TableCell>
                    <TableCell align='right'>{row.dateStartedTaking}</TableCell>
                    <TableCell align='right'>{row.dateStoppedTaking}</TableCell>
                    {filters.status !== 'active' && (
                      <TableCell align='right'>{row.status}</TableCell>
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ fontWeight: 'bold', m: 1 }}>Edit in EHR – Direct links coming soon.</Box>
    </>
  );
};
