export function convertGramsToLbs(grams: number) {
  return grams * 0.00220462;
}

export function convertLbsToKgs(lbs: number) {
  return lbs * (1 / 2.20462);
}

export function convertFeetNInchesToCms(feet: number, inches: number) {
  return feet * 30.48 + inches * 2.54;
}

export function convertFeetNInchesToMeters(feet: number, inches: number) {
  return convertFeetNInchesToCms(feet, inches) / 100;
}

export function calculateBmi(weightInKg: number, heightInMeters: number) {
  return weightInKg / heightInMeters ** 2;
}
