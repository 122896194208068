import styled from 'styled-components';

export const Container = styled.div`
  // background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 4px;
  min-height: 200px;
  padding: 5px 16px 0px;
`;

export const ChatBox = styled.div`
  height: calc(100vh - 286px);
  //max-height 350px;
  min-height 350px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 6px;
  padding-top: 6px;

  ::-webkit-scrollbar {
    width: 10px;
  };
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.grayLighter}; 
  };
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.grayLight};
  };
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.turquoise};
  };
`;

export const CheckSelector = styled.div`
  display: inline-block;
  margin-top: 10px;
`;

export const CheckInput = styled.div`
  color: ${({ theme }) => theme.gray};
  display: inline-block;
  font: 600 15px ${({ theme }) => theme.primaryFont};
  margin-left: 10px;
`;
