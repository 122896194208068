import { CircularProgress, Tooltip } from '@mui/material';
import api from 'api';
import { Button, Modal } from 'components/elements';
import { INFO_ICON } from 'components/icon/unicode';
import { ChangeEvent, FC, useContext, useState } from 'react';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { useBulkActions } from 'state/contexts/bulkActions/context';
import { SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY } from 'state/contexts/bulkActions/types';
import { UserContext } from 'state/contexts/user';
import { getOriginalUserFullName } from 'utils/helpers';
import { ReactComponent as Send } from '../../icon/svgs/send.svg';
import { BULK_SENDING_CHUNKS } from '../bulk-types';
import './bulk-message-view.css';

enum TemplateTypes {
  FirstName = '{first-name}',
  Program = '{program}',
}

const PERSONALIZE_MESSAGE_TOOLTIP = `Quickly personalize your bulk message with tags: E.g. {first-name}.
  Click a tag to add it, and it'll auto-fill when sending.`;

const BulkMessageView: FC = () => {
  const [message, setMessage] = useState('');
  const [chatMessage, setChatMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { userState } = useContext(UserContext);

  const {
    bulkActionsState: { selectedPatients },
    dispatchBulkActions,
  } = useBulkActions();

  const handleSendMessage = async () => {
    const parsedMessage = message.trim();

    if (parsedMessage.length === 0) {
      return;
    }

    setChatMessage(parsedMessage);

    setShowConfirmModal(true);
  };

  const handleSendBulkMessages = async () => {
    setIsSending(true);

    setMessage('');

    const originalUserFullName = getOriginalUserFullName();

    // Let's send messages in chunks of 10
    let pivot = 0;
    while (pivot < selectedPatients.length) {
      const selectedMembersChunk = selectedPatients.slice(pivot, pivot + BULK_SENDING_CHUNKS);

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        selectedMembersChunk.map((member) => {
          const msg = chatMessage.replaceAll(`${TemplateTypes.FirstName}`, member.first_name);

          return api().post(`messages?chat_id=${member.uuid}&auth_token=AUTH_TOKEN`, {
            content: msg,
            ...(userState.isOriginalUser === false &&
              originalUserFullName !== null && { original_user: originalUserFullName }),
          });
        })
      );

      pivot += BULK_SENDING_CHUNKS;
    }

    setIsSending(false);
    setShowConfirmModal(false);

    dispatchBulkActions({ type: SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY, payload: true });

    MixpanelWrapperInstance.track(EventNames.BulkMessageSendMessage, {
      targetLabel: 'send messages',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'confirmation modal',
      'member-ids': selectedPatients.map((member) => Number(member.id)),
    });
  };

  const handleAddTemplateValue = (template: TemplateTypes) => () => {
    if (!message) {
      return;
    }

    setMessage(`${message} ${template}`.split('  ').join(' '));
  };

  const renderPersonalizedOption = () => {
    return (
      <div className='bulk-message-template'>
        <div className='bulk-message-template-title-container'>
          <span>Personalize your message </span>
          <Tooltip title={PERSONALIZE_MESSAGE_TOOLTIP} placement='right-end'>
            <span style={{ cursor: 'pointer' }}>{String.fromCodePoint(INFO_ICON)} </span>
          </Tooltip>
        </div>
        <div className='bulk-message-pill-container'>
          <span
            aria-hidden='true'
            className='bulk-message-template-pill'
            onClick={handleAddTemplateValue(TemplateTypes.FirstName)}>
            {TemplateTypes.FirstName}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className='bulk-message-container'>
      <span className='bulk-title'>
        {' '}
        {selectedPatients.length > 0
          ? `Send private message to ${selectedPatients.length} members`
          : 'No members selected'}
      </span>
      <div className='bulk-message-chat-container'>
        <div className='bulk-message-chat'>
          <span className='bulk-message-bubble' style={chatMessage !== '' ? { padding: 10 } : {}}>
            {chatMessage}
          </span>
        </div>
        <div className='bulk-text-input-container'>
          <textarea
            className='bulk-message-input'
            value={message}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
          />
          <button
            type='button'
            aria-label='send bulk message'
            className={
              selectedPatients.length === 0 || message === ''
                ? 'bulk-message-input-button-disabled'
                : 'bulk-message-input-button'
            }
            disabled={selectedPatients.length === 0 || message === ''}
            onClick={handleSendMessage}>
            <Send className='bulk-message-input-button-icon' />
          </button>
        </div>
      </div>

      {renderPersonalizedOption()}

      {showConfirmModal && (
        <Modal
          onClose={() => setShowConfirmModal(false)}
          size='small'
          customClass='bulk-message-modal'
          showClose={false}
          showSmallCloseBtn>
          <div>
            <h2>Confirm</h2>
            <p>
              You are about to send private messages to {selectedPatients.length}{' '}
              {selectedPatients.length > 1 ? 'members' : 'member'}
            </p>
          </div>
          <div className='bulk-reviewed-action'>
            <Button
              variant='link'
              onClick={() => {
                setChatMessage('');
                setShowConfirmModal(false);
              }}>
              Back to edit
            </Button>

            <Button type='submit' onClick={handleSendBulkMessages} disabled={isSending}>
              Send messages{' '}
              {isSending && (
                <CircularProgress style={{ color: '#ffffff', marginLeft: 5 }} size={15} />
              )}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BulkMessageView;
