import { StyledComponentsDivProps, StyledComponentsTextareaProps } from 'components/general-styles';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export const Container = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  height: 88%;
  width: 108%;
  margin: 0px 0px 0px -10px;
  background-color: ${({ theme }) => theme.grayLighter};
`;

export const Body = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  float: left;
  height: 90%;
  margin-bottom: 1em;
  overflow-y: auto;
`;

export const Metadata = styled.div<StyledComponentsDivProps>`
  // color: ${({ theme }) => theme.gray};
  // font: 600 14px ${({ theme }) => theme.primaryFont};
  // margin-top: 4px;
  // margin-bottom: 8px;
`;

export const BubbleFooter = styled.div<StyledComponentsDivProps>`
  display: flex;
  color: ${({ theme }) => theme.gray};
  font: 500 14px ${({ theme }) => theme.primaryFont};
  margin: 2px 0px -30px 10px;
`;

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Sender = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 45px;
`;

export const Comment = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  margin: 0px 10px 0px 10px;
  padding: 10px;
`;

export const Name = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.grayDarker};
  font: 600 11px/10px ${({ theme }) => theme.primaryFont};
  word-break: keep-all;
`;

export const Row = styled.div<PropsWithChildren & { provider?: string }>`
  display: flex;
  margin-bottom: 32px;
  word-break: break-word;

  ${(props) =>
    props.provider === 'true'
      ? css`
          ${Comment} {
            background-color: ${({ theme }) => theme.teal};
            color: ${({ theme }) => theme.white};
          }
          ${Name} {
            margin: 5px 0px 5px 245px;
          }
          ${BubbleFooter} {
            justify-content: flex-end;
            margin-right: 10px;
          }
        `
      : css`
          ${Comment} {
            background-color: ${({ theme }) => theme.grayLight};
            color: ${({ theme }) => theme.grayDarker};
          }
          ${Name} {
            margin: 5px 0px 5px 15px;
          }
          ${BubbleFooter} {
            // justify-content: flex-start;
            margin: 5px 0px 5px 15px;
          }
        `}
`;

export const Footer = styled.div<StyledComponentsDivProps>`
  display: flex;
  align-items: flex-end;
`;

export const Input = styled.textarea<StyledComponentsTextareaProps>`
  border-radius: 4px;
  // border:none;
  line-height: 19px;
  margin-bottom: 5px 0px;
  padding: 10px;
  resize: none;
  width: 70%;
  background-color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  margin: 5px;
`;

export const SendButton = styled.button<
  PropsWithChildren &
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>`
  background-color: ${({ theme }) => theme.teal};
  border-radius: 4px;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  height: 25px;
  text-align: center;
  margin: 3px 0px 5px 0px;

  &:disabled {
    background: ${({ theme }) => theme.gray};
    cursor: auto;
  }
`;
