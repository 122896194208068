import api from 'api';
import { CancelToken } from 'axios';
import moment from 'moment';
import { ILegacyMyPanelResponseEntry, ILegacyPatientsResponse } from './MyPanelLegacyClient.types';

export class MyPanelLegacyClient {
  async getMyPanel(cancelToken: CancelToken): Promise<ILegacyMyPanelResponseEntry[]> {
    const { data } = await api().get<ILegacyPatientsResponse>('/patients?auth_token=AUTH_TOKEN', {
      cancelToken,
    });

    const myPanel: ILegacyMyPanelResponseEntry[] = data.patients.map((patient) => ({
      ...patient,
      program_start_days_ago: MyPanelLegacyClient.daysCountSince(patient.program_start_date),
      current_program: patient.current_program ? patient.current_program : '',
      full_name: `${patient.first_name} ${patient.last_name}`,
      avatar: patient.avatar ? { thumb: { url: patient.avatar } } : undefined,
    }));

    return myPanel;
  }

  static daysCountSince(start: string) {
    const day = moment();
    return start ? day.diff(start, 'days') : 0;
  }
}
