import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const Input = styled.textarea`
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  display: block;
  padding: 12px;
  padding-right: 10px;
  min-width: 400px;
  resize: ${(props) => props.resize};
  font-family: ${({ theme }) => theme.secondaryFont};
  line-height: 1.25em;
  overflow-y: scroll;
  overflow-x: hidden;
  width: ${(props) => props.width};
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.grayLighter};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.grayLight};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.turquoise};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;
