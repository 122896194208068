import React from 'react';
import { IconContent } from '../style';

const DecreaseChart = () => (
  <IconContent
    fill='currentColor'
    viewBox='0 0 41 36'
    preserveAspectRatio='xMidYMid meet'
    width='100%'>
    <g>
      <path d='M33.667 26h4.666c.369 0 .667.336.667.75v4.5c0 .414-.298.75-.667.75h-4.666c-.368 0-.667-.336-.667-.75v-4.5c0-.414.299-.75.667-.75zm.666 4.5h3.334v-3h-3.334v3zM26.667 24h4.666c.369 0 .667.326.667.727v6.546c0 .401-.298.727-.667.727h-4.666c-.368 0-.667-.326-.667-.727v-6.546c0-.401.299-.727.667-.727zm.666 6.545h3.334v-5.09h-3.334v5.09zM18.667 20h4.666c.369 0 .667.336.667.75v10.5c0 .414-.298.75-.667.75h-4.666c-.368 0-.667-.336-.667-.75v-10.5c0-.414.299-.75.667-.75zm.666 10.5h3.334v-9h-3.334v9zM10.667 16h4.666c.369 0 .667.312.667.696v14.609c0 .384-.298.695-.667.695h-4.666c-.368 0-.667-.311-.667-.695v-14.61c0-.383.299-.695.667-.695zm.666 14.609h3.334V17.392h-3.334v13.217zM2.667 5h4.666c.369 0 .667.327.667.73v25.54c0 .403-.298.73-.667.73H2.667C2.299 32 2 31.673 2 31.27V5.73c0-.403.299-.73.667-.73zm.666 25.54h3.334V6.46H3.333v24.08zM7.129 1.491C13.53 12.852 24.436 20.35 39.77 23.973a1 1 0 1 0 .46-1.946C25.412 18.525 14.983 11.355 8.871.509a1 1 0 0 0-1.742.982zM1 36h39a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2z' />
    </g>
  </IconContent>
);

export default DecreaseChart;
