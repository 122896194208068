import PropTypes from 'prop-types';
import { useContext } from 'react';
import useForm from 'react-hook-form';

import { ConfirmationContext } from 'state/contexts/confirmation';
import { PatientContext } from 'state/contexts/patient';

import { Button, Datepicker, Float, Modal } from 'components/elements';
import Toast from 'components/toast';
import { WeightsContext } from 'state/contexts/weights';
import { ActionTypes as ConfirmationActionTypes } from 'state/reducers/confirmation';
import { ActionTypes as WeightsActionTypes } from 'state/reducers/weights';
import { debug } from 'utils/helpers';
import MembersClient from '../../../../../../../node-api/members/MembersClient';
import { Actions, Display, Header } from './style';

const ManageWeight = ({ onClose: manageWeightOnClose, weightData }) => {
  /**
   * TODO: This modal is not working -> Make it work
   */
  const { patientState } = useContext(PatientContext);
  const { confirmationDispatch } = useContext(ConfirmationContext);
  const { weightsDispatch } = useContext(WeightsContext);
  const { errors } = useForm({});
  const patient = patientState.data.find((p) => p.current);
  const patientId = patient.id;

  const weightDisplayValue = Number.parseFloat(weightData.weight.toFixed(2)).toString();

  const onYes = async (onClose, id, callBack) => {
    const memberUuid = patient.uuid;
    const membersServiceClient = new MembersClient();

    let deletedRecordId = null;
    try {
      const response = await membersServiceClient.deleteWeightInAllPlatforms(patientId, {
        healthRecordId: weightData.id,
        memberUuid,
      });
      deletedRecordId = response.data.record?.id ?? null;
    } catch (err) {
      debug('Error', err);
      Toast.show('error', 'Unable to delete the weight record');
      onClose();
      callBack();
      return;
    }

    // Then remove the record from the state
    if (deletedRecordId !== null) {
      weightsDispatch({
        type: WeightsActionTypes.DELETE_WEIGHT,
        payload: {
          weight: { id: deletedRecordId },
        },
      });
      Toast.show('success', 'Weight record deleted successfully');
    } else {
      Toast.show('success', 'Weight record deleted successfully. Please refresh the page');
    }

    onClose();
    callBack();
  };

  const onNo = async (onClose) => {
    onClose();
  };

  const handleDelete = () => {
    confirmationDispatch({
      type: ConfirmationActionTypes.SHOW,
      payload: {
        onYes,
        onNo,
        id: weightData.id,
        message: `Are you sure you want to remove the weight ${weightDisplayValue} lbs collected on ${weightData.date}?`,
        callBack: manageWeightOnClose, // to close the manage weight dialog
      },
    });
  };

  return (
    <>
      <Modal onClose={manageWeightOnClose}>
        <Header>
          <h2>Manage Weight </h2>
          <p>
            Patient : {patient.first_name} {patient.last_name}.
          </p>
        </Header>
        <Float
          errors={errors.weight_value}
          label='Weight value'
          name='weight_value'
          value={weightDisplayValue}
          setValue={() => {
            /** Do nothing */
          }}
          endAdornment='lbs'
          readOnly
          width={245}
        />
        <Display>
          <Datepicker
            label='Weight Date'
            name='weight_date'
            value={weightData.date}
            setValue={() => {
              /** Do nothing */
            }}
            readOnly
          />
        </Display>
        <Actions>
          <Button variant='link' onClick={manageWeightOnClose}>
            Cancel
          </Button>
          <Button type='button' onClick={handleDelete}>
            Delete
          </Button>
        </Actions>
      </Modal>
    </>
  );
};

ManageWeight.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ManageWeight;
