import { CORE_API, PUSHER } from 'config';
import Pusher from 'pusher-js';

class PusherService {
  constructor() {
    this.connection = {};
    this.channel = {};
  }

  connect(socket, token) {
    if (this.connection[socket]) return;

    this.connection[socket] = new Pusher(PUSHER.key, {
      app_id: PUSHER.appId,
      secret: PUSHER.secret,
      cluster: PUSHER.cluster,
      authEndpoint: `${CORE_API.url}/v1/messages/pusher_auth/${token}`,
    });
  }

  subscribe(socket, channel) {
    this.connection[socket].unsubscribe(channel);
    this.channel[socket] = undefined;
    this.channel[socket] = this.connection[socket].subscribe(channel);
  }

  bind(socket, event, callback) {
    this.channel[socket].unbind(event);
    this.channel[socket].bind(event, callback);
  }

  disconnect(socket) {
    this.channel[socket].unbind_global();
    this.connection[socket].disconnect();
    this.channel[socket] = undefined;
    this.connection[socket] = undefined;
  }
}

export default new PusherService();
