import styled from 'styled-components';

export const Record = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.grayLighter};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  // &:first-child {
  //   width: 17%;
  //   position: relative;
  //   left: -8px;
  // }
`;

export const Image = styled.div`
  display: inline;
  margin-left: auto;
  margin-right: auto;
`;
