import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 310px;
  z-index: 1000;

  button {
    justify-content: flex-start;
    padding: 0;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.grayDarker};
  font: 21px/24px ${({ theme }) => theme.primaryFont};
  margin: 0 0 10px;
`;

export const SubTitleContainer = styled.div`
  display: flex;
  button {
    padding: 4px;
    top: 7px;
  }
`;

export const SubTitle = styled.div`
  color: ${({ theme }) => theme.grayDarker};
  font: 18px/20px ${({ theme }) => theme.primaryFont};
  margin: 10px 10px 10px 0;
`;

export const LoginAsUser = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;

  & input {
    width: 100%;
  }
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 5px;
  border: none;
  height: 34px;
  width: 60px;
  padding: 0 8px;
`;

export const ButtonLoginAsUser = styled.div`
  & button {
    height: 34px;
    margin: 0px 4px;
    font-size: 14px;
    padding: 5px;
  }
`;

export const SwitchRole = styled.div`
  margin-bottom: 6px;
`;

export const Ul = styled.ul`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 8px;
}
`;

export const Li = styled.li`
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

export const BadgeContent = styled.div`
  width: 16px;
  height: 16px;
  top: 1px;
  left: 3px;
  position: relative;
`;

export const UserName = styled.div`
  width: 100%;
  padding: 3px;
  &:hover {
    background-color: ${({ theme }) => theme.grayLighter};
  }
`;

export const SwitchUserRows = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`;
