import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: auto;
`;

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.grayDark};
  font: 600 19px/22px ${({ theme }) => theme.primaryFont};
  height: 100%;

  & .slick-track {
    margin: ${(props) => (props.numberOfReviewLists > 2 ? 'auto' : 0)};
  }
  & .slick-slide {
    padding-right: 20px;
  }
`;

const CarouselArrow = css`
  display: ${(props) => (props.disabled ? 'none' : 'block')};
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  right: -50px;
  top: 0;
`;

export const Next = styled.div`
  ${CarouselArrow}
  right: -50px;
`;

export const Prev = styled.div`
  ${CarouselArrow}
  left: -50px;
`;

Container.defaultProps = {
  'data-id': 'carouselContainer',
};

Wrapper.defaultProps = {
  'data-id': 'Wrapper',
};
