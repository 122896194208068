import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Thumbnail = styled.div<StyledComponentsDivProps & { imageURL: string }>`
  height: 8em;
  width: 8em;
  color: ${({ theme }) => theme.gray};
  background-image: url(${(props) => props.imageURL ?? ''});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  border: 1px solid;
  cursor: pointer;
`;
