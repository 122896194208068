import {
  ButtonHTMLAttributes,
  HTMLAttributes,
  ImgHTMLAttributes,
  PropsWithChildren,
  TextareaHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';

export type StyledComponentsSpanProps = HTMLAttributes<HTMLSpanElement> & PropsWithChildren;
export type StyledComponentsDivProps = HTMLAttributes<HTMLDivElement> & PropsWithChildren;
export type StyledComponentsPProps = HTMLAttributes<HTMLParagraphElement> & PropsWithChildren;
export type StyledComponentsImgProps = ImgHTMLAttributes<HTMLImageElement> & PropsWithChildren;
export type StyledComponentsTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
export type StyledComponentsButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
export type StyledComponentsSectionProps = HTMLAttributes<HTMLTableSectionElement> &
  PropsWithChildren;

export const MainSectionContainer = styled.div<StyledComponentsDivProps>`
  align-items: stretch;
  display: flex;
  min-height: 60%;
  width: 100%;
`;

export const MainSection = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding-right: 10px;
  min-width: 760px;
  width: calc(67vw - 36px);
`;

export const Section = styled.div<StyledComponentsDivProps>`
  width: 100%;
  margin-bottom: 15px;
`;

export const ChartSection = styled.div<StyledComponentsDivProps>`
  width: 100%;
`;

export const PageTitleWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  button {
    padding: 0 8px 0 0;
  }
`;

export const PageHeader = styled.div<StyledComponentsDivProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageTitle = styled.h3`
  color: ${({ theme }) => theme.grayDark};
  font: 26px/25px ${({ theme }) => theme.primaryFont};
  font-weight: bold;
`;

export const SectionTitle = styled.h3<StyledComponentsSpanProps>`
  color: ${({ theme }) => theme.grayDarker};
  font: 21px/24px ${({ theme }) => theme.primaryFont};
  margin: 24px 0;
`;

export const SectionMessage = styled.div<StyledComponentsDivProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0px 0 16px;

  p {
    color: ${({ theme }) => theme.grayDark};
    margin: 0 16px 0 0;
    font: 19px/20px ${({ theme }) => theme.primaryFont};
  }
`;

export const Box = styled.section<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

export const ChartBox = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
`;

export const BoxContent = styled.div<StyledComponentsDivProps>`
  padding: 32px 24px;
`;

export const Tabs = styled.div<StyledComponentsDivProps>`
  background-color: ${({ theme }) => theme.grayLighter};
  display: flex;
  min-height: 48px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
`;

export const TabBox = styled.div<StyledComponentsDivProps>`
  padding: 0px 6px;
`;

export const SectionTabs = styled.ul`
  margin-bottom: 32px;
  display: flex;
`;

export const SectionTab = styled.li<PropsWithChildren & { selected?: boolean }>`
  border-bottom: 3px solid
    ${(props) => (props.selected ? props.theme.grayDark : props.theme.grayLight)};
  color: ${(props) => (props.selected ? props.theme.grayDark : props.theme.grayLight)};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  font: 21px/24px ${({ theme }) => theme.primaryFont};
  margin-right: 8px;
  padding-bottom: 8px;
  text-align: center;
  width: 120px;
`;

export const Divider = styled.hr`
  display: block;
  height: 1px;
  padding: 0px;
  margin: 16px 0;
  background: none;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.grayLight};
`;

export const Row = styled.div<PropsWithChildren & { spacing?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.spacing &&
    css`
      margin: ${props.spacing || 0}px;

      & > * {
        margin: ${props.spacing || 0}px;
      }
    `}
`;
