import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
}

export type Clinic = {
  id: number;
  name: string;
  zip_code: string;
  assigned: boolean;
};

export type Team = {
  id: number;
  first_name: string;
  last_name: string;
  uuid: string;
  role: string;
  clinic_id: number;
};

export type Role = {
  id: number;
  name: string;
  team: Team[];
};

export type DataType = {
  clinics: Clinic[];
  clinicsAssignedToProvider: Clinic[];
  roles: Role[];
};

export const initialState: DataType = {
  clinicsAssignedToProvider: [],
  clinics: [],
  roles: [],
};

type Payload = {
  [ActionTypes.SET]: DataType;
};

export type Action = DefaultAction<Payload>;

export const reducer: Reducer<DataType, Action> = (
  state: DataType = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
