import styled from 'styled-components';

export const CurrentProgramContent = styled.div`
  width: auto;
  padding: 6px 12px;
  margin-right: 10px;
  margin-left: 0px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.teal};

  i {
    cursor: pointer;
    top: -1px;
  }
`;

export const CurrentProgramName = styled.div`
  margin-right: 8px;
  min-width: 100px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 15px;

  & > button {
    margin-right: 15px;
  }
`;

export const Profile = styled.div`
  padding: 10px 24px 24px;
  display: flex;
`;

export const Media = styled.div`
  margin-right: 32px;
  width: 160px;

  img {
    background: ${({ theme }) => theme.turquoise};
    border-radius: 4px;
    display: block;
    height: 155px;
    margin-bottom: 16px;
    width: 162px;
  }

  button {
    width: 100%;
  }

  & :nth-child(2) {
    margin-bottom: 8px;
  }

  & :nth-child(3) {
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;

export const Ghost = styled.div`
  width: 46px;
  height: 50px;
  position: absolute;
  right: 0px;
  bottom: 0;
  margin-bottom: 0px !important;

  img {
    width: 100%;
    height: 100%;
    background: none;
  }
`;

export const Content = styled.div`
  flex-grow: 1;

  h5 {
    color: ${({ theme }) => theme.gray};
    font: 600 14px/17px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }

  h6 {
    color: ${({ theme }) => theme.gray};
    font: 400 14px/17px ${({ theme }) => theme.primaryFont};
    margin: 0px 0 4px;
  }

  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }
`;

export const Name = styled.div`
  flex: 1;

  p {
    color: ${({ theme }) => theme.grayDark};
    font: 600 24px ${({ theme }) => theme.primaryFont};
    margin: 0 0 5px;
  }

  p:last-child {
    color: ${({ theme }) => theme.grayDarker};
    font: 21px ${({ theme }) => theme.primaryFont};
  }

  img {
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
    margin-right: 6px;
  }

  span {
    color: ${({ theme }) => theme.grayDark};
  }
`;
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const ClinicName = styled.div`
  padding-left: 24px;
  span {
    font-weight: bold;
  }
`;

export const Relations = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const Providers = styled.div`
  margin-right: 14px;

  ul {
    height: 40px;
    margin: 10px 0 0;
    position: relative;
    width: 150px;
  }

  li {
    background-color: ${({ theme }) => theme.white};
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.white};
    position: absolute;

    &:nth-child(1) {
      left: 0px;
    }
    &:nth-child(2) {
      left: 20px;
    }
    &:nth-child(3) {
      left: 40px;
    }
    &:nth-child(4) {
      left: 60px;
    }
    &:nth-child(5) {
      left: 80px;
    }
    &:nth-child(6) {
      left: 105px;
    }

    &:hover {
      z-index: 2;
    }

    & > img,
    & > span,
    & > button {
      border-radius: 20px;
      display: block;
      height: 35px;
      overflow: hidden;
      text-align: center;
      width: 35px;
    }

    & > img {
      background: ${({ theme }) => theme.turquoise};
    }

    & > span {
      background: ${({ theme }) => theme.gray};
      color: ${({ theme }) => theme.white};
      font: 17px/35px ${({ theme }) => theme.primaryFont};
    }

    & > button {
      padding: 0;
      i {
        margin: 0;
      }
    }
  }
`;

export const GlucoseMonitoring = styled.div`
  margin-right: 14px;
  min-width: max-content;
`;

export const GlucoseMonitoringSwitch = styled.div`
  margin-top: 17px;
`;

export const Tags = styled.div`
  width: 100%;
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.turquoise};
  border-radius: 4px;
  height: 155px;
  margin-bottom: 16px;
  width: 162px;
`;

export const TagsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

export const Link = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.teal};
  color: ${({ theme }) => theme.teal};
  font: 17px/21px ${({ theme }) => theme.primaryFont};
  height: 20px;
  margin-top: 10px;

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.tealLight};
    color: ${({ theme }) => theme.tealLight};
    cursor: pointer;
  }
`;

export const Stats = styled.div`
  display: flex;

  div {
    flex-grow: 1;
  }
`;

// export const Status = styled.div`
//   color: ${({ theme }) => theme.teal};
//   font: 600 17px ${({ theme }) => theme.primaryFont};
//   margin-right: 10px;
//   margin-top: 20px;
//   height: 20px;
// `;

export const Item = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    position: relative;
    left: 0px;
  }
`;

export const Record = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 100%;
  }
`;

export const StatusWrapper = styled.div`
  margin-right: 10px;
  margin-left: 0px;
  border-radius: 4px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.15);
  &:hover {
    background: ${({ theme }) => theme.grayLighter};
  }
`;

export const Dob = styled.p`
  font: 500 15px/16px ${({ theme }) => theme.primaryFont} !important;

  span {
    font: 600 15px/16px ${({ theme }) => theme.primaryFont};
  }
`;

export const Email = styled.a`
  color: ${({ theme }) => theme.grayDark};
`;

export const Phone = styled.a`
  color: ${({ theme }) => theme.grayDark};
`;
