import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './style';

/**
 * Box
 *
 * @param children the innner component that should be rendered inside the Box Component.
 * @param direction the flex-direction that will be set for the components within the box.
 *
 * Example:
 * <Box direction="column">
 *  <div>This is a component wrapped with the Box component</div>
 *  <div>Th direction of the box is set as "column"</div>
 * </Box>
 */

const Box = ({ children, direction }) => <Container direction={direction}>{children}</Container>;

Box.propTypes = {
  children: PropTypes.shape({}).isRequired,
  direction: PropTypes.string,
};

Box.defaultProps = {
  direction: '',
};

export default Box;
