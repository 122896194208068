import React from 'react';
import { IconContent } from '../style';

const Bell = () => (
  <IconContent fill='currentColor' viewBox='0 0 21 24'>
    <path d='M8.178 21.726C8.178 22.983 9.217 24 10.5 24c1.283 0 2.322-1.017 2.322-2.274H8.178zm10.349-4.789v-6.651c0-3.715-2.625-6.823-6.172-7.646v-.823C12.355.811 11.527 0 10.5 0 9.473 0 8.645.811 8.645 1.817v.823c-3.547.823-6.172 3.931-6.172 7.646v6.651L0 19.36v1.211h21V19.36l-2.473-2.423z' />
  </IconContent>
);

export default Bell;
