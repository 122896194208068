import { Task } from 'lib/types';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  ToggleModal = 'toggle-modal',
  Set = 'set',
  Add = 'add',
  Update = 'update',
  Reload = 'reload',
}

export type State = {
  modal: boolean;
  reload: number;
  data: { [patientId: number]: Task[] };
};

type Payload = {
  [ActionTypes.ToggleModal]: undefined;
  [ActionTypes.Set]: { id: number; data: Task[] };
  [ActionTypes.Add]: { id: number; data: Task };
  [ActionTypes.Update]: { id: number; taskId: number; status: boolean };
  [ActionTypes.Reload]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  reload: 0,
  data: {},
};

export const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ToggleModal: {
      return { ...state, modal: !state.modal };
    }

    case ActionTypes.Set: {
      const { id, data } = action.payload;

      return { ...state, data: { ...state.data, [id]: data } };
    }

    case ActionTypes.Add: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: { ...state.data, [id]: [...(state.data[id] ? state.data[id] : []), data] },
      };
    }

    case ActionTypes.Update: {
      const { id, taskId, status } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [id]: state.data[id].map((task) => {
            if (task.id !== taskId) {
              return task;
            }

            return { ...task, status };
          }),
        },
      };
    }

    case ActionTypes.Reload: {
      return { ...state, reload: Date.now() };
    }

    default:
      throw new Error('Unexpected action');
  }
};
