import { GridFilterModel } from '@mui/x-data-grid';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { Clinic } from 'node-api/dashboard.types';
import { Program } from 'state/reducers/program';
import { PanelListType, PanelRow, PanelSettings, TimeFilter } from './types';

export class PanelAssistant {
  public static filterPanelRows({
    filterModel,
    listType,
    panelRows,
    settings,
  }: {
    filterModel: GridFilterModel;
    listType: PanelListType;
    panelRows: PanelRow[];
    settings: PanelSettings;
  }): PanelRow[] {
    const activeDimensionKeys: AttentionDimension[] = (
      Object.values(AttentionDimension) as AttentionDimension[]
    ).filter((key) => !settings?.activeDimensions || settings.activeDimensions[key] !== false);

    return panelRows.filter((row) => {
      if (listType !== 'panel') {
        if (listType === 'any-attention') {
          if (
            !activeDimensionKeys.some((activeAttentionKey) =>
              row.attention.find((rowAttention) => rowAttention.dimensionKey === activeAttentionKey)
            )
          ) {
            return false;
          }
        } else if (!row.attention.some((attention) => attention.dimensionKey === listType)) {
          return false;
        }
      }
      for (const filter of filterModel.items) {
        if (filter.field === 'clinic_name') {
          const values: Clinic[] = filter.value;

          if (values.length === 0 || values.find((c) => c.id === row.clinic?.id)) {
            // eslint-disable-next-line no-continue
            continue;
          }
          return false;
        }
        if (filter.field === 'patient_status') {
          const values: string[] =
            typeof filter.value === 'string' ? filter.value.split(',') : filter.value || [];

          if (values.length === 0 || values.includes(row.member.patient_status || '')) {
            // eslint-disable-next-line no-continue
            continue;
          }
          return false;
        }
        if (filter.field === 'current_program') {
          const values: Program[] = filter.value || [];

          if (
            values.length === 0 ||
            values.find((v) => v.display_value === row.member.current_program)
          ) {
            // eslint-disable-next-line no-continue
            continue;
          }
          return false;
        }
        if (filter.field === 'program_start_days_ago') {
          const values: TimeFilter[] = filter.value || [];

          if (
            values.length === 0 ||
            values.find(
              (v) =>
                v.fromDay <= row.member.program_start_days_ago &&
                row.member.program_start_days_ago <= v.toDay
            )
          ) {
            // eslint-disable-next-line no-continue
            continue;
          }
          return false;
        }
      }
      return true;
    });
  }
}
