import { EXTERNAL_URL_FIELD_REF } from './HealthRecordsClient';
import {
  TypeformAnswerTypes,
  TypeformDigestedAnswer,
  TypeformDigestedRecordContent,
} from './HealthRecordsClient.pvq.types';

export const getExternalUrlAnswer = (
  formResponse?: TypeformDigestedRecordContent['formResponse'] | null
) => {
  const externalUrlAnswer = formResponse?.find(
    (response) => response.field.ref === EXTERNAL_URL_FIELD_REF
  );
  const externalUrl =
    formResponse &&
    externalUrlAnswer?.answer.type === TypeformAnswerTypes.Url &&
    externalUrlAnswer.answer.url;

  return externalUrl;
};

/**
 * Format HR form responses into sections, similar to the format OnboardingAPI does.
 * For sections in format like: {section}-{'question'|'statement'}-{index}
 * it will group it within sections with common 'section' part.
 * @param formResponse
 * @returns
 */
export const getAnswersBySection = (
  formResponse?: TypeformDigestedRecordContent['formResponse']
) => {
  const sections: {
    id: number;
    name: string;
    code: string;
    questions: TypeformDigestedAnswer[];
  }[] = [];

  if (formResponse) {
    formResponse.forEach((answer) => {
      /**
       * ref format: {section}-{'question'|'statement'}-{index}
       * example: medical_history-question-1
       * */
      const sectionRefParts = answer.field.ref.split('-');

      // When ref is not in the expected format, add the answer to the last section
      if (sectionRefParts.length !== 3) {
        if (sections.length === 0) {
          sections.push({ questions: [answer], code: '', name: 'Other', id: sections.length });
        } else {
          sections[sections.length - 1].questions.push(answer);
        }
      } else {
        const sectionCode = sectionRefParts[0];
        const existingSection =
          sectionCode && sections.find((section) => section.code === sectionCode);

        if (existingSection) {
          existingSection.questions.push(answer);
        } else {
          // Split refs by underscore and merge as title case:
          const name = sectionCode
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          sections.push({
            questions: [answer],
            code: sectionCode,
            name,
            id: sections.length,
          });
        }
      }
    });
  }
  return sections;
};

export const getAnswerValue = (answer: TypeformDigestedAnswer['answer']): string => {
  switch (answer.type) {
    case TypeformAnswerTypes.Boolean:
      return answer.boolean ? 'Yes' : 'No';
    case TypeformAnswerTypes.Choice:
      return answer.choice.label;
    case TypeformAnswerTypes.Choices:
      return answer.choices.labels?.join(', ') || answer.choices.other || '';
    case TypeformAnswerTypes.Date:
      return answer.date;
    case TypeformAnswerTypes.Email:
      return answer.email;
    case TypeformAnswerTypes.FileUrl:
      return answer.file_url;
    case TypeformAnswerTypes.Number:
      return answer.number.toString();
    case TypeformAnswerTypes.Text:
      return answer.text;
    case TypeformAnswerTypes.Url:
      return answer.url;
    default:
      return '';
  }
};
