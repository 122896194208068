import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const StatsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.sm}) {
    flex-direction: column;
    height: 400px;
    width: 100px;
  }
`;

export const StatContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-around;
  margin: 36px 18px 0 0;
  padding: 18px 28px 0;
  width: 260px;

  @media (max-width: ${({ theme }) => theme.md}) {
    width: 150px;
    height: 100px;
    margin: 24px 18px 0 0;
  }

  &.bordered {
    border-left: solid 2px ${({ theme }) => theme.grayLight};
  }
`;

export const Stats = styled.div`
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 8px;
  color: ${({ theme }) => theme.gray};
  font: 18px/19px ${({ theme }) => theme.primaryFont};
  width: 60%;
  span.value {
    color: ${({ theme }) => theme.black};
    font: 600 24px/28px ${({ theme }) => theme.primaryFont};
    margin: 0 0 4px;
  }
`;

export const Icon = styled.i`
  display: flex;
  align-items: flex-start;
  margin-right: 20px;
  font-size: 48px;
  @media (max-width: ${({ theme }) => theme.md}) {
    font-size: 36px;
  }
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.salmon};
  margin-top: 5px;

  &:hover {
    color: ${({ theme }) => theme.salmonDark};
    text-decoration: underline;
  }
`;
