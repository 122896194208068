import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/patientSummary';

const PatientSummaryContext = createContext<{
  patientSummaryState: State;
  patientSummaryDispatch: Dispatch<Action>;
}>({
  patientSummaryState: initialState,
  patientSummaryDispatch: () => null,
});

const PatientSummaryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [patientSummaryState, patientSummaryDispatch] = useReducer(reducer, initialState);
  return (
    <PatientSummaryContext.Provider value={{ patientSummaryState, patientSummaryDispatch }}>
      {children}
    </PatientSummaryContext.Provider>
  );
};

export { PatientSummaryContext, PatientSummaryProvider };
