import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const ScheduleActions = styled.div<StyledComponentsDivProps>`
  margin: 0em 2em 0em 0em;
`;

export const HourRadioOption = styled.div<StyledComponentsDivProps & { selected: boolean }>`
  position: relative;
  align-items: center;
  background: ${(props) => (props.selected ? props.theme.teal : props.theme.white)};
  border-radius: 10px;
  border: 2px solid ${(props) => (props.selected ? props.theme.teal : props.theme.white)};
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
  color: ${(props) => (props.selected ? props.theme.white : props.theme.teal)};
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 30px;
  margin: 0 0 10px;
  width: 100%;

  svg {
    color: ${(props) => (props.selected ? props.theme.white : props.theme.teal)};
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.teal};
  }

  @media (max-width: ${({ theme }) => theme.lg}) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.sm}) {
    width: 100%;
  }
`;

export const Calendar = styled.div<StyledComponentsDivProps>`
  border-radius: 10px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
  margin: 0 30px 30px 0;
  overflow: hidden;
  zoom: 0.7;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 0 0 30px 0;
    width: 100%;
  }
`;

export const CalendarDayWrapper = styled.div<StyledComponentsDivProps>`
  position: relative;
`;

export const IssuesMarker = styled.div<StyledComponentsDivProps>`
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 0.6em;
  z-index: 1;
`;

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 40px;
  position: relative;

  .MuiLinearProgress-root {
    position: absolute;
    top: -20px;
  }

  @media (max-width: ${({ theme }) => theme.lg}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const Hours = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-wrap: wrap;
  height: 350px;
  justify-content: space-between;
  align-content: flex-start;
  margin: 0 0 30px;
  overflow-x: auto;
  width: 310px;
  zoom: 0.7;

  h5 {
    color: ${({ theme }) => theme.teal};
    font: 600 22px/22px 'Proxima Nova Rg';
  }

  @media (max-width: ${({ theme }) => theme.lg}) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const Hour = styled.p`
  color: inherit;
  font: 600 22px/22px 'Proxima Nova Rg';
  margin: 0 0 0 10px;
`;

export const Wrapper = styled.div<StyledComponentsDivProps>`
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.lg}) {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.md}) {
    max-width: 87vw;
  }
`;
