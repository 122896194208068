import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/comment';

const CommentContext = createContext<{ commentState: State; commentDispatch: Dispatch<Action> }>({
  commentState: initialState,
  commentDispatch: () => null,
});

const CommentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [commentState, commentDispatch] = useReducer(reducer, initialState);
  return (
    <CommentContext.Provider value={{ commentState, commentDispatch }}>
      {children}
    </CommentContext.Provider>
  );
};

export { CommentContext, CommentProvider };
