import styled from 'styled-components';

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;
  & h2,
  p {
    color: ${({ theme }) => theme.grayDark};
  }
`;

export const Actions = styled.footer`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 24px;

  & > button {
    margin-right: 20px;
  }
`;

export const Display = styled.div`
  display: flex;
  flex-wrap: wrap;

  textarea {
    margin-right: 36px;
  }
`;
