import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const ALLOWED_TIMEZONES = [
  { description: 'ET', value: 'US/Eastern' },
  { description: 'CT', value: 'US/Central' },
  { description: 'MT', value: 'US/Mountain' },
  { description: 'PT', value: 'US/Pacific' },
];
export const LOCAL_TIMEZONE = ALLOWED_TIMEZONES.find((tz) =>
  momentTimezone().tz(moment.tz.guess()).format('z').startsWith(tz.description.substring(0, 1))
);
