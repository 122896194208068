import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

export const Input = styled.input`
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  display: block;
  padding: 16px;
  width: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;

Field.defaultProps = {
  'data-id': 'Field',
};

Input.defaultProps = {
  'data-id': 'Input',
};

Label.defaultProps = {
  'data-id': 'Label',
};
