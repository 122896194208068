import { FC, PropsWithChildren } from 'react';
import { ChartProvider } from 'state/contexts/chart';
import { ChatProvider } from 'state/contexts/chat';
import { ClinicProvider } from 'state/contexts/clinic';
import { CommentProvider } from 'state/contexts/comment';
import { ConfirmationProvider } from 'state/contexts/confirmation';
import { EventProvider } from 'state/contexts/event';
import { FeaturesProvider } from 'state/contexts/features';
import { NotificationProvider } from 'state/contexts/notification';
import { PatientProvider } from 'state/contexts/patient';
import { PatientSummaryProvider } from 'state/contexts/patientSummary';
import { PatientTagsProvider } from 'state/contexts/patientTags';
import { ProgramProvider } from 'state/contexts/program';
import { ProviderProvider } from 'state/contexts/provider';
import { ProvidersProvider } from 'state/contexts/providers';
import { ReviewLists4Provider } from 'state/contexts/reviewLists4';
import { StatusProvider } from 'state/contexts/status';
import { StickyProvider } from 'state/contexts/sticky';
import { TagProvider } from 'state/contexts/tag';
import { TaskProvider } from 'state/contexts/task';
import { UserProvider } from 'state/contexts/user';
import { WeightsProvider } from 'state/contexts/weights';

const ALL_KNOWN_PROVIDERS = [
  UserProvider,
  TaskProvider,
  StickyProvider,
  PatientProvider,
  ProviderProvider,
  CommentProvider,
  ChatProvider,
  NotificationProvider,
  ProgramProvider,
  ChartProvider,
  ProvidersProvider,
  TagProvider,
  PatientTagsProvider,
  PatientSummaryProvider,
  ConfirmationProvider,
  EventProvider,
  StatusProvider,
  ClinicProvider,
  WeightsProvider,
  FeaturesProvider,
  ReviewLists4Provider,
];

const ComposeProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {ALL_KNOWN_PROVIDERS.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children
      )}
    </>
  );
};

export default ComposeProviders;
