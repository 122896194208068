import styled from 'styled-components';

export const Text = styled.div`
  color: ${({ theme }) => theme.teal};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  pointer-events: none;
`;
