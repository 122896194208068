import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/providers';

const ProvidersContext = createContext<{
  providersState: State;
  providersDispatch: Dispatch<Action>;
}>({
  providersState: initialState,
  providersDispatch: () => null,
});

const ProvidersProvider: FC<PropsWithChildren> = ({ children }) => {
  const [providersState, providersDispatch] = useReducer(reducer, initialState);
  return (
    <ProvidersContext.Provider value={{ providersState, providersDispatch }}>
      {children}
    </ProvidersContext.Provider>
  );
};

export { ProvidersContext, ProvidersProvider };
