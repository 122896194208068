import { StyledComponentsDivProps } from 'components/general-styles';
import { ImgHTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

const loaderAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderImg = styled.img<ImgHTMLAttributes<HTMLImageElement>>`
  display: block;
  position: absolute;
  height: 12%;
`;

export const LoaderWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  position: relative;
  height: 100%;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div<StyledComponentsDivProps>`
  margin: auto;
  border: 5px solid ${({ theme }) => theme.navBackground};
  border-radius: 50%;
  border-top: 5px solid ${({ theme }) => theme.teal};
  width: 100%;
  height: 100%;
  background-opacity: 1;
  animation: ${loaderAnimation} 1s ease-in-out infinite;
`;
