import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
  ADD = 'ADD',
  PREPEND = 'PREPEND',
}

export type State = {
  direction: 'next' | 'prev';
  data: {
    direct: Record<string | number, { messages: unknown[]; acknowledgeMessage: unknown }>;
    team: Record<string | number, { messages: unknown[]; acknowledgeMessage: unknown }>;
  };
};

type Payload = {
  [ActionTypes.SET]: {
    id: number;
    type: 'direct' | 'team';
    data: unknown[];
    acknowledgeMessage: unknown;
  };
  [ActionTypes.ADD]: {
    id: number;
    direction: State['direction'];
    type: 'direct' | 'team';
    data: unknown[];
    acknowledgeMessage: unknown;
  };
  [ActionTypes.PREPEND]: {
    id: number;
    direction: State['direction'];
    type: 'direct' | 'team';
    data: unknown[];
    acknowledgeMessage: unknown;
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  direction: 'next',
  data: {
    direct: {},
    team: {},
  },
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const { type, id, data, acknowledgeMessage } = action.payload;
      return {
        ...state,
        direction: 'next',
        data: {
          ...state.data,
          [type]: {
            [id]: {
              messages: data,
              acknowledgeMessage,
            },
          },
        },
      };
    }

    case ActionTypes.ADD: {
      const { direction, type, id, data, acknowledgeMessage } = action.payload;
      return {
        ...state,
        direction,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            [id]: {
              messages: [...state.data[type][id].messages, ...data],
              acknowledgeMessage: acknowledgeMessage || state.data[type][id].acknowledgeMessage,
            },
          },
        },
      };
    }

    case ActionTypes.PREPEND: {
      const { direction, type, id, data, acknowledgeMessage } = action.payload;
      return {
        ...state,
        direction,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            [id]: {
              messages: [...data, ...state.data[type][id].messages],
              acknowledgeMessage: acknowledgeMessage || state.data[type][id].acknowledgeMessage,
            },
          },
        },
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
