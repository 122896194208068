/* eslint-disable */
import api from 'api';

let food_data;

export const getFoodsFromCore = async (
  patientId,
  searchDateRangeStart,
  searchDateRangeEnd,
  sourceToken
) => {
  const { data } = await api().get(
    `patient_foods/AUTH_TOKEN?patient_id=${patientId}&start_date=${searchDateRangeStart}&end_date=${searchDateRangeEnd}`,
    {
      cancelToken: sourceToken,
    }
  );
  setFoods(data);
  return data;
};

const setFoods = (foods) => (food_data = foods);

export const getFoods = () => food_data;
