import React from 'react';
import { IconContent } from '../style';

const Sort = () => (
  <IconContent fill='currentColor' viewBox='0 0 16 16'>
    <path d='M11 7h-6l3-4z' />
    <path d='M5 9h6l-3 4z' />
  </IconContent>
);

export default Sort;
