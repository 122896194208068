/* eslint-disable */
import { useState } from 'react';

import api from 'api';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Button, Input, Modal } from 'components/elements';
import { debug } from 'utils/helpers';
import { Actions, Header } from './style';

const CreateTaskList = ({
  taskRows,
  setTaskRows,
  formatTaskRows,
  setTaskListLibs,
  taskListLibs,
  selectedtaskListLibName,
  openCreate,
  setOpenCreate,
}) => {
  const [taskListName, setTaskListName] = useState('');
  const schema = yup.object().shape({
    task_list_name: yup
      .string()
      .test(
        'duplicated',
        '${path} must be unique',
        (value) =>
          !taskListLibs
            .map((item) => item.name)
            .includes(
              value !== ''
                ? value
                    .replace(/\b(\w)/g, (s) => s.toUpperCase())
                    .split(/\W+/)
                    .join('_')
                : value
            )
      )
      .required('Task List name is required'),
  });

  const { handleSubmit, register, errors, formState } = useForm({
    validationSchema: schema,
  });

  const onSubmit = async () => {
    // Send Data Formated
    formatTaskRows(taskRows);
    const name = taskListName
      .replace(/\b(\w)/g, (s) => s.toUpperCase())
      .split(/\W+/)
      .join('_');
    try {
      await api().post('task_lists', {
        auth_token: 'AUTH_TOKEN',
        // name: taskListName.split(/\W+/).join('_'),
        name,
        task_templates_attributes: taskRows,
      });

      const { data } = await api().get('task_lists?auth_token=AUTH_TOKEN');
      setTaskListLibs(data);
      if (selectedtaskListLibName !== '')
        setTaskRows(data.filter((item) => item.name === selectedtaskListLibName)[0].task_templates);
      else setTaskRows([]);

      // to close only 'create new task'
      return handleClose();
      // to close 'add new task' and 'create new task'
      // return onClose();
    } catch (err) {
      debug(err);
    }
  };

  const handleOnChange = (e) => {
    setTaskListName(e.target.value);
  };

  const handleClose = () => {
    setOpenCreate(!openCreate);
  };

  return (
    <Modal onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h2>Save As New</h2>
        </Header>
        <Input
          errors={errors.task_list_name}
          label='Task List Name'
          name='task_list_name'
          register={register}
          type='text'
          value={taskListName}
          onChange={handleOnChange}
          style={{ width: '100%' }}
        />
        <Actions>
          <Button type='submit' submitting={formState.isSubmitting}>
            Save Task List
          </Button>
        </Actions>
      </form>
    </Modal>
  );
};

CreateTaskList.propTypes = {
  taskRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openCreate: PropTypes.bool.isRequired,
  setOpenCreate: PropTypes.func.isRequired,
};

export default CreateTaskList;
