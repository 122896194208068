import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/sticky';

const StickyContext = createContext<{ stickyState: State; stickyDispatch: Dispatch<Action> }>({
  stickyState: initialState,
  stickyDispatch: () => null,
});

const StickyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stickyState, stickyDispatch] = useReducer(reducer, initialState);
  return (
    <StickyContext.Provider value={{ stickyState, stickyDispatch }}>
      {children}
    </StickyContext.Provider>
  );
};

export { StickyContext, StickyProvider };
