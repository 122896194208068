import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import { ChartContext } from 'state/contexts/chart';
import ProgramStepIcon from './ProgramStepIcon';

const ProgramHistory = ({
  selectedProgramAssignmentId,
  onClick,
}: {
  selectedProgramAssignmentId?: number;
  onClick?: (programAssignmentId: number) => void;
}) => {
  const { chartState } = useContext(ChartContext);

  const programHistory = chartState?.data?.programs?.values?.length
    ? chartState.data.programs.values.sort((a, b) => {
        const dateA = moment(a.start_date);
        const dateB = moment(b.start_date);

        if (dateA.isSame(dateB)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return b.user_program_id! - a.user_program_id!;
        }
        return dateB.diff(dateA);
      })
    : [];

  return (
    <Box className='manage-programs-history manage-programs-section'>
      <Typography component='h5' variant='h5' marginBottom={3}>
        Program History
      </Typography>
      <Stepper orientation='vertical'>
        {programHistory.map(
          ({
            display_value: displayValue,
            value,
            end_date: endDate,
            start_date: startDate,
            user_program_id: programAssignmentId,
          }) => (
            <Step key={`${value}-${startDate}`}>
              <StepLabel
                StepIconComponent={ProgramStepIcon}
                className='step-label-container'
                componentsProps={{ label: { className: 'step-label' } }}
                onClick={() => {
                  if (!!onClick && !!programAssignmentId) {
                    onClick(programAssignmentId);
                  }
                }}>
                <Box
                  width='100%'
                  className={`step-label-text ${
                    programAssignmentId === selectedProgramAssignmentId ? 'selected' : ''
                  }`}
                  marginRight={2}>
                  <Typography>{displayValue}</Typography>
                  <Typography>
                    from {moment(startDate).toISOString().slice(0, 10)}{' '}
                    {endDate ? `to ${moment(endDate).toISOString().slice(0, 10)}` : ''}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          )
        )}
      </Stepper>
    </Box>
  );
};

export default ProgramHistory;
