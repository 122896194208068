import React from 'react';
import { IconContent } from '../style';

const Message = () => (
  <IconContent fill='currentColor' viewBox='0 0 20 20'>
    <path d='M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-2 12H4v-2h12v2zm0-3H4V7h12v2zm0-3H4V4h12v2z' />
  </IconContent>
);

export default Message;
