import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px 20px;
  width: 100%;
  height: 100%;

  .react-datepicker {
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: 1.1rem;
  }

  .react-datepicker__current-month {
    font-size: 1.3rem;
  }

  .react-datepicker__day--today {
    border: 1px solid black;
    border-radius: 8px;
  }

  .react-datepicker__day-name {
    margin: 0.8rem;
  }

  .react-datepicker__day {
    line-height: 1.8rem;
    margin: 0.8rem;
    border-radius: 8px;
  }

  .react-datepicker__day--selected {
    color: black;
    font-weight: bold;
    background: transparent;
  }

  .react-datepicker__day--highlighted-green {
    background: ${({ theme }) => theme.tealLight};
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
  }

  .react-datepicker__day--highlighted-red {
    background: ${({ theme }) => theme.salmon};
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
  }
`;

export const ExercisesWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  width: 100%;
  height: -webkit-fill-available;
  align-items: center;
  justify-content: space-evenly;
`;

export const CalendarWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DayExercises = styled.div<StyledComponentsDivProps>`
  max-height: 65%;
  width: 45%;
  overflow: auto;
`;

export const MonthsWithNewLogs = styled.div<StyledComponentsDivProps>`
  margin-bottom: 20px;
`;

export const Title = styled.div<StyledComponentsDivProps>`
  font: 20px/24px ${({ theme }) => theme.primaryFont};
  margin-bottom: 16px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
`;
export const LegendExercise = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;
export const CircleExercise = styled.div<StyledComponentsDivProps>`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: ${({ theme }) => theme.teal};
`;
export const TextExercise = styled.div<StyledComponentsDivProps>`
  margin-left: 7px;
  font-weight: bold;
`;
