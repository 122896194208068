import PropTypes from 'prop-types';
import { useContext } from 'react';

import { CommentContext } from 'state/contexts/comment';

import MainProfileActions from 'components/main';
import { ActionTypes } from 'state/reducers/comment';
import Food from './food';
import ViewFood from './view-food';

const Pictures = ({ patientId, setPicturesTabUnreviewed }) => {
  const { commentState, commentDispatch } = useContext(CommentContext);

  return (
    <>
      {/* activate all buttons from profile page */}
      <MainProfileActions key={`main-profile-buttons-${patientId}`} />

      <Food
        patientId={patientId}
        key={`food-${patientId}`}
        setPicturesTabUnreviewed={setPicturesTabUnreviewed}
      />

      {commentState.modal && (
        <ViewFood
          element_id={commentState.element_id}
          elements={commentState.elements}
          patientId={patientId}
          onClose={(element_id, elements) =>
            commentDispatch({
              type: ActionTypes.TOGGLE_MODAL,
              payload: { element_id, elements },
            })
          }
          callback={commentState.callback}
        />
      )}
    </>
  );
};

Pictures.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default Pictures;
