import { HTMLAttributes, SVGAttributes } from 'react';
import styled, { css } from 'styled-components';

export const IconBox = styled.i<
  HTMLAttributes<HTMLSpanElement> & {
    size?: number;
    margin?: number;
    rotate?: number;
    event?: string;
    background?: string;
  }
>`
  color: ${(props) => (props?.color === 'inherit' ? 'inherit' : props.theme[props.color || ''])};
  display: inline-block;
  height: ${(props) => props.size}px;
  margin: ${(props) => props.margin}px;
  overflow: hidden;
  position: relative;
  transform: rotate(${(props) => props.rotate}deg);
  vertical-align: middle;
  width: ${(props) => props.size}px;
  pointer-events: ${(props) => props.event};

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme[props.background]};
      padding: 10px;
      height: ${props.size || 0 + 20}px;
      width: ${props.size || 0 + 20}px;
      border-radius: 100%;

      svg {
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: auto;
      }
    `}
`;

export const IconContent = styled.svg<SVGAttributes<HTMLOrSVGElement>>`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;
