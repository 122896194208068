import api from 'api';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

// Libraries
import moment from 'moment-timezone';

import { debug } from 'utils/helpers';

import { EventContext } from 'state/contexts/event';

import { Event } from 'components/elements';

const EventNotes = ({ patientUuid, patientId }) => {
  const { eventState, eventDispatch } = useContext(EventContext);

  const [events, SetEvents] = useState([]);

  const source = axios.CancelToken.source();

  const handleClose = async (id) => {
    try {
      eventDispatch({
        type: 'UPDATE',
        payload: {
          patientId,
          id,
        },
      });
      await api().put(`patients/${patientUuid}/events/${id}/?auth_token=AUTH_TOKEN&pinned=false`);
    } catch (err) {
      debug('Error', err);
    }
  };

  const getEventNotes = async () => {
    try {
      const { data } = await api().get(`/patients/${patientUuid}/events?auth_token=AUTH_TOKEN`, {
        auth_token: 'AUTH_TOKEN',
      });

      eventDispatch({
        type: 'SET',
        payload: {
          patientId,
          data: data?.filter((event) => event.pinned),
        },
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
      }
    }
  };

  useEffect(() => {
    SetEvents(eventState.data[patientId]);
  }, [eventState.data]);

  useEffect(() => {
    if (eventState.reload !== 0) {
      setTimeout(() => {
        getEventNotes();
      }, 1000);
    }
  }, [eventState.reload]);

  useEffect(() => {
    SetEvents([]);
    getEventNotes();

    return () => {
      source.cancel();
    };
  }, [patientId]);

  return (
    <>
      {events.map((event) => (
        <Event
          key={`event-${event.id}`}
          backgroundColor='turquoise'
          color='teal'
          onClose={() => handleClose(event.id)}>
          Event:{' '}
          {event.end_date
            ? `${event.name} (${moment(event.start_date).format('MM/DD/YYYY')} - ${moment(
                event.end_date
              ).format('MM/DD/YYYY')})`
            : `${event.name} (${moment(event.start_date).format('MM/DD/YYYY')})`}
        </Event>
      ))}
    </>
  );
};

EventNotes.propTypes = {
  patientId: PropTypes.number.isRequired,
};

EventNotes.defaultProps = {};

export default EventNotes;
