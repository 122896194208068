import { EFeatures } from 'lib/feature/features.types';
import { FC, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FeaturesContext } from 'state/contexts/features';
import MyPanelList from '../MyPanel/MyPanelList';
import { Panel } from './Panel';

export const PanelVersionSelector: FC<RouteComponentProps> = (props) => {
  const { featuresState } = useContext(FeaturesContext);
  if (featuresState[EFeatures.ProviderPanelWithFilters]) {
    return <Panel {...props} />;
  }
  return <MyPanelList {...props} />;
};
