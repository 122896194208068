import Theme from 'styles/theme';

export const inputStyle = {
  control: {
    backgroundColor: Theme.grayLight,
    display: 'block',
    height: '100%',
  },

  highlighter: {
    border: 'none',
    height: '100%',

    span: {
      background: Theme.white,
      zIndex: 1,
      position: 'relative',
    },
  },

  input: {
    border: 'none',
    color: Theme.grayDarker,
    font: `600 15px ${({ theme }) => theme.primaryFont}`,
    height: '100%',
    margin: 0,
    padding: 0,
    width: '100%',
  },

  suggestions: {
    list: {
      backgroundColor: Theme.white,
      border: `1px solid ${Theme.grayLight}`,
    },

    item: {
      padding: '8px 16px',
      borderBottom: `1px solid ${Theme.grayLight}`,
      font: `14px ${({ theme }) => theme.primaryFont}`,

      '&focused': {
        backgroundColor: Theme.turquoise,
      },
    },
  },
};

export const actionStyle = {
  background: Theme.grayLight,
  color: Theme.salmonDark,
  pointerEvents: 'none',
  position: 'relative',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  zIndex: 1,
};

export const mentionStyle = {
  background: Theme.grayLight,
  color: Theme.teal,
  pointerEvents: 'none',
  position: 'relative',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  zIndex: 1,
};
