import React from 'react';
import { IconContent } from '../style';

const Arrow = () => (
  <IconContent fill='currentColor' viewBox='0 0 16 9'>
    <path d='M7.65133179,6.12351194 C9.54933179,4.21851194 11.3863318,2.37451194 13.2233318,0.530511944 C13.5753318,0.177511944 13.9503318,-0.0974880563 14.4933318,0.0575119437 C15.1743318,0.250511944 15.4963318,0.947511944 15.1653318,1.57051194 C15.0583318,1.77051194 14.9013318,1.95151194 14.7403318,2.11351194 C12.7253318,4.13351194 10.7083318,6.14951194 8.68833179,8.16451194 C7.92933179,8.92051194 7.38033179,8.92951194 6.63433179,8.18651194 C4.60033179,6.16151194 2.56933179,4.13351194 0.541331785,2.10251194 C-0.0396682145,1.51951194 -0.149668215,0.971511944 0.195331785,0.493511944 C0.615331785,-0.0894880563 1.30933179,-0.173488056 1.85833179,0.335511944 C2.44633179,0.880511944 3.00033179,1.46251194 3.56733179,2.03051194 C4.91333179,3.37851194 6.25833179,4.72751194 7.65133179,6.12351194' />
  </IconContent>
);

export default Arrow;
