import React from 'react';
import { IconContent } from '../style';

const Close = () => (
  <IconContent fill='currentColor' viewBox='0 0 30 30'>
    <path d='M30 3.021L26.979 0 15 11.979 3.021 0 0 3.021 11.979 15 0 26.979 3.021 30 15 18.021 26.979 30 30 26.979 18.021 15z' />
  </IconContent>
);

export default Close;
