import { Popover as MuiPopover, PopoverOrigin, PopoverProps } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = {
  anchorOrigin?: PopoverOrigin;
  children: ReactNode;
  onClose: () => void;
} & Partial<PopoverProps>;

const Popover: FC<Props> = ({ anchorEl, anchorOrigin, children, onClose, open, ...props }) => {
  const autoOpen = open ?? Boolean(anchorEl);
  return (
    <MuiPopover
      open={autoOpen}
      anchorEl={anchorEl}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'left',
        }
      }
      onClose={onClose}
      {...props}>
      {children}
    </MuiPopover>
  );
};

export default Popover;
