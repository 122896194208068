export enum ReviewListSlugs {
  NotEngagedByMe = 'not-engaged-by-me',
  PatientsDueForEngagementByMe = 'not_engaged_by_me',
  PatientsDueForEngagementByAny = 'not_engaged_by_any',
  WeightRegainWarning = 'weightregain',
  Disengaging = 'disengaging',
}

export type ListReviewListsEntryResponseV1 = {
  assigned_provider_id: number;
  completion_rate: number;
  created_at: string;
  creator_id: number;
  dimension_key: string;
  dimension_range_days: number;
  expires_on: string;
  id: number;
  label: string;
  source: string;
  status: string;
  uuid: string;
  filter: MemberSearchFilterType;
};

export type MemberEntryResponseV1 = {
  patient_id: number;
  patient_uuid: string;
  user_program_id?: number;
  profile_status_id?: number;
};

// TODO: I'm very very confused why we have different outputs
export interface ReviewListPatientResponse {
  id: number;
  uuid: string;
  first_name: string;
  avatar: { url: string; thumb: { url: string } };
}

export type ReviewListEntryResponse = {
  dimensionKey: string;
  dimensionRangeDays: number;
  expiresOn: string;
  label: string;
  memberCount: number;
  members: {
    pendingReview: ReviewListPatientResponse[];
    reviewed: ReviewListPatientResponse[];
  };
  reviewListUuid: string;
  source: string;
};

export interface IPatient {
  id: number;
  uuid: string;
  first_name: string;
  avatar: { url: string; thumb: { url: string } };
}

export type CreateReviewListResponseV1 = {
  message: string;
  reviewListUuid: string;
  source: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: { [key: string]: any };
  reviewList: ReviewListEntryResponse;
};

export type MemberSearchFilterType = {
  dimensionKey: string;
  dimensionRangeDays: number;
  profileStatusId: number;
  userProgramIds?: number[];
  needsAttention: string;
};

export type ISearchResponse = {
  source: string;
  filter: MemberSearchFilterType;
  providerId: number;
  providerName: string;
  memberCount: number;
  patients: {
    patient_id: number;
    patient_uuid: string;
    user_program_id?: number;
    profile_status_id?: number;
    first_name: string;
    last_name: string;
    avatar: { url: string; thumb: { url: string } };
    patient_status: string;
    program_start_date: number;
    current_program: string;
  }[];
};

type ReviewListSummaryType = {
  success?: boolean;
  id: number;
  description: string;
  label: string;
  count: number;
  list_name: string;
  skip_if_reviewed_by_team?: boolean;
  skip_if_reviewed_n_days_ago?: boolean;
};

export type ReviewList1SummaryResponse = ReviewListSummaryType[];
export type ReviewList2SummaryResponse = ReviewListSummaryType[];
export type ReviewList4SummaryResponse = ReviewListSummaryType;

type ReviewList4User = {
  app_version: string;
  avatar: { avatar: { url: string; thumb: { url: string } } };
  birthdate: string;
  clinic_id: number;
  clinic_name: string;
  created_at: string;
  diabetic: boolean;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  last_timezone: string;
  phone: string;
  status: string;
  uuid: string;
};

export interface ReviewList4Response {
  success: boolean;
  review_list: string;
  users: ReviewList4User[];
}

export interface ReviewLists4Response {
  success: boolean;
  reviewLists: {
    id: number;
    createdAt: string;
    description: string;
    name: string;
    slug: string;
    updatedAt: string;
  }[];
}
