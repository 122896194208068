import axios, { CancelTokenSource } from 'axios';
import { Carousel } from 'components/elements';
import Spinner from 'components/elements/loaders/spinner';
import InformationModal from 'components/elements/modal/info';
import { Section, SectionMessage } from 'components/general-styles';
import infoFilled from 'components/icon/pngs/info-filled.png';
import infoOutline from 'components/icon/pngs/info-outline.png';
import { getUserInfo } from 'components/main/rpm-event/util';
import Toast from 'components/toast';
import { ReviewListClient } from 'node-api/reviewList/reviewlistClient';
import { ReviewListLegacyClient } from 'node-api/reviewList/reviewlistLegacyClient';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { UserContext } from 'state/contexts/user';
import { debug } from 'utils/helpers';
import {
  Container,
  Content,
  ContentWrapper,
  IconContainer,
  InfoIcon,
  Link,
  ReviewListTitleWrapper,
  TealBadge,
  Title,
} from './style';
import { ReviewListData } from './types';

const PatientsReviewLists = () => {
  const [reviewLists, setReviewLists] = useState<JSX.Element[]>([]);
  const [loading, setLoading] = useState(true);
  const [successCount, setSuccessCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [reviewListDescription, setReviewListDescription] = useState('');
  const { userState } = useContext(UserContext);
  const { reviewLists4State } = useContext(ReviewLists4Context);

  const totalRLEndpointsFetched = successCount + errorCount;

  const getAvailableLists = (data: ReviewListData[], isNewReviewList = false) => {
    return data
      .filter((list) => list.count > 0)
      .map((list) => (
        <ContentWrapper key={`slide-${list.id}`}>
          <Content>
            <h4>{list.label}</h4>
            <p>{list.count} new patients</p>
            <Link
              to={`/review/${list.list_name}`}
              title={`Review list for ${list.label}`}
              onClick={() => {
                const user = getUserInfo(userState);
                MixpanelWrapperInstance.track(EventNames.ReviewListsClickReviewList, {
                  List: list.label,
                  mainRole: `${user.clinicalRole?.id}-${user.clinicalRole?.name}`,
                });
              }}>
              Review
            </Link>
          </Content>
          {isNewReviewList && (
            <IconContainer>
              <InfoIcon
                infoIconFilled={infoFilled}
                infoIconOutline={infoOutline}
                onClick={() => {
                  const user = getUserInfo(userState);
                  MixpanelWrapperInstance.track(EventNames.ReviewListsClickInfoButton, {
                    targetType: ClickableElement.BUTTON,
                    'review-list': list.label,
                    'full-name': user.fullName,
                    role: user.role,
                    mainRole: `${user.clinicalRole?.id}-${user.clinicalRole?.name}`,
                    targetLocation: 'review list card',
                  });
                  setReviewListDescription(list.description ?? 'No description provided');
                }}
              />
              <TealBadge>New</TealBadge>
            </IconContainer>
          )}
        </ContentWrapper>
      ));
  };

  const REVIEW_LIST_ENDPOINTS_COUNT = useMemo(() => {
    return reviewLists4State.data.length + 2; // 2 ==> RL1 + RL2
  }, [reviewLists4State]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getReviewList1 = async () => {
      try {
        const data: ReviewListData[] = await new ReviewListLegacyClient().getReviewList1Summary(
          source.token
        );
        setReviewLists((prevState) => prevState.concat(getAvailableLists(data)));
        setSuccessCount((prevState) => prevState + 1);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
        }
        setErrorCount((prevState) => prevState + 1);
      }
    };

    getReviewList1();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getReviewList2 = async () => {
      try {
        // Node review lists
        const data = await new ReviewListLegacyClient().getReviewList2Summary(source.token);
        setReviewLists((prevState) => prevState.concat(getAvailableLists(data)));
        setSuccessCount((prevState) => prevState + 1);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
        }
        setErrorCount((prevState) => prevState + 1);
      }
    };

    getReviewList2();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const sources: CancelTokenSource[] = [];

    if (reviewLists4State.data.length && totalRLEndpointsFetched < REVIEW_LIST_ENDPOINTS_COUNT) {
      const fetchAndUpdateReviewList = async () => {
        const source = axios.CancelToken.source();
        sources.push(source);

        reviewLists4State.data.forEach(async (reviewlist4) => {
          try {
            // review list 4
            const data = await new ReviewListClient().getReviewList4Summary(
              { reviewListName: reviewlist4.slug },
              source.token
            );
            if (data.success) {
              setReviewLists((prevState) => prevState.concat(getAvailableLists([data], true)));
              setSuccessCount((prevState) => prevState + 1);
            } else {
              setErrorCount((prevState) => prevState + 1);
            }
          } catch (err) {
            if (!axios.isCancel(err)) {
              debug(err as string);
            }
            setErrorCount((prevState) => prevState + 1);
          }
        });
      };

      fetchAndUpdateReviewList();
    }

    return () => {
      sources.forEach((source) => source.cancel());
    };
  }, [reviewLists4State]);

  useEffect(() => {
    if (totalRLEndpointsFetched >= REVIEW_LIST_ENDPOINTS_COUNT) {
      setLoading(false);
      if (errorCount) {
        if (successCount) {
          Toast.show('error', 'Review Lists partially loaded!');
        } else {
          Toast.show('error', "Couldn't load Review Lists!");
        }
      }
    }
  }, [successCount, errorCount]);

  return (
    <Section>
      <ReviewListTitleWrapper>
        <Title>Review Lists</Title>
        {loading && <Spinner size={20} />}
      </ReviewListTitleWrapper>
      <Container numberOfReviewLists={reviewLists.length}>
        {!loading && reviewLists.length === 0 && (
          <SectionMessage>
            {errorCount === 0 ? (
              <p>There are no patients to review</p>
            ) : (
              <p>Couldn&apos;t load any Review List</p>
            )}
          </SectionMessage>
        )}

        {reviewLists.length > 0 && <Carousel data={reviewLists} />}
      </Container>
      {reviewListDescription && (
        <InformationModal
          text={reviewListDescription}
          onClose={() => setReviewListDescription('')}
        />
      )}
    </Section>
  );
};

PatientsReviewLists.propTypes = {};

export default PatientsReviewLists;
