/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import api from 'api';
import { Datepicker, Modal } from 'components/elements';
import Toast from 'components/toast';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { DATE_FORMAT } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes } from 'services/mixpanel/mixpanel.types';
import { ChartContext } from 'state/contexts/chart';
import { ActionTypes } from 'state/reducers/chart';
import { Program } from 'state/reducers/program';
import ProgramHistory from './ProgramHistory';

type CurrentProgramInput = {
  id: number;
  startDate: Date;
  endDate: Date | null;
};

const EditProgram = ({
  onClose,
  programs,
  programAssignment,
}: {
  onClose: () => void;
  programs: Program[];
  programAssignment: {
    programAssignmentId: number;
    programId: number;
    startDate: Date;
    endDate?: Date;
  };
}) => {
  const {
    programAssignmentId: initialProgramAssignmentId,
    programId: initialProgramId,
    startDate: initialStartDate,
    endDate: initialEndDate,
  } = programAssignment;
  const { chartState, chartDispatch } = useContext(ChartContext);

  const [programAssignmentId, setProgramAssignmentId] = useState<number>(
    initialProgramAssignmentId
  );
  const [currentProgram, setCurrentProgram] = useState<CurrentProgramInput>({
    id: initialProgramId,
    startDate: initialStartDate,
    endDate: initialEndDate ?? null,
  });
  const [showDeleteProgramConfirmationModal, setShowDeleteProgramConfirmationModal] =
    useState<boolean>(false);

  const handleDeleteCurrentProgram = async () => {
    const { id: programId } = currentProgram;

    if (!programAssignmentId) {
      return;
    }

    try {
      await api({ version: 'v3' }).delete(`/user_programs/${programAssignmentId}`);
    } catch (err) {
      console.error(err);
      Toast.show('error', 'There was an issue removing the current program');
      return;
    } finally {
      setShowDeleteProgramConfirmationModal(false);
    }

    MixpanelWrapperInstance.trackClick({
      name: 'Delete program',
      type: ClickElementTypes.BUTTON,
      source: 'Manage programs modal',
      inputData: { programId },
    });
    chartDispatch({
      type: ActionTypes.DELETE_PROGRAM,
      payload: { programAssignmentId },
    });

    onClose();
  };

  const handleSubmit = async () => {
    const { id: programId, startDate, endDate } = currentProgram;

    if (!programId || !startDate) {
      Toast.show('error', 'Complete required fields');
      return;
    }
    const program = programs.find(({ id }) => id === programId);
    if (!program) {
      return;
    }
    const startDateToSend = moment(startDate).format(DATE_FORMAT);
    const endDateToSend = !!endDate ? moment(endDate).format(DATE_FORMAT) : undefined;

    try {
      await api({ version: 'v3' }).patch(`/user_programs/${programAssignmentId}`, {
        auth_token: 'AUTH_TOKEN',
        user_program_id: programId,
        start_date: startDateToSend,
        end_date: endDateToSend,
      });
    } catch (err) {
      console.error(err);
      Toast.show('error', 'There was an issue updating the program');
      return;
    }

    MixpanelWrapperInstance.trackClick({
      name: 'Edit Program',
      type: ClickElementTypes.BUTTON,
      source: 'Manage programs modal',
      inputData: {
        program: program.text,
        startDate: startDateToSend,
        endDate: endDateToSend,
      },
    });

    chartDispatch({
      type: ActionTypes.UPDATE_PROGRAM,
      payload: {
        programAssignmentId,
        data: {
          programId: Number(programId),
          programName: program.text,
          programDisplayValue: program.display_value,
          ...(startDateToSend !== undefined && { startDate: startDateToSend }),
          ...(endDateToSend !== undefined && { endDate: endDateToSend }),
        },
      },
    });

    onClose();
  };

  useEffect(() => {
    const selectedProgram = chartState?.data?.programs?.values?.find?.(
      ({ user_program_id: programAId }) => programAId === programAssignmentId
    );
    if (selectedProgram) {
      setCurrentProgram({
        id: selectedProgram.id!,
        startDate: moment(selectedProgram.start_date!, 'YYYYMMDDTHHmmss').toDate(),
        endDate: selectedProgram.end_date
          ? moment(selectedProgram.end_date, 'YYYYMMDDTHHmmss').toDate()
          : null,
      });
    }
  }, [programAssignmentId]);

  return (
    <>
      <Box className='manage-programs-tab-content'>
        <ProgramHistory
          selectedProgramAssignmentId={programAssignmentId}
          onClick={setProgramAssignmentId}
        />
        <Divider orientation='vertical' variant='middle' flexItem />
        <Box className='manage-programs-section'>
          <Box className='title'>
            <Typography component='h5' variant='h5' marginBottom={3}>
              Edit Program
            </Typography>
            <Button
              className='secondary'
              variant='text'
              onClick={() => setShowDeleteProgramConfirmationModal(true)}>
              Delete Program
            </Button>
          </Box>
          <Box className='manage-programs-form-content'>
            <FormControl fullWidth>
              <InputLabel>Program</InputLabel>
              <Select
                label='Program'
                value={`${currentProgram.id}`}
                onChange={(event: SelectChangeEvent) => {
                  setCurrentProgram((program) => ({ ...program, id: Number(event.target.value) }));
                }}>
                {programs.map(({ display_value: displayValue, id }) => (
                  <MenuItem value={`${id}`}>{displayValue}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Datepicker
              errors=''
              label='Program Start Date'
              name='programStartDate'
              date={currentProgram.startDate}
              setValue={(fieldName: string, value: Date) => {
                setCurrentProgram((program) => ({
                  ...program,
                  startDate: value,
                }));
              }}
              dateFormat='yyyy-MM-dd'
            />
            <Datepicker
              errors=''
              label='Program End Date'
              name='programEndDate'
              date={currentProgram.endDate}
              setValue={(fieldName: string, value: Date) => {
                setCurrentProgram((program) => ({
                  ...program,
                  endDate: value,
                }));
              }}
              dateFormat='yyyy-MM-dd'
            />
            <Box className='actions'>
              <Button variant='outlined' onClick={onClose}>
                Cancel
              </Button>
              <Button className='primary' variant='contained' onClick={handleSubmit}>
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {showDeleteProgramConfirmationModal && (
        <Modal
          customClass='manage-programs-modal confirmation-modal'
          onClose={() => setShowDeleteProgramConfirmationModal(false)}
          size='small'>
          <Box alignContent='center'>
            <Typography variant='h6' component='h6'>
              Are you sure?
            </Typography>
            <Typography component='p'>You&apos;re about to delete the selected program</Typography>
            <Box className='delete-program-confirmation-modal-actions'>
              <Button
                variant='outlined'
                onClick={() => setShowDeleteProgramConfirmationModal(false)}>
                No
              </Button>
              <Button className='primary' variant='contained' onClick={handleDeleteCurrentProgram}>
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default EditProgram;
