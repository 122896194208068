/* eslint-disable react/prefer-stateless-function,react/no-unused-prop-types */
import { Component } from 'react';

interface Props {
  children: Component;
  fallback: Component;
  key: string;
}

interface State {
  error: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error: Error) {
    console.error(`${error.name}: ${error.message}`);
    this.setState({ error: true });
  }

  render() {
    const { error } = this.state;
    const { children, fallback } = this.props;
    if (error) {
      return <>{fallback}</>;
    }
    return <>{children}</>;
  }
}
