import { Theme } from '@mui/material';
import styled, { StyledComponent } from 'styled-components';

export const Tabs = styled.ul`
  margin-bottom: 16px;
  display: flex;
  overflow: auto;
  white-space: nowrap;
`;

export const Tab: StyledComponent<'li', Theme, { selected?: boolean }, never> = styled.li`
  font: 600 21px/24px ${({ theme }) => theme.primaryFont};
  margin-right: 16px;
  padding: 8px 24px;
  text-align: center;
  border-bottom: 3px solid ${(props) => (props.selected ? props.theme.teal : props.theme.grayLight)};
  color: ${(props) => (props.selected ? props.theme.teal : props.theme.gray)};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  display: inline-block;
`;
