import React from 'react';
import { IconContent } from '../style';

const Event = () => (
  <IconContent fill='currentColor' viewBox='0 0 18 18'>
    <path d='M8 14c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zM8 0v4h2V2.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L9 10l1.41-1.41-6.8-6.8v.02C1.42 3.45 0 6.05 0 9c0 4.97 4.02 9 9 9A9 9 0 0 0 9 0H8zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM3 9c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z' />
  </IconContent>
);

export default Event;
