import React from 'react';
import { IconContent } from '../style';

const Map = () => (
  <IconContent fill='currentColor' viewBox='0 0 18 18'>
    <path d='M17.5 0l-.16.03L12 2.1 6 0 .36 1.9c-.21.07-.36.25-.36.48V17.5c0 .28.22.5.5.5l.16-.03L6 15.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V.5c0-.28-.22-.5-.5-.5zM12 16l-6-2.11V2l6 2.11V16z' />
  </IconContent>
);

export default Map;
