import api from 'api';
import { CancelToken } from 'axios';
import { Constants } from 'components/main/calendar/utils';
import moment from 'moment';
import { ChartStateData } from 'state/reducers/chart';
import MembersClient from '../../../../../node-api/members/MembersClient';

type CoreChartData = Omit<ChartStateData, 'weights'> & {
  weights: { date: string; id: number; source: string; value: number }[];
};

type WeightRecord = {
  date: string;
  id: number;
  source: string;
  value: number;
  isAnomaly?: boolean;
  confidence?: number;
};

type WeightRecordsResponse = {
  weights: WeightRecord[];
};

export async function getWeightsFromHealthRecords(
  memberId: number,
  options?: { cancelToken: CancelToken }
): Promise<WeightRecordsResponse> {
  const { cancelToken } = options ?? {};
  const currentDate = moment();
  const membersServiceClient = new MembersClient();

  const response = await membersServiceClient.getWeights(
    memberId,
    {
      fromDate: currentDate.clone().subtract(10, 'years').format(Constants.DATE_FORMAT),
      toDate: currentDate.format(Constants.DATE_FORMAT),
    },
    cancelToken
  );

  return {
    weights: response.data.records.map(
      ({ id, recordedValue, source, sourceCreatedAt, confidence, isAnomaly }) => ({
        date: sourceCreatedAt,
        id,
        source,
        value: recordedValue,
        confidence,
        isAnomaly,
      })
    ),
  };
}

export async function getWeightsFromCore(
  memberId: number,
  options?: { cancelToken: CancelToken }
): Promise<WeightRecordsResponse> {
  const { cancelToken } = options ?? {};

  const response = await api().get<CoreChartData>(
    `/stats/patient/weight_stats/${memberId}/AUTH_TOKEN`,
    {
      cancelToken,
    }
  );

  return { weights: response.data.weights };
}

export async function getChartDataFromCore(
  memberId: number,
  options?: { cancelToken: CancelToken }
) {
  const { cancelToken } = options ?? {};
  const weightStatsFromCore = await api().get<CoreChartData>(
    `/stats/patient/weight_stats/${memberId}/AUTH_TOKEN`,
    {
      cancelToken,
    }
  );

  return weightStatsFromCore.data as CoreChartData & {
    current_bmi: number;
    initial_bmi: number;
    initial_weight: number;
    weight_loss_percentage: number;
    weight_loss_total: number;
  };
}
