import { FC } from 'react';
import logoSrc from '../../../navbar/assets/logo.png';
import { Loader, LoaderImg, LoaderWrapper } from './styles';

const EnaraLoader: FC = () => (
  <LoaderWrapper>
    <Loader />
    <LoaderImg src={logoSrc} alt='Enara logo' />
  </LoaderWrapper>
);

export default EnaraLoader;
