import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Actions = styled.div<StyledComponentsDivProps>`
  button {
    margin: auto;
  }
`;

export const Content = styled.div<StyledComponentsDivProps>`
  p {
    font-weight: bold;
    margin: 5%;
  }
`;
