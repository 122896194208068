import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import * as React from 'react';
import { FC, useEffect } from 'react';
import { PanelRow } from './types';

type Props = DialogProps & {
  selectedPanelRows: PanelRow[];
  onDismissNeedForAttention: (selectedAttention: AttentionDimension[]) => void;
  onClose: (
    value: object,
    reason?: 'backdropClick' | 'escapeKeyDown' | 'cancelButtonClick'
  ) => void;
};

/**
 * React Dialog showing all Attention Dimensions that are needed for attention for selected Panel Rows.
 * Only show Active Attention Dimensions.
 * Allows multiselect of Attention Dimensions to dismiss from Review, then calls handler to remove (dismiss) the members from selected review lists.
 * @param props
 * @returns
 */
export const PanelReviewDialog: FC<Props> = (props) => {
  const { onClose, onDismissNeedForAttention, open, selectedPanelRows, ...other } = props;

  const [selectedDimensions, setSelectedDimensions] = React.useState([] as AttentionDimension[]);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (!open) {
      setSelectedDimensions([]);
    }
  }, [open]);

  const rowsByAttentionType = selectedPanelRows.reduce((acc, row) => {
    row.attention.forEach((attention) => {
      if (!acc.has(attention.dimensionKey)) {
        acc.set(attention.dimensionKey, []);
      }
      acc.get(attention.dimensionKey)?.push(row);
    });
    return acc;
  }, new Map<AttentionDimension, PanelRow[]>());

  const mostNeededAttention = Array.from(rowsByAttentionType.entries())
    .map(([dimension, rows]) => ({
      dimension,
      count: rows.length,
      displayName: Object.entries(AttentionDimension).find(([, dim]) => dim === dimension)?.[0],
    }))
    .sort((a, b) => b.count - a.count);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose({}, 'cancelButtonClick');
  };

  const handleOk = () => {
    onDismissNeedForAttention(selectedDimensions);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth='xs'
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      onClose={onClose}
      {...other}>
      <DialogTitle>Dismiss members from Reviews</DialogTitle>
      <DialogContent>
        This will dismiss selected members from the chosen types of attention, therefore removing
        them from ongoing Review Lists.
      </DialogContent>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}>
        {mostNeededAttention.map(({ dimension, count, displayName }) =>
          displayName ? (
            <FormControlLabel
              key={dimension}
              control={
                <Checkbox
                  checked={selectedDimensions.some((a) => a === dimension)}
                  onChange={() => {
                    setSelectedDimensions(
                      selectedDimensions.includes(dimension)
                        ? selectedDimensions.filter((v) => v !== dimension)
                        : [...selectedDimensions, dimension]
                    );
                  }}
                />
              }
              label={`${displayName} (${count} members)`}
            />
          ) : (
            // Hide the dimension if it's not found in the enum, but keep the key in the DOM for debugging purposes
            <div key={dimension} id={dimension} style={{ display: 'none' }} />
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={selectedDimensions.length === 0} onClick={handleOk}>
          Dismiss from Review
        </Button>
      </DialogActions>
    </Dialog>
  );
};
