import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;

  h2 {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 24px/28px ${({ theme }) => theme.primaryFont};
  }
  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
  }
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    width: 50%;
  }

  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const Display = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div:not(:first-child) {
    margin-left: 20px;
  }

  textarea {
    margin-right: 36px;
  }
`;

export const AddBulkTasksList = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;  
  }
`;
