import { Theme } from '@mui/material';
import { StyledComponentsDivProps } from 'components/general-styles';
import { InputHTMLAttributes } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';

export const Input = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 5px;
  border: none;
  height: 40px;
  padding: 0 8px;
  width: 188px;
`;

export const InlineRecord = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 0px 120px 0px 100px;
`;

export const InlineRecordItem: StyledComponent<
  'div',
  Theme,
  { customWidth?: string; customPaddingLeft?: string },
  never
> = styled.div`
  color: ${({ theme }) => theme.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  width: ${(props) => props.customWidth || '25%'};
  padding-left: ${(props) => props.customPaddingLeft || '0'};

  &:first-child {
    justify-content: flex-start;
  }

  p {
    margin: 0 8px 0 0;
  }
`;

export const Text = styled.p`
  font: 600 15px/17px ${({ theme }) => theme.primaryFont};
  margin-bottom: 6px;
`;

export const Record = styled.div<StyledComponentsDivProps>`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.grayLight};
  display: flex;
  flex-grow: 1;
  height: 68px;
  justify-content: space-between;
  margin-bottom: -1px;
  padding: 25px;

  img {
    display: block;
    border-radius: 100%;
    height: 35px;
    width: 35px;
  }

  a {
    flex-shrink: 0;
  }
`;

export const Item = styled.div<StyledComponentsDivProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;

  &:first-child {
    width: 5%;
    padding-left: 0px;
    justify-content: flex-start;
  }

  &:nth-child(2) {
    justify-content: flex-start;
  }
`;

export const Image = styled.div<StyledComponentsDivProps & { size?: number }>`
  margin: 0 10px 0 0;
  display: inline;
  padding-right: 15px;
  ${(size) => size.size && `width: ${size.size}px; height: ${size.size}px;`}
`;

export const Link = styled(RouterLink)`
  border-bottom: 1px solid ${({ theme }) => theme.teal};
  color: ${({ theme }) => theme.teal};
  font: ${({ theme }) => theme.primaryFont};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.tealLight};
    color: ${({ theme }) => theme.tealLight};
  }
`;
