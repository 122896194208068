import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/provider';

const ProviderContext = createContext<{ providerState: State; providerDispatch: Dispatch<Action> }>(
  {
    providerState: initialState,
    providerDispatch: () => null,
  }
);

const ProviderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [providerState, providerDispatch] = useReducer(reducer, initialState);
  return (
    <ProviderContext.Provider value={{ providerState, providerDispatch }}>
      {children}
    </ProviderContext.Provider>
  );
};

export { ProviderContext, ProviderProvider };
