import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { Container, Counter } from './style';

const Tab = ({ selected, tab, onClick }) => {
  const [count, setCount] = useState();

  useEffect(() => {
    setCount(0);
  }, []);

  return (
    <Container selected={selected} onClick={() => onClick(tab.name)}>
      {tab.label}
      {!!count && <Counter>{count}</Counter>}
    </Container>
  );
};

Tab.propTypes = {
  tab: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

Tab.defaultProps = {
  selected: false,
};

export default Tab;
