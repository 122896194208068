import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/task';

const TaskContext = createContext<{ taskState: State; taskDispatch: Dispatch<Action> }>({
  taskState: initialState,
  taskDispatch: () => null,
});

const TaskProvider: FC<PropsWithChildren> = ({ children }) => {
  const [taskState, taskDispatch] = useReducer(reducer, initialState);
  return (
    <TaskContext.Provider value={{ taskState, taskDispatch }}>{children}</TaskContext.Provider>
  );
};

export { TaskContext, TaskProvider };
