import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  Set = 'SET',
  ToggleModal = 'TOGGLE_MODAL',
}

export type State = {
  data: unknown[];
  modal: boolean;
};

type Payload = {
  [ActionTypes.Set]: State['data'];
  [ActionTypes.ToggleModal]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  data: [],
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.Set: {
      const { payload } = action;
      return {
        ...state,
        data: payload,
      };
    }

    case ActionTypes.ToggleModal: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
