import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  Reload = 'RELOAD',
}

export type State = {
  reload: number;
};

type Payload = {
  [ActionTypes.Reload]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  reload: 0,
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.Reload: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
