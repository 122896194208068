import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Input, Button } from '../../elements';
import { Title, Text, Form } from '../account-styles';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password minimun length should be 8 chars long')
    .required('Password is required field'),
  password_confirmation: yup
    .string()
    .required('Password confirmation is required field')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = ({ submit }) => {
  const { handleSubmit, register, errors, formState } = useForm({
    validationSchema: schema,
  });

  return (
    <>
      <Title>Password Reset</Title>
      <Text>Choose a new password</Text>
      <Form onSubmit={handleSubmit(submit)}>
        <Input
          errors={errors.password}
          name='password'
          label='New password'
          type='password'
          register={register}
        />

        <Input
          errors={errors.passwordConfirmation}
          name='password_confirmation'
          label='Confirm password'
          type='password'
          register={register}
        />

        <Button type='submit' size='large' submitting={formState.isSubmitting}>
          Confirm
        </Button>
      </Form>
    </>
  );
};

SetPassword.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default SetPassword;
