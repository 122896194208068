import styled from 'styled-components';

export const CalendarLayout = styled.div`
  display: block;
  background: #ffffff;
  width: 46%;
  height: 300px;
  border: none;
  border-radius: 8px;
  padding: 10px 0;
  box-shadow: 0 0 15px 0 #c0c0c0;
  font-size: 1.4rem;
  text-align: center;
`;

export const Month = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: ${({ theme }) => theme.gray};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  font-weight: 600;
`;

export const Week = styled.div`
  width: 90%
  height: 35px;
  margin: 0 20px;
  border-radius: 6px;
  background-color: #CBCBCB;

  span {
    color: #FFFFFF;
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: 13px;
    font-weight: 600;
    padding: 0 15px;
  }
`;

export const Daystyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 30px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: 400;
  font-size: 16px;
  color: ${({ color }) => `#${color}`};
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  margin: 2px;

  &:hover {
    background: #efefef;
  }

  &:first-child {
    border-left: none;
  }

  ${({ today }) =>
    today &&
    `
    background: lighten(#CBCBCB,45%);
  `}

  ${({ selected }) =>
    selected &&
    `
    border-radius: 5px;
    background: #CBCBCB;
    color: #FFFFFF;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

export const Section = styled.div`
  width: 175px;
  color: ${({ theme }) => theme.gray};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 16px;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
`;
