import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const AutocompleteWrapper = styled.div`
  height: max-content;
  width: max-content;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.grayLighter};
  display: flex;
`;

export const CompactAutocompleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2;
  height: 26px;
`;

export const CompactFilterLabel = styled.label`
  color: ${({ theme }) => theme.gray};
  align-self: center;
  width: 100%;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  padding-top: 1px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;
