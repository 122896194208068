import { IRole } from './rpm.types';

export const checkRole = (roles: IRole[], roleToFind: string) =>
  roles?.filter((role) => role.first_name === roleToFind)?.length > 0;

export const getUserInfo = (
  user: Partial<{
    available_logins: string[];
    original_user: {
      first_name: string;
      last_name: string;
      available_logins: string[];
    };
    clinical_role: {
      description: string;
      id: number;
      name: string;
    };
    first_name: string;
    last_name: string;
  }>
) => {
  const current = user?.original_user
    ? {
        roles: user.original_user.available_logins,
        fullName: `${user.original_user.first_name} ${user.original_user.last_name}`,
        role: `${user.first_name} ${user.last_name}`,
        clinicalRole: user.clinical_role,
      }
    : {
        roles: user.available_logins,
        fullName: `${user.first_name} ${user.last_name}`,
        role: '',
        clinicalRole: user.clinical_role,
      };
  return current;
};

export const dateFormatWithTime = (value: string | Date) => {
  if (value instanceof Date) {
    return value.toISOString().substring(0, 19);
  }

  return value.substring(0, 19);
};
