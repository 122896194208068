import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
}

type User = Partial<{
  available_logins: string[];
  clinical_role: {
    description: string;
    id: number;
    name: string;
  };
  first_name: string;
  id: number;
  uuid: string;
  last_name: string;
  original_user: {
    available_logins: string[];
    first_name: string;
    last_name: string;
  };
}>;

export type State = User & { auth_token?: string; isOriginalUser?: boolean };

type Payload = {
  [ActionTypes.SET]: User & { isOriginalUser?: boolean };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
