import BulkActionsView from 'components/bulk/BulkActionsView';
import BulkMessageView from 'components/bulk/bulk-message/BulkMessageView';
import { Button } from 'components/elements';
import { SectionTitle } from 'components/general-styles';
import Icon from 'components/icon';
import { isReviewList4 } from 'controllers/review/review-controller';
import FeatureManager from 'lib/feature/feature';
import { delay, lowerCase, startCase } from 'lodash';
import { PropTypes } from 'prop-types';
import { useCallback, useContext, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { ChartContext } from 'state/contexts/chart';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { ActionTypes as ChartActionTypes } from 'state/reducers/chart';
import { ActionTypes } from 'state/reducers/patient';
import { config } from './config';
import { Content, Counter, Item, Pagination, Section } from './style';

const ReviewList = ({ reviewListLabel, reviewListName }) => {
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { reviewLists4State } = useContext(ReviewLists4Context);
  const { featuresState } = useContext(FeaturesContext);
  const { chartDispatch } = useContext(ChartContext);

  const patients = patientState.data;
  const numberOfPatients = patients.length;

  const sliderRef = useRef(null);
  const currentPatientIndex = useRef(patients.findIndex((patient) => patient.current));

  const handleMoveBetweenPatients = useCallback(
    (direction) => {
      const newIndex =
        direction === 'prev' ? currentPatientIndex.current - 1 : currentPatientIndex.current + 1;

      if (newIndex < 0 || newIndex > numberOfPatients - 1) {
        return;
      }

      if (direction === 'prev') {
        sliderRef.current.slickPrev();
      } else {
        sliderRef.current.slickNext();
      }

      currentPatientIndex.current = newIndex;

      patientDispatch({ type: ActionTypes.SELECT, payload: { id: patients[newIndex].id } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberOfPatients, patientDispatch]
  );

  const handleSelectPatient = (id) => {
    if (id !== patients[currentPatientIndex.current].id) {
      const index = patients.findIndex((patient) => patient.id === id);
      currentPatientIndex.current = index;

      chartDispatch({ type: ChartActionTypes.CLEAR });

      patientDispatch({ type: ActionTypes.SELECT, payload: { id } });
    }
  };

  useEffect(() => {
    if (patientState.slide) {
      delay(handleMoveBetweenPatients, 50, 'next');
    }
  }, [patientState.slide, handleMoveBetweenPatients]);

  return (
    <Section>
      <div style={{ marginTop: 10 }}>
        {FeatureManager.isBulkMessagesEnabledForReviewList({
          reviewListSlug: reviewListName,
          featuresState,
        }) && (
          <div style={{ float: 'right' }}>
            <BulkActionsView
              buttonActionLabel='Send Bulk Message'
              rightChildComponent={<BulkMessageView />}
              reviewListName={reviewListName}
            />
          </div>
        )}

        <SectionTitle>
          {isReviewList4(reviewListName, reviewLists4State.data)
            ? reviewListLabel
            : startCase(lowerCase(reviewListLabel))}
        </SectionTitle>
      </div>

      {numberOfPatients > 0 ? (
        <Content slidesToShow={config.slidesToShow} numberOfPatients={numberOfPatients}>
          <Slider ref={sliderRef} {...config}>
            {patients.map((patient) => (
              <Item
                key={`patient-slide-${patient.id}`}
                current={patient.current}
                onClick={() => handleSelectPatient(patient.id)}
                reviewed={patient.reviewed}
                role='button'>
                {patient.avatar?.thumb?.url ? (
                  <img src={patient.avatar.thumb.url} alt={patient.first_name} />
                ) : (
                  <Icon icon='userBubble' color='turquoise' size={80} />
                )}

                <p>{patient.first_name}</p>
              </Item>
            ))}
          </Slider>

          <Pagination>
            <Counter>{`${currentPatientIndex.current + 1}/${numberOfPatients}`}</Counter>
            <Button
              onClick={() => handleMoveBetweenPatients('prev')}
              disabled={currentPatientIndex.current === 0}>
              Previous
            </Button>
            <Button
              onClick={() => handleMoveBetweenPatients('next')}
              disabled={currentPatientIndex.current + 1 === numberOfPatients}>
              Next
            </Button>
          </Pagination>
        </Content>
      ) : (
        <div>There are no patients waiting for review.</div>
      )}
    </Section>
  );
};

ReviewList.propTypes = {
  reviewListLabel: PropTypes.string.isRequired,
  reviewListName: PropTypes.string.isRequired,
};

ReviewList.default = {};

export default ReviewList;
