import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/weights';

const WeightsContext = createContext<{ weightsState: State; weightsDispatch: Dispatch<Action> }>({
  weightsState: initialState,
  weightsDispatch: () => null,
});

const WeightsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [weightsState, weightsDispatch] = useReducer(reducer, initialState);
  return (
    <WeightsContext.Provider value={{ weightsState, weightsDispatch }}>
      {children}
    </WeightsContext.Provider>
  );
};

export { WeightsContext, WeightsProvider };
