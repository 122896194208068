const AVATAR_BASE_URL = process.env.REACT_APP_AVATAR_BASE_URL;

export const getMemberProfileUrl = (member: { uuid: string }) => `/members/${member.uuid}?`;
export const getMemberAvatarUrl = ({
  id,
  avatar,
}: {
  id: number;
  avatar: string;
}): {
  url: string;
  thumb: {
    url: string;
  };
} => ({
  url: avatar ? `${AVATAR_BASE_URL}${id}/${avatar}` : '',
  thumb: {
    url: avatar ? `${AVATAR_BASE_URL}${id}/thumb_${avatar}` : '',
  },
});
