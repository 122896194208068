import { StyledComponentsDivProps } from 'components/general-styles';
import styled, { StyledComponent } from 'styled-components';

export const DashboardContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  margin: 2px;
  gap: 15px;
`;

export const FilterContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const FiltersActions: StyledComponent<
  'div',
  never,
  { showFilters: boolean },
  never
> = styled.div`
  border: 1px solid ${({ theme }) => theme.dashboard.gray};
  border-radius: 5px;
  display: ${(props) => (props.showFilters ? 'default' : 'none')};
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.white};
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Filters = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const FilterLinkContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: end;
  padding: 2px;
  gap: 20px;
  min-width: 350px;
`;

export const FilterTitleContainer: StyledComponent<
  'div',
  never,
  { showFilters: boolean },
  never
> = styled.div`
  display: flex;
  margin-top: 10px;
  font-family: ${({ theme }) => theme.primaryFont};

  button {
    padding: 4px;
    top: 11px;
    transform: ${({ showFilters }) => (showFilters ? 'none' : 'rotate(-90deg)')};
  }
`;

export const FilterTitle = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.grayDarker};
  font: 20px/22px ${({ theme }) => theme.primaryFont};
  margin: 10px 10px 10px 0;
`;

export const SpanLabel = styled.span`
  font-size: 13px;
`;

export const CompactFilterSeparator = styled.div<StyledComponentsDivProps>`
  width: 1px;
  background-color: ${({ theme }) => theme.dashboard.gray};
`;

export const Separator = styled.div<StyledComponentsDivProps>`
  width: 10px;
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  & button {
    width: 180px;
  }

  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const Record = styled.div<StyledComponentsDivProps>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
`;

export const Item = styled.div<StyledComponentsDivProps>``;

export const MetricsContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 25px;
  width: 100%;
`;

export const NoMetrics = styled.div<StyledComponentsDivProps>``;
