import axios from 'axios';
import { Loading } from 'components/elements';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment';
import node_api from 'node-api';
import { FC, FormEvent, useContext, useEffect, useState } from 'react';
import CardsService from 'services/card-service';
import { ILegacyNodeCardComment, ILegacyNodeCardListEntry } from 'services/card-service.types';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { debug } from 'utils/helpers';
import {
  Body,
  BubbleFooter,
  Comment,
  Container,
  Content,
  Footer,
  Input,
  Name,
  Row,
  SendButton,
  Sender,
} from './style';

const CardsPendingCommentsDetail: FC<{ card: ILegacyNodeCardListEntry; memberId: number }> = ({
  card,
  memberId,
}) => {
  const { patientState } = useContext(PatientContext);
  const member = patientState.data.find((p) => p.id === memberId);

  const { featuresState } = useContext(FeaturesContext);
  const canMarkAsReviewed = featuresState?.[EFeatures.ProviderProfileShowNeedsAttention];

  const [commentContent, setCommentContent] = useState('');
  const [comments, setComments] = useState<ILegacyNodeCardComment[]>([]);
  const [loading, setLoading] = useState(true);

  const getComments = async () => {
    const source = axios.CancelToken.source();
    try {
      const params = { card_id: card.card_id, auth_token: 'AUTH_TOKEN' };
      const {
        data: { data },
      } = await node_api().get<{ data: { comments: ILegacyNodeCardComment[] } }>('card', {
        cancelToken: source.token,
        params,
      });
      typeof data.comments === 'undefined' ? setComments([]) : setComments(data.comments);
      setLoading(false);
    } catch (err: unknown) {
      if (!axios.isCancel(err)) {
        debug(err as string);
        setLoading(false);
      }
    }
  };

  const refreshComments = () => {
    setCommentContent('');
    setLoading(true);
    const source = axios.CancelToken.source();
    getComments();
    return source.cancel;
  };

  const handlePostComment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!member || !card) {
      return;
    }
    try {
      await CardsService.postComment({ member, card, comment: commentContent });

      MixpanelWrapperInstance.track(EventNames.CardWithPendingCommentsClickCommentButton, {
        targetLabel: 'Comment',
        targetType: ClickableElement.BUTTON,
        'card-id': card.card_id,
        'member-id': memberId,
      });
      refreshComments();
    } catch (err: unknown) {
      debug(err as string);
    }
  };

  const handleMarkAsReviewed = async () => {
    if (!member || !card) {
      return;
    }
    try {
      await CardsService.markAsReviewed({ member, card });
      // TODO: Remove this card from the list
      MixpanelWrapperInstance.track(EventNames.CardWithPendingCommentsClickReviewButton, {
        targetLabel: 'Reviewed',
        targetType: ClickableElement.BUTTON,
        'card-id': card.card_id,
        'member-id': memberId,
      });
      refreshComments();
    } catch (err: unknown) {
      debug(err as string);
    }
  };

  useEffect(() => {
    const cancel = refreshComments();

    return () => {
      cancel();
    };
  }, []);

  if (loading) return <Loading />;

  return (
    <Container>
      <Body>
        {!loading &&
          comments.map((singleComment) => (
            <Row
              key={`c-${singleComment.sender_first_name}-${singleComment.comment_date}`}
              provider={singleComment.provider.toString()}>
              <Content>
                <Sender>
                  <Name>{singleComment.sender_first_name}</Name>
                </Sender>
                <Comment>{singleComment.content}</Comment>
                <BubbleFooter>{`${moment(singleComment.comment_date).fromNow()}`}</BubbleFooter>
              </Content>
            </Row>
          ))}
      </Body>
      <Footer>
        <form
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            backgroundColor: 'white',
            flexDirection: 'row',
            flex: 1,
          }}
          onSubmit={handlePostComment}>
          <Input
            placeholder='Write comment'
            onChange={(e) => setCommentContent(e.target.value)}
            // value={comment}
          />
          <div
            style={{
              // This code will be removed once we implement the new ReviewList/Attention designs
              display: 'flex',
              alignItems: 'flex-end',
              justifyItems: 'flex-end',
              flexDirection: 'column',
            }}>
            {canMarkAsReviewed && (
              <SendButton type='button' disabled={!!commentContent} onClick={handleMarkAsReviewed}>
                Reviewed
              </SendButton>
            )}
            <SendButton type='submit' disabled={!commentContent}>
              Comment
            </SendButton>
          </div>
        </form>
      </Footer>
    </Container>
  );
};

export default CardsPendingCommentsDetail;
