import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Switch, Slider, Label } from './style';

/**
 * Toggle
 *
 * @param {String} label Content to show next to the toggle
 * @param {boolean} isChecked Toggle state
 * @param {function} onUpdate Callback to execute on state update
 *
 * Example: <Toggle label="Turn notifications on" />
 */
const Toggle = ({ isChecked, label, onUpdate }) => {
  const [state, setState] = useState(isChecked || false);

  return (
    <Wrapper>
      <Switch>
        <input
          type='checkbox'
          defaultChecked={state ? 'checked' : null}
          onClick={() => {
            if (onUpdate) onUpdate(!state);
            return setState(!state);
          }}
        />
        <Slider className='slider round' />
      </Switch>
      <Label>{typeof label === 'string' ? label : state ? label[0] : label[1]}</Label>
    </Wrapper>
  );
};

Toggle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isChecked: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Toggle.defaultProps = {
  isChecked: false,
  onUpdate() {
    // eslint-disable-next-line no-console
    console.log('You should handle onUpdate with a callback function...');
  },
};

export default Toggle;
