import { FeaturesState } from 'state/reducers/features';

export enum EFeatures {
  ProviderShowAcuityButton = 'provider-show-acuity-button',
  ProviderShowAppointments = 'provider-show-appointments',
  ProviderShowExerciseNotifications = 'provider-show-exercise-notifications',
  ProviderShowPcc = 'provider-show-pcc',
  ProviderShowPcs = 'provider-show-pcs',
  ProviderShowRecentCards = 'provider_show_recent_cards',
  ProviderShowSchedulingLinkButton = 'provider-show-scheduling-link-button',
  ProviderBulkMessagesDisengaging = 'provider-bulk-messages-disengaging',
  ProviderBulkMessagesDueForEngagementByAny = 'provider-bulk-messages-due-for-engagement-by-any',
  ProviderBulkMessagesDueForEngagementByMe = 'provider-bulk-messages-due-for-engagement-by-me',
  ProviderBulkMessagesWeightRegainWarning = 'provider-bulk-messages-weight-regain-warning',
  ProviderGetWeightsFromHealthRecords = 'provider-get-weights-from-health-records',
  ProviderPanelWithFilters = 'provider-panel-with-filters',
  ProviderPanelShowDrawer = 'provider-panel-drawer',
  ProviderProfileChartMedicationHistory = 'provider-profile-charts-medication',
  ProviderProfileShowNeedsAttention = 'provider-profile-show-needs-attention',
  ProviderProfileTabsShowMore = 'provider-profile-tabs-show-more',
  ProviderRL4ShowFoodPictureComments = 'provider-rl4-show-food-picture-comments',
  ProviderShowCalendar = 'provider-show-calendar',
  ProviderShowGroupClasses = 'provider-show-group-classes',
  ProviderShowPvq = 'provider-show-pvq',
  ProviderShowScheduleWarnings = 'provider-show-schedule-warnings',
  ProviderShowWeightPrediction = 'provider-show-weight-prediction',
  ProviderShowVitals = 'provider-show-vitals',
  ProviderShowNotes = 'provider-show-notes',
  ProviderProfileEnableDisableNewVideoCall = 'provider-enabled-disabled-new-video-call',
}

export const DEFAULT_FEATURES: FeaturesState = {
  [EFeatures.ProviderShowAcuityButton]: false,
  [EFeatures.ProviderShowAppointments]: false,
  [EFeatures.ProviderShowExerciseNotifications]: false,
  [EFeatures.ProviderShowPcc]: false,
  [EFeatures.ProviderShowPcs]: false,
  [EFeatures.ProviderShowRecentCards]: false,
  [EFeatures.ProviderShowSchedulingLinkButton]: false,
  [EFeatures.ProviderBulkMessagesDisengaging]: false,
  [EFeatures.ProviderBulkMessagesDueForEngagementByAny]: false,
  [EFeatures.ProviderBulkMessagesDueForEngagementByMe]: false,
  [EFeatures.ProviderBulkMessagesWeightRegainWarning]: false,
  [EFeatures.ProviderGetWeightsFromHealthRecords]: false,
  [EFeatures.ProviderPanelWithFilters]: false,
  [EFeatures.ProviderPanelShowDrawer]: false,
  [EFeatures.ProviderProfileChartMedicationHistory]: false,
  [EFeatures.ProviderProfileShowNeedsAttention]: false,
  [EFeatures.ProviderProfileTabsShowMore]: false,
  [EFeatures.ProviderRL4ShowFoodPictureComments]: false,
  [EFeatures.ProviderShowCalendar]: false,
  [EFeatures.ProviderShowGroupClasses]: false,
  [EFeatures.ProviderShowPvq]: false,
  [EFeatures.ProviderShowScheduleWarnings]: false,
  [EFeatures.ProviderShowWeightPrediction]: false,
  [EFeatures.ProviderShowVitals]: false,
  [EFeatures.ProviderShowNotes]: false,
  [EFeatures.ProviderProfileEnableDisableNewVideoCall]: false,
};
