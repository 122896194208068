import styled from 'styled-components';

export const InputMessageBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.grayLight};
  font: 15px/17px ${({ theme }) => theme.primaryFont};
  border-radius: 56px;
  box-shadow: 0 0 12px 7px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  min-height: 80px;
  margin: 1px 0 20px;
  padding: 10px 25px;
  width: 100%;

  & > div {
    height: 100%;
    margin: 0 16px 0 0;
    max-width: calc(100% - 62px);
    width: 100%;
  }

  & > button {
    flex-shrink: 0;
  }

  & textarea::placeholder {
    color: ${(props) => (props.type === 'team' ? props.theme.teal : props.theme.salmon)};
  }
`;

export const SendButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.teal};
  border-radius: 50%;
  border: none;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 46px;

  &:disabled {
    background: ${({ theme }) => theme.gray};
    cursor: auto;
  }

  & i {
    margin-right: -3px;
  }
`;

export const ActionButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.teal};
  border-radius: 3px;
  border: none;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 8px;
  width: 30px;
`;

export const Input = styled.input`
  border: none;
  color: ${({ theme }) => theme.gray};
  font: 16px/19px ${({ theme }) => theme.primaryFont};
  margin-right: 5px;
  width: 95%;
`;

export const ActionTag = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.teal};
  display: inherit;
  height: 40px;
  padding: 0 10px;
  width: auto;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 14px;
  margin-left: 26px;

  img {
    cursor: pointer;
    margin-right: 6px;
  }
`;
