import styled from 'styled-components';

export const Table = styled.table`
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.teal};
  text-align: center;

  th {
    font: 600 20px/20px ${({ theme }) => theme.primaryFont};
    padding: 10px 10px;
  }

  td {
    font: 600 70px/50px ${({ theme }) => theme.primaryFont};
    padding: 5px 15px;

    p {
      display: flex;
      flex-grow: 1;
      margin: 55px -55px 0px -50px;
      font: 600 15px/20px ${({ theme }) => theme.primaryFont};
    }
  }
`;

export const Info = styled.div`
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.turquoise};
  display: block;
  justify-content: space-between;
  padding: 40px 50px;
  }
`;

export const InfoFirstColumn = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 40px 50px;
  color: ${({ theme }) => theme.grayDarker};
`;

export const FiltersActions = styled.div`
  padding: 20px;
  border: 2px solid ${({ theme }) => theme.gray};
  border-radius: 8px;
  display: ${(props) => (props.showFilters ? 'default' : 'none')};
`;

export const FilterTitleContainer = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    padding: 4px;
    top: 11px;
  }
`;

export const FilterTitle = styled.div`
  color: ${({ theme }) => theme.grayDarker};
  font: 22px/24px ${({ theme }) => theme.primaryFont};
  margin: 10px 10px 10px 0;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Separator = styled.div`
  width: 10px;
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  & button {
    width: 180px;
  }

  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const Record = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
`;

export const Item = styled.div``;
