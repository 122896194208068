import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const LabelSpan = styled.span`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  bottom: 40px;
  left: 14px;
  padding: 4px 6px;
  color: #fff;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const Image = styled.img`
  display: flex;
  height: 100%;
  width: 65%;
  margin-right: -40px;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15px;
  padding: 30px;
  width: 35%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  height: 50%;
  margin-bottom: 1em;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.grayDark};
    font: 600 15px/17px ${({ theme }) => theme.primaryFont};
    height: 17px;
    margin: 5px 0px;
    width: 112px;
  }
`;

export const Comments = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const Avatar = styled.img`
  border-radius: 20px;
  display: block;
  height: 35px;
  margin-right: 12px;
  overflow: hidden;
  text-align: center;
  width: 35px;
`;

export const SectionImage = styled.div``;

export const SectionDetails = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 15px/17px ${({ theme }) => theme.primaryFont};
    margin-bottom: 4px;
  }

  div:last-child {
    color: ${({ theme }) => theme.gray};
    font: 300 13px/14px ${({ theme }) => theme.primaryFont};
  }
`;

export const Line = styled.hr`
  border: 0.25px solid ${({ theme }) => theme.grayLight};
  margin: 16px 0;
`;

export const Input = styled.textarea.attrs((props) => ({ value: props.value }))`
  background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  height: 128px;
  line-height: 19px;
  margin-bottom: 5px;
  padding: 10px;
  resize: none;
  width: 100%;
`;

export const AutoComment = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: .5px solid ${({ theme }) => theme.grayLight};
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.15);
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  height: 98%;
  justify-content: space-around;
  margin-bottom: 10px;
  position: absolute;
  top: 14%
  width: 100%;

  p {
    width:auto;
    color:${({ theme }) => theme.grayDarker};
    font: 300 19px/24px ${({ theme }) => theme.primaryFont};
    height: 24px;
    margin-top: 7px;
  }

  button {
    background-color: ${({ theme }) => theme.white};
    border: none;
    color: ${({ theme }) => theme.teal};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: 15px;
    line-height: 16px;

    width: 100%;
    padding: 3px 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;  
    text-align: left;  
  }
`;

export const PredefinedMessages = styled.ul`
  width: 100%;
  padding-left: 30px;
  padding-right: 10px;
`;

export const PredefinedMessage = styled.li`
  width: 100%;
  list-style-type: unset;
`;

export const PostCommentHeader = styled.div`
  display: flex;
`;

export const Button = styled.div`
  border: none;
  display: flex;
  margin-right: 8px;
  padding-top: 2px;
  cursor: pointer;
`;

export const PostComment = styled.div`
  white-space: nowrap;
`;

export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.teal};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  height: 35px;
  padding: 0px 8px;
  text-align: center;

  &:disabled {
    background: ${({ theme }) => theme.gray};
    cursor: auto;
  }
`;

export const RightArrow = styled.button`
  display: initial;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  bottom: 40px;
  padding: 0px !important;
  margin: 0px !important;
  color: #1a667a;
  top: 40px;
  border-color: transparent;
`;

export const LeftArrow = styled.button`
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  bottom: 40px;
  padding: 0px !important;
  margin: 0px !important;
  color: teal;
  top: 40px;
  border-color: transparent;
`;
