import React from 'react';
import { IconContent } from '../style';

const Heart = () => (
  <IconContent fill='currentColor' viewBox='0 0 25 23'>
    <path d='M12.425 4.063C13.435 1.671 15.74 0 18.42 0c3.612 0 6.213 3.09 6.54 6.772 0 0 .177.914-.212 2.56-.529 2.24-1.773 4.231-3.45 5.751L12.426 23l-8.724-7.918C2.025 13.563.781 11.572.252 9.331-.137 7.686.04 6.771.04 6.771.367 3.09 2.968 0 6.58 0c2.681 0 4.836 1.671 5.845 4.063z' />
  </IconContent>
);

export default Heart;
