import axios from 'axios';
import node_api from 'node-api';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { ChartContext } from 'state/contexts/chart';

import { Loading } from 'components/elements';
import { debug } from 'utils/helpers';

import { ChartBox, ChartSection } from 'components/general-styles';
import { ActionTypes } from 'state/reducers/chart';
import { ChartTitle } from '../style';
import Charts from './chart';

const CardChart = ({ patientId, visible }) => {
  const { chartState, chartDispatch } = useContext(ChartContext);

  const [loading, setLoading] = useState(true);
  const [chart, setChart] = useState();

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    const getChartData = async () => {
      if (!(chartState.patientId === patientId && chartState.data.providers.values)) {
        if (chartState.patientId !== patientId) {
          chartDispatch({ type: ActionTypes.CLEAR });
        }
        try {
          const { data } = await node_api().get('cardscompletion', {
            cancelToken: source.token,
            params: {
              auth_token: 'AUTH_TOKEN',
              patient_id: patientId,
            },
          });

          chartDispatch({
            type: ActionTypes.SET,
            payload: {
              patientId,
              data,
            },
          });

          setChart(data.data);
          setLoading(false);
        } catch (err) {
          if (!axios.isCancel(err)) {
            debug(err);
            setLoading(false);
          }
        }
      } else {
        setChart(chartState.data);
        setLoading(false);
      }
    };

    getChartData();

    return () => {
      source.cancel();
    };
  }, [patientId]);

  if (!visible) {
    return null;
  }

  if (loading) return <Loading />;

  return (
    <>
      {chart && (
        <ChartSection>
          <ChartBox>
            <ChartTitle>
              <div>
                <h4>Card Engagment</h4>
              </div>
            </ChartTitle>
            {chart.providers && <Charts />}
          </ChartBox>
        </ChartSection>
      )}
    </>
  );
};

CardChart.propTypes = {
  patientId: PropTypes.number.isRequired,
};

CardChart.defaultProps = {};

export default CardChart;
