import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  DashboardFilters,
  DashboardMetricValuesQueryParams,
  DashboardMetricValuesResponse,
} from 'node-api/dashboard.types';

export default class DashboardService extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.dashboards);
  }

  public async getFilters() {
    try {
      const { data } = await this.axiosClient.get<DashboardFilters>(
        `/v1/dashboards/provider-dashboard/filters`
      );

      return data;
    } catch (err) {
      console.error(`[DashboardService::getFilters] Error getting filters: ${err}`);
      return null;
    }
  }

  public async getMetricValue(
    providerId: number,
    metricSlug: string,
    { clinicId, dashboardProgramSlug, dashboardStatusSlug, date }: DashboardMetricValuesQueryParams
  ) {
    try {
      const { data } = await this.axiosClient.get<DashboardMetricValuesResponse>(
        `/v1/providers/${providerId}/metrics/${metricSlug}`,
        { params: { clinicId, dashboardProgramSlug, dashboardStatusSlug, date } }
      );

      return data;
    } catch (err) {
      console.error(
        `[DashboardService::getMetricValue] Error getting dashboard metric values: ${err}`
      );
      throw err;
    }
  }
}
