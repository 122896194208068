/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  makeStyles,
} from '@material-ui/core';
import Theme from 'styles/theme';

const useStyles = makeStyles(() => ({
  radioGroup: {
    gap: '0.6em',
  },
  radioButton: {
    border: `1px solid ${Theme.grayLight}`,
    borderRadius: '4px',
    padding: '7px',
    margin: 0,
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  selectedRadioButton: {
    border: `2px solid ${Theme.turquoiseDark}`,
    color: Theme.teal,
    '& .MuiTypography-root': {
      fontWeight: 'bold',
    },
  },
}));

interface RadioButtonsProps extends RadioGroupProps {
  options: (Pick<FormControlLabelProps, 'label' | 'value'> & Partial<FormControlLabelProps>)[];
}

const RadioButton: FC<RadioProps> = (props) => (
  <Radio disableRipple disableFocusRipple {...props} />
);

const RadioButtonsElement: FC<RadioButtonsProps> = ({
  onChange,
  options,
  value,
  ...radioGroupProps
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth hiddenLabel variant='outlined'>
      <RadioGroup
        {...radioGroupProps}
        onChange={onChange}
        value={value}
        className={classes.radioGroup}
        row>
        {options.map((optionProps) => (
          <FormControlLabel
            key={`radio-button-appointment-type-${optionProps.value}`}
            className={
              value === optionProps.value
                ? [classes.selectedRadioButton, classes.radioButton].join(' ')
                : classes.radioButton
            }
            control={<RadioButton />}
            {...optionProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsElement;
