import React from 'react';
import { IconContent } from '../style';

const Clock = () => (
  <IconContent fill='currentColor' viewBox='0 0 30 30'>
    <g>
      <path d='M6.27 5.588c.311.259.572.453.806.676.29.276.347.618.041.898-.278.255-.61.2-.855-.088-.22-.257-.406-.542-.635-.851-2.176 2.453-3.276 5.093-3.452 8.198.403 0 .742.002 1.08 0 .388-.002.64.197.645.573.005.386-.264.57-.644.575-.338.003-.676 0-1.08 0 .176 3.126 1.29 5.77 3.374 8.114.249-.27.465-.512.687-.748.268-.284.582-.411.89-.098.294.296.222.615-.066.9-.236.234-.463.478-.69.715 1.828 1.902 5.848 3.547 8.05 3.283 0-.293.007-.594-.002-.895-.013-.416.11-.735.584-.73.48.006.576.348.569.75-.005.3-.001.598-.001.95 3.143-.175 5.782-1.29 8.113-3.378-.309-.282-.573-.502-.811-.747-.268-.274-.295-.58-.004-.855.272-.26.567-.213.813.04.246.258.462.544.723.854 2.114-2.398 3.25-5.032 3.395-8.152-.33 0-.58-.003-.828 0-.418.006-.85-.007-.86-.565-.011-.557.402-.601.832-.588.265.008.53.002.86.002-.153-3.136-1.289-5.77-3.405-8.16-.247.299-.449.553-.663.797-.25.281-.556.363-.855.109-.33-.28-.27-.62.009-.895.251-.246.548-.446.844-.681-2.419-2.127-5.054-3.247-8.165-3.412 0 .404.003.743-.001 1.08-.005.381-.192.648-.578.64-.375-.006-.573-.259-.57-.648.004-.337.001-.675.001-1.082-3.105.19-5.75 1.278-8.15 3.419M29 14.983c0 7.74-6.29 14.023-14.034 14.017C7.26 28.994.99 22.702 1 14.98 1.01 7.248 7.27.995 14.996 1 22.721 1.004 29 7.272 29 14.983' />
      <path d='M6.27 5.588c.311.259.572.453.806.676.29.276.347.618.041.898-.278.255-.61.2-.855-.088-.22-.257-.406-.542-.635-.851-2.176 2.453-3.276 5.093-3.452 8.198.403 0 .742.002 1.08 0 .388-.002.64.197.645.573.005.386-.264.57-.644.575-.338.003-.676 0-1.08 0 .176 3.126 1.29 5.77 3.374 8.114.249-.27.465-.512.687-.748.268-.284.582-.411.89-.098.294.296.222.615-.066.9-.236.234-.463.478-.69.715 1.828 1.902 5.848 3.547 8.05 3.283 0-.293.007-.594-.002-.895-.013-.416.11-.735.584-.73.48.006.576.348.569.75-.005.3-.001.598-.001.95 3.143-.175 5.782-1.29 8.113-3.378-.309-.282-.573-.502-.811-.747-.268-.274-.295-.58-.004-.855.272-.26.567-.213.813.04.246.258.462.544.723.854 2.114-2.398 3.25-5.032 3.395-8.152-.33 0-.58-.003-.828 0-.418.006-.85-.007-.86-.565-.011-.557.402-.601.832-.588.265.008.53.002.86.002-.153-3.136-1.289-5.77-3.405-8.16-.247.299-.449.553-.663.797-.25.281-.556.363-.855.109-.33-.28-.27-.62.009-.895.251-.246.548-.446.844-.681-2.419-2.127-5.054-3.247-8.165-3.412 0 .404.003.743-.001 1.08-.005.381-.192.648-.578.64-.375-.006-.573-.259-.57-.648.004-.337.001-.675.001-1.082-3.105.19-5.75 1.278-8.15 3.419zM29 14.983c0 7.74-6.29 14.023-14.034 14.017C7.26 28.994.99 22.702 1 14.98 1.01 7.248 7.27.995 14.996 1 22.721 1.004 29 7.272 29 14.983z' />
      <path d='M15.25 14.416c-.63-.002-1.147.541-1.132 1.19.014.668.53 1.205 1.145 1.192.586-.013 1.1-.558 1.11-1.177.01-.652-.505-1.205-1.123-1.205zm.567-4.596c0 .967.012 1.935-.005 2.902-.007.365.08.563.411.771.398.249.764.647 1.002 1.071.195.35.377.447.724.441 1.098-.02 2.196.002 3.295-.011.424-.006.762.125.756.618-.006.465-.345.58-.741.576-1.134-.01-2.267.005-3.4-.009-.295-.003-.46.077-.59.395-.456 1.118-1.444 1.502-2.278 1.414-1.017-.107-1.827-.963-1.96-1.946-.16-1.175.312-2.134 1.334-2.618.277-.132.334-.304.333-.595a708.12 708.12 0 0 1 0-6.026c0-.197.004-.459.113-.571.14-.143.465-.287.573-.211.197.139.402.435.412.674.044 1.04.02 2.083.02 3.125h.001z' />
      <path d='M15.25 14.416c-.63-.002-1.147.541-1.132 1.19.014.668.53 1.205 1.145 1.192.586-.013 1.1-.558 1.11-1.177.01-.652-.505-1.205-1.123-1.205zm.567-4.596c0 .967.012 1.935-.005 2.902-.007.365.08.563.411.771.398.249.764.647 1.002 1.071.195.35.377.447.724.441 1.098-.02 2.196.002 3.295-.011.424-.006.762.125.756.618-.006.465-.345.58-.741.576-1.134-.01-2.267.005-3.4-.009-.295-.003-.46.077-.59.395-.456 1.118-1.444 1.502-2.278 1.414-1.017-.107-1.827-.963-1.96-1.946-.16-1.175.312-2.134 1.334-2.618.277-.132.334-.304.333-.595a708.12 708.12 0 0 1 0-6.026c0-.197.004-.459.113-.571.14-.143.465-.287.573-.211.197.139.402.435.412.674.044 1.04.02 2.083.02 3.125h.001z' />
    </g>
  </IconContent>
);

export default Clock;
