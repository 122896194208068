import axios from 'axios';
import { CORE_API } from 'config';
import AuthService, { AuthProps } from 'services/auth-service';

/**
 * Axios API Instance
 */

type Props = {
  contentType?: string;
  version?: 'v1' | 'v2' | 'v3';
};

const AUTH_TOKEN_UPPER = 'AUTH_TOKEN';
const AUTH_TOKEN_LOWER = 'auth_token';
const AUTH_ID = 'AUTH_ID';

const api = (args?: Props) => {
  const { contentType = 'application/json', version = 'v1' } = args || {};
  const { Authorization } = AuthService.getAuth() as AuthProps;
  const customAxios = axios.create({
    baseURL: `${CORE_API.url}/${version}`,
    headers: {
      Accept: `${contentType}`,
      'Access-Control-Allow-Origin': CORE_API.cors,
      'Content-Type': `${contentType}`,
      'X-Requested-With': 'XMLHttpRequest',
      authorization: `Bearer ${Authorization.token}`,
    },
    // mode: 'cors',
  });

  customAxios.interceptors.response.use((response) => response);

  customAxios.interceptors.request.use((config) => {
    let { params, data } = config;

    if (config.data && Object.prototype.hasOwnProperty.call(config.data, AUTH_TOKEN_LOWER)) {
      data = {
        ...config.data,
        auth_token: config.data.auth_token.replace(AUTH_TOKEN_UPPER, Authorization.token),
      };
    }

    if (config.params && Object.prototype.hasOwnProperty.call(config.params, AUTH_TOKEN_LOWER)) {
      params = {
        ...config.params,
        auth_token: config.params.auth_token.replace(AUTH_TOKEN_UPPER, Authorization.token),
      };
    }

    return {
      ...config,
      data,
      params,
      url: config.url
        ? config.url
            .replace(AUTH_TOKEN_UPPER, Authorization.token)
            .replace(AUTH_ID, `${Authorization.id}`)
        : '',
    };
  });
  return customAxios;
};

export default api;
