/* eslint-disable */
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Icon from 'components/icon';
import { Constants } from 'components/main/calendar/utils';
import parse from 'html-react-parser';
import momentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Theme from 'styles/theme';
import { replaceUtcStringFromDateByZeroOffset } from 'utils/dateHelpers';
import ErrorBoundary from 'utils/errorBoundary';
import { imageExist } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import checkMarkIcon from '../../../../icon/pngs/checkmark-50.png';
import {
  Avatar,
  AvatarContent,
  Bubble,
  BubbleFooter,
  Content,
  Image,
  Metadata,
  Name,
  Row,
  Seen,
  Sender,
  Text,
} from './style';

const PROP_NAMES = {
  direct: {
    avatar: 'sender_avatar_url',
    content: 'content',
    image: 'image_url',
    id: 'id',
    name: 'sender_name',
    position: 'side',
    timestamp: 'created_at',
  },
  team: {
    avatar: 'avatar',
    content: 'content',
    image: 'image_url',
    id: 'id',
    name: 'sender_name',
    position: 'position',
    timestamp: 'date',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiBadge-colorSecondary': {
      backgroundColor: Theme.salmon,
    },
  },
}));

const getLastProviderMsgIndex = (messages, position) => {
  const l = messages.length - 1;
  let i;
  for (i = l; i >= 0; i--) {
    if (messages[i][position] !== 'left' && messages[i].created_by_system !== true) {
      // workaround to cover the issue of messages[i][position] == undefined (undefined is returned instead of 'right')
      return i;
    }
  }
  return i;
};

const MessageList = ({ messages, acknowledgeMessage, type, hideBadge, showSwitch }) => {
  const { avatar, content, id, name, position, timestamp, image } = PROP_NAMES[type];

  const classes = useStyles();
  const [lightboxSrc, setLightboxSrc] = useState(null);

  const startShowDotIndex = getLastProviderMsgIndex(messages, position);

  const getBubbleContent = (message) => {
    return (
      <Bubble>
        {message[image] ? (
          <div>
            <Image
              src={message[image]}
              alt='image'
              onClick={() => setLightboxSrc(message[image])}
            />
          </div>
        ) : null}
        <ErrorBoundary fallback={<Text>Unsupported message</Text>}>
          <Text hasImage={!!message[image]}>
            {message[content] && message[content] !== '*image*' ? parse(message[content]) : null}
          </Text>
        </ErrorBoundary>
      </Bubble>
    );
  };

  const renderMetadata = (type, message) => {
    return (
      <Metadata>
        {type === 'direct'
          ? momentTimezone(
              replaceUtcStringFromDateByZeroOffset(message[timestamp]),
              Constants.STANDARD_FORMATTING_FOR_RAW_DATES_FORMAT
            )
              .tz(DEFAULT_TIMEZONE)
              .format(Constants.SHORT_DATETIME_AM_PM_FORMAT)
          : momentTimezone(message[timestamp], Constants.DATETIME_NO_SECONDS_FORMAT).format(
              Constants.SHORT_DATETIME_AM_PM_FORMAT
            )}
      </Metadata>
    );
  };

  return (
    <>
      {messages.map((message, index) => {
        if (message[position] === 'left') {
          return (
            <Row key={`message-${message[id]}`} type='incoming'>
              <Sender>
                <AvatarContent>
                  {message[avatar] && imageExist(message[avatar]) ? (
                    <Avatar src={message[avatar]} />
                  ) : (
                    <Icon icon='userBubble' color='turquoise' size={45} />
                  )}
                </AvatarContent>
                <Name>{message[name]}</Name>
              </Sender>
              <Content>
                <div className={classes.root}>
                  {index > startShowDotIndex &&
                    message[timestamp].substr(0, 19) >
                      ((acknowledgeMessage.created_at &&
                        acknowledgeMessage.created_at.substr(0, 19).replace('T', ' ')) ||
                        '') &&
                    showSwitch(true) == undefined && (
                      <Badge id='badge' color='secondary' variant='dot' invisible={hideBadge} />
                    )}
                </div>
                {getBubbleContent(message)}
                <BubbleFooter type='incoming'>{renderMetadata(type, message)}</BubbleFooter>
              </Content>
            </Row>
          );
        }
        return (
          <Row key={`message-${message[id]}`} type='outgoing'>
            <Content>
              {
                // hide switch only if not a system message
                (message.created_by_system === true || showSwitch(false) == undefined) &&
                  getBubbleContent(message)
              }
              <BubbleFooter type='outgoing'>
                {message.status === 'seen' && (
                  <>
                    <img src={checkMarkIcon} height='16' width='16' alt='' />
                    <Seen>Seen</Seen>
                  </>
                )}

                {renderMetadata(type, message)}
              </BubbleFooter>
            </Content>
            <Sender>
              <AvatarContent>
                {message[avatar] && imageExist(message[avatar]) ? (
                  <Avatar src={message[avatar]} />
                ) : (
                  <Icon icon='userBubble' color='turquoise' size={45} />
                )}
              </AvatarContent>
              <Name>{message[name]}</Name>
            </Sender>
          </Row>
        );
      })}

      {lightboxSrc && (
        <Lightbox mainSrc={lightboxSrc} onCloseRequest={() => setLightboxSrc(null)} />
      )}
    </>
  );
};

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  acknowledgeMessage: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  hideBadge: PropTypes.bool,
  showSwitch: PropTypes.func.isRequired,
};

MessageList.defaultProps = {
  hideBadge: false,
};

export default MessageList;
