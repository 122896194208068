import MixpanelService from './mixpanel-service';
import { TrackChangeProps, TrackClickProps } from './mixpanel.types';
import { ProviderAppMixpanelInstance } from './provider-app-mixpanel-service';

export class MixpanelWrapper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(eventName: string, props?: any) {
    MixpanelService.track(eventName, props);

    ProviderAppMixpanelInstance.track({
      eventName,
      ...props,
    });
  }

  trackClick({ customEventName, name, type, source, inputData, ...restProps }: TrackClickProps) {
    this.track(customEventName || `Clicked ${name}`, {
      'Target Name': name,
      'Target Type': type,
      Source: source,
      'Input Data': inputData,
      ...restProps,
    });
  }

  trackChange({ customEventName, name, type, source, value, ...restProps }: TrackChangeProps) {
    this.track(customEventName || `Changed ${name}`, {
      'Field Name': name,
      'Field Type': type,
      Source: source,
      'Field Value': value,
      ...restProps,
    });
  }
}

export const MixpanelWrapperInstance = new MixpanelWrapper();
