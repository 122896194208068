import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;

  & > div {
    width: 100%;
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.teal};
    border-radius: 8px;
  }
  .react-datepicker__day--today {
    background: ${({ theme }) => theme.turquoise};
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
    font-weight: 400;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const Datepicker = styled(DatePicker)`
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  display: block;
  padding: 16px;
  padding-right: 40px;
  width: 100%;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;

export const DatepickerContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CalendarIcon = styled.div`
  position: absolute;
  top: 14px;
  right: 10px;
`;
