/* eslint-disable @typescript-eslint/no-explicit-any */
import { MIXPANEL } from 'config';
import mixpanel from 'mixpanel-browser';
import React, { FC, ReactNode } from 'react';
import { MixpanelWrapperInstance } from './mixpanel-wrapper';
import { ChangeElementTypes, ClickElementTypes, EventHandlerNames } from './mixpanel.types';

mixpanel.init(MIXPANEL.key, {}, 'private');

const MixpanelService = {
  identify(uuid: string) {
    mixpanel.private.identify(uuid);
  },

  alias(id: number) {
    mixpanel.private.alias(id);
  },

  set(props: any) {
    mixpanel.private.people.set(props);
  },

  track(eventName: string, props?: any) {
    mixpanel.private.track(eventName, props);
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export interface TrackableElementProps extends React.PropsWithChildren {
  customEventName?: string;
  name: string;
  type: ClickElementTypes | ChangeElementTypes;
  value?: string | number | boolean;
  source?: string;
  eventHandlerName: EventHandlerNames;
  [key: string]: any;
}

export const TrackableElement: FC<TrackableElementProps> = ({
  children,
  customEventName,
  name,
  type,
  value,
  source,
  eventHandlerName,
  ...extraProps
}) => {
  const updatedChildren = React.Children.map<ReactNode, ReactNode>(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line dot-notation
      const actualEventHandler = child.props[eventHandlerName];
      if (typeof actualEventHandler === 'function') {
        return React.cloneElement(child, {
          [eventHandlerName]: (e: any, p: any) => {
            actualEventHandler(e, p);

            if (type === ChangeElementTypes.DROPDOWN || type === ChangeElementTypes.CHECKBOX) {
              MixpanelWrapperInstance.trackChange({
                customEventName,
                name,
                type,
                source,
                value: value ?? p?.text,
                ...extraProps,
              });
            } else {
              MixpanelWrapperInstance.trackClick({
                customEventName,
                name,
                type: type as ClickElementTypes,
                source,
                ...extraProps,
              });
            }
          },
        });
      }
    }
    return child;
  });

  return <>{updatedChildren}</>;
};

export default MixpanelService;
