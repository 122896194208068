import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/user';

const UserContext = createContext<{ userState: State; userDispatch: Dispatch<Action> }>({
  userState: initialState,
  userDispatch: () => null,
});

const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userState, userDispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ userState, userDispatch }}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserProvider };
