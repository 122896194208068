import { ReviewListSlugs } from 'config/reviewLists';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';

export const REVIEW_LIST_ATTENTION_DIMENSIONS: { [key in ReviewListSlugs]?: AttentionDimension } = {
  // Review Lists 1
  [ReviewListSlugs.MentionedInTeamChat]: AttentionDimension.TeamChat,
  [ReviewListSlugs.PrivateMessages]: AttentionDimension.MemberChat,
  // Review Lists 2
  [ReviewListSlugs.CardEngagement]: AttentionDimension.CardComments,
  [ReviewListSlugs.FoodPictures]: AttentionDimension.Food,
  [ReviewListSlugs.PictureCommentReplies]: AttentionDimension.FoodComments,
  [ReviewListSlugs.WeightRegainWarning]: AttentionDimension.Weights,
  // Review Lists 4
  [ReviewListSlugs.FoodPictureComments]: AttentionDimension.FoodComments,
  [ReviewListSlugs.VitalsAndBodyComp]: AttentionDimension.Vitals,
  [ReviewListSlugs.WeightRegain]: AttentionDimension.Weights,
};
