import { AttentionDimension, AttentionResponseEntry } from 'node-api/attention/AttentionApi.types';
import { Clinic } from 'node-api/dashboard.types';
import { ILegacyMyPanelResponseEntry } from 'node-api/panel/MyPanelLegacyClient.types';

export const getActiveAttentionKeys = (
  attention: AttentionResponseEntry[],
  settings: PanelSettings
) =>
  (attention || [])
    .map((a) => a.dimensionKey)
    .filter((a) => !settings.activeDimensions || settings.activeDimensions[a] !== false);

export type PanelRow = {
  id: number;
  member: ILegacyMyPanelResponseEntry;
  clinic: Clinic | null;
  attention: AttentionResponseEntry[];
};

export type PanelSettings = {
  activeDimensions?: { [dim in AttentionDimension]?: boolean | null | undefined };
};

export type PanelListType = 'panel' | 'any-attention' | AttentionDimension;
export const PanelListTypeIterator: PanelListType[] = [
  'panel',
  'any-attention',
  ...(Object.values(AttentionDimension) as AttentionDimension[]),
];

export type TimeFilter = {
  fromDay: number; // @property fromDay (inclusive)
  toDay: number; // @property toDay (inclusive)
  displayText: string;
};
