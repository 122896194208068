import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const FullPageWrapperForLoader = styled.div<StyledComponentsDivProps>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled.div<StyledComponentsDivProps>`
  height: 20%;
`;
