import api from 'api';
import { CancelToken } from 'axios';
import { ChartDataValuesResponse } from './chart-types';

export default class ChartService {
  public async getWeightData(
    patientId: string,
    cancelToken?: CancelToken
  ): Promise<ChartDataValuesResponse | null> {
    try {
      const { data } = await api().get<ChartDataValuesResponse>(
        `/stats/patient/weight_stats/${patientId}/AUTH_TOKEN`,
        {
          cancelToken,
        }
      );

      return data;
    } catch (err) {
      console.error('[ChartService::getChartData] problems getting chart data: ', err);
    }

    return null;
  }
}
