import { CopyAll } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { NoteDetail } from 'node-api/scribe/scribe';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import { renderSectionsByOutputType } from './section';

export type Props = {
  note: NoteDetail;
  copyNoteContent: () => void;
};

export const NoteEditionPreview = ({ note, copyNoteContent }: Props) => {
  return (
    <Box className='note-edition-preview-content'>
      <Box className='note-preview-content-header'>
        <Typography
          component='h4'
          fontWeight={600}
          color='#353535'
          sx={{
            fontSize: '18px !important',
          }}>
          Preview
        </Typography>
        <Button
          size='small'
          startIcon={<CopyAll />}
          onClick={copyNoteContent}
          style={{
            fontFamily: 'Proxima Nova Rg',
            color: '#1a667a',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '1em',
            padding: 0,
          }}>
          Copy
        </Button>
      </Box>

      {note.content
        ?.sort((a, b) => a.order - b.order)
        .map((section) => {
          return renderSectionsByOutputType(section);
        })}

      {note.meetingSummary?.signatureHistory?.map(({ signedBy, signedOn }) => {
        return (
          <Typography>{`Signed by ${signedBy.firstName} ${signedBy.lastName}, ${
            signedBy.specialty
          } at ${getFormattedDateFromISOString(signedOn, 'MM/DD/YYYY h:mm A z')}`}</Typography>
        );
      })}
    </Box>
  );
};
