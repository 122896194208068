import { Comparison, EntrySet } from 'node-api/dashboard.types';

export enum DashboardMetrics {
  PANEL_SIZE = 'panel-size',
  APPOINTMENT_COUNT = 'appointments',
  CHAT_COUNT = 'sent-messages',
  FOOD_COMMENT = 'sent-food-comments',
  WEIGHT_LOSS = 'weight-loss',
}

export type CardType = 'Chat' | 'Panel' | 'Appointment' | 'Stats' | 'Food' | 'Weight';

export type CardData = {
  type: CardType;
  title: string;
  subtitle: string;
  descriptionAvgFirstLine: string;
  descriptionAvg: string;
  totalFirstLine: string;
  renderDescriptionTotal: (value: number) => string;
  hoverMessage: string;
};
export interface MetricStaticData {
  key: DashboardMetrics;
  cardData: CardData;
}

export interface MetricRawValue {
  self: EntrySet | null;
  comparisonAverage: Comparison | null;
  comparisonTotal: Comparison | null;
}
export interface MetricValue {
  values: MetricRawValue;
  isLoading: boolean;
}

export enum MetricLoadState {
  InProgress = 1,
  Fail = 2,
  Ready = 3,
}
