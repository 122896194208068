import styled, { css } from 'styled-components';

export const Filter = styled.div`
  margin-bottom: 16px;

  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.li`
  align-items: start;
  border-top: 1px solid ${({ theme }) => theme.grayLighter};
  display: flex;
  padding: 16px 0;

  i {
    flex-shrink: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 16px;
`;

export const Details = styled.p`
  color: ${({ theme }) => theme.gray};
  font: 300 14px ${({ theme }) => theme.primaryFont};
  margin: 0 0 4px;

  strong {
    font: 600 14px ${({ theme }) => theme.primaryFont};
  }

  span {
    color: ${({ theme }) => theme.teal};
  }
`;

export const DueDate = styled.span<{ pastDue: boolean }>`
  ${(props) =>
    props.pastDue === true
      ? css`
          color: ${({ theme }) => theme.salmonDark} !important;
          font-weight: bold;
        `
      : css``}
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.grayDarker};
  font: 17px/24px ${({ theme }) => theme.primaryFont};
  margin: 8px 0 0;
`;

export const Action = styled.div`
  flex-shrink: 0;
`;
