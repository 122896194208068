import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Container = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.teal};
  display: flex;
  flex-direction: column;
  margin: 2em 0em 0em 2em;

  p,
  h6 {
    font-weight: bold;
  }
`;

export const ProviderBox = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.salmon};
  margin: 1em 0em 0em 0em;
`;
export const MemberBox = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.grey};
  margin: 1em 0em 0em 0em;
`;
