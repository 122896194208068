import PropTypes from 'prop-types';

import { Button, Spinner } from './style';

const ButtonElement = ({
  children,
  color,
  disabled,
  submitting,
  onClick,
  type,
  variant,
  size,
  style,
}) => (
  <Button
    color={color}
    type={type}
    variant={variant}
    size={size}
    disabled={disabled || submitting}
    onClick={onClick}
    style={style}>
    {submitting && <Spinner className='material-icons'>cached</Spinner>}
    {children}
  </Button>
);

ButtonElement.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['normal', 'large']),
  style: PropTypes.shape(),
  submitting: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'link']),
};

ButtonElement.defaultProps = {
  children: [],
  color: 'primary',
  disabled: false,
  onClick: undefined,
  size: 'normal',
  submitting: false,
  type: 'button',
  variant: 'contained',
};

export default ButtonElement;
