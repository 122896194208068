import PropTypes from 'prop-types';

import { Container, Hidden, Icon, Visible } from './style';

const CheckBox = ({ checked, onClick }) => (
  <Container>
    <Hidden checked={checked} onChange={() => {}} />
    <Visible checked={checked} onClick={onClick}>
      <Icon viewBox='0 0 24 24'>
        <polyline points='20 6 9 17 4 12' />
      </Icon>
    </Visible>
  </Container>
);

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

CheckBox.defaultProps = {};

export default CheckBox;
