import styled, { css } from 'styled-components';

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;
  & h2,
  p {
    color: ${({ theme }) => theme.grayDark};
  }
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 5px;
  border: none;
  height: 50px;
  padding: 0 8px;
  width: 100%;
`;

export const ProvidersList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 290px;
  overflow: auto;
  margin-top: 4px;
  margin-bottom: 24px;
`;

export const Record = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;

  img {
    display: block;
    border-radius: 100%;
    height: 42px;
    width: 42px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 30px;
    width: 30px;
    margin-left: 5px;
  }
`;

export const Name = styled.div`
  width: 75%;
  margin-left: 20px;

  ${(props) =>
    props.flag === props.pStatus.ASSIGNED
      ? css`
          color: ${({ theme }) => theme.salmonDark};
        `
      : props.flag === props.pStatus.ASSIGNED_REMOVING
      ? css`
          color: ${({ theme }) => theme.salmonDark};
          font-weight: bold;
        `
      : props.flag === props.pStatus.UNASSIGNED_ADDING_VISIBLE ||
        props.flag === props.pStatus.UNASSIGNED_ADDING_HIDDEN ||
        props.flag === props.pStatus.ASSIGNED_UNREMOVABLE ||
        props.flag === props.pStatus.ASSIGNED_PRIMARY_PROVIDER
      ? css`
          font-weight: bold;
        `
      : css``}
`;

export const Role = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
`;

export const ActionIcon = styled.div`
  img {
    height: 30px;
    width: 30px;
    cursor: pointer;
    margin-left: 5px;
  }
`;

export const DisabledImage = styled.img`
  cursor: default !important;
`;

export const IconPlaceholder = styled.div`
  height: 30px;
  width: 30px;
  margin-left: 5px;
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & button {
    width: 50%;
  }
  & button:first-of-type {
    margin: 8px 0;
  }
`;
