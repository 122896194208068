import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const BodyTitle = styled.div<StyledComponentsDivProps>`
  background-color: ${({ theme }) => theme.turquoise};
  margin: -15px -11px 0px -10px;
  height: 20%;
`;

export const Title = styled.div<StyledComponentsDivProps>`
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  font-weight: bold;
  line-height: 19px;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.teal};
`;

export const BubbleFooter = styled.div<StyledComponentsDivProps>`
  display: flex;
  color: ${({ theme }) => theme.gray};
  font: 500 14px ${({ theme }) => theme.primaryFont};
  margin-top: 6px;
  margin-bottom: 8px;
`;

export const Cards = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: ${({ theme }) => theme.grayDark};
`;

export const Card = styled.div<StyledComponentsDivProps>`
  width: 300px;
  height: 400px;
  margin: 10px 0px 20px 0px;
  text-align: start;
  padding: 15px 10px;
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.2), 0px -1px 3px -1px rgba(0,0,0,0.1);
  border: 1px solid ${({ theme }) => theme.turquoise};
  margin-left: 20px
  }
`;

export const Count = styled.div<StyledComponentsDivProps>`
  background: ${({ theme }) => theme.teal};
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  font: 14px ${({ theme }) => theme.primaryFont};
  padding: 1px 3px;
  position: absolute;
  right: 30px;
  top: 13px;
`;

export const TabBox = styled.div<StyledComponentsDivProps>`
  // display: inline-flex;
  // align-items: center;
  width: 100%;
  min-height: 560px;
  position: relative;
  padding: 32px 10px;
`;
