import styled from 'styled-components';

export const ChatSection = styled.div`
  background-color: ${({ theme }) => theme.white};
  border-left: 2px solid ${({ theme }) => theme.turquoiseDark};
  min-width: 350px;
  width: calc(33vw - 36px);
  padding-left: 10px;
`;

export const StickyBox = styled.div`
  position: sticky;
  top: 0;
`;

export const InputMessageBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
  box-shadow: 0 0 12px 7px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  height: 77px;
  margin: 32px 0 16px;
  padding: 15px 25px;
  width: 100%;

  background-color: ${({ theme }) => theme.teal};
  height: 46px;
  width: 46px;
`;

export const Text = styled.input`
  border: none;
  color: ${({ theme }) => theme.gray};
  font: 16px/19px ${({ theme }) => theme.primaryFont};
  margin-right: 16px;
  width: 95%;
`;

export const Icon = styled.i`
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  font-size: 28px;

  @media (max-width: ${({ theme }) => theme.md}) {
    font-size: 36px;
  }
`;

ChatSection.defaultProps = {
  'data-id': 'ChatSection',
};
