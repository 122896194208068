import styled, { css, keyframes } from 'styled-components';
import Theme from 'styles/theme';

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button`
  align-items: center;
  border-radius: 4px;
  border: none;
  display: flex;
  font: 17px ${({ theme }) => theme.primaryFont};
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.1s ease-in-out;

  ${(props) =>
    props.size === 'large' &&
    css`
      font: 600 23px ${({ theme }) => theme.primaryFont};
      padding: 14px 32px;
    `}

  ${(props) =>
    props.variant &&
    props.variant === 'contained' &&
    props.color === 'primary' &&
    css`
      background: ${({ theme }) => theme.teal};
      color: ${({ theme }) => theme.white};
      &:hover {
        background: ${({ theme }) => theme.tealLight};
      }
    `}

  ${(props) =>
    props.variant &&
    props.variant === 'contained' &&
    props.color === 'white' &&
    css`
      background: ${({ theme }) => theme.white};
      box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.15);
      // color: ${({ theme }) => theme.grayDark};
      color: ${({ theme }) => theme.teal};

      &:hover {
        background: ${({ theme }) => theme.grayLighter};
      }
    `}

  ${(props) =>
    props.variant &&
    props.variant === 'link' &&
    css`
      color: ${({ theme }) => theme.teal};
      background-color: transparent;
      &:hover {
        color: ${({ theme }) => theme.tealLight};
        text-decoration: underline;
      }
    `}

  ${(props) =>
    props.type &&
    props.type === 'button' &&
    props.variant !== 'link' &&
    props.color === Theme.salmon &&
    css`
      color: ${({ theme }) => theme.white};
      background-color: ${({ theme }) => theme.salmon};
      &:hover {
        background-color: ${({ theme }) => theme.salmonDark};
      }
    `}

  ${(props) =>
    props.variant &&
    props.variant === 'link' &&
    props.color === Theme.salmon &&
    css`
      color: ${({ theme }) => theme.salmon};
      background-color: transparent;
      &:hover {
        color: ${({ theme }) => theme.salmonDark};
        text-decoration: underline;
      }
    `}

  i {
    margin-right: 8px;
  }

  &:disabled {
    background: ${({ theme }) => theme.gray};
    cursor: auto;
    &:hover {
      background: ${({ theme }) => theme.gray};
    }

    ${(props) =>
      props.variant &&
      props.variant === 'link' &&
      css`
        background: ${({ theme }) => theme.white};
        &:hover {
          background: ${({ theme }) => theme.white};
        }
        i {
          background: ${({ theme }) => theme.turquoise};
        }
      `}

    ${(props) =>
      props.type &&
      props.type === 'button' &&
      props.variant !== 'link' &&
      css`
        i {
          background: ${({ theme }) => theme.gray};
        }
      `}

    // i {
    //   background: ${({ theme }) => theme.turquoise};
    // }
  }
`;

export const Spinner = styled.i`
  animation: ${spinnerAnimation} 1s 0s linear infinite;
  font-size: 20px;
  margin-right: 16px;
  vertical-align: middle;
`;
