/* eslint-disable */
import Icon from 'components/icon';
import Can from 'config/casl/can';
import PropTypes from 'prop-types';
import { Link, StatContainer, Stats, StatsCard } from './style';

const StatsList = (props) => {
  const { data, bordered } = props;
  return (
    <StatsCard>
      {data.map((d) => (
        <StatContainer key={`stats-${d.id}-${d.label}`} className={bordered ? 'bordered' : null}>
          <Icon icon={d.icon} size={35} />
          <Stats>
            <div onClick={() => d.onClick && d.onClick()}>
              <span id={d.label} className='value'>
                {d.format ? d.format(d.value) : d.value}
              </span>
              <div id={d.label}>{d.label}</div>
            </div>

            {d.link && (
              <Can {...d.link.can}>
                {() => (
                  <Link to={d.link.to} title={d.link.text} onClick={d.link.onclick}>
                    {d.link.text}
                  </Link>
                )}
              </Can>
            )}
          </Stats>
        </StatContainer>
      ))}
    </StatsCard>
  );
};

StatsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  bordered: PropTypes.bool,
};

StatsList.defaultProps = {
  data: [{}],
  bordered: false,
};

export default StatsList;
