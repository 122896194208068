import axios from 'axios';
import { Badge } from 'components/elements';
import ImageToggleOnMouseOver from 'components/elements/image-toggle';
import { Box, Section, Tabs } from 'components/general-styles';
import { APP_NAME } from 'config';
import { Patient } from 'lib/types';
import { ITabNotification } from 'node-api/members/member.types';
import MembersClient from 'node-api/members/MembersClient';
import { FC, useContext, useEffect, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { FeaturesContext } from 'state/contexts/features';
import { UserContext } from 'state/contexts/user';
import { FeaturesState } from 'state/reducers/features';
import { debug } from 'utils/helpers';
import Tab from '../../../elements/tab';
import arrowLeftHover from '../../../icon/pngs/arrow-left-hover.png';
import arrowLeft from '../../../icon/pngs/arrow-left.png';
import arrowRightHover from '../../../icon/pngs/arrow-right-hover.png';
import arrowRight from '../../../icon/pngs/arrow-right.png';
import { GRAPH_TABS, Graphs } from './constants';
import Exercises from './exercises-calendar';
import { LeftArrow, RightArrow, TabBox } from './style';
import { GraphTab } from './types';

const isGraphTabNotificationEnabled = (graphTab: GraphTab, featuresState: FeaturesState): boolean =>
  !graphTab.notificationFeatureFlag || !!featuresState?.[graphTab.notificationFeatureFlag];

const isGraphTabFeatureEnabled = (graphTab: GraphTab, featuresState: FeaturesState): boolean =>
  !graphTab.displayFeatureFlag || !!featuresState?.[graphTab.displayFeatureFlag];

export const ChartsBox: FC<{
  patient: Patient;
  setProfileTabUnreviewed: (value: boolean) => void;
}> = ({ patient, setProfileTabUnreviewed }) => {
  const { featuresState } = useContext(FeaturesContext);
  const { userState } = useContext(UserContext);
  const [profileNotifications, setProfileNotifications] = useState<ITabNotification[]>([]);
  const [selectedGraphTab, setSelectedGraphTab] = useState(GRAPH_TABS[0]);

  const visibleTabs = GRAPH_TABS.filter((tab) => isGraphTabFeatureEnabled(tab, featuresState));

  const someUnreviewedSubTabs = (tabNotifications: ITabNotification[]) => {
    return !!tabNotifications?.some((tabNotification) => {
      const graphTab = visibleTabs.find(
        (tab) => tab.notificationsFieldName === tabNotification.name
      );
      return (
        graphTab &&
        tabNotification.active &&
        tabNotification.newUnreviewedEntries &&
        isGraphTabNotificationEnabled(graphTab, featuresState)
      );
    });
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchProfileNotifications = async () => {
      try {
        const data = await new MembersClient().profileNotifications(
          { memberId: patient.id },
          source.token
        );

        setProfileTabUnreviewed(someUnreviewedSubTabs(data.profileNotifications));
        setProfileNotifications(data.profileNotifications);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(`${err}`);
          setProfileTabUnreviewed(false);
          setProfileNotifications([]);
        }
      }
    };

    fetchProfileNotifications();

    return () => {
      source.cancel();
    };
  }, []);

  const updateNotificationsAndReloadTabs = () => {
    const updatedProfileNotifications = profileNotifications.map((tabNotifications) => {
      if (tabNotifications.name === selectedGraphTab.notificationsFieldName) {
        return { ...tabNotifications, newUnreviewedEntries: false };
      }
      return tabNotifications;
    });
    setProfileNotifications(updatedProfileNotifications);
    setProfileTabUnreviewed(someUnreviewedSubTabs(updatedProfileNotifications));
  };

  const isTabUnreviewed = (graphTab: GraphTab) => {
    const tabDataMetrics = profileNotifications.find(
      (tabNotifications) => tabNotifications.name === graphTab.notificationsFieldName
    );
    return !!(
      tabDataMetrics?.active &&
      tabDataMetrics?.newUnreviewedEntries &&
      isGraphTabNotificationEnabled(graphTab, featuresState)
    );
  };

  const markTabAsReviewed = async (currentTab: GraphTab) => {
    if (!currentTab.tabReviewedByContent) {
      let skipMarkingAsReviewed = false;
      const updatedProfileNotifications = profileNotifications.map((tabNotifications) => {
        // clicked tab is inactive or already reviewed --> skip
        if (
          tabNotifications.name === currentTab.notificationsFieldName &&
          !(
            tabNotifications.active &&
            (tabNotifications.newUnreviewedEntries || tabNotifications.hasNoReviewEntries)
          )
        ) {
          skipMarkingAsReviewed = true;
        }
        return {
          ...tabNotifications,
          newUnreviewedEntries:
            tabNotifications.label === currentTab.label
              ? false
              : tabNotifications.newUnreviewedEntries,
        };
      });
      if (currentTab.notificationsFieldName && !skipMarkingAsReviewed && userState.id) {
        try {
          // Mark clicked tab as reviewed
          await new MembersClient().reviewProfileSubTab({
            providerId: userState.id,
            memberId: patient.id,
            tabName: currentTab.notificationsFieldName,
            source: APP_NAME,
          });

          setProfileTabUnreviewed(someUnreviewedSubTabs(updatedProfileNotifications));
          setProfileNotifications(updatedProfileNotifications);
        } catch (e) {
          console.error(`Couldn't mark the clicked tab <${currentTab.label}> as reviewed!`);
        }
      }
    }
  };

  const handleClickTab = (tabName: string) => {
    const clickedTab = GRAPH_TABS.find((tab) => tabName === tab.name);
    if (clickedTab) {
      MixpanelWrapperInstance.track(`Clicked ${clickedTab.label}`);
      markTabAsReviewed(clickedTab);
      setSelectedGraphTab(clickedTab);
    } else {
      console.error(`Tab <${tabName}> not found!`);
    }
  };

  const getTabName = (step: 'next' | 'previous') =>
    visibleTabs[
      (visibleTabs.findIndex((t) => t.name === selectedGraphTab.name) +
        visibleTabs.length +
        (step === 'next' ? +1 : -1)) %
        visibleTabs.length
    ];

  const handleMoveTab = (step: 'next' | 'previous') => setSelectedGraphTab(getTabName(step));

  return (
    <Section>
      <Box>
        <Tabs>
          <LeftArrow onClick={() => handleMoveTab('previous')}>
            <ImageToggleOnMouseOver
              style={{ textAlign: 'center', minWidth: 24 }}
              imgStyle={{ maxHeight: 16 }}
              primaryImg={arrowLeft}
              secondaryImg={arrowLeftHover}
              tooltipID='leftArrow'
              tooltipText={getTabName('previous').label}
              key={`previous-chart-${selectedGraphTab.name}`}
            />
          </LeftArrow>
          <div style={{ flexGrow: 1, overflowX: 'scroll', display: 'flex' }}>
            {visibleTabs.map((tab) => (
              <Badge key={`badge-${patient.id}-${tab.name}`} visible={isTabUnreviewed(tab)}>
                <Tab
                  onClick={handleClickTab}
                  selected={tab.name === selectedGraphTab.name}
                  tab={tab}
                  key={`tab-${patient.id}-${tab.name}`}
                />
              </Badge>
            ))}
          </div>
          <RightArrow onClick={() => handleMoveTab('next')}>
            <ImageToggleOnMouseOver
              style={{ textAlign: 'center', minWidth: 24 }}
              imgStyle={{ maxHeight: 16 }}
              primaryImg={arrowRight}
              secondaryImg={arrowRightHover}
              tooltipID='rightArrow'
              tooltipText={getTabName('next').label}
              key={`next-chart-${selectedGraphTab.name}`}
            />
          </RightArrow>
        </Tabs>
        <TabBox>
          {selectedGraphTab.name === Graphs.Exercises ? (
            <Exercises
              patientId={patient.id}
              key={`calendar-exercises-${patient.id}`}
              notificationsData={
                profileNotifications.find(
                  (tabNotification) =>
                    tabNotification.active &&
                    tabNotification.name === selectedGraphTab.notificationsFieldName
                )?.newUnreviewedData || []
              }
              reloadTabs={updateNotificationsAndReloadTabs}
            />
          ) : (
            visibleTabs.map(
              (graph) =>
                graph &&
                graph.componentFunction && (
                  <graph.componentFunction
                    key={graph.name}
                    patientId={patient.id}
                    patientUuid={patient.uuid}
                    visible={selectedGraphTab.name === graph.name}
                  />
                )
            )
          )}
        </TabBox>
      </Box>
    </Section>
  );
};
