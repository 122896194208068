import {
  Avatar,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import api from 'api';
import { Button, Modal } from 'components/elements';
import { Content } from 'components/elements/modal/style';
import Icon from 'components/icon';
import { ReviewListSlugs } from 'config/reviewLists';
import { isReviewList4 } from 'controllers/review/review-controller';
import { ReviewListClient } from 'node-api/reviewList/reviewlistClient';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';

import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { BulkActionsProvider, useBulkActions } from 'state/contexts/bulkActions/context';
import {
  ADD_SELECTED_PATIENT,
  CLEAR_SELECTED_PATIENTS,
  REMOVE_SELECTED_PATIENT,
  SET_MANY_PATIENTS,
  SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY,
} from 'state/contexts/bulkActions/types';
import { PatientContext } from 'state/contexts/patient';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { UserContext } from 'state/contexts/user';
import { ActionTypes, Member } from 'state/reducers/patient';
import { getOriginalUserFullName } from 'utils/helpers';
import { ReactComponent as PaperPlane } from '../icon/svgs/paper-plane.svg';
import {
  AllSelectedMembers,
  BULK_SENDING_CHUNKS,
  BulkModalOption,
  ListAvatarProps,
} from './bulk-types';
import './bulk-view.css';

export interface BulkViewProps {
  buttonActionLabel: string;
  rightChildComponent: React.ReactNode;
  reviewListName: ReviewListSlugs;
}

const ListAvatar: FC<ListAvatarProps> = ({ url, altLabel }) => {
  return url ? (
    <Avatar alt={altLabel} src={url} sizes='28' />
  ) : (
    <Icon icon='userBubble' color='turquoise' size={28} />
  );
};

const BulkActionsView: FC<BulkViewProps> = ({
  buttonActionLabel,
  rightChildComponent,
  reviewListName,
}) => {
  const [currentModal, setCurrentModal] = useState<BulkModalOption>(BulkModalOption.None);
  const [allChecked, setAllChecked] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);

  const allSelectedMembersRef = useRef<AllSelectedMembers>({});

  const { userState } = useContext(UserContext);
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { reviewLists4State } = useContext(ReviewLists4Context);

  const {
    dispatchBulkActions,
    bulkActionsState: { shouldPerformActionPostBulkActivity, selectedPatients },
  } = useBulkActions();

  const patients = patientState.data;

  const handleSendBulkMessages = async () => {
    setCurrentModal(BulkModalOption.ViewList);

    MixpanelWrapperInstance.track(EventNames.BulkActionsClickBulkMessage, {
      targetLabel: 'send bulk message',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'members to review',
    });
  };

  const handleMemberSelection = (patient: Member) => () => {
    const patientId = Number(patient.id);

    const checked = allSelectedMembersRef.current[patientId];

    if (checked) {
      dispatchBulkActions({ type: REMOVE_SELECTED_PATIENT, payload: patient.uuid });
    } else {
      dispatchBulkActions({ type: ADD_SELECTED_PATIENT, payload: patient });
    }

    allSelectedMembersRef.current[patientId] = !checked;

    const allIds = Object.keys(allSelectedMembersRef.current);

    setAllChecked(
      allIds.length === patients.length &&
        allIds.every((pId) => allSelectedMembersRef.current[Number(pId)])
    );
  };

  const handleCheckAll = () => {
    const allCheckedNewStatus = !allChecked;

    if (allCheckedNewStatus) {
      dispatchBulkActions({ type: SET_MANY_PATIENTS, payload: patients });

      allSelectedMembersRef.current = patients.reduce(
        (prev, curr) => ({ ...prev, [Number(curr.id)]: true }),
        {}
      );
    } else {
      dispatchBulkActions({ type: CLEAR_SELECTED_PATIENTS });

      allSelectedMembersRef.current = {};
    }

    setAllChecked(allCheckedNewStatus);
  };

  const cleanBulkActionData = () => {
    dispatchBulkActions({ type: CLEAR_SELECTED_PATIENTS });
    dispatchBulkActions({ type: SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY, payload: false });

    setAllChecked(false);
    setCurrentModal(BulkModalOption.None);

    allSelectedMembersRef.current = {};
  };

  const handleCloseModal = () => {
    cleanBulkActionData();
  };

  const handleReviewed = async () => {
    const originalUserFullName = getOriginalUserFullName();

    setIsReviewing(true);

    const patientIds: number[] = [];

    // Let's send messages in chunks of 10
    let pivot = 0;
    while (pivot < selectedPatients.length) {
      const chunks = selectedPatients.slice(pivot, pivot + BULK_SENDING_CHUNKS);

      // eslint-disable-next-line no-await-in-loop
      await Promise.all(
        chunks.map((patient) => {
          patientIds.push(Number(patient.id));

          if (isReviewList4(reviewListName, reviewLists4State.data)) {
            return new ReviewListClient().reviewMemberInReviewList4({
              reviewListName,
              memberId: Number(patient.id),
            });
          }
          // else, we are in ReviewList2
          return api().post('/reviews/AUTH_TOKEN', {
            list_name: reviewListName,
            patient_id: patient.id,
            ...(userState.isOriginalUser === false &&
              originalUserFullName !== null && { original_user: originalUserFullName }),
          });
        })
      );
      pivot += BULK_SENDING_CHUNKS;
    }

    setIsReviewing(false);

    patientDispatch({
      type: ActionTypes.REVIEW_MANY,
      payload: {
        ids: patientIds,
      },
    });

    dispatchBulkActions({ type: SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY, payload: false });

    MixpanelWrapperInstance.track(EventNames.BulkMessageMarkAsReviewed, {
      targetLabel: 'mark as reviewed',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'reviewed modal',
      'member-ids': selectedPatients.map((member) => Number(member.id)),
    });

    cleanBulkActionData();
  };

  useEffect(() => {
    // Raised when a child component need to execute an action before end his logic
    if (!shouldPerformActionPostBulkActivity) {
      return;
    }

    setCurrentModal(BulkModalOption.Reviewed);
  }, [shouldPerformActionPostBulkActivity]);

  const renderBaseModal = () => {
    return (
      <Modal
        onClose={handleCloseModal}
        size='small'
        customClass='bulk-modal'
        showClose={false}
        showSmallCloseBtn>
        <Content>
          <div className='bulk-container'>
            {/** member list - left hand components */}

            <div className='member-list-container'>
              <div className='member-list-header'>
                <span className='bulk-title'>Select recipients</span>
                <div>
                  <Checkbox
                    edge='start'
                    checked={allChecked}
                    disableRipple
                    onChange={handleCheckAll}
                  />
                  <span className='bulk-check-all-text'>Select All</span>
                </div>
              </div>

              <div className='member-list-wrapper'>
                <List dense className='member-list '>
                  {patients.map((patient) => {
                    const labelId = `checkbox-list-secondary-label-${patient.id}`;
                    return (
                      <ListItem
                        key={`patient-slide-${patient.id}`}
                        disablePadding
                        className='member-list-item'>
                        <ListItemButton onClick={handleMemberSelection(patient)}>
                          <ListItemIcon
                            style={{
                              width: '17px',
                              height: '17px',
                            }}>
                            <Checkbox
                              checked={
                                allChecked
                                  ? true
                                  : !!allSelectedMembersRef.current[Number(patient.id)]
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar className='member-list-item-avatar'>
                            <ListAvatar
                              url={patient.avatar?.thumb?.url}
                              altLabel={`${patient.first_name}`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            id={labelId}
                            primary={`${patient.first_name} ${patient.last_name}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>

            {/** bulk component - right hand components */}
            <div style={{ flex: 1 }}>{rightChildComponent}</div>
          </div>
        </Content>
      </Modal>
    );
  };

  const renderReviewedModal = () => {
    return (
      <Modal onClose={handleCloseModal} size='normal' showClose={false} showSmallCloseBtn>
        <Content>
          <div className='bulk-reviewed-modal'>
            <PaperPlane />
            <span className='bulk-reviewed-message'>
              Your message was sent to {selectedPatients.length}{' '}
              {selectedPatients.length > 1 ? 'members' : 'member'}
            </span>
            <Button type='submit' onClick={handleReviewed} disabled={isReviewing}>
              Mark as Reviewed{' '}
              {isReviewing && (
                <CircularProgress style={{ color: '#ffffff', marginLeft: 5 }} size={15} />
              )}
            </Button>
            <span className='bulk-reviewed-message-hint'>You may now close this window</span>
          </div>
        </Content>
      </Modal>
    );
  };

  const renderModal = () => {
    switch (currentModal) {
      case BulkModalOption.ViewList:
        return renderBaseModal();
      case BulkModalOption.Reviewed:
        return renderReviewedModal();
      default:
        return null;
    }
  };

  return (
    <>
      <Button onClick={handleSendBulkMessages}>{buttonActionLabel}</Button>
      {renderModal()}
    </>
  );
};

export default (props: BulkViewProps) => {
  return (
    <BulkActionsProvider>
      <BulkActionsView {...props} />
    </BulkActionsProvider>
  );
};
