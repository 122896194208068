import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import { Field, Datepicker, DatepickerContainer, CalendarIcon, Label } from './style';
import { Errors } from '../shared-style';

const DatePickerElement = ({ date, errors, label, name, setValue, ...props }) => (
  <Field error={!!errors} fullWidth>
    <Label htmlFor={`${name}-input`}>{label}</Label>
    <DatepickerContainer>
      <Datepicker
        id={`${name}-input`}
        name={name}
        selected={date}
        autoComplete='off'
        onChange={(d) => {
          setValue(name, d, true);
        }}
        {...props}
      />
      <CalendarIcon>
        <Icon icon='schedule' size={20} />
      </CalendarIcon>
    </DatepickerContainer>

    {errors && <Errors id={`${name}-error`}>{errors.message ? errors.message : errors}</Errors>}
  </Field>
);

DatePickerElement.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

DatePickerElement.defaultProps = {
  errors: null,
  date: '',
};

export default DatePickerElement;
