import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/status';

const StatusContext = createContext<{ statusState: State; statusDispatch: Dispatch<Action> }>({
  statusState: initialState,
  statusDispatch: () => null,
});

const StatusProvider: FC<PropsWithChildren> = ({ children }) => {
  const [statusState, statusDispatch] = useReducer(reducer, initialState);
  return (
    <StatusContext.Provider value={{ statusState, statusDispatch }}>
      {children}
    </StatusContext.Provider>
  );
};

export { StatusContext, StatusProvider };
