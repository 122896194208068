import { CancelToken } from 'axios';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from '../common/SharedEnaraNodeClient';
import { MemberAgreement } from './types';

export default class OnboardingServiceClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.onboarding);
  }

  async getMemberAgreements(memberId: number, cancelToken?: CancelToken) {
    const { data } = await this.axiosClient.get<{
      agreements: MemberAgreement[];
      agreementsStatus: string;
    }>(`/onboarding/v1.3/users/${memberId}/agreements`, {
      cancelToken,
    });
    return data;
  }
}
