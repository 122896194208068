/* eslint-disable */
import api from 'api';
import Icon from 'components/icon';
import videoLinkSMS from 'components/icon/pngs/sms-52.png';
import videoLinkChat from 'components/icon/pngs/video-link-white-96.png';
import Toast from 'components/toast';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import ReactTooltip from 'react-tooltip';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes, EventHandlerNames } from 'services/mixpanel/mixpanel.types';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { ConfirmationContext } from 'state/contexts/confirmation';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as ConfirmationActionTypes } from 'state/reducers/confirmation';
import { ActionTypes as MemberActionTypes } from 'state/reducers/patient';

import {
  debug,
  getCurrentProviderState,
  getOriginalUserFullName,
  isCurrentProviderHidden,
} from 'utils/helpers';
import { actionStyle, inputStyle, mentionStyle } from './mention-style';
import { IconContainer, InputMessageBox, SendButton } from './style';

const MessageInput = ({ type, patient, onClick, autoFocus }) => {
  const { userState } = useContext(UserContext);
  const { providerState } = useContext(ProviderContext);
  const [currentProviderState] = useState(getCurrentProviderState(userState, providerState));
  const isCurProviderHidden = isCurrentProviderHidden(currentProviderState);
  const { confirmationDispatch } = useContext(ConfirmationContext);
  const { patientDispatch } = useContext(PatientContext);

  const [smsVideoLinkEnabled, setSmsVideoLinkEnabled] = useState(true);
  const [value, setValue] = useState('');
  const [providers, setProviders] = useState([]);

  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (patient.chat?.message) {
      patientDispatch({ type: MemberActionTypes.CLEAR_MESSAGE, payload: { memberId: patient.id } });
    }
  };

  const handleOnClick = () => {
    onClick(value);
    setValue('');
  };

  // Add keyboard listener for command + enter (on mac) or control + enter (on windows)
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        e.preventDefault();
        handleOnClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleOnClick]);

  const appendVideoLink = async () => {
    if (!smsVideoLinkEnabled) {
      return;
    }

    setSmsVideoLinkEnabled(false);

    const provider = providerState.data.find(({ id }) => id === userState.id);
    if (!provider) {
      Toast.show('error', "Can't get the provider");
      return;
    }

    MixpanelWrapperInstance.track(EventNames.MemberCallChatLink, {
      targetLabel: 'Append video link (chat)',
      targetType: ClickableElement.BUTTON,
      memberUuid: patient.uuid,
      memberName: `${patient.name}`,
      providerUuid: provider.uuid,
      source: 'append-video-link-chat',
    });

    const link = await schedulingV3Client.getProviderVideoUrl(
      {
        id: patient.id,
        uuid: patient.uuid,
      },
      { id: provider.id, uuid: provider.uuid },
      'member',
      'append-video-link-chat'
    );

    setSmsVideoLinkEnabled(true);
    setValue((prev) => {
      let text = prev;
      if (prev.trimEnd().endsWith(link)) {
        const index = prev.lastIndexOf(link);
        text = prev.slice(0, index).trimEnd();
      } else if (text.trimEnd()) {
        text = `${text.trimEnd()} ${link}`;
      } else {
        text = link;
      }
      return text;
    });
  };

  // ----------- Confirmation -------------
  const onYes = async (onClose, id) => {
    // send sms to the patient
    try {
      const { data } = await api().post(`patients/send_sms_video_link`, {
        auth_token: 'AUTH_TOKEN',
        patient_uuid: patient.uuid,
      });
      debug(data);
      if (data.message) {
        Toast.show('success', data.message);
      } else {
        Toast.show(
          'error',
          `Couldn't send the SMS to ${patient.first_name} ${patient.last_name}, please try again later!`
        );
      }
    } catch (err) {
      debug('Error', err);
      Toast.show(
        'error',
        `Couldn't send the SMS to ${patient.first_name} ${patient.last_name}, please try again later!`
      );
    }
    onClose();
  };

  const onNo = async (onClose, id) => {
    onClose();
  };

  const sendSMS = () => {
    confirmationDispatch({
      type: ConfirmationActionTypes.SHOW,
      payload: {
        onYes,
        onNo,
        message: `Are you sure you want to send the video link by SMS to ${patient.first_name} ${patient.last_name}?`,
      },
    });
  };

  const actions = [
    {
      id: 'task',
      display: 'task',
    },
    {
      id: 'stick',
      display: 'stick',
    },
  ];

  useEffect(() => {
    const data = providerState.data.map((p) => ({
      id: p.id,
      display: `${p.first_name} ${p.last_name}`,
    }));

    setProviders(data);
  }, [providerState.data]);

  useEffect(() => {
    // Set direct message
    if (!!patient?.chat?.message) {
      setValue(patient.chat.message);
    }
  }, [patient?.chat?.message]);

  return (
    <>
      <IconContainer>
        {type === 'direct' && !(isCurProviderHidden === true) ? (
          <>
            <div
              data-tip='Append/Remove Video link'
              data-for='append-remove-video-link'
              key='append-remove-video-link'
              disabled={!smsVideoLinkEnabled}>
              <ReactTooltip id='append-remove-video-link' place='top' type='dark' effect='float' />
              <img
                src={videoLinkChat}
                disabled={!smsVideoLinkEnabled}
                height='30'
                width='30'
                alt=''
                onClick={appendVideoLink}
              />
            </div>
            <div
              data-tip='Send Video link by SMS'
              data-for='send-video-link-sms'
              key='send-video-link-sms'>
              <ReactTooltip id='send-video-link-sms' place='top' type='dark' effect='float' />
              <img src={videoLinkSMS} height='30' width='30' alt='' onClick={sendSMS} />
            </div>
          </>
        ) : null}
      </IconContainer>
      <InputMessageBox type={type}>
        {type === 'team' ? (
          <MentionsInput
            autoFocus={autoFocus}
            value={value}
            onChange={handleOnChange}
            style={inputStyle}
            placeholder='Team Chat'>
            <Mention
              markup='@[[__display__]]((id:__id__))'
              trigger='@'
              data={providers}
              style={mentionStyle}
              displayTransform={(id, display) => `@${display}`}
              appendSpaceOnAdd
            />

            <Mention
              markup='#[[__display__]]'
              trigger='#'
              data={actions}
              style={actionStyle}
              displayTransform={(id, display) => `#${display}`}
              appendSpaceOnAdd
            />
          </MentionsInput>
        ) : (
          <MentionsInput
            autoFocus={autoFocus}
            value={value}
            onChange={handleOnChange}
            style={inputStyle}
            disabled={type === 'direct' && isCurProviderHidden}
            placeholder='Patient Chat'>
            <Mention
              markup='@[[__display__]]((id:__id__))'
              trigger='avoidtriggeringhere'
              style={mentionStyle}
              appendSpaceOnAdd
            />
          </MentionsInput>
        )}
        <TrackableElement
          customEventName='Sent a message'
          name='Send Message'
          type={ClickElementTypes.BUTTON}
          source={type === 'team' ? 'Team' : 'Patient'}
          eventHandlerName={EventHandlerNames.ONCLICK}
          inputData={{
            isOriginalUser: userState.isOriginalUser,
            originalUser: getOriginalUserFullName(),
          }}>
          <SendButton
            className='send'
            disabled={(type === 'direct' && isCurProviderHidden) || !value.trim()}
            onClick={handleOnClick}>
            <Icon icon='send' size={20} color='white' />
          </SendButton>
        </TrackableElement>
      </InputMessageBox>
    </>
  );
};

MessageInput.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

MessageInput.defaultProps = {
  onClick: () => {},
};

export default MessageInput;
