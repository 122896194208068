import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/confirmation';

const ConfirmationContext = createContext<{
  confirmationState: State;
  confirmationDispatch: Dispatch<Action>;
}>({
  confirmationState: initialState,
  confirmationDispatch: () => null,
});

const ConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [confirmationState, confirmationDispatch] = useReducer(reducer, initialState);
  return (
    <ConfirmationContext.Provider value={{ confirmationState, confirmationDispatch }}>
      {children}
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationContext, ConfirmationProvider };
