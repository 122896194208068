import styled from 'styled-components';

export const Wrapper = styled.nav`
  align-items: center;
  background-color ${({ theme }) => theme.white};
  border-bottom: 2px solid ${({ theme }) => theme.turquoiseDark};
  display: flex;
  justify-content: space-between;
  // margin-bottom: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const Logo = styled.img`
  display: block;
  height: 30px;
  // margin: 30px auto;
  margin: -15px 20px auto;
  width: 145px;
`;

export const Nav = styled.ul`
  margin: -10px 0 0 10px;

  a {
    align-items: center;
    color: ${({ theme }) => theme.gray};
    display: flex;
    margin: 28px 0 0;

    i {
      margin-right: 10px;
    }

    &.selected {
      color: ${({ theme }) => theme.grayDarker};
    }
  }
`;

export const Count = styled.div`
  background: ${({ theme }) => theme.teal};
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  font: 14px ${({ theme }) => theme.primaryFont};
  padding: 1px 3px;
  position: absolute;
  right: 2px;
  top: 1px;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.grayDarker};
  display: flex;
  font: 17px ${({ theme }) => theme.primaryFont};
  margin: 0 8px 0 32px;

  i,
  img {
    margin-right: 8px;
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
  height: 45px;
  width: 45px;
`;
