import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { debug } from 'utils/helpers';
import { getRPMEvents } from 'utils/rpmEvents';
import { TRPMEvent } from '../rpm.types';
import { dateFormatWithTime } from '../util';

const EventList = ({ memberUuid }: { memberUuid: string }) => {
  const [events, setEvents] = React.useState<TRPMEvent | null>();
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fetchEvents = async (limit: number, offset: number) => {
    try {
      const response = await getRPMEvents(memberUuid, limit, offset);
      if (response?.data) {
        setEvents(response.data);
        setTotalRows(Number(response.data.total));
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err as string);
      }
    }
  };

  const LoadDataEvents = () => {
    const source = axios.CancelToken.source();
    fetchEvents(rowsPerPage, page);

    return () => {
      source.cancel();
    };
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    LoadDataEvents();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    LoadDataEvents();
  }, []);

  return (
    <div>
      {(events === undefined || events === null) && <h5>Loading...</h5>}
      {events?.data.length === 0 && <h5>No events</h5>}

      {events && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Provider Id</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events?.data.map((event) => {
                  return (
                    <TableRow
                      key={`e-${event.eventType}-${event.providerId}-${event.time}`}
                      component='tr'>
                      <TableCell>{event.providerId}</TableCell>
                      <TableCell>{event.eventType}</TableCell>
                      <TableCell>{event.time ? dateFormatWithTime(event.time) : ''}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[15, 25, 100]}
            component='div'
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default EventList;
