import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/patient';

const PatientContext = createContext<{ patientState: State; patientDispatch: Dispatch<Action> }>({
  patientState: initialState,
  patientDispatch: () => null,
});

const PatientProvider: FC<PropsWithChildren> = ({ children }) => {
  const [patientState, patientDispatch] = useReducer(reducer, initialState);
  return (
    <PatientContext.Provider value={{ patientState, patientDispatch }}>
      {children}
    </PatientContext.Provider>
  );
};

export { PatientContext, PatientProvider };
