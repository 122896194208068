import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  background: ${(props) => (props.disabled ? props.theme.calendarBackground : 'transparent')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border-radius: 100%;
  border: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
  transition: all 0.1s ease-in-out;

  &:hover {
    background: ${(props) =>
      props.disabled ? props.theme.calendarBackground : props.theme.inputBackground};
  }

  &:focus-within {
    background: ${({ theme }) => theme.calendarBackground};
  }
`;
