import { Box, Typography } from '@mui/material';
import { AutoCompleteFilter, RadioButtons } from 'components/elements';
import { ScheduleSelectedAppointment } from 'components/main/calendar/ScheduleAppointment/ScheduleSelectedAppointment';
import { RADIO_BUTTONS_TO_DROPDOWN_THRESHOLD } from 'config';
import {
  AppointmentTypeCategory,
  AppointmentTypeWithProviders,
  User,
} from 'node-api/schedule/Appointments.types';
import { FC, useEffect, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import SchedulingService from 'services/scheduling';
import MemberUpcomingAppointments from '../MemberUpcomingAppointments';
import { SendSelfScheduleLinkButton } from './AppointmentActions';
import { AppointmentsWrapper, ButtonWrapper, Content, Record } from './style';

interface CategoryProps {
  data: AppointmentTypeCategory;
  patient: {
    id: number;
    clinic_id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    last_timezone: string;
  };
}

const ProviderCategoryTab: FC<CategoryProps> = ({ data, patient }) => {
  const filteredData = { ...data, appointmentTypes: data.appointmentTypes.filter((e) => e.active) };
  const [reload, setReload] = useState(Date.now());
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentTypeWithProviders>();
  const [providers, setProviders] = useState<User[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<User>();
  const [schedulingLink, setSchedulingLink] = useState<string>();

  const resetOptions = () => {
    setReload(Date.now());
    setProviders([]);
  };

  const handleChangeAppointmentType = (appointmentTypeId: number) => {
    MixpanelWrapperInstance.track(EventNames.ScheduleClickAppointmentType);
    resetOptions();
    const appointmentType = filteredData.appointmentTypes.find((e) => e.id === appointmentTypeId);
    setSelectedAppointmentType(appointmentType);
    setProviders(appointmentType?.providers ?? []);
  };

  useEffect(() => {
    if (selectedAppointmentType && selectedProvider) {
      const schedulingService = new SchedulingService();
      setSchedulingLink(
        schedulingService.getSchedulingLink({
          appointmentType: selectedAppointmentType.id,
          calendarID: selectedProvider.calendarId,
          email: patient.email,
          firstName: patient.first_name,
          lastName: patient.last_name,
          phone: patient.phone,
        })
      );
    }
  }, [selectedAppointmentType, selectedProvider, patient]);

  return (
    <>
      <ButtonWrapper>
        <SendSelfScheduleLinkButton
          appointmentTypeId={selectedAppointmentType?.id}
          appointmentScheduleLink={schedulingLink}
          memberId={patient.id}
          provider={selectedProvider}
        />
      </ButtonWrapper>
      <Content>
        <AppointmentsWrapper>
          <Box mb={3}>
            <Typography paragraph variant='h6'>
              Appointment Types:
            </Typography>
            {filteredData.appointmentTypes.length >= RADIO_BUTTONS_TO_DROPDOWN_THRESHOLD ? (
              <AutoCompleteFilter
                id='appointmentTypes-input'
                name='appointmentTypes'
                label=''
                placeholder=''
                width='700px'
                customStyle={{ maxWidth: 400 }}
                getData={() => filteredData.appointmentTypes}
                onChange={(ev, opt: AppointmentTypeWithProviders) => {
                  MixpanelWrapperInstance.track(EventNames.ScheduleClickAppointmentType);
                  resetOptions();
                  setSelectedAppointmentType(opt);
                  setProviders(opt.providers || []);
                }}
                renderOption={(opt) => (
                  <Record key={`option-appointment-type-${opt.id}`}>
                    <div>{opt.name}</div>
                  </Record>
                )}
              />
            ) : (
              <RadioButtons
                onChange={(e) => handleChangeAppointmentType(+e.target.value)}
                options={filteredData.appointmentTypes.map((appointmentType) => ({
                  value: appointmentType.id,
                  label: appointmentType.name,
                }))}
                value={selectedAppointmentType?.id ?? null}
              />
            )}
          </Box>
          <Box mb={3}>
            <Typography paragraph variant='h6'>
              Pick Provider:
            </Typography>
            <AutoCompleteFilter
              key={`provider-input-${reload}`}
              id='provider-input'
              name='provider'
              label=''
              placeholder=''
              width='300px'
              getData={() => providers.map((p) => ({ ...p, name: `${p.firstName} ${p.lastName}` }))}
              onChange={(ev, opt) => {
                MixpanelWrapperInstance.track(EventNames.SchedulePickProvider);
                setSelectedProvider((opt && opt) || undefined);
              }}
              renderOption={(opt) => (
                <Record key={`option-provider-${opt.id}`}>
                  <div>{opt.name}</div>
                </Record>
              )}
            />
          </Box>
          {selectedAppointmentType?.id && selectedProvider && (
            <Box>
              <ScheduleSelectedAppointment
                appointmentType={selectedAppointmentType}
                provider={selectedProvider}
                patient={patient}
                isMainProvider={false}
              />
            </Box>
          )}
        </AppointmentsWrapper>
        <MemberUpcomingAppointments patient={patient} />
      </Content>
    </>
  );
};

export default ProviderCategoryTab;
