import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;
  & h2,
  p {
    color: ${({ theme }) => theme.grayDark};
  }
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & button {
    width: 50%;
  }
  & button:first-of-type {
    margin: 8px 0;
  }
`;
