import axios from 'axios';
import { TRPMEvent } from 'components/main/rpm-event/rpm.types';
import AuthService from 'services/auth-service';
import { rpmEventAPIData } from './providerTimeTracker.types';

const contentType = 'application/json';

export enum ProviderEvent {
  ProviderViewMemberProfile = 'provider-view-member-profile',
  ProviderVisitMemberProfile = 'provider-visit-member-profile',
  ProviderSendPrivateMessageToMember = 'provider-send-private-message-to-member',
  ProviderSendPrivateMessageToProvider = 'provider-send-private-message-to-provider',
  ProviderReviewPicture = 'provider-review-picture',
  ProviderSendCard = 'provider-send-card',
  ProviderSendCardComment = 'provider-send-card-comment',
  ProviderSendFoodComment = 'provider-send-food-comment',
}

export class ProviderTimeTrackerClient {
  auth = AuthService.getAuth();

  providerId = this.auth?.Authorization.id;

  customAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BILLING_BASE_URL,
    headers: {
      Accept: `${contentType}`,
      'Content-Type': `${contentType}`,
      Authorization: `Bearer ${this.auth?.Authorization.token}`,
    },
  });

  track = async (
    {
      event,
      patientUuid,
    }: {
      event: ProviderEvent;
      patientUuid: string;
    },
    entityId?: string
  ) => {
    const data: rpmEventAPIData = {
      memberUuid: patientUuid.toString(),
      providerId: this.providerId?.toString() || '',
      eventType: event.toString(),
      entityId: entityId?.toString(),
    };
    await this.customAxios.post<{ data: string }>(`/billing/v1/events`, data);
    return data;
  };

  list = async ({
    patientUuid,
    limit,
    offset,
  }: {
    patientUuid: string;
    limit: number;
    offset: number;
  }) => {
    const url = `/billing/v1/events?memberUuid=${patientUuid}&limit=${limit}&offset=${offset}`;
    const response = await this.customAxios.get<TRPMEvent>(url);
    return response;
  };
}
