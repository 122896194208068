import FactCheckIcon from '@mui/icons-material/FactCheck';
import SettingsIcon from '@mui/icons-material/Settings'; // Adjust the import path as needed
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  GridFooterContainer,
  GridPagination,
  GridRowId,
  GridSelectedRowCount,
  useGridApiContext,
} from '@mui/x-data-grid';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { FC, PropsWithChildren } from 'react';
import { PanelReviewDialog } from './PanelReviewDialog';
import { PanelSettingsDialog } from './PanelSettingsDialog'; // Adjust the import path as needed
import { PanelRow, PanelSettings } from './types';

type Props = PropsWithChildren & {
  panelSettings: {
    initialSettings: PanelSettings;
    modalOpen: boolean;
    onCloseModal: () => void;
    onOpenModal: () => void;
    onUpdateSettings: (settings: PanelSettings) => void;
  };
  markAsAttended: {
    selectedRowIds: GridRowId[];
    modalOpen: boolean;
    onDismissNeedForAttention: (selectedAttention: AttentionDimension[]) => void;
    onCloseModal: () => void;
    onOpenModal: () => void;
  };
};

const PanelFooter: FC<Props> = ({
  panelSettings: {
    modalOpen: settingsModalOpen,
    onCloseModal: handleSettingsModalClose,
    onOpenModal: handleSettingsDialogOpen,
    initialSettings,
    onUpdateSettings: handleUpdateSettings,
  },
  markAsAttended: {
    selectedRowIds,
    modalOpen: markModalOpen,
    onCloseModal: handleCloseMarkAsAttended,
    onOpenModal: handleOpenMarkAsAttended,
    onDismissNeedForAttention,
  },
}) => {
  const apiRef = useGridApiContext();
  const selectedPanelRows: PanelRow[] = selectedRowIds.map(
    (id) => apiRef.current.getRow(id) as PanelRow
  );
  const activeDimensions = initialSettings.activeDimensions || {};
  const selectedMemberNeedsActiveAttention = selectedPanelRows.filter((row) =>
    row.attention.some((attention) => activeDimensions[attention.dimensionKey] !== false)
  );
  const isMac = navigator.platform.toUpperCase().includes('MAC');

  return (
    <GridFooterContainer>
      <div style={{ display: 'flex', padding: '0 10px', flex: 1 }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          <PanelSettingsDialog
            open={settingsModalOpen}
            onClose={handleSettingsModalClose}
            onUpdateSettings={handleUpdateSettings}
            initialSettings={initialSettings}
          />
          <PanelReviewDialog
            selectedPanelRows={selectedPanelRows}
            open={markModalOpen}
            onClose={handleCloseMarkAsAttended}
            onDismissNeedForAttention={onDismissNeedForAttention}
          />
          <Tooltip title='Settings' placement='top'>
            <IconButton aria-label='Open Panel Settings dialog' onClick={handleSettingsDialogOpen}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          {selectedMemberNeedsActiveAttention.length === 0 ? (
            <IconButton disabled aria-label='Open Dismiss need for review'>
              <FactCheckIcon />
            </IconButton>
          ) : (
            <Tooltip title='Dismiss need for review' placement='top'>
              <IconButton
                aria-label='Open Dismiss need for review'
                onClick={handleOpenMarkAsAttended}>
                <FactCheckIcon />
              </IconButton>
            </Tooltip>
          )}
          {selectedPanelRows.length > 0 && (
            <GridSelectedRowCount
              selectedRowCount={selectedPanelRows.length}
              sx={{ marginLeft: 0 }}
            />
          )}
          <Typography variant='body2' sx={{ marginLeft: 1 }}>
            Navigate: {isMac ? <b>⌘ + Left/Right keys</b> : <b>Ctrl + Left/Right keys</b>}
          </Typography>
        </div>

        <GridPagination />
      </div>
    </GridFooterContainer>
  );
};

export default PanelFooter;
