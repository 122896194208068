import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import LoadingButton from 'components/atoms/wrappers/LoadingButton';
import { CLIPBOARD_ICON, ENVELOP_ICON } from 'components/icon/unicode';
import Toast from 'components/toast';
import { PVQ_TYPE_FORM_URL } from 'config';
import { AssessmentsClient } from 'node-api/assessments/AssessmentsApiClient';
import { getExternalUrlAnswer } from 'node-api/health-records/HealthRecords-utils';
import { HealthRecordsClient } from 'node-api/health-records/HealthRecordsClient';
import { TypeformDigestedRecordContent } from 'node-api/health-records/HealthRecordsClient.pvq.types';
import { FC, useEffect, useRef, useState } from 'react';
import Theme from 'styles/theme';

type Props = {
  memberId: number;
  formResponse: TypeformDigestedRecordContent['formResponse'] | null;
};

const RESET_MARKER = 'RESET';

export const PvqActions: FC<Props> = ({ memberId, formResponse }) => {
  const existingResponse: 'not-completed' | 'url' | 'without-url' =
    !formResponse || getExternalUrlAnswer(formResponse) === RESET_MARKER
      ? 'not-completed'
      : getExternalUrlAnswer(formResponse)
      ? 'url'
      : 'without-url';
  const [externalPvqUrl, setExternalPvqUrl] = useState<string>(
    existingResponse === 'url' ? getExternalUrlAnswer(formResponse) || '' : ''
  );

  const [markedAsCompleted, setMarkedAsCompleted] = useState<
    'not-completed' | 'updating' | 'url' | 'without-url'
  >(
    existingResponse === 'not-completed'
      ? 'not-completed'
      : getExternalUrlAnswer(formResponse)
      ? 'url'
      : 'without-url'
  );
  const [sendingStatus, setSendingStatus] = useState<
    false | 'sending-request' | 'sending-complete' | 'request-error'
  >(false);

  const assessmentsClient = useRef(new AssessmentsClient());
  const healthRecordsClient = useRef(new HealthRecordsClient());

  useEffect(() => {
    if (!formResponse) {
      setMarkedAsCompleted('updating');

      (async () => {
        try {
          const data = await assessmentsClient.current.getMemberOnboardingStatus(memberId);
          const url = data?.preVisitQuestionnaireExternalFormUrl;
          if (url === RESET_MARKER) {
            setExternalPvqUrl('');
            setMarkedAsCompleted('not-completed');
          } else if (url) {
            setExternalPvqUrl(url);
            setMarkedAsCompleted('url');
          } else if (data?.preVisitQuestionnaireAnswered) {
            setExternalPvqUrl('');
            setMarkedAsCompleted('without-url');
          } else {
            setExternalPvqUrl('');
            setMarkedAsCompleted('not-completed');
          }
        } catch (error) {
          console.error(error);
          setSendingStatus('request-error');
        }
      })();
    }
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(`${PVQ_TYPE_FORM_URL}?id=${memberId}`);

    Toast.show('info', 'PVQ link copied to clipboard.');
  };

  const handleSendRequest = async () => {
    setSendingStatus('sending-request');

    try {
      await assessmentsClient.current.sendPvqRequestOverEmail(memberId);
      Toast.show('info', "PVQ request sent. Awaiting member's completion.");
    } catch (error) {
      Toast.show('error', 'Unable to send PVQ request.');
    }

    setSendingStatus(false);
    setMarkedAsCompleted('not-completed');
  };

  const getHandleMarkAsCompleted =
    ({ reset, useExternalPvqUrl }: { reset?: true; useExternalPvqUrl?: boolean }) =>
    async () => {
      setSendingStatus('sending-complete');

      try {
        await Promise.all([
          assessmentsClient.current.updateMemberOnboardingStatus(memberId, {
            preVisitQuestionnaireAnswered: !reset,
            preVisitQuestionnaireExternalFormUrl: reset
              ? RESET_MARKER
              : useExternalPvqUrl
              ? externalPvqUrl
              : '',
          }),
          healthRecordsClient.current.markPvqAsComplete(memberId, {
            externalUrl: reset ? RESET_MARKER : useExternalPvqUrl ? externalPvqUrl : '',
          }),
        ]);

        if (reset) {
          Toast.show('info', 'PVQ reset');
          setMarkedAsCompleted('not-completed');
        } else {
          Toast.show('info', 'PVQ marked as complete');
          setMarkedAsCompleted(externalPvqUrl ? 'url' : 'without-url');
        }
        setSendingStatus(false);
      } catch (error) {
        Toast.show('error', 'Unable to send PVQ request');
        setSendingStatus('request-error');
      }
    };

  const markedAsCompletedWithExternalUrl = markedAsCompleted === 'url' && externalPvqUrl;
  const markedAsCompletedWithoutExternalUrl = markedAsCompleted === 'without-url';

  const handleViewResults = () => {
    window.open(
      externalPvqUrl?.startsWith('http') || externalPvqUrl?.startsWith('//')
        ? externalPvqUrl
        : `//${externalPvqUrl}`,
      '_blank'
    );
  };

  return (
    <>
      <Box display='flex' flexDirection='row' marginBottom={2}>
        <Typography variant='h6' paddingRight={1}>
          Pre-Visit Questionnaire
        </Typography>
      </Box>
      <Box display='flex' flexDirection='row'>
        <Typography variant='h6' style={{ color: Theme.teal }}>
          {markedAsCompletedWithExternalUrl
            ? 'Marked as completed (with URL)'
            : markedAsCompletedWithoutExternalUrl
            ? 'Marked as completed (without URL)'
            : 'Pending'}
        </Typography>
        <Divider />
      </Box>

      {externalPvqUrl && markedAsCompletedWithExternalUrl && (
        <Box display='flex' flexDirection='row' marginBottom={2}>
          <Button
            variant='contained'
            style={{ marginTop: 10, marginBottom: 10 }}
            onClick={handleViewResults}>
            View Results
          </Button>
        </Box>
      )}

      {!markedAsCompletedWithoutExternalUrl && (
        <>
          <Divider />
          <Typography marginTop={2}>
            Request PVQ completion to prepare for initial medical appointment.
          </Typography>
          <Box display='flex' gap={1} marginTop={2} marginBottom={2}>
            <Button variant='contained' onClick={handleCopyToClipboard}>
              {String.fromCodePoint(CLIPBOARD_ICON)} Copy Link
            </Button>
            <LoadingButton
              loading={sendingStatus === 'sending-request'}
              variant='contained'
              onClick={handleSendRequest}>
              {String.fromCodePoint(ENVELOP_ICON)} Send Request
            </LoadingButton>
          </Box>
        </>
      )}
      {!markedAsCompletedWithExternalUrl && (
        <Box marginBottom={2}>
          <Divider />
          <Typography marginTop={2}>
            For PVQs completed externally, save the results URL here.
          </Typography>

          <TextField
            label='Enter PVQ URL'
            variant='outlined'
            fullWidth
            value={externalPvqUrl}
            onChange={(event) => setExternalPvqUrl(event.target.value)}
          />

          <LoadingButton
            loading={sendingStatus === 'sending-complete'}
            variant='contained'
            style={{ marginTop: 10 }}
            onClick={getHandleMarkAsCompleted({ useExternalPvqUrl: true })}
            disabled={externalPvqUrl.length < 8}>
            Save URL
          </LoadingButton>
        </Box>
      )}
      {!markedAsCompletedWithoutExternalUrl && (
        <Box marginBottom={2}>
          <Divider />
          <Typography marginTop={2}>
            Mark PVQ as completed if verified through other means.
          </Typography>
          <LoadingButton
            loading={sendingStatus === 'sending-complete'}
            variant='contained'
            style={{ marginTop: 10 }}
            onClick={getHandleMarkAsCompleted({ useExternalPvqUrl: false })}>
            Mark as Completed
          </LoadingButton>
        </Box>
      )}
      {markedAsCompletedWithExternalUrl || markedAsCompletedWithoutExternalUrl ? (
        <Box marginBottom={2}>
          <Divider />
          <Typography marginTop={2}>
            In case you need to reset the PVQ manual completion status
          </Typography>
          <LoadingButton
            loading={sendingStatus === 'sending-complete'}
            variant='contained'
            color='warning'
            style={{ marginTop: 10 }}
            onClick={getHandleMarkAsCompleted({ reset: true })}>
            Reset Status
          </LoadingButton>
        </Box>
      ) : null}
    </>
  );
};
