import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Input, Button } from '../../elements';
import { Title, Text, Form } from '../account-styles';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required field'),
});

const ResetPassword = ({ submit }) => {
  const { handleSubmit, register, errors, formState } = useForm({
    validationSchema: schema,
  });

  return (
    <>
      <Title>Password Reset</Title>
      <Text>Please enter the email associated with your account</Text>
      <Form onSubmit={handleSubmit(submit)}>
        <Input errors={errors.email} name='email' label='Email' type='text' register={register} />

        <Button type='submit' size='large' submitting={formState.isSubmitting}>
          Reset
        </Button>
      </Form>
    </>
  );
};

ResetPassword.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default ResetPassword;
