import { StyledComponentsDivProps, StyledComponentsSpanProps } from 'components/general-styles';
import styled from 'styled-components';

export const AppointmentListTitle = styled.div<StyledComponentsDivProps>`
  font-weight: bold;
`;

export const AppointmentsSection = styled.div<StyledComponentsDivProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.9rem;
`;

export const AppointmentListMonth = styled.div<StyledComponentsDivProps>``;
export const AppointmentEntry = styled.div<StyledComponentsDivProps>`
  margin-bottom: 5px;
  background-color: white;
  height: 52px;
  display: flex;
  gap: 10px;
  max-width: 328px;
  overflow: hidden;

  padding: 4px;
  box-sizing: border-box;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  font-size: 0.9rem;
`;

export const AppointmentEntryDate = styled.div<StyledComponentsDivProps & { typeColor?: string }>`
  min-width: 45px;
  background-color: var(--color-${(props) => props.typeColor});
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  color: black;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  box-sizing: border-box;
  justify-content: space-around;
  line-height: 0.5;
`;

export const AppointmentEntryDescription = styled.div<StyledComponentsDivProps>`
  box-sizing: border-box;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  gap: 4px;
`;

export const AppointmentEntryDescriptionTitle = styled.div<StyledComponentsDivProps>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

export const AppointmentEntryDescriptionDetails = styled.div<StyledComponentsDivProps>`
  align-items: center;
  display: flex;
  justify-content: start;
  font-size: 11px;
  gap: 5px;
`;
export const AppointmentEntryDescriptionDetailsDuration = styled.span<StyledComponentsSpanProps>`
  opacity: 0.7;
`;

export const AppointmentEntryDateDay = styled.div<StyledComponentsDivProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
`;

export const AppointmentsContainer = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.grayDark};
  padding: 10px;
  padding-bottom: 0px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AppointmentsList = styled.div<StyledComponentsDivProps>`
  overflow-y: scroll;
  flex-grow: 1;
  padding-bottom: 10px;
  position: relative;
`;
