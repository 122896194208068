import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/notification';

const NotificationContext = createContext<{
  notificationState: State;
  notificationDispatch: Dispatch<Action>;
}>({
  notificationState: initialState,
  notificationDispatch: () => null,
});

const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [notificationState, notificationDispatch] = useReducer(reducer, initialState);
  return (
    <NotificationContext.Provider value={{ notificationState, notificationDispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
