import axios from 'axios';
import { Loading, Tabs } from 'components/elements';
import { PVQResponseSection } from 'node-api/assessments/AssessmentsApi.types';
import { AssessmentsClient } from 'node-api/assessments/AssessmentsApiClient';
import { getAnswersBySection } from 'node-api/health-records/HealthRecords-utils';
import { HealthRecordsClient } from 'node-api/health-records/HealthRecordsClient';
import { TypeformDigestedRecordContent } from 'node-api/health-records/HealthRecordsClient.pvq.types';
import { FC, useEffect, useRef, useState } from 'react';
import MainProfileActions from '..';
import { HealthRecordsFormAnswer } from './pv-questionnaire/HealthRecordsFormAnswer';
import { PVQuestionnaire } from './pv-questionnaire/PVQuestionnaire';
import { PvqActions } from './pv-questionnaire/pvq-manager/PvqActions';
import { Text } from './style';

type Props = {
  patientId: number;
};

const AssessmentTab: FC<Props> = ({ patientId }) => {
  const [legacyPvqData, setLegacyPVQData] = useState<PVQResponseSection[]>([]);
  const [formResponse, setFormResponse] = useState<
    TypeformDigestedRecordContent['formResponse'] | null
  >(null);
  const [status, setStatus] = useState<'loading' | 'pvq-not-found' | 'done'>('loading');

  const assessmentsClient = useRef(new AssessmentsClient());
  const healthRecordsClient = useRef(new HealthRecordsClient());

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getData = async () => {
      try {
        const [legacySections, formDefinition] = await Promise.all([
          assessmentsClient.current.fetchPvqResponse(patientId, source.token),
          healthRecordsClient.current.getLatestPvqEntry(patientId),
        ]);

        if (formDefinition) {
          setFormResponse(formDefinition.formResponse);

          if (formDefinition.formResponse?.length > 1) {
            return setStatus('done');
          }
        }
        if (legacySections && legacySections?.length > 0) {
          setLegacyPVQData(legacySections);
          return setStatus('done');
        }

        return setStatus('pvq-not-found');
      } catch (err) {
        return setStatus('pvq-not-found');
      }
    };

    getData();

    return () => {
      source.cancel();
    };
  }, []);

  const answersBySection = formResponse ? getAnswersBySection(formResponse) : [];

  return (
    <>
      <MainProfileActions key={`main-profile-buttons-${patientId}`} />

      {status === 'loading' ? (
        <Loading />
      ) : status === 'pvq-not-found' ? (
        <PvqActions memberId={patientId} formResponse={formResponse} />
      ) : legacyPvqData && legacyPvqData.length > 0 ? (
        <Tabs
          data={legacyPvqData.map((e, index) => ({ ...e, id: index + 1, name: e.title }))}
          renderTabLabel={(data) => <Text> {data.name} </Text>}
          renderTabContent={(data) => (
            <PVQuestionnaire key={`PVQuestionnaire-${data.id}`} data={data} />
          )}
        />
      ) : formResponse && formResponse.length > 1 ? (
        <Tabs
          data={answersBySection}
          renderTabLabel={(data) => <Text> {data.name} </Text>}
          renderTabContent={(data) => <HealthRecordsFormAnswer key={data.id} data={data} />}
        />
      ) : (
        <div>Error</div>
      )}
    </>
  );
};

export default AssessmentTab;
