import React from 'react';
import PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { Input, Button } from '../../elements';
import { Title, Text, Form } from '../account-styles';

const schema = yup.object().shape({
  code: yup.string().required('Code is required field'),
});

const ValidateCode = ({ submit }) => {
  const { handleSubmit, register, errors, formState } = useForm({
    validationSchema: schema,
  });

  return (
    <>
      <Title>Password Reset</Title>
      <Text>Please enter the verification code that was sent to you</Text>
      <Form onSubmit={handleSubmit(submit)}>
        <Input
          errors={errors.code}
          name='code'
          label='Verification code'
          type='code'
          register={register}
        />

        <Button type='submit' size='large' submitting={formState.isSubmitting}>
          Continue
        </Button>
      </Form>
    </>
  );
};

ValidateCode.propTypes = {
  submit: PropTypes.func.isRequired,
};

export default ValidateCode;
