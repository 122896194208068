import { StyledComponentsDivProps, StyledComponentsPProps } from 'components/general-styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
`;

export const Call = styled.div<StyledComponentsDivProps>`
  display: flex;
  width: 100%;
  margin: 0 0 20px;
`;

export const Moment = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 0;
  width: auto;
`;

export const Day = styled.div`
  color: ${({ theme }) => theme.gray};
  font: 19px/22px ${({ theme }) => theme.primaryFont};
  margin: 0 0 5px;
`;

export const Month = styled.div`
  color: ${({ theme }) => theme.gray};
  font: 600 15px/17px ${({ theme }) => theme.primaryFont};
  margin: 0 0 5px;
`;

export const Time = styled.div`
  color: ${({ theme }) => theme.gray};
  font: 17px/19px ${({ theme }) => theme.primaryFont};
  margin: 0 0 5px;
  text-transform: uppercase;
`;

export const Information = styled.div<StyledComponentsDivProps & { isPatientMissing?: boolean }>`
  align-items: center;
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isPatientMissing === false ? props.theme.grayLight : props.theme.salmon)};
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 25px 35px;

  img {
    display: block;
    border-radius: 100%;
    height: 35px;
    width: 35px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 25%;

  &:last-child {
    justify-content: flex-end;
    min-width: 120px;
    width: 20%;
  }

  h4 {
    color: ${({ theme }) => theme.gray};
    font: 600 15px/17px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }
  p {
    color: ${({ theme }) => theme.grayDark};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }

  button {
    background-color: ${({ theme }) => theme.teal};
    width: 100%;
  }
`;

export const Image = styled.div`
  margin: 0 10px 0 0;
`;

export const Description = styled.div`
  flex-direction: column;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
`;

export const PatientName = styled.div<StyledComponentsDivProps>`
  cursor: pointer;
`;

export const PatientMissing = styled.p<StyledComponentsPProps>`
  color: ${({ theme }) => theme.salmonDark} !important;
`;

export const PatientImage = styled.div<StyledComponentsDivProps & { isPatientMissing?: boolean }>`
  margin: 0 10px 0 0;
  cursor: ${(props) => (props.isPatientMissing ? 'default' : 'pointer')};
`;
