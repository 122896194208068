import axios from 'axios';
import { Loading } from 'components/elements';
import node_api from 'node-api';
import { FC, useEffect, useState } from 'react';
import { ILegacyNodeCardListEntry } from 'services/card-service.types';
import { debug } from 'utils/helpers';
import { BodyTitle, Card, Cards, Title } from '../style';
import CardsPendingCommentsDetail from './CardsPendingCommentsDetail';

const CardsPendingCommentsCardEntry: FC<{ memberId: number }> = ({ memberId }) => {
  const [cards, setCards] = useState<ILegacyNodeCardListEntry[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();

    const getCards = async () => {
      try {
        const params = { patient_id: memberId, auth_token: 'AUTH_TOKEN' };
        const {
          data: { data },
        } = await node_api().get<{ data: { cards: ILegacyNodeCardListEntry[] } }>('cardstoreview', {
          cancelToken: source.token,
          params,
        });
        setCards(data.cards);
      } catch (err: unknown) {
        if (!axios.isCancel(err)) {
          debug(err as string);
        }
      }
      setLoading(false);
    };

    getCards();
    return () => {
      source.cancel();
    };
  }, []);

  if (loading) return <Loading />;

  return (
    <Cards>
      {cards.map((card) => (
        <Card key={`card-pen-cmt-${card.card_id}`}>
          <BodyTitle>
            <br />
            <Title>{card.title}</Title>
          </BodyTitle>
          <CardsPendingCommentsDetail card={card} memberId={memberId} />
        </Card>
      ))}
    </Cards>
  );
};

export default CardsPendingCommentsCardEntry;
