import axios from 'axios';
import { CORE_API } from '../config';

type SignInProps = {
  phone: string;
  password: string;
};

type ResetPasswordProps = {
  email: string;
};

type SetPasswordProps = {
  password: string;
  password_confirmation: string;
  auth_token: string;
};

type SetAuthProps = {
  auth_token: string;
  user_id: number;
};

export type AuthProps = {
  Authorization: {
    id: number;
    token: string;
  };
};

export const AUTHORIZATION = 'Authorization';

const AuthService = {
  signIn(payload: SignInProps) {
    return axios.post(`${CORE_API.url}/v1/auth`, payload);
  },

  resetPassword(payload: ResetPasswordProps) {
    return axios.post(`${CORE_API.url}/v1/auth/send_reset_email`, payload);
  },

  setPassword(payload: SetPasswordProps) {
    return axios.post(`${CORE_API.url}/v1/profile/change_password`, payload);
  },

  // Auth Data
  removeAuth() {
    localStorage.removeItem(AUTHORIZATION);
  },

  setAuth(payload: SetAuthProps) {
    localStorage.setItem(
      AUTHORIZATION,
      JSON.stringify({
        id: payload.user_id,
        auth_token: payload.auth_token,
      })
    );
  },

  getAuth(): AuthProps | undefined {
    if (localStorage.getItem(AUTHORIZATION) === 'undefined') {
      localStorage.removeItem(AUTHORIZATION);
      return undefined;
    }

    const Authorization = JSON.parse(localStorage.getItem(AUTHORIZATION) || '{}');

    if (!Authorization) {
      return undefined;
    }

    return {
      Authorization: {
        id: Authorization.id,
        token: Authorization.auth_token,
      },
    };
  },
};

export default AuthService;
