import { APP_NAME, APP_VERSION, SHARED_MIXPANEL } from 'config';
import { EventNames } from './provider-app-mixpanel-events';
import SharedMixpanelService from './shared-mixpanel-service';

export const ProviderAppMixpanelService = SharedMixpanelService<EventNames>;
ProviderAppMixpanelService.configure({
  mixpanelKey: SHARED_MIXPANEL?.key || '',
  appName: APP_NAME || '',
  appVersion: APP_VERSION || '',
});
export const ProviderAppMixpanelInstance = new ProviderAppMixpanelService();
