import styled, { keyframes } from 'styled-components';

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Spinner = styled.i`
  display: block;
  animation: ${spinnerAnimation} 1s 0s linear infinite;
  font-size: 30px;
  margin-right: 10px;
  vertical-align: middle;
`;
