import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Text = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.teal};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  pointer-events: none;
  min-width: 5em;
  text-align: center;
`;

export const WarningBox = styled.p`
  background: ${({ theme }) => theme.lightWarning};
  border-radius: 10px;
  padding: 1em;
`;

export const WarningInformation = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const WarningSpan = styled.span`
  color: ${({ theme }) => theme.warning};
`;
