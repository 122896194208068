// LocalStorage
export const LOCAL_STORAGE_USER = 'currentUser';
export const ORIGINAL_USER_KEY = 'Origu';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const STATUS_HIDDEN = 'hidden';
export const DIRECT_ROOM = 'direct';
export const TEAM_ROOM = 'team';
export const ROLE_UNAVAILABLE = 'N/A';
export const PROVIDER_DASHBOARD = 'provider-dashboard';

export enum REVIEW_LIST_STATUS {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  COMPLETED = 'completed',
  DELETED = 'deleted',
}

export const IMAGE_IDENTIFIER = '*image*';
export const SEND_GRID_MISSED_APPOINTMENTS_PARAM = { name: 'fsgma', value: 't' };

export enum Conversion {
  Default = 1,
  GramsToLibs = 2,
}

export const GRAMS_PER_POUND = 453.4;
