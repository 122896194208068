import axios from 'axios';
import { ProviderEvent, ProviderTimeTrackerClient } from 'node-api/ProviderTimeTrackerClient';
import { useEffect, useRef } from 'react';
import { debug } from 'utils/helpers';

export const TIME_TRACKER_SIGNAL_INTERVAL_MS = 10000; // default is 10 seconds
export const TIME_TRACKER_IDLE_TIME_MS = 60000; // default is 60 seconds

export const ProviderTimeTracker = ({
  event,
  patientUuid,
  signalIntervalMs,
  idleTimeMs,
}: {
  event: ProviderEvent;
  patientUuid: string;
  signalIntervalMs?: number;
  idleTimeMs?: number;
}) => {
  const timeTrackerCounter = useRef<number>(0);
  const isIdle = useRef<boolean>(false);
  const makeIdleInterval = useRef<ReturnType<typeof setTimeout> | null>();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const trackTime = async () => {
      if (document.visibilityState !== 'visible' || isIdle.current === true) {
        debug('Pause---------');
        return;
      }
      try {
        await new ProviderTimeTrackerClient().track({ event, patientUuid });
        timeTrackerCounter.current += 1;
        debug(`TimeTrackerCounter:${timeTrackerCounter.current}`);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
        }
      }
    };

    const makeIdle = () => {
      debug(`idle before makeIdle: ${isIdle.current}`);
      isIdle.current = true;
      debug(`idle makeIdle: ${isIdle.current}`);
    };

    const interraction = () => {
      // debug(`idle before interraction: ${isIdle.current}`);
      if (document.visibilityState === 'visible') {
        isIdle.current = false;
        if (makeIdleInterval.current) {
          clearTimeout(makeIdleInterval.current);
        }
        makeIdleInterval.current = setTimeout(makeIdle, idleTimeMs || TIME_TRACKER_IDLE_TIME_MS);
        // debug(`idle after interraction: ${isIdle.current}`);
      }
    };

    document.addEventListener('mousemove', interraction);
    document.addEventListener('keypress', interraction);
    document.addEventListener('visibilitychange', interraction);

    makeIdleInterval.current = setTimeout(makeIdle, idleTimeMs || TIME_TRACKER_IDLE_TIME_MS);
    const trackTimeInterval = setInterval(
      trackTime,
      signalIntervalMs || TIME_TRACKER_SIGNAL_INTERVAL_MS
    );

    return () => {
      debug('Stop---------');
      clearInterval(trackTimeInterval);
      if (makeIdleInterval.current) {
        clearTimeout(makeIdleInterval.current);
      }
      source.cancel();
      document.removeEventListener('mousemove', interraction);
      document.removeEventListener('keypress', interraction);
      document.removeEventListener('visibilitychange', interraction);
    };
  }, []);
};

export default ProviderTimeTracker;
