import React from 'react';
import PropTypes from 'prop-types';

import { Field, Select, Label } from './style';
import { Errors } from '../shared-style';

const SelectElement = ({
  errors,
  label,
  name,
  register,
  options,
  height,
  onChange,
  width,
  defaultValue,
}) => (
  <Field error={!!errors} fullWidth>
    {label && <Label htmlFor={`${name}-input`}>{label}</Label>}

    <Select
      id={`${name}-input`}
      name={name}
      ref={register}
      height={height}
      onChange={onChange}
      width={width}
      defaultValue={defaultValue}>
      {options.map((option) => (
        <option key={`select-${name}-${option.value}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>

    {errors && <Errors id={`${name}-error`}>{errors.message ? errors.message : errors}</Errors>}
  </Field>
);

SelectElement.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  register: PropTypes.func,
  height: PropTypes.number,
  onChange: PropTypes.func,
};

SelectElement.defaultProps = {
  errors: null,
  options: [],
  height: 50,
  onChange: undefined,
};

export default SelectElement;
