import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  ToggleModal = 'TOGGLE_MODAL',
  Set = 'SET',
  Add = 'ADD',
  Delete = 'DELETE',
  Reload = 'RELOAD',
}

export type State = {
  modal: boolean;
  reload: number;
  data: { [key: number]: { id: number }[] };
};

type Payload = {
  [ActionTypes.ToggleModal]: undefined;
  [ActionTypes.Set]: {
    patientId: number;
    data: State['data'][0];
  };
  [ActionTypes.Add]: {
    patientId: number;
    data: State['data'][0][0];
  };
  [ActionTypes.Delete]: {
    id: number;
    patientId: number;
  };
  [ActionTypes.Reload]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  reload: 0,
  data: {},
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.ToggleModal: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    case ActionTypes.Set: {
      const { patientId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: data,
        },
      };
    }

    case ActionTypes.Add: {
      const { patientId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: [...(state.data[patientId] ? state.data[patientId] : []), data],
        },
      };
    }

    case ActionTypes.Delete: {
      const { id, patientId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: state.data[patientId].filter((sticky) => sticky.id !== id),
        },
      };
    }

    case ActionTypes.Reload: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
