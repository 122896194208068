import Icon from 'components/icon';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSpring } from 'react-spring';
import './custom-modal.css';
import { Backdrop, CloseButton, Content, Footer, Header, Main, Wrapper } from './style';

/**
 * Modal
 *
 * @param {*} children Content to show inside the modal
 * @param {*} header Modal header
 * @param {*} footer Modal footer
 * @param {boolean} showClose Show close button
 * @param {function} onClose Callback to execute on modal close
 *
 * Example: <Modal onClose={() => console.log("Close modal")}>Lipsum</Modal>
 */
const Modal = ({
  children,
  size,
  header,
  footer,
  showClose,
  onClose,
  disableBackdropClick,
  customClass,
  showSmallCloseBtn,
}) => {
  const modalRoot = document.querySelector('#modal');
  const appRoot = document.querySelector('#root');

  const backdropAnim = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });
  const wrapperAnim = useSpring({
    transform: 'translate3d(0,0,0)',
    from: { transform: 'translate3d(0,-40px,0)' },
  });

  useEffect(() => {
    appRoot.classList.add('modalBg');

    return () => appRoot.classList.remove('modalBg');
  });

  return ReactDOM.createPortal(
    <Backdrop onClick={disableBackdropClick === true ? null : onClose} style={backdropAnim}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        style={wrapperAnim}
        id='enara-custom-modal'
        className={customClass}>
        {showSmallCloseBtn && (
          <div>
            <button type='button' className='modal-close-btn' onClick={onClose}>
              &times;
            </button>
          </div>
        )}
        <Content size={size}>
          {header && <Header>{header}</Header>}
          {children && <Main>{children}</Main>}
          {footer && <Footer>{footer}</Footer>}
          {showClose && (
            <CloseButton onClick={onClose}>
              <Icon icon='close' />
            </CloseButton>
          )}
        </Content>
      </Wrapper>
    </Backdrop>,
    modalRoot
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  footer: PropTypes.shape({}),
  showClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  customClass: PropTypes.string,
  showSmallCloseBtn: PropTypes.bool,
};

Modal.defaultProps = {
  onClose() {
    // eslint-disable-next-line no-console
    console.log('You should handle onClose with a callback function...');
  },
  showClose: true,
  showSmallCloseBtn: false,
  size: 'normal',
  disableBackdropClick: false,
  customClass: '',
};

export default Modal;
