import { StyledComponentsDivProps } from 'components/general-styles';
import styled, { StyledComponent } from 'styled-components';

export const CardContainer: StyledComponent<
  'div',
  never,
  { width: string; height: string; isLoading: boolean },
  never
> = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.grayDarker};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px ${(props) => (props.isLoading ? 'dashed' : 'solid')} ${({ theme }) => theme.grayLight};
  border-radius: 10px;
`;

export const HeaderContainer: StyledComponent<
  'div',
  never,
  { isLoading: boolean },
  never
> = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1
  color: ${({ theme }) => theme.grayDarker};
  border-bottom: 1px ${(props) => (props.isLoading ? 'dashed' : 'solid')}
    ${({ theme }) => theme.grayLight};
  justify-content: center;
`;

export const HeaderTitleContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  gap: 5px;
  width: 85%;
  height: 30%;
  margin: 0 auto;
`;

export const HeaderIcon = styled.div<StyledComponentsDivProps>`
  img {
    width: 18px;
    height: 18px;
  }
`;

export const HeaderTitle = styled.div<StyledComponentsDivProps>`
  display: flex;
  font: 700 16px/18px ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  color: ${({ theme }) => theme.block};
`;

export const HeaderSubtitle = styled.div<StyledComponentsDivProps>`
  display: flex;
  font: 11px/14px ${({ theme }) => theme.primaryFont};
  width: 85%;
  height: 30%;
  margin: 0 auto;
`;

// The i style is for the spinner
export const MainContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  flex: 3
  margin: 0 auto;
  i { 
    margin: auto;
  }
`;

export const MetricContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
`;

export const Metric = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export const MetricValue = styled.div<StyledComponentsDivProps>`
  display: flex;
  font: bold 50px/40px ${({ theme }) => theme.basePrimaryFont};
  font-size: 36px;
  flex: 4;
  align-items: center;
`;

export const MetricDescription = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font: 600 16px/17px ${({ theme }) => theme.basePrimaryFont};
  color: ${({ theme }) => theme.grayDarker};
  font-size: 11px;
  width: 100%;
  height: 10px;
`;

export const MetricSecondDescription = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font: 16px/17px ${({ theme }) => theme.basePrimaryFont};
  color: ${({ theme }) => theme.block};
  font-size: 11px;
  width: 100%;
  height: 10px;
`;

export const MetricEmptyContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  height: 100%;
`;

export const MetricEmptyLabel = styled.span`
  display: flex;
  font-size: 36px;
  flex: 4;
  align-items: center;
`;

export const MetricEmptyBottomLabel = styled.span`
  font-size: 11px;
  width: 100%;
`;

export const ComparisonContainer: StyledComponent<
  'div',
  never,
  { isPositive: boolean },
  never
> = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font: bold 12px/13px ${({ theme }) => theme.primaryFont};
  padding: 2px 10px;

  div:first-child {
    font: 11px ${({ theme }) => theme.primaryFont};
    color: ${(props) => (props.isPositive ? props.theme.green : props.theme.red)};
    margin-right: 6px;
    letter-spacing: 1px;

    span {
      font-size: 15px;
      margin-right: 3px;
    }
  }
`;
