import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const Select = styled.select`
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  display: block;
  padding: 12px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => height}px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 12px;
`;
