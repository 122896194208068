import api from 'api';
import { CancelToken } from 'axios';
import node_api from 'node-api';
import { ScheduleAppointment } from 'node-api/schedule/Appointments.types';
import { ReviewList1SummaryResponse, ReviewList2SummaryResponse } from './reviewlist.types';

export class ReviewListLegacyClient {
  async getReviewList1Summary(cancelToken?: CancelToken) {
    const { data }: { data: ReviewList1SummaryResponse } = await api().get('reviews/AUTH_TOKEN', {
      cancelToken,
    });
    return data;
  }

  async getAppointmentByMemberId(memberId: number): Promise<ScheduleAppointment[]> {
    const {
      data,
    }: {
      data: ScheduleAppointment[];
    } = await api().get(`/v2/patient/${memberId}/schedulings`);
    return data;
  }

  async getReviewList2Summary(cancelToken?: CancelToken) {
    const { data }: { data: ReviewList2SummaryResponse } = await node_api().get(
      'reviews/AUTH_TOKEN',
      {
        cancelToken,
      }
    );
    return data;
  }
}
