import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.teal};
  font: 600 19px/22px ${({ theme }) => theme.primaryFont};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.tealLight};
  }
`;
