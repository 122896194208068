import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const TabBox = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  vertical-align: top;
  width: 100%;
  height: 700px;
  position: relative;
  padding: 16px 16px;
`;

export const RightArrow = styled.div<StyledComponentsDivProps>`
  right: 8px;
  cursor: pointer;
  width: 48px;
`;

export const LeftArrow = styled.div<StyledComponentsDivProps>`
  left: 8px;
  cursor: pointer;
  width: 48px;
`;

export const ChartWrapper = styled.div<StyledComponentsDivProps>`
  height: 500px;
  width: 100%;
  padding: 0 32px 32px 16px;

  svg {
    overflow: visible;
  }

  .apexcharts-xaxis-annotations {
    cursor: pointer;

    & > * {
      transition: all 0s ease-out;
    }
  }

  .apexcharts-line-series .apexcharts-series:first-child {
    transition: none;
    pointer-events: none;
  }

  .apexcharts-datalabel {
    font: 17px ${({ theme }) => theme.primaryFont};
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(-90deg);
  }

  .apexcharts-zoom-icon.selected svg {
    fill: ${({ theme }) => theme.teal} !important;
  }

  .apexcharts-pan-icon.selected svg {
    stroke: ${({ theme }) => theme.teal} !important;
  }
`;

export const SeriesWrappers = styled.div<StyledComponentsDivProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const Serie = styled.div<StyledComponentsDivProps & { type: string; visible?: boolean }>`
  align-items: center;
  cursor: ${(props) => (props.type === 'programs' ? 'pointer' : 'cursor')};
  display: flex;
  font: 400 18px/16px ${({ theme }) => theme.primaryFont};
  justify-content: center;
  margin: 0 20px;

  i {
    background: ${(props) => (props.visible ? props.theme.series[props.type] : props.theme.white)};
    border: 2px solid ${(props) => props.theme.series[props.type]};
    display: block;
    height: 16px;
    margin-right: 8px;
    width: 16px;

    &.serie_0 {
      border-radius: 100%;
    }

    &.serie_1 {
      border-radius: 100%;
    }

    &.serie_2 {
      border-radius: 0;
      transform: rotate(45deg);
    }

    &.serie_3 {
      border-radius: 0;
    }

    &.serie_4 {
      border-radius: 0;
      width: 32px;
    }
  }
`;

export const Tooltip = styled.div<StyledComponentsDivProps & { type: string }>`
  background: ${({ theme }) => theme.white};
  border-left: 8px solid ${(props) => props.theme.series[props.type]};
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, .15);
  margin: -8px -21px;
  overflow: hidden;
  padding: 15px 40px 15px 15px;
  width: 300px;

  h4 {
    color: ${({ theme }) => theme.grayDarker}
    font: 19px/23px ${({ theme }) => theme.primaryFont};
    margin: 0 0 16px;
  }
  
  p {
    color: ${({ theme }) => theme.gray}
    font: 17px/19px ${({ theme }) => theme.primaryFont};
    margin: 0 0 8px;
  }
`;

export const Actions = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;

  a {
    border-bottom: 1px solid ${({ theme }) => theme.teal};
    color: ${({ theme }) => theme.teal};
    display: block;
    font: 17px/19px ${({ theme }) => theme.primaryFont};
    margin: 0 8px;

    &:hover {
      color: ${({ theme }) => theme.tealLight};
      border-bottom: 1px solid ${({ theme }) => theme.tealLight};
    }
  }
`;

export const ChartTitle = styled.div<StyledComponentsDivProps>`
  h4 {
    color: ${({ theme }) => theme.gray};
    font: 600 15px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }
`;
