interface SchedulingLinkParameters {
  appointmentType: number;
  calendarID: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export default class SchedulingService {
  private acuityDirectLinkBaseUrl: string;

  constructor() {
    this.acuityDirectLinkBaseUrl = process.env.REACT_APP_ACUITY_DIRECT_LINK_BASE_URL as string;
  }

  public getSchedulingLink(params: Partial<SchedulingLinkParameters>) {
    const queryParameters = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    return `${this.acuityDirectLinkBaseUrl}/schedule.php?${queryParameters}`;
  }
}
