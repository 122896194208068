import {
  Box,
  Chip,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Spinner from 'components/elements/loaders/spinner';
import Toast from 'components/toast';
import moment from 'moment';
import OnboardingServiceClient from 'node-api/onboarding/OnboardingServiceClient';
import { MemberAgreement } from 'node-api/onboarding/types';
import { FC, useEffect, useState } from 'react';

type Props = {
  memberId: number;
};

const Agreements: FC<Props> = ({ memberId }) => {
  const [signedAgreements, setSignedAgreements] = useState<MemberAgreement[]>([]);
  const [fetchingAgreements, setFetchingAgreements] = useState(true);

  useEffect(() => {
    (async () => {
      setFetchingAgreements(true);
      const agreementsClient = new OnboardingServiceClient();
      try {
        const response = await agreementsClient.getMemberAgreements(memberId);
        setSignedAgreements(response.agreements);
      } catch (err) {
        Toast.show('error', `Couldn't get signed agreements`);
        console.error(err);
      }
      setFetchingAgreements(false);
    })();
  }, []);

  if (fetchingAgreements) {
    return (
      <Stack alignItems='center'>
        <Spinner size={50} />
      </Stack>
    );
  }

  return (
    <Box>
      {!!signedAgreements.length ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Accepted On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signedAgreements.map(({ acceptedOn, code, pdfUrl, title }) => (
                <TableRow>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <Chip label={code} />
                  </TableCell>
                  <TableCell>
                    <Link href={pdfUrl}>Download</Link>
                  </TableCell>
                  <TableCell>{moment(acceptedOn).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack alignItems='center'>
          <Typography>There are no signed agreements</Typography>
        </Stack>
      )}
    </Box>
  );
};

export default Agreements;
