import { Button } from '@mui/material';
import { CSSProperties, useRef, useState } from 'react';
import {
  DisplayTransformFunc,
  Mention,
  MentionItem,
  MentionsInput,
  OnChangeHandlerFunc,
  SuggestionDataItem,
} from 'react-mentions';
import { ChatBoxInputStyle } from './ChatBoxInputStyle';

type Props = {
  autoFocus: boolean;
  sendOnEnter?: boolean;
  mentionOptions: {
    [trigger: string]: {
      markup: string;
      displayTransform: DisplayTransformFunc;
      items: SuggestionDataItem[];
      style?: CSSProperties;
    };
  };
  placeholder: string;
  onChange?: (value: string, plainTextValue: string, mentions: MentionItem[]) => unknown;
  onSend: (
    value: string,
    plainTextValue: string,
    mentions: MentionItem[],
    trackingSource: string
  ) => unknown;
};
export const ChatBoxInput: React.FC<Props> = ({
  autoFocus,
  mentionOptions,
  onChange,
  onSend,
  placeholder,
  sendOnEnter,
}) => {
  const [value, setValue] = useState('');
  const [plainTextValue, setPlainTextValue] = useState('');
  const [mentions, setMentions] = useState<MentionItem[]>([]);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const sendMessage = (trackingSource: string) => {
    onSend(value, plainTextValue, mentions, trackingSource);
    setValue('');
    inputRef.current?.focus();
  };

  const handleOnChange: OnChangeHandlerFunc = (
    event,
    updatedValue,
    updatedPlainTextValue,
    updatedMentions
  ) => {
    // Ideally, we would receive the native event here, but react-mentions does not pass it
    if (sendOnEnter && updatedValue.endsWith('\n')) {
      return sendMessage('chatbox:enter');
    }
    setValue(updatedValue);
    setPlainTextValue(updatedPlainTextValue);
    setMentions(updatedMentions);
    if (onChange) {
      onChange(updatedValue, updatedPlainTextValue, updatedMentions);
    }
  };

  const handleClickSend = () => {
    sendMessage('chatbox:send');
  };

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={divRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: ' 8px 0px 8px 16px',
        borderTop: '1px solid #ddd',
        backgroundColor: 'white',
      }}>
      <div style={{ flexGrow: 1, position: 'relative' }}>
        <MentionsInput
          forceSuggestionsAboveCursor
          inputRef={inputRef}
          autoFocus={autoFocus}
          value={value}
          onChange={handleOnChange}
          style={ChatBoxInputStyle}
          placeholder={placeholder}>
          {Object.entries(mentionOptions).map(
            ([trigger, { markup, displayTransform, items, style }]) => (
              <Mention
                key={trigger}
                markup={markup}
                trigger={trigger}
                data={items}
                style={style}
                displayTransform={displayTransform}
                appendSpaceOnAdd
              />
            )
          )}
        </MentionsInput>
      </div>
      <Button onClick={handleClickSend}>Send</Button>
    </div>
  );
};
