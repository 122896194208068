/* eslint-disable @typescript-eslint/no-use-before-define */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import axios from 'axios';
import moment from 'moment';

import { CheckBox, Loading } from 'components/elements';
import checkedIcon from 'components/icon/pngs/checked-40.png';
import uncheckedIcon from 'components/icon/pngs/unchecked-40.png';
import { CommentContext } from 'state/contexts/comment';
import { ActionTypes } from 'state/reducers/comment';
import { debug } from 'utils/helpers';
import CardsService from '../../../../services/card-service';
import {
  CardOwner,
  CardStatus,
  CardTitle,
  Container,
  Content,
  DayCards,
  Filter,
  IconContainer,
} from './style';

const CardHistory = ({ patientId }) => {
  const { commentDispatch } = useContext(CommentContext);
  const [cards, setCards] = useState({});
  const [onlyMe, setOnlyMe] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format('YYYY-MM'));
  const today = moment(new Date()).format('YYYY-MM-DD');
  // const today = '2020-10-29'
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([
    {
      'react-datepicker__day--highlighted-green': [],
    },
    {
      'react-datepicker__day--highlighted-red': [],
    },
    {
      'react-datepicker__day--highlighted-black': [],
    },
  ]);

  const source = axios.CancelToken.source();
  const getCards = async () => {
    try {
      const cardListing = await CardsService.getLegacyCardListing(patientId, selectedMonth, onlyMe);
      highlightDates(orderCards(cardListing));
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
      }
    }
    setLoading(false);
  };

  const orderCards = (cardsParam) => {
    const orderedCards = {};
    for (const c of cardsParam) {
      const dueDateKey = moment.tz(c.due_at, 'America/Los_Angeles').format('YYYY-MM-DD');
      // const dueDateKey = moment(c.due_at).format('YYYY-MM-DD');
      if (!orderedCards[dueDateKey]) {
        orderedCards[dueDateKey] = [];
      }
      orderedCards[dueDateKey].push(c);
    }
    setCards(orderedCards);
    return orderedCards;
  };

  const highlightDates = (orderedCards) => {
    const dates = [
      {
        'react-datepicker__day--highlighted-green': [],
      },
      {
        'react-datepicker__day--highlighted-red': [],
      },
      {
        'react-datepicker__day--highlighted-black': [],
      },
    ];

    // highlighted dates
    for (const due_date_str of Object.keys(orderedCards)) {
      let allCompleted = true;
      for (const c of orderedCards[due_date_str]) {
        if (c.completed !== true) {
          allCompleted = false;
          break;
        }
      }

      if (allCompleted === true) {
        dates[0]['react-datepicker__day--highlighted-green'].push(
          moment(due_date_str, 'YYYY-MM-DD').toDate()
        );
      } else if (due_date_str >= today) {
        dates[2]['react-datepicker__day--highlighted-black'].push(
          moment(due_date_str, 'YYYY-MM-DD').toDate()
        );
      } else {
        dates[1]['react-datepicker__day--highlighted-red'].push(
          moment(due_date_str, 'YYYY-MM-DD').toDate()
        );
      }
    }

    setHighlightedDates(dates);
  };

  const hasCards = (date) => {
    const dueDateKey = moment(date).format('YYYY-MM-DD');
    return cards[dueDateKey] && cards[dueDateKey].length > 0;
  };

  useEffect(() => {
    setLoading(true);
    setHighlightedDates([
      {
        'react-datepicker__day--highlighted-green': [],
      },
      {
        'react-datepicker__day--highlighted-red': [],
      },
      {
        'react-datepicker__day--highlighted-black': [],
      },
    ]);

    getCards();
    return () => {
      source.cancel();
    };
  }, [selectedMonth, onlyMe]);

  // if (loading) return <Loading />;

  return (
    <Container>
      <Filter>
        <CheckBox checked={onlyMe} onClick={() => setOnlyMe((state) => !state)} />
        Show only my cards
      </Filter>
      <Content>
        <DayCards key={`cards_due_at_${startDate}`}>
          {loading ? (
            <Loading />
          ) : (
            (cards[moment(startDate).format('YYYY-MM-DD')] || []).map((card) => (
              <div key={`cardwrapper-${card.card_id}`}>
                {onlyMe === false && (
                  <CardOwner>{`${card.provider_first_name} ${card.provider_last_name}`}</CardOwner>
                )}
                <CardStatus key={`card-${card.card_id}`}>
                  <IconContainer>
                    <img
                      src={card.completed === true ? checkedIcon : uncheckedIcon}
                      height='34'
                      width='34'
                      alt=''
                    />
                  </IconContainer>
                  <CardTitle
                    onClick={() =>
                      commentDispatch({
                        type: ActionTypes.TOGGLE_MODAL,
                        payload: { element: card },
                      })
                    }>
                    {card.title}
                  </CardTitle>
                </CardStatus>
              </div>
            ))
          )}
        </DayCards>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          onMonthChange={(date) => {
            setSelectedMonth(moment(date).format('YYYY-MM'));
            setStartDate(date);
          }}
          highlightDates={highlightedDates}
          // excludeDates={[new Date(), subDays(new Date(), 1)]}
          // includeDates={[new Date(), addDays(new Date(), 1)]}
          filterDate={hasCards}
          // dateFormat="MMMM d, yyyy h:mm aa"
          // openToDate={new Date("1993/09/28")}
          // todayButton="Today"
          showMonthDropdown
          // showYearDropdown
          dropdownMode='select'
          inline
        />
      </Content>
    </Container>
  );
};

CardHistory.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default CardHistory;
