import axios, { AxiosInstance } from 'axios';
import { ENV, PLATFORM_ENVIRONMENT } from 'config';
import AuthService from 'services/auth-service';

const contentType = 'application/json';

/**
 * CommonEnaraNodeClient
 */
export abstract class SharedEnaraNodeClient {
  protected readonly axiosClient: AxiosInstance;

  constructor(protected readonly baseURL?: string, includeExtraParams = true) {
    this.axiosClient = axios.create({
      baseURL,
      headers: {
        Accept: `${contentType}`,
        'Content-Type': `${contentType}`,
      },
    });

    this.axiosClient.interceptors.request.use((config) => {
      const { Authorization } = AuthService.getAuth() || {};

      if (Authorization?.token) {
        config.headers.Authorization = `Bearer ${Authorization.token}`;
      }

      config.params = {
        ...(includeExtraParams && { env: ENV, 'fe-env': PLATFORM_ENVIRONMENT }),
        ...config.params,
      };

      return config;
    });
  }
}
