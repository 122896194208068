/* eslint-disable */
import axios from 'axios';
import { AutoCompleteFilter, Button, IconButton, Loading } from 'components/elements';
import Icon from 'components/icon';
import node_api from 'node-api';
import { useContext, useEffect, useState } from 'react';
import { debug } from 'utils/helpers';

import { ClinicContext } from 'state/contexts/clinic';
import { ProgramContext } from 'state/contexts/program';
import { StatusContext } from 'state/contexts/status';

import Theme from 'styles/theme';

import moment from 'moment';
import {
  Actions,
  Filters,
  FiltersActions,
  FilterTitle,
  FilterTitleContainer,
  Info,
  InfoFirstColumn,
  Item,
  Record,
  Separator,
  Table,
} from './style';

const header = ['Time Frame', 'Completer WL %', 'ITTL WL %', 'Completers', 'Dropouts'];

const WeightLossStats = () => {
  const [loading, setLoading] = useState(true);
  const [weightLossStats, setweightLossStats] = useState({});
  const [showFilters, SetShowFilters] = useState(true);

  const { clinicState } = useContext(ClinicContext);
  const { statusState } = useContext(StatusContext);
  const { programState } = useContext(ProgramContext);

  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState();

  const [mps, setMPs] = useState([]);
  const [selectedMP, setSelectedMP] = useState();

  const [rds, setRDs] = useState([]);
  const [selectedRD, setSelectedRD] = useState();

  const [ecs, setECs] = useState([]);
  const [selectedEC, setSelectedEC] = useState();

  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();

  const [programs, setPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState();

  const [genders, setGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState();

  const [ages, setAges] = useState([]);
  const [selectedAge, setSelectedAge] = useState();

  const [BMIs, setBMIs] = useState([]);
  const [selectedBMI, setSelectedBMI] = useState();

  const [startDates, setStartDates] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();

  const [reload, setReload] = useState(Date.now());

  const floatOrInt = (val) => {
    return val && val.indexOf('.') > 0 ? parseFloat(val) : parseInt(val);
  };

  const makeRange = (val) => {
    let min;
    let max;
    try {
      val = val.trim();
      if (val.startsWith('>')) {
        min = floatOrInt(val.substring(1));
      } else if (val.startsWith('<')) {
        max = floatOrInt(val.substring(1));
      } else if (val.indexOf('-') > 0) {
        const l = val.split('-');
        min = floatOrInt(l[0]);
        max = floatOrInt(l[1]);
      } else {
        const conv = floatOrInt(val);
        return { val: Number.isNaN(conv) ? val : conv };
      }
    } catch (e) {
      return { val };
    }
    return { min, max };
  };

  const makeDateRange = (val) => {
    let min;
    let max;
    try {
      val = val.trim();
      if (val.startsWith('>')) {
        min = `${val.substring(1).toString()}-01-01`;
      } else if (val.indexOf('-') > 0 && val.indexOf('_') > 0) {
        const l = val.split('_');
        let y = l[0];
        const m = l[1].split('-');
        let mMin = m[0].length === 1 ? `0${m[0]}` : m[0];
        if (mMin === '06') {
          mMin = '07';
        }
        min = `${y}-${mMin}-01`;
        let mMax = m[1].length === 1 ? `0${m[1]}` : m[1];
        if (mMax === '12') {
          mMax = '01';
          y = floatOrInt(y) + 1;
        }
        max = `${y}-${mMax}-01`;
      } else if (val.indexOf('-') > 0) {
        const l = val.split('-');
        min = `${l[0].toString()}-01-01`;
        max = `${l[1].toString()}-01-01`;
      }
    } catch (e) {
      return { val };
    }
    return { min, max };
  };

  const generateStartDates = () => {
    const names = ['>2017'];
    const ids = ['>2017'];
    const data = [];
    const years = [];
    const currentYear = moment().year();
    for (var i = 2017; i <= currentYear.toString(); i++) {
      years.push(i.toString());
    }
    const { length } = years;
    for (var i = 0; i < length - 2; i++) {
      names.push(`${years[i]}-${years[i + 1]}`);
      ids.push(`${years[i]}-${years[i + 1]}`);
    }

    names.push(`${(currentYear - 1).toString()} 1-6`);
    names.push(`${(currentYear - 1).toString()} 7-12`);
    names.push(`${currentYear.toString()} 1-6`);
    names.push(`${currentYear.toString()} 7-12`);

    ids.push(`${(currentYear - 1).toString()}_1-6`);
    ids.push(`${(currentYear - 1).toString()}_7-12`);
    ids.push(`${currentYear.toString()}_1-6`);
    ids.push(`${currentYear.toString()}_7-12`);

    for (var i = 0; i < names.length; i++) {
      data.push({ name: names[i], id: ids[i] });
    }
    return data;
  };

  const submit = async (payload) => {
    setLoading(true);
    const source = axios.CancelToken.source();
    const mp = [
      ...((selectedMP && [selectedMP]) || []),
      ...((selectedRD && [selectedRD]) || []),
      ...((selectedEC && [selectedEC]) || []),
    ].join();
    const clinic_id = selectedClinic;
    const status_id = selectedStatus;
    const program_id = selectedProgram;
    const gender = selectedGender;
    const { min: min_age, max: max_age } = makeRange(selectedAge);
    const { min: min_bmi, max: max_bmi } = makeRange(selectedBMI);
    const { min: min_start_date, max: max_start_date } = makeDateRange(selectedStartDate);
    const params = {
      auth_token: 'AUTH_TOKEN',
      ...(clinic_id && { clinic_id }),
      ...(mp && { mp }),
      ...(status_id && { status_id }),
      ...(program_id && { program_id }),
      ...(gender && { gender }),
      ...(min_age && { min_age }),
      ...(max_age && { max_age }),
      ...(min_bmi && { min_bmi }),
      ...(max_bmi && { max_bmi }),
      ...(min_start_date && { min_start_date }),
      ...(max_start_date && { max_start_date }),
    };
    try {
      // Get Data
      const {
        data: { data },
      } = await node_api().get('weightloss', {
        cancelToken: source.token,
        params,
      });

      setweightLossStats(data);

      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
        setLoading(false);
      }
    }
  };

  const setMPFilters = (clinic) => {
    // MPs Data
    let mpsList = [];
    let rdsList = [];
    let ecsList = [];
    for (const roles of clinicState.roles) {
      const list = clinic
        ? roles.team
            .filter((key) => key.clinic_id === clinic.id)
            .map((key) => ({
              name: `${key.first_name} ${key.last_name}`,
              id: key.id,
              clinic_id: key.clinic_id,
            }))
        : roles.team.map((key) => ({
            name: `${key.first_name} ${key.last_name}`,
            id: key.id,
            clinic_id: key.clinic_id,
          }));
      switch (roles.name) {
        case 'Medical Provider':
          mpsList = list;
          break;
        case 'Dietitian':
          rdsList = list;
          break;
        case 'Exercise Coach':
          ecsList = list;
          break;
        default:
          break;
      }
    }
    setMPs(
      mpsList
        .filter(
          (ele, ind) =>
            ind === mpsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    setRDs(
      rdsList
        .filter(
          (ele, ind) =>
            ind === rdsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    setECs(
      ecsList
        .filter(
          (ele, ind) =>
            ind === ecsList.findIndex((key) => key.name === ele.name && key.id === ele.id)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
  };

  const setClinicFilter = (mp, rd, ec) => {
    // Clinics Data
    let clinicsList = [];
    if (mp !== undefined)
      clinicsList = clinicState.clinics.filter((key) => key.id === mp.clinic_id);
    if (rd !== undefined)
      clinicsList.length > 0
        ? (clinicsList = clinicsList.filter((key) => key.id === rd.clinic_id))
        : (clinicsList = clinicState.clinics.filter((key) => key.id === rd.clinic_id));
    if (ec !== undefined)
      clinicsList.length > 0
        ? (clinicsList = clinicsList.filter((key) => key.id === ec.clinic_id))
        : (clinicsList = clinicState.clinics.filter((key) => key.id === ec.clinic_id));
    if (mp === undefined && rd === undefined && ec === undefined)
      clinicsList = clinicState.clinics.sort((a, b) => (a.name > b.name ? 1 : -1));

    setClinics(clinicsList);
  };

  const clinicOnChange = (clinic) => {
    setSelectedClinic((clinic && clinic.id) || undefined);
    setMPFilters(clinic);
  };

  const mpOnChange = (mp) => {
    setSelectedMP((mp && mp.id) || undefined);
    setClinicFilter(mp || undefined, undefined, undefined);
  };

  const rdOnChange = (rd) => {
    setSelectedRD((rd && rd.id) || undefined);
    setClinicFilter(undefined, rd || undefined, undefined);
  };

  const ecOnChange = (ec) => {
    setSelectedEC((ec && ec.id) || undefined);
    setClinicFilter(undefined, undefined, ec || undefined);
  };

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();
    const params = { auth_token: 'AUTH_TOKEN' };

    const getData = async () => {
      try {
        // Get Data
        const {
          data: { data },
        } = await node_api().get('weightloss', {
          cancelToken: source.token,
          params,
        });
        setweightLossStats(data);

        // ----  Get Filters Data ----
        // Clinics Data
        setClinics(
          clinicState.clinics
            .map((key) => ({ name: key.name, id: key.id }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
        // MPs Data
        setMPFilters();
        // Statuses Data
        const included_statuses = ['Active', 'Completed', 'Dropout', 'Disengaged', 'Paused'];
        setStatuses(
          statusState.data
            .filter((key) => included_statuses.includes(key.text))
            .map((key) => ({ name: key.text, id: key.id }))
        );
        // Programs Data
        setPrograms(
          programState.data
            .map((key) => ({ name: key.text, id: key.id }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        );
        setGenders([
          { name: 'Female', id: 'Female' },
          { name: 'Male', id: 'Male' },
        ]);
        setAges([
          { name: '18 to 24', id: '18-24' },
          { name: '25 to 34', id: '25-34' },
          { name: '35 to 44', id: '35-44' },
          { name: '45 to 54', id: '45-54' },
          { name: '55 to 64', id: '55-64' },
          { name: '>65', id: '>65' },
        ]);
        setBMIs([
          { name: '25-29.9', id: '25-29.9' },
          { name: '>30', id: '>30.0' },
          { name: '30.0-34.9', id: '30.0-34.9' },
          { name: '35.0-39.9', id: '35.0-39.9' },
          { name: '>40.0', id: '>40' },
        ]);
        setStartDates(generateStartDates());
        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
          setLoading(false);
        }
      }
    };
    getData();

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <>
      <FilterTitleContainer>
        <FilterTitle>Filters</FilterTitle>
        <div>
          <IconButton onClick={() => SetShowFilters((prev) => !prev)}>
            <Icon icon='arrow' color='gray' size={16} />
          </IconButton>
        </div>
      </FilterTitleContainer>
      {
        <FiltersActions key={`filter-${reload}`} showFilters={showFilters}>
          <Filters>
            <AutoCompleteFilter
              id='clinic-input'
              name='clinic'
              label='Clinic'
              placeholder='All Clinics'
              // register={register}
              getData={() => clinics}
              onChange={(e, opt) => {
                clinicOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='mp-input'
              name='mp'
              label='Medical Provider'
              placeholder='All Medical Providers'
              getData={() => mps}
              onChange={(e, opt) => {
                mpOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='rd-input'
              name='rd'
              label='Registered Dietitian'
              placeholder='All Registered Dietitians'
              getData={() => rds}
              onChange={(e, opt) => {
                rdOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='ec-input'
              name='ec'
              label='Exercise Coach'
              placeholder='All Exercise Coaches'
              getData={() => ecs}
              onChange={(e, opt) => {
                ecOnChange(opt);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
          </Filters>
          <br />
          <Filters>
            <AutoCompleteFilter
              id='status-input'
              name='status'
              label='Status'
              placeholder='Status'
              getData={() => statuses}
              onChange={(e, opt) => {
                setSelectedStatus((opt && opt.id) || undefined);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='program-input'
              name='program'
              label='Program'
              placeholder='Program'
              getData={() => programs}
              onChange={(e, opt) => {
                setSelectedProgram((opt && opt.id) || undefined);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
          </Filters>
          <br />
          <Filters>
            <AutoCompleteFilter
              id='gender-input'
              name='gender'
              label='Gender'
              placeholder='Gender'
              getData={() => genders}
              onChange={(e, opt) => {
                setSelectedGender((opt && opt.id) || undefined);
                // debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='age-input'
              name='age'
              label='Age'
              placeholder='Age'
              getData={() => ages}
              onChange={(e, opt) => {
                setSelectedAge((opt && opt.id) || undefined);
                // debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='bmi-input'
              name='bmi'
              label='BMI'
              placeholder='BMI'
              getData={() => BMIs}
              onChange={(e, opt) => {
                setSelectedBMI((opt && opt.id) || undefined);
                // debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
            <Separator />
            <AutoCompleteFilter
              id='start_date-input'
              name='start_date'
              label='Start Date'
              placeholder='Start Date'
              getData={() => startDates}
              onChange={(e, opt) => {
                setSelectedStartDate((opt && opt.id) || undefined);
                debug(opt);
              }}
              renderOption={(opt) => (
                <Record key={`option-${opt.id}`}>
                  <Item>{opt.name}</Item>
                </Record>
              )}
            />
          </Filters>
          <Actions>
            <Button
              variant='link'
              onClick={() => {
                setReload(Date.now());
                setSelectedClinic();
                setSelectedMP();
                setSelectedRD();
                setSelectedEC();
                setSelectedStatus();
                setSelectedProgram();
                setSelectedGender();
                setSelectedAge();
                setSelectedBMI();
                setMPFilters();
                setClinicFilter();
                setSelectedStartDate();
              }}>
              Reset
            </Button>
            <Button type='submit' onClick={submit}>
              Submit
            </Button>
          </Actions>
        </FiltersActions>
      }
      {loading ? (
        <Loading />
      ) : (
        <Table key='table'>
          <tbody key='tbody'>
            <tr key='header'>
              {header.map((wl, i) =>
                i === 0 ? (
                  <th key={`th-${i}`} style={{ color: Theme.grayDarker }}>
                    {' '}
                    {header[i]}{' '}
                  </th>
                ) : (
                  <th key={`th-${i}`}> {header[i]} </th>
                )
              )}
            </tr>
          </tbody>
          {weightLossStats.wl_stats.map((wl) => (
            <tbody key={`tbody-${wl.month}`}>
              <tr key={`tr-${wl.month}`}>
                {Object.values(wl).map((val, i) =>
                  typeof val === 'number' ? (
                    val < 10 ? (
                      <td key={`td-${val}`} style={{ padding: '5px 30px' }}>
                        <InfoFirstColumn>
                          {val}
                          <p> Months </p>
                        </InfoFirstColumn>
                      </td>
                    ) : (
                      <td key={`td-${val}`} style={{ padding: '5px 10px' }}>
                        <InfoFirstColumn>
                          {val}
                          <p> Months </p>
                        </InfoFirstColumn>
                      </td>
                    )
                  ) : (
                    <td key={`td-${val}-${i}`}>
                      {' '}
                      <Info> {val} </Info>{' '}
                    </td>
                  )
                )}
              </tr>
            </tbody>
          ))}
        </Table>
      )}
    </>
  );
};

export default WeightLossStats;
