import { PVQResponseSection } from 'node-api/assessments/AssessmentsApi.types';
import { FC } from 'react';
import {
  Answer,
  Header,
  Pair,
  PairContainer,
  Question,
  QuestionContainer,
  SubPair,
  SubQuestion,
} from './style';

type Props = {
  data: PVQResponseSection;
};

export const PVQuestionnaire: FC<Props> = ({ data }) => {
  return (
    <>
      <Header>{data.title}</Header>
      <QuestionContainer>
        {data.fields.map((question) => (
          <PairContainer key={`PairContainer-${question.id}`}>
            {question.answer && (
              <Pair>
                <Question>{question.title}</Question>
                <Answer>{question?.answer}</Answer>
              </Pair>
            )}
            {question.fields && (
              <Pair>
                <Question>{question.title}</Question>
                {question.fields.map((subQuestion) => (
                  <SubPair key={`SubPair-${subQuestion.id}`}>
                    <SubQuestion>{subQuestion.title}</SubQuestion>
                    <Answer>{subQuestion?.answer}</Answer>
                  </SubPair>
                ))}
              </Pair>
            )}
          </PairContainer>
        ))}
      </QuestionContainer>
    </>
  );
};
