import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Datepicker = styled(DatePicker)`
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.teal};
  display: block;
  padding: 16px;
  padding-right: 40px;
  width: 80%;
  height: 40px;
  margin-left: 30px;
  margin-top: 10px;
  text-align: center;
  align-content: center;
`;

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;

  & > div {
    width: 100%
  }

  .react-datepicker__day--selected {
    background: ${({ theme }) => theme.teal};
    border-radius: 8px;
  }
  .react-datepicker__day--today {
    background: ${({ theme }) => theme.turquoise};
    border-radius: 8px;
    color: ${({ theme }) => theme.white};
    font-weight: 400;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }

  input#select_week-input {
    padding-right: 40px;
    width: 100%;
    height: 40px;
    margin-left: -5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: medium;
  }

  #select_week-input::placeholderText: {
    fontSize: 18,
    color: "teal";
  }
`;

export const DatepickerContainer = styled.div`
  display: inline-block;
  position: relative;

  .react-datepicker__day--highlighted-gray {
    background: ${({ theme }) => theme.grayLight};
    border-radius: 8px;
  }
  .react-datepicker__day--highlighted-green {
    background: ${({ theme }) => theme.tealLight};
    border-radius: 8px;
  }
`;

export const CalendarIcon = styled.div`
  position: absolute;
  top: 16px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 0px;
  min-height: 60px;
`;

export const UnreviewedPicturesFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const DateFilter = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 60px;
  margin-left: auto;

  Button {
    margin-right: 20px
    margin-top: 10px;
    margin-bottom: 10px
  }
  img {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-right: 5px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 500px;
`;

export const BodyInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  .container {
    margin-bottom: 30px;
  }
`;

export const SingleImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 206px;
  width: 24%;
  margin-right: 1%;
  margin-bottom: 16px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1px;
  border: 3px solid
    ${(props) =>
      props.label === 'Breakfast'
        ? props.theme.turquoiseDark
        : props.label === 'Lunch'
        ? props.theme.salmon
        : props.theme.grayDark};
`;

export const ImageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  height: 28px;
  width: calc(100% - 2px);
  border-radius: 4px 4px 0 0;
  top: 1px;
  left: 1px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 5px 5px;
  margin: 0;
`;

export const ImageTimestamp = styled.div`
  display: flex;
  align-items: center;
`;

export const CommentsCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 4px;

  img {
    height: 14px;
    width: 14px;
    position: relative;
    top: 1px;
  }
`;
export const CommentsCount = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 2px;
  // margin-top: 10px;
  // margin-right: 6px;
  // margin-left: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  // border: 3px solid #F37962;
`;

export const WeekRange = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 21px;
  line-height: 21px;
  font-weight: bold;
  color: #fff;
  // background: rgba(0,0,0,0.6);
  background-color: ${(props) => (props.empty ? props.theme.gray : props.theme.teal)};

  // background-color: ${({ theme }) => theme.teal};
  align-items: center;
  padding: 10px;
  border-radius: 40px 40px 4px 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  justify-content: center;
  margin-bottom: 4px;
`;

export const BodyTitle = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 19px;
  line-height: 19px;
  font-weight: bold;
  color: ${({ theme }) => theme.teal};
  background-color: ${({ theme }) => theme.turquoise};
  height: 40px;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const BodyLine = styled.hr`
  border: 2px solid ${({ theme }) => theme.grayLighter};
  margin: 0.5em 0;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: ${({ theme }) => theme.grayDark};
`;

// export const ImageContainer = styled.div`
//   width: 100%;
//   height: 80%;
//   line-height: 1.25em;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   overflow: auto;
//   ::-webkit-scrollbar {
//     width: 10px;
//   }
//   ::-webkit-scrollbar-track {
//     background: ${({ theme }) => theme.grayLighter};
//   }
//   ::-webkit-scrollbar-thumb {
//     background: ${({ theme }) => theme.grayLight};
//   }
//   ::-webkit-scrollbar-thumb:hover {
//     background: ${({ theme }) => theme.turquoise};
//   }
// `;

export const BodyAverage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 36px;

  div {
    display: flex;
    flex-direction: row;
  }
  img {
    display: flex;
    position: relative;
    flex-wrap: wrap;
  }
  span {
    display: flex;
    margin-left: 20px;
    font-weight: bold;
    line-height: 36px;
  }

  div:nth-child(1) {
    color: ${({ theme }) => theme.turquoiseDark};
  }
  div:nth-child(2) {
    color: ${({ theme }) => theme.salmon};
  }
  div:nth-child(3) {
    color: ${({ theme }) => theme.grayDark};
  }
`;
