import { getAnswerValue } from 'node-api/health-records/HealthRecords-utils';
import { TypeformDigestedAnswer } from 'node-api/health-records/HealthRecordsClient.pvq.types';
import { FC } from 'react';
import { Answer, Header, Pair, PairContainer, Question, QuestionContainer } from './style';

type Props = {
  data: { name: string; questions: TypeformDigestedAnswer[] };
};

export const HealthRecordsFormAnswer: FC<Props> = ({ data }) => {
  return (
    <>
      <Header>{data.name}</Header>
      <QuestionContainer>
        {data.questions.map((question) => (
          <PairContainer key={question.field.ref}>
            <Pair>
              <Question>{question.field.title}</Question>
              <Answer>{getAnswerValue(question.answer)}</Answer>
            </Pair>
          </PairContainer>
        ))}
      </QuestionContainer>
    </>
  );
};
