import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const AccordionWrapper = styled.div<StyledComponentsDivProps>`
  width: 100%;

  & .MuiAccordionSummary-root {
    .MuiTypography-root {
      font-size: 1.1rem;
    }
  }

  & .MuiAccordionDetails-root {
    flex-direction: column;
    color: ${({ theme }) => theme.grayDark};

    .MuiTypography-root {
      font-size: 1rem;
    }

    span {
      font-weight: bold;
      margin-left: 18px;
    }
  }
`;
