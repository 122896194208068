export enum AcuityAppointmentTag {
  ProviderAppScheduleTab = 'source:provider-app:schedule-tab',
}

export type User = {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  calendarId: number;
  timezone?: string;
};

export interface AppointmentTypeWithProviders {
  id: number;
  active: boolean;
  name: string;
  code: string;
  calendarIDs?: number[];
  providers: User[];
}

export interface AppointmentTypeWithCareTeam extends AppointmentTypeWithProviders {
  scheduleLinkHash: string;
}

export interface AppointmentTypeCategory {
  name: string;
  appointmentTypes: AppointmentTypeWithProviders[];
  visible: boolean;
  order: number;
}

export interface AppointmentTypeCategoryWithProviderByRole {
  name: string;
  provider: User;
  appointmentTypes: AppointmentTypeWithCareTeam[];
}

export interface GetAppointmentTypesForClinicResponse {
  categories: AppointmentTypeCategory[];
  success: boolean;
}

export interface GetAppointmentTypesForCareTeamResponse {
  categories: AppointmentTypeCategoryWithProviderByRole[];
  success: boolean;
}

export interface Appointment {
  appointmentId: number;
  email: string;
  date: string;
  time: string;
  endTime: string;
  dateCreated: string;
  datetimeCreated: string;
  datetime: string;
  type: string;
  appointmentTypeID: number;
  category: string;
  duration: string;
  location: string;
  timezone: string;
  calendar: string;
  canceled: boolean;
}

export interface Appointments {
  upcomingAppointments: Appointment[];
  pastAppointments: Appointment[];
}

export interface AppointmentsResponse extends Appointments {
  success: boolean;
}

export interface ScheduleAppointment {
  id: number;
  user_id: number;
  provider_id: number;
  provider_visit_url: string;
  patient_visit_url: string;
  provider_visit_code: string;
  patient_visit_code: string;
  appointment_id: number;
  appointment_type_id: number;
  appointment_type: string;
  calendar_id: number;
  start_at: string;
  end_at: string;
  status: string;
  drchrono_appointment_id: number;
  patient_email: string;
  place_of_service: string;
  is_editable: boolean;
}

/**
 * Types for new schedule availability (v2)
 * Will include `V2` in the name to differentiate from the old types and remove we move all code to V2
 */
type AvailabilityV2 = {
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    calendarId: number;
    timezone: string;
  };
  appointmentType: {
    id: number;
    officialName: string;
  };
};

export enum Specialty {
  Medical = 'MD',
  Nutritional = 'NS',
  Exercise = 'ES',
  Metabolism = 'DIA',
}

export enum ScheduleRestrictionRuleCode {
  // Monthly
  WeeklyNSEs = 'weekly-ns-es',
  WeeklyGC = 'weekly-gc',
  FollowUpMD = 'followup-md',
  FollowUpOther = 'followup-other',

  // Daily
  InitialFragmentationBadSplit = 'initial_fragmentation-bad_split',
}

export type MonthlyAvailabilityV2 = AvailabilityV2 & {
  dates: {
    allowed: boolean;
    date: string;
    issues: ScheduleRestrictionRuleCode[];
  }[];
};

export type DailyAvailabilityV2 = AvailabilityV2 & {
  times: {
    allowed: boolean;
    time: string;
    slotsAvailable: number;
    issues: ScheduleRestrictionRuleCode[];
  }[];
};

export type GetGroupClassAppointmentsResponse = {
  groupClassAppointments: AppointmentTypeWithProviders[];
};
