import { Constants } from 'components/main/calendar/utils';
import moment from 'moment-timezone';
import { Actions } from './style';

type Serie = 'programs' | 'events' | 'appointments';

export interface Annotation {
  value: string;
  name: string;
  date: string;
  start_date: string;
  end_date: string;
  duration: number;
}

export const getTooltipContent = (serie: Serie, annotation: Annotation) => {
  switch (serie) {
    case 'programs': {
      const today = moment(new Date()).format(Constants.DATE_FORMAT);
      const startDate = moment(annotation.start_date).format(Constants.DATE_FORMAT);
      return (
        <>
          <p>
            Patient: <strong>{annotation.value}</strong>
          </p>
          <p>
            Starting: <strong>{moment(annotation.start_date).format(Constants.DATE_FORMAT)}</strong>
          </p>
          {annotation.end_date ? (
            <>
              <p>
                Ending: <strong>{moment(annotation.end_date).format(Constants.DATE_FORMAT)}</strong>
              </p>
              <p>
                Duration: <strong>{`${annotation.duration} days`}</strong>
              </p>
            </>
          ) : (
            <>
              {startDate < today ? (
                <p>
                  Duration: <strong>{`${annotation.duration} days`}</strong>
                </p>
              ) : startDate > today ? (
                <p>
                  Starting in : <strong>{`${Math.abs(annotation.duration)} days`}</strong>
                </p>
              ) : null}
            </>
          )}
        </>
      );
    }
    case 'events': {
      return (
        <>
          <p>
            Patient: <strong>{annotation.value ? annotation.value : annotation.name}</strong>
          </p>
          <p>
            Starting: <strong>{moment(annotation.start_date).format(Constants.DATE_FORMAT)}</strong>
          </p>
          <p>
            Ending:{' '}
            <strong>
              {annotation.end_date !== null && annotation.end_date !== undefined
                ? moment(annotation.end_date).format(Constants.DATE_FORMAT)
                : ''}
            </strong>
          </p>
        </>
      );
    }
    case 'appointments': {
      return (
        <>
          <p>
            <strong>{annotation.value}</strong> -{' '}
            {moment(annotation.date).format(Constants.SHORT_DATETIME_FORMAT)}
          </p>
          <Actions>
            <a href='/' title='Notes'>
              Notes
            </a>
            <a href='/' title='Reschedule'>
              Reschedule
            </a>
            <a href='/' title='Cancel'>
              Cancel
            </a>
          </Actions>
        </>
      );
    }
    default:
  }
};
