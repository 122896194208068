import styled from 'styled-components';

export const Errors = styled.p`
  color: ${({ theme }) => theme.error};
  font-size: 1rem;
  font-style: italic;
  margin: 8px 0 16px;
  text-align: left;
  width: 100%;
`;
