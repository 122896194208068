import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/patientTags';

const PatientTagsContext = createContext<{
  patientTagsState: State;
  patientTagsDispatch: Dispatch<Action>;
}>({
  patientTagsState: initialState,
  patientTagsDispatch: () => null,
});

const PatientTagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [patientTagsState, patientTagsDispatch] = useReducer(reducer, initialState);
  return (
    <PatientTagsContext.Provider value={{ patientTagsState, patientTagsDispatch }}>
      {children}
    </PatientTagsContext.Provider>
  );
};

export { PatientTagsContext, PatientTagsProvider };
