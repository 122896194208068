import { AxiosError, CancelToken } from 'axios';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  GetMemberPVQResponseResponse,
  GetMemberStatusResponse,
  MemberStatus,
  PVQResponseSection,
  RequestPvqCompletionResponse,
  UpdateMemberStatusResponse,
} from './AssessmentsApi.types';

export class AssessmentsClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.onboarding);
  }

  public async fetchPvqResponse(
    memberId: number,
    cancelToken: CancelToken
  ): Promise<PVQResponseSection[] | null> {
    try {
      const { sections } = (
        await this.axiosClient.get<GetMemberPVQResponseResponse>(
          `onboarding/v2.0/members/${memberId}/pvq-response`,
          { cancelToken }
        )
      ).data;

      return sections;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
        return null;
      }
      throw error;
    }
  }

  public async sendPvqRequestOverEmail(memberId: number): Promise<boolean> {
    const { success } = (
      await this.axiosClient.put<RequestPvqCompletionResponse>(
        `/v3/members/${memberId}/reminders/pvq`
      )
    ).data;

    return success;
  }

  public async getMemberOnboardingStatus(memberId: number): Promise<MemberStatus> {
    const memberStatus = (
      await this.axiosClient.get<GetMemberStatusResponse>(
        `/onboarding/v2.0/members/${memberId}/status/lite`
      )
    ).data;

    return memberStatus;
  }

  public async updateMemberOnboardingStatus(
    memberId: number,
    params: {
      preVisitQuestionnaireAnswered: boolean;
      preVisitQuestionnaireExternalFormUrl?: string;
    }
  ): Promise<MemberStatus> {
    const { data } = (
      await this.axiosClient.put<UpdateMemberStatusResponse>(
        `/onboarding/v2.0/providers/members/${memberId}/status`,
        params
      )
    ).data;

    return data;
  }
}
