import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';
import Theme from 'styles/theme';
import { AutocompleteWrapper } from './style';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      color: Theme.teal,
      top: '-3px',
      position: 'relative',
    },
  },
  inputRoot: {
    font: "17px/19px 'Proxima Nova Rg'",

    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: '3px 4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${Theme.white} !important`,
    },
    '& .MuiAutocomplete-tag': {
      color: Theme.teal,
      font: "17px/19px 'Proxima Nova Rg'",
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.white,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.white,
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
    '& .MuiIconButton-root': {
      color: Theme.teal,
    },
  },
  input: {
    '&::placeholder': {
      color: Theme.teal,
      opacity: 1,
    },
    color: Theme.teal,
  },
  popper: {
    top: '-7px !important;',
  },
}));

const AutocompleteFilterElement = ({
  id,
  name,
  placeholder,
  helperText,
  getData,
  onChange,
  renderOption,
  register,
  disabled,
  multiple,
  width,
  height,
  value,
  customStyle,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const classes = useStyles();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const data = getData();
    if (active) {
      setOptions(data.map((key) => ({ ...key, name: `${key.name}` })));
    }

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AutocompleteWrapper style={customStyle}>
      <Autocomplete
        multiple={multiple}
        id={id}
        style={{ width, height }}
        autoHighlight
        classes={classes}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={onChange}
        getOptionSelected={(option, optionValue) => option.name === optionValue.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        noOptionsText='No Data'
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            placeholder={placeholder || ''}
            name={name}
            inputRef={register}
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={renderOption}
      />
    </AutocompleteWrapper>
  );
};

AutocompleteFilterElement.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  getData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  register: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.shape({ name: PropTypes.string }),
  customStyle: PropTypes.shape(),
};

AutocompleteFilterElement.defaultProps = {
  errors: null,
  disabled: false,
  multiple: false,
  width: '280px',
  height: '44px',
};

export default AutocompleteFilterElement;
