import { TimeFilter } from './types';

export const PANEL_STATUS_FILTER_DEFAULT_ONLY_ACTIVE = {
  items: [{ field: 'patient_status', operator: 'in', value: ['Active'] }],
};

export const PANEL_TIME_FILTER_OPTIONS: TimeFilter[] = [
  {
    fromDay: 1,
    toDay: 14,
    displayText: 'Weeks 1-2',
  },

  {
    fromDay: 15,
    toDay: 28,
    displayText: 'Weeks 3-4',
  },
  {
    fromDay: 29,
    toDay: 60,
    displayText: 'Month 2',
  },
  {
    fromDay: 61,
    toDay: 90,
    displayText: 'Month 3',
  },
  {
    displayText: 'Month 4-6',
    fromDay: 91,
    toDay: 180,
  },
  {
    displayText: 'Month 7-12',
    fromDay: 181,
    toDay: 360,
  },
  {
    displayText: 'Year 2',
    fromDay: 361,
    toDay: 720,
  },
  {
    displayText: 'Year 3+',
    fromDay: 721,
    toDay: 10000,
  },
];
