import React from 'react';
import { IconContent } from '../style';

const Suitcase = () => (
  <IconContent fill='currentColor' viewBox='0 0 15 15'>
    <path d='M13.5 3h-3V1.5C10.5.668 9.832 0 9 0H6c-.832 0-1.5.668-1.5 1.5V3h-3C.668 3 .008 3.668.008 4.5L0 12.75c0 .832.668 1.5 1.5 1.5h12c.832 0 1.5-.668 1.5-1.5V4.5c0-.832-.668-1.5-1.5-1.5zM9 3H6V1.5h3V3z' />
  </IconContent>
);

export default Suitcase;
