/* eslint-disable react/destructuring-assignment */
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { makeStyles } from '@material-ui/core/styles';
import { Errors } from '../shared-style';
import { Field, FloatContainer, Label } from './style';

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // thousandSeparator
      isNumericString
      suffix=''
    />
  );
};

const FloatElement = ({
  value,
  errors,
  label,
  name,
  setValue,
  onChange,
  min,
  step,
  startAdornment,
  endAdornment,
  width,
  readOnly,
}) => {
  const classes = useStyles();

  return (
    <Field error={!!errors} fullWidth width={width}>
      <Label htmlFor={`${name}-input`}>{label}</Label>
      <FloatContainer>
        <TextField
          value={value}
          onChange={setValue ? (e) => setValue(name, e.target.value) : onChange}
          name={name}
          id={name}
          min={min}
          step={step}
          width={width}
          InputProps={{
            inputComponent: NumberFormatCustom,
            classes,
            endAdornment: endAdornment ? (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ) : null,
            startAdornment: startAdornment ? (
              <InputAdornment position='end'>{startAdornment}</InputAdornment>
            ) : null,
            readOnly: readOnly || false,
          }}
        />
      </FloatContainer>

      {errors && <Errors id={`${name}-error`}>{errors.message ? errors.message : errors}</Errors>}
    </Field>
  );
};

FloatElement.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  endAdornment: PropTypes.string,
  startAdornment: PropTypes.string,
  readOnly: PropTypes.bool,
};

FloatElement.defaultProps = {
  errors: null,
  value: '',
};

export default FloatElement;
