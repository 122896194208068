import React, { FC } from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Theme from 'styles/theme';

interface BadgeProps extends React.PropsWithChildren {
  visible: boolean;
  top?: number;
  right?: number;
  size?: number;
}

const BadgeElement: FC<BadgeProps> = ({ visible, top, right, size, children }) => {
  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiBadge-colorSecondary': {
        backgroundColor: Theme.salmon,
      },
      '& .MuiBadge-anchorOriginTopRightRectangle': {
        top: top || 10,
        right: right || 10,
      },
      '& .MuiBadge-dot': {
        height: size || 8,
        minWidth: size || 8,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Badge className={classes.root} variant='dot' color='secondary' invisible={!visible}>
      {children}
    </Badge>
  );
};

export default BadgeElement;
