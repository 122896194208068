import { CSSProperties } from 'react';
import Theme from 'styles/theme';

export const ChatBoxInputStyle = {
  control: {
    backgroundColor: Theme.white,
    display: 'block',
  },

  highlighter: {
    border: 'none',

    span: {
      background: Theme.warning,
      zIndex: 1,
      position: 'relative',
    },
  },

  input: {
    border: 'none',
    color: Theme.black,
    font: `600 15px ${({ theme }: { theme: typeof Theme }) => theme.primaryFont}`,
    height: '100%',
    margin: 0,
    padding: 0,
    width: '100%',
  },

  suggestions: {
    list: {
      backgroundColor: Theme.white,
      border: `1px solid ${Theme.grayLight}`,
    },

    item: {
      padding: '8px 16px',
      borderBottom: `1px solid ${Theme.grayLight}`,
      font: `14px ${({ theme }: { theme: typeof Theme }) => theme.primaryFont}`,

      '&focused': {
        backgroundColor: Theme.turquoise,
      },
    },
  },
};

export const ChatBoxActionStyle: CSSProperties = {
  background: Theme.grayLight,
  color: Theme.salmonDark,
  pointerEvents: 'none',
  position: 'relative',
  textShadow: '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
  zIndex: 1,
};

export const ChatBoxMentionStyle: CSSProperties = {
  color: Theme.teal,
  pointerEvents: 'none',
  position: 'relative',
  textShadow: '1px 1px 1px yellow, 1px -1px 1px yellow, -1px 1px 1px yellow, -1px -1px 1px yellow',
  zIndex: 1,
};
