import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -25px;

  h2 {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 24px/28px ${({ theme }) => theme.primaryFont};
  }
  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;

  & button {
    width: 20%;
    height: 50px;
    margin-left: 10px;
  }

  // & button:first-of-type {
  //   margin: 8px 0;
  // }

  textarea {
    margin-right: 36px;
  }
`;

export const TaskRows = styled.header``;

export const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 1080px;
  margin-bottom: 10px;

  & > div:not(:first-child) {
    margin-left: 20px;
  }

  textarea {
    margin-right: 36px;
  }
`;

export const AddNewTaskRow = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.teal};
  margin-bottom: 40px;

  & > button {
    display: contents;
    i {
      margin: 0;
      height: 25px;
      width: 25px;
      margin-left: 8px;

      svg {
        height: calc(100% - 12px);
        width: calc(100% - 12px);
      }
    }
  }
`;

Header.defaultProps = {
  'data-id': 'Header',
};

Actions.defaultProps = {
  'data-id': 'Actions',
};

Display.defaultProps = {
  'data-id': 'Display',
};

AddNewTaskRow.defaultProps = {
  'data-id': 'AddNewTaskRow',
};
