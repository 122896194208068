import React, { FunctionComponent } from 'react';

const columnStyle: React.CSSProperties = {
  border: '1px solid grey',
  borderRadius: 10,
  margin: 10,
  padding: 10,
  flex: 1,
};
const ContentBrowser: FunctionComponent = () => (
  <div style={{ display: 'flex', minHeight: '80vh' }}>
    <div style={columnStyle}>Categories</div>
    <div style={columnStyle}>Cards</div>
    <div style={columnStyle}>Users</div>
  </div>
);

export default ContentBrowser;
