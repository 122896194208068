import React from 'react';
import Theme from 'styles/theme';
import { IconContent } from '../style';

const UserBubble = () => (
  <IconContent fill='currentColor' viewBox='0 0 35 35'>
    <g transform='translate(-1136.000000, -22.000000)'>
      <g transform='translate(1136.000000, 22.000000)'>
        <circle fill={Theme.teal} cx='17' cy='17' r='15' />
        <path d='M17.5,0 C7.84,0 0,7.84 0,17.5 C0,27.16 7.84,35 17.5,35 C27.16,35 35,27.16 35,17.5 C35,7.84 27.16,0 17.5,0 L17.5,0 Z M17.5,5 C20.5433333,5 23,7.45666667 23,10.5 C23,13.5433333 20.5433333,16 17.5,16 C14.4566667,16 12,13.5433333 12,10.5 C12,7.45666667 14.4566667,5 17.5,5 L17.5,5 Z M17.5,30 C13.125,30 9.2575,27.7650794 7,24.3777778 C7.0525,20.9031746 14,19 17.5,19 C20.9825,19 27.9475,20.9031746 28,24.3777778 C25.7425,27.7650794 21.875,30 17.5,30 L17.5,30 Z' />
      </g>
    </g>
  </IconContent>
);

export default UserBubble;
