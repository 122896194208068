import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div<{
  children: (JSX.Element | boolean)[];
  numberOfReviewLists: number;
}>`
  width: 88vw;
  margin: ${(props) => (props.numberOfReviewLists > 2 ? 'auto' : 0)};
`;

export const ContentWrapper = styled.div<{ children: (JSX.Element | boolean)[] }>`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.inputBackground};
  border-left: 7px solid ${({ theme }) => theme.turquoise};
  border-radius: 4px;
  box-sizing: border-box;
  height: auto;
  width: auto;
  position: relative;
`;

export const Content = styled.div`
  align-items; center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 15px 0 30px;
  text-align: left;

  h4 { 
    color: ${({ theme }) => theme.gray};
    font: 600 16px/17px ${({ theme }) => theme.primaryFont};
    margin: 24px 0 5px 0;
  }
  p {
    font: 18px/22px ${({ theme }) => theme.primaryFont};
    color: ${({ theme }) => theme.black};
    margin: 5px 0;
  }
  a {
    font: 17px/19px ${({ theme }) => theme.primaryFont};
    margin: 5px 0 24px;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.grayDarker};
  font: 21px/24px ${({ theme }) => theme.primaryFont};
  margin: 24px 14px 24px 0px;
`;

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  right: 8px;
  top: 4px;
`;

export const InfoIcon = styled.div<{
  infoIconFilled: string;
  infoIconOutline: string;
  onClick: () => void;
}>`
  margin-right: 5px;
  background-image: url(${(props) => props.infoIconOutline});
  background-size: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    background-image: url(${(props) => props.infoIconFilled});
  }
`;

export const TealBadge = styled.div`
  font: 500 11px/12px ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.tealLight};
  padding: 4px 10px;
  border-radius: 12px;
`;

export const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.salmon};
  font: 600 19px/22px ${({ theme }) => theme.primaryFont};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.salmonDark};
  }
`;

export const NoDataMessage = styled.div`
  color: ${({ theme }) => theme.grayDark};
  display: flex;
  font: 19px/21px ${({ theme }) => theme.primaryFont};
  min-width: 300px;
  padding: 2% 40% 2% 40%;

  @media (max-width: ${({ theme }) => theme.md}) {
    padding: 2% 20% 2% 20%;
  }
`;

export const ReviewListTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GearWrapper = styled.div`
  height: 100%;
  margin-left: 6px;
  position: relative;
  top: 2px;
  cursor: pointer;
`;
