import Float from 'components/elements/float';
import Input from 'components/elements/input';
import Select from 'components/elements/select';
import PropTypes from 'prop-types';
import { Container, TaskIndex } from './style';

const TaskElement = ({
  description,
  role_id,
  start_date_in_days,
  due_date_in_days,
  task_id,
  handleOnChange,
  roles,
}) => {
  let startAdornment = '';
  let endAdornment = '';
  let error = null;
  if (start_date_in_days !== 'today') {
    startAdornment = '+';
    endAdornment = 'Days';
    if (parseInt(due_date_in_days) < parseInt(start_date_in_days))
      error = 'Start date must be earlier than Due date';
  }

  return (
    <Container key={`task-row-${task_id}`}>
      <TaskIndex>{task_id + 1}</TaskIndex>
      <Input
        // errors={errors.description}
        label='Task Description'
        name={`description-${task_id}`}
        value={description}
        type='text'
        width={300}
        onChange={(e) => {
          handleOnChange(task_id, 'description', e.target.value);
        }}
      />
      <Select
        // errors={errors.assigned_to}
        label='Assign Provider'
        name={`role_id-${task_id}`}
        height={55}
        defaultValue={role_id}
        options={roles.map((role) => ({
          label: role.name,
          value: role.id,
        }))}
        onChange={(e) => {
          handleOnChange(task_id, 'role_id', e.target.value);
        }}
      />
      <Float
        // errors={errors.start_date_in_days}
        label='Start Date'
        name={`start_date_in_days-${task_id}`}
        value={
          start_date_in_days !== 'undefined' && start_date_in_days !== null
            ? start_date_in_days.toString()
            : start_date_in_days
        }
        onChange={(e) => {
          const reg_num = /^[0-9\b]+$/;
          if (e.target.value === '' || reg_num.test(e.target.value)) {
            handleOnChange(task_id, 'start_date_in_days', e.target.value);
          }
        }}
        step={1}
        min={0}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        width={130}
      />
      <Float
        // errors={errors.due_date_in_days}
        errors={error}
        label='Due Date'
        name={`due_date_in_days-${task_id}`}
        value={
          due_date_in_days !== 'undefined' && due_date_in_days !== null
            ? due_date_in_days.toString()
            : due_date_in_days
        }
        onChange={(e) => {
          const reg_num = /^[0-9\b]+$/;
          if (e.target.value === '' || reg_num.test(e.target.value)) {
            handleOnChange(task_id, 'due_date_in_days', e.target.value);
          }
        }}
        step={1}
        min={0}
        startAdornment='+'
        endAdornment='Days'
        width={130}
      />
    </Container>
  );
};

TaskElement.propTypes = {
  // errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  description: PropTypes.string.isRequired,
  role_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  start_date_in_days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  due_date_in_days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  task_id: PropTypes.number.isRequired,
  handleOnChange: PropTypes.func.isRequired,
};

export default TaskElement;
