import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/tag';

const TagContext = createContext<{ tagState: State; tagDispatch: Dispatch<Action> }>({
  tagState: initialState,
  tagDispatch: () => null,
});

const TagProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tagState, tagDispatch] = useReducer(reducer, initialState);
  return <TagContext.Provider value={{ tagState, tagDispatch }}>{children}</TagContext.Provider>;
};

export { TagContext, TagProvider };
