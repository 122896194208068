import { IconButton } from 'components/elements';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Next, Prev, Wrapper } from './style';

const Carousel = ({ data }) => {
  const NextArrow = (props) => {
    const { onClick, className } = props;
    return (
      <Next disabled={className.includes('slick-disabled')}>
        <IconButton disabled={className.includes('slick-disabled')} onClick={onClick}>
          <Icon icon='arrow' color='gray' size={16} rotate={-90} />
        </IconButton>
      </Next>
    );
  };

  const PrevArrow = (props) => {
    const { onClick, className } = props;
    return (
      <Prev disabled={className.includes('slick-disabled')}>
        <IconButton disabled={className.includes('slick-disabled')} onClick={onClick}>
          <Icon icon='arrow' color='gray' size={16} rotate={90} />
        </IconButton>
      </Prev>
    );
  };

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 4,
    speed: 500,
    swipe: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper numberOfReviewLists={data.length}>
      <Slider {...settings}>{data.map((d) => d)}</Slider>
    </Wrapper>
  );
};

Carousel.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Carousel.defaultProps = {
  onClick: () => {},
  className: '',
};

export default Carousel;
