import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TabBox, Tabs } from 'components/general-styles';
import { CHATS, REVIEW_LIST_TEAM_CHAT_AS_DEFAULT } from 'config';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import Pusher from 'services/pusher-service';
import { PatientContext } from 'state/contexts/patient';
import { UserContext } from 'state/contexts/user';
import Theme from 'styles/theme';
import { DIRECT_ROOM, TEAM_ROOM } from 'utils/constants';
import { debug, playSoundMessageReceived, setFavicon } from 'utils/helpers';
import Tab from '../../../elements/tab';
import Room from './room/Room';
import { ChatSection, StickyBox } from './style';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiBadge-colorSecondary': {
      backgroundColor: Theme.salmon,
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: 10,
      right: 10,
    },
  },
}));

const Chat = ({ patientId, reviewList }) => {
  const roomComponentRef = useRef();
  const { userState } = useContext(UserContext);
  const { patientState } = useContext(PatientContext);
  const patient = patientState.data.find((p) => p.id === patientId);
  const [selected, setSelected] = useState(
    reviewList && REVIEW_LIST_TEAM_CHAT_AS_DEFAULT.includes(reviewList) ? TEAM_ROOM : DIRECT_ROOM
  );
  const [newDirectMsg, setNewDirectMsg] = useState(false);
  const [newTeamMsg, setNewTeamMsg] = useState(false);

  const classes = useStyles();

  setFavicon(newDirectMsg || newTeamMsg);

  const onClickTab = (tab) => {
    if (tab === DIRECT_ROOM) {
      setNewDirectMsg(false);
    } else {
      setNewTeamMsg(false);
    }
    setSelected(tab);
  };

  useEffect(() => {
    Pusher.connect(DIRECT_ROOM, userState.auth_token);
    Pusher.subscribe(DIRECT_ROOM, `${CHATS.pusher[DIRECT_ROOM].channel}-${userState.uuid}`);
    Pusher.bind(DIRECT_ROOM, CHATS.pusher[DIRECT_ROOM].event, ({ sender_uuid, patient_uuid }) => {
      // direct message received
      if (sender_uuid === patient.uuid) {
        playSoundMessageReceived();
        setNewDirectMsg(true);
        debug(`TAB-${DIRECT_ROOM}-received------${sender_uuid}-${patient_uuid}`);
        roomComponentRef.current.handleMessageReceived({
          sender_uuid,
          patient_uuid,
          room_name: DIRECT_ROOM,
        });
      }
    });
    Pusher.connect(TEAM_ROOM, userState.auth_token);
    Pusher.subscribe(TEAM_ROOM, `${CHATS.pusher[TEAM_ROOM].channel}-${userState.uuid}`);
    Pusher.bind(TEAM_ROOM, CHATS.pusher[TEAM_ROOM].event, ({ sender_uuid, patient_uuid }) => {
      // team message received
      if (sender_uuid !== userState.uuid && patient_uuid === patient.uuid) {
        playSoundMessageReceived();
        setNewTeamMsg(true);
        debug(`TAB-${TEAM_ROOM}-received------${sender_uuid}-${patient_uuid}`);
        roomComponentRef.current.handleMessageReceived({
          sender_uuid,
          patient_uuid,
          room_name: TEAM_ROOM,
        });
      }
    });

    return () => {
      debug('Disconnect direct');
      Pusher.disconnect(DIRECT_ROOM);

      debug('Disconnect team');
      Pusher.disconnect(TEAM_ROOM);
    };
  }, []);

  return (
    <ChatSection>
      <StickyBox>
        <Box>
          <Tabs>
            {CHATS.tabs.map((tab) => (
              <Badge
                className={classes.root}
                key={`badge-${patientId}-${tab.name}`}
                color='secondary'
                variant='dot'
                invisible={
                  !(
                    (newDirectMsg && tab.name === DIRECT_ROOM) ||
                    (newTeamMsg && tab.name === TEAM_ROOM)
                  )
                }>
                <Tab
                  onClick={onClickTab}
                  selected={selected === tab.name}
                  tab={tab}
                  key={`tab-${patientId}-${tab.name}`}
                />
              </Badge>
            ))}
          </Tabs>
          <TabBox>
            {CHATS.rooms.map(
              (room) =>
                selected === room.name && (
                  <Room
                    ref={roomComponentRef}
                    patientId={patientId}
                    room={room}
                    key={`room-${patientId}-${room.name}`}
                    setNewDirectMsg={setNewDirectMsg}
                    setNewTeamMsg={setNewTeamMsg}
                  />
                )
            )}
          </TabBox>
        </Box>
      </StickyBox>
    </ChatSection>
  );
};

Chat.propTypes = {
  patientId: PropTypes.number.isRequired,
  reviewList: PropTypes.string,
};

Chat.defaultProps = {
  reviewList: null,
};

export default Chat;
