import moment from 'moment-timezone';

/**
 * Provider long form names for timezones
 * source: https://momentjs.com/timezone/docs/#/using-timezones/formatting/
 */

type TimezoneAbbrs = 'EST' | 'EDT' | 'CST' | 'CDT' | 'MST' | 'MDT' | 'PST' | 'PDT';

const abbrs: { [key in TimezoneAbbrs]: string } = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
};

moment.fn.zoneName = function zoneName() {
  const abbr: TimezoneAbbrs = this.zoneAbbr();
  return abbrs[abbr] || this.tz();
};
