import { CancelToken } from 'axios';
import { NODE_API } from 'config';
import { SharedEnaraNodeClient } from 'node-api/common/SharedEnaraNodeClient';
import {
  GetExercisesResponse,
  GetProfileNotificationsResponse,
  MemberPreference,
  PreferenceUpdateResponse,
  Weight,
} from './member.types';

export default class MembersClient extends SharedEnaraNodeClient {
  constructor() {
    super(NODE_API.members);
  }

  async exercises(
    { memberId, startDate, endDate }: { memberId: number; startDate: string; endDate: string },

    cancelToken?: CancelToken
  ): Promise<GetExercisesResponse> {
    const { data }: { data: GetExercisesResponse } = await this.axiosClient.get(
      `members/v1/members/${memberId}/exercises`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
        cancelToken,
      }
    );
    return data;
  }

  async profileNotifications(
    { memberId }: { memberId: number },
    cancelToken?: CancelToken
  ): Promise<GetProfileNotificationsResponse> {
    const { data }: { data: GetProfileNotificationsResponse } = await this.axiosClient.get(
      `members/v1/providers/members/${memberId}/profile-notifications`,
      {
        cancelToken,
      }
    );
    return data;
  }

  reviewProfileSubTab(
    {
      providerId,
      memberId,
      tabName,
      source,
    }: { providerId: number; memberId: number; tabName: string; source: string },

    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post(
      `members/v1/providers/${providerId}/health-data-metrics/${tabName}`,
      {
        cancelToken,
        memberId,
        source,
      }
    );
  }

  reviewExercise(
    {
      memberId,
      source,
      memberActivityIds,
    }: { memberId: number; source: string; memberActivityIds: number[] },

    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post(`members/v1/providers/exercise-review`, {
      cancelToken,
      memberId,
      source,
      memberActivityIds,
    });
  }

  getWeights(
    memberId: number,
    { fromDate, toDate }: { fromDate: string; toDate: string },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.get<{ records: Weight[] }>(
      `/v1/members/${memberId}/health-records/types/scale-weight`,
      {
        cancelToken,
        params: { fromDate, toDate },
      }
    );
  }

  addWeightInAllPlatforms(
    memberId: number,
    { date, source, weightInPounds }: { date: string; source: string; weightInPounds: number },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.post<{ record: { processedRecords: { id: number }[] } }>(
      `/v1/members/${memberId}/health-records/types/scale-weight`,
      {
        date,
        source,
        value: weightInPounds,
      },
      { cancelToken }
    );
  }

  deleteWeightInAllPlatforms(
    memberId: number,
    {
      coreScaleId,
      healthRecordId,
      memberUuid,
    }: { coreScaleId?: number; healthRecordId?: number; memberUuid?: string },
    cancelToken?: CancelToken
  ) {
    return this.axiosClient.delete<{ record: { id: number } }>(
      `/v1/members/${memberId}/health-records`,
      {
        cancelToken,
        data: {
          legacyScaleId: coreScaleId,
          memberUuid,
          recordId: healthRecordId,
        },
      }
    );
  }

  async getMemberPreferences(
    memberId: number,
    cancelToken?: CancelToken
  ): Promise<MemberPreference[] | null> {
    try {
      const response = await this.axiosClient.get<{ success: boolean; data: MemberPreference[] }>(
        `/members/v1/members/${memberId}/preferences`,
        { cancelToken }
      );

      const { data } = response.data;

      return data;
    } catch (e) {
      console.error(`getMemberPreferences(): ${e}`);
      return null;
    }
  }

  async updateMemberPreference(
    memberId: number,
    preferenceId: number,
    cancelToken?: CancelToken
  ): Promise<{
    preferenceId: number;
    state: boolean;
  } | null> {
    try {
      const response = await this.axiosClient.post<PreferenceUpdateResponse>(
        `/members/v1/members/${memberId}/preferences/${preferenceId}`,
        { cancelToken }
      );

      const { data } = response.data;

      return { preferenceId: data.preferenceId, state: data.value };
    } catch (e) {
      console.error(`updateMemberPreference(): ${e}`);
      return null;
    }
  }
}
