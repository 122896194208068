import React from 'react';
import PropTypes from 'prop-types';

import { Field, Input, Label } from './style';
import { Errors } from '../shared-style';

const InputElement = React.memo(
  ({ errors, label, name, register, type, value, width, onChange }) => (
    <Field error={!!errors} fullWidth width={width}>
      <Label htmlFor={`${name}-input`}>{label}</Label>
      <Input
        id={`${name}-input`}
        name={name}
        type={type}
        ref={register}
        value={value}
        onChange={onChange}
      />

      {errors && <Errors id={`${name}-error`}>{errors.message ? errors.message : errors}</Errors>}
    </Field>
  )
);

InputElement.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

InputElement.defaultProps = {
  errors: null,
};

export default InputElement;
