import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import api from 'api';
import { Button, IconButton } from 'components/elements';
import Icon from 'components/icon';
import Toast from 'components/toast';
import { ACTIVE_ADMIN, ENVIRONMENT, FEEDBACK } from 'config';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AuthService from 'services/auth-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import Theme from 'styles/theme';
import { debug } from 'utils/helpers';
import {
  BadgeContent,
  ButtonLoginAsUser,
  Container,
  Input,
  Li,
  LoginAsUser,
  SubTitle,
  SubTitleContainer,
  SwitchRole,
  SwitchUserRows,
  Title,
  Ul,
  UserName,
} from './style';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBadge-colorSecondary': {
      backgroundColor: Theme.salmon,
    },
  },
}));

// user is the current user who is logged in
const UserDropdown = ({ toggle, history, user }) => {
  const classes = useStyles();
  const [targetUserUUID, setTargetUserUUID] = useState('');
  const [showMenu, toggleMenu] = useState(false);

  // Used for testing
  const changeUser = async () => {
    try {
      const { data } = await api().post(`users/login_as?auth_token=AUTH_TOKEN`, {
        auth_token: 'AUTH_TOKEN',
        target_user_uuid: targetUserUUID,
      });
      if (!data.success === 'true') {
        Toast.show('error', 'Switch user failed.');
      } else setTargetUserUUID('');
    } catch (err) {
      debug(err);
      Toast.show('error', 'Switch user failed.');
    }
  };

  const switchUser = async (e) => {
    try {
      const { uuid } = e.target.dataset;
      const name = e.target.dataset.fullname;

      const { data } = await api().post(`users/login_as?auth_token=AUTH_TOKEN`, {
        auth_token: 'AUTH_TOKEN',
        target_user_uuid: uuid,
      });
      if (!data.success === 'true') {
        Toast.show('error', 'Switch user failed.');
      } else {
        AuthService.removeAuth();
        AuthService.setAuth({ auth_token: data.user.auth_token, user_id: data.user.id });

        const original_user_uuid =
          (localStorage.getItem('Origu') && localStorage.getItem('Origu').split('|')[0]) || null;
        if (original_user_uuid === data.user.uuid) {
          // we switched back to the original user
          localStorage.removeItem('Origu');
        } else if (user.isOriginalUser) {
          // the first time we are switching
          localStorage.setItem('Origu', `${user.uuid}|${user.first_name} ${user.last_name}`);
        }
        Toast.show('success', `Switching to ${name}`);
        toggle(false);

        // go to the home page and reload
        setTimeout(() => {
          history.push('/');
          return history.go();
        }, 1800);
      }
    } catch (err) {
      debug(err);
      Toast.show('error', 'Switch user failed.');
    }
  };

  const logout = () => {
    AuthService.removeAuth();
    localStorage.removeItem('Origu');

    MixpanelWrapperInstance.track(EventNames.SessionLogOut);

    history.push('/login');
  };

  const activeadmin = () => {
    window.open(ACTIVE_ADMIN.url, '_blank');
  };

  const feedback = () => {
    window.open(FEEDBACK.url, '_blank');
  };

  return (
    <Container>
      <Title>Profile Options</Title>
      {!ENVIRONMENT.isProd && (
        <LoginAsUser>
          <div>
            <Input
              name='switch_user'
              type='text'
              value={targetUserUUID}
              onChange={(e) => {
                return setTargetUserUUID(e.target.value);
              }}
            />
          </div>
          <ButtonLoginAsUser>
            <Button onClick={changeUser} disabled={targetUserUUID === ''}>
              Submit
            </Button>
          </ButtonLoginAsUser>
        </LoginAsUser>
      )}

      {((!user.isOriginalUser && user.original_user?.available_logins?.length > 0) ||
        user.available_logins?.length > 0) && (
        <SwitchRole>
          <SubTitleContainer>
            <SubTitle>Switch Roles</SubTitle>
            <div>
              <IconButton onClick={() => toggleMenu((prev) => !prev)}>
                <Icon icon='arrow' color='gray' size={16} />
              </IconButton>
            </div>
          </SubTitleContainer>
          {showMenu && (
            <Ul>
              <Li
                key={`user-${((!user.isOriginalUser && user.original_user) || user).uuid}`}
                onClick={!user.isOriginalUser ? switchUser : null}
                clickable={!user.isOriginalUser}>
                <SwitchUserRows className={classes.root}>
                  <BadgeContent>
                    <Badge
                      id='badge'
                      color='secondary'
                      variant='dot'
                      invisible={!user.isOriginalUser}
                    />
                  </BadgeContent>
                  <UserName
                    data-uuid={((!user.isOriginalUser && user.original_user) || user).uuid}
                    data-fullname={`${
                      ((!user.isOriginalUser && user.original_user) || user).first_name
                    } ${((!user.isOriginalUser && user.original_user) || user).last_name}`}>
                    {`${((!user.isOriginalUser && user.original_user) || user).first_name} ${
                      ((!user.isOriginalUser && user.original_user) || user).last_name
                    }`}
                  </UserName>
                </SwitchUserRows>
              </Li>
              {(
                (!user.isOriginalUser && user.original_user.available_logins) ||
                user.available_logins
              ).map((availableUser) => (
                <Li
                  key={`user-${availableUser.uuid}`}
                  onClick={!(availableUser.uuid === user.uuid) ? switchUser : null}
                  clickable={!(availableUser.uuid === user.uuid)}>
                  <SwitchUserRows className={classes.root}>
                    <BadgeContent>
                      <Badge
                        id='badge'
                        color='secondary'
                        variant='dot'
                        invisible={!(availableUser.uuid === user.uuid)}
                      />
                    </BadgeContent>
                    <UserName
                      data-uuid={availableUser.uuid}
                      data-fullname={`${availableUser.first_name} ${availableUser.last_name}`}>
                      {`${availableUser.first_name} ${availableUser.last_name}`}
                    </UserName>
                  </SwitchUserRows>
                </Li>
              ))}
            </Ul>
          )}
        </SwitchRole>
      )}
      <Button variant='link' onClick={activeadmin}>
        Active Admin
      </Button>
      <Button variant='link' onClick={feedback}>
        Log a bug or improvement request
      </Button>
      <Button variant='link' onClick={logout}>
        Logout
      </Button>
      <span style={{ fontSize: 9, opacity: 0.5 }}>{process.env.REACT_APP_VERSION}</span>
    </Container>
  );
};

UserDropdown.propTypes = {
  toggle: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
};

export default UserDropdown;
