/**
 * TODO: This file should be deprecated in order to use the new 'timezone' field
 */

export const timezonesByClinicId = {
  1: 'America/Los_Angeles', // Enara Health - San Mateo
  2: 'America/Chicago', // WellHealth - Frisco
  3: 'America/Chicago', // Dr. Yazen Joudeh Clinic - Orland Park
  5: 'America/Chicago', // WellHealth Las Colinas
  6: 'America/Los_Angeles', // Kafri Chula Vista
  8: 'America/Los_Angeles', // Kafri El Cajon
  9: 'America/Chicago', // Mankato Clinic - Mankato
  10: 'America/Chicago', // EHG Texas
  11: 'America/Los_Angeles', // Kafri Chula Vista
  12: 'America/Los_Angeles', // Kafri El Cajon
  13: 'America/Phoenix', // Phoenix Heart
  14: 'America/Los_Angeles', // Test Clinic Connected to Staging Instance 2
  15: 'America/Los_Angeles', // Happy Doc
  16: 'America/Los_Angeles', // Arora Clinic
  17: 'America/Los_Angeles', // APEX
};

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const UTC_TIMEZONE = 'UTC';

export const getTimezoneByClinicId = (clinicId: number) => {
  return clinicId && clinicId in timezonesByClinicId
    ? timezonesByClinicId[clinicId as keyof typeof timezonesByClinicId]
    : UTC_TIMEZONE;
};
