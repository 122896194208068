import { useEffect, useState } from 'react';

/**
 * Hook to check if the window has focus.
 * Returns an useState with the current focus status, and can be used in a useEffect to check if the window focus has changed.
 * @returns {boolean} focus - The current focus status (from useState)
 */
export const useWindowHasFocus = () => {
  // get the initial state
  const [focus, setFocus] = useState(document.hasFocus());

  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);

    const onVisibilityChange = () => setFocus(!document.hidden);

    // assign the listeners
    window?.addEventListener('focus', onFocus);
    window?.addEventListener('blur', onBlur);
    // Newer browsers support visibilitychange event
    document?.addEventListener('visibilitychange', onVisibilityChange);

    // remove the listeners
    return () => {
      window?.removeEventListener('focus', onFocus);
      window?.removeEventListener('blur', onBlur);
      document?.addEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return focus;
};
