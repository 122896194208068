import React from 'react';
import { IconContent } from '../style';

const WeightScale = () => (
  <IconContent fill='currentColor' viewBox='0 0 30 30'>
    <g>
      <path d='M27.363 0H2.637A2.64 2.64 0 0 0 0 2.637v24.726A2.64 2.64 0 0 0 2.637 30h24.726A2.64 2.64 0 0 0 30 27.363V2.637A2.64 2.64 0 0 0 27.363 0zm.88 27.363a.88.88 0 0 1-.88.88H2.637a.88.88 0 0 1-.88-.88V2.637a.88.88 0 0 1 .88-.88h24.726a.88.88 0 0 1 .88.88v24.726z' />
      <path d='M25.648 6.338A16.936 16.936 0 0 0 15.5 3 16.936 16.936 0 0 0 5.352 6.338a.861.861 0 0 0-.176 1.217l4.645 6.098a.883.883 0 0 0 1.232.173 7.422 7.422 0 0 1 4.447-1.462c1.617 0 3.155.505 4.447 1.462.389.288.94.21 1.232-.173l4.644-6.099a.86.86 0 0 0-.175-1.216zm-5.373 5.61a9.152 9.152 0 0 0-3.895-1.28V8.021a.874.874 0 0 0-.88-.868.874.874 0 0 0-.88.868v2.647a9.152 9.152 0 0 0-3.895 1.28L7.128 7.226A15.171 15.171 0 0 1 15.5 4.737c3.01 0 5.887.858 8.373 2.488l-3.598 4.724z' />
    </g>
  </IconContent>
);

export default WeightScale;
