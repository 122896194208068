import './program-step-icon.css';

const ProgramStepIcon = ({ active = false }: { active?: boolean }) => {
  const currentProgramClassName = active ? 'active' : 'disabled';

  return (
    <div className='step-icon-container'>
      <div className={`step-circle ${currentProgramClassName}`} />
    </div>
  );
};

export default ProgramStepIcon;
