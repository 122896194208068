import PropTypes from 'prop-types';

import * as icons from './list';
import { IconBox } from './style';

const renderIcon = (icon) => {
  const Svg = icons[icon.charAt(0).toUpperCase() + icon.slice(1)];
  return <Svg />;
};

const Icon = ({
  background = '',
  color = 'inherit',
  event = '',
  icon,
  margin = 0,
  rotate = 0,
  size = 16,
}) => (
  <IconBox
    color={color}
    background={background}
    size={size}
    margin={margin}
    rotate={rotate}
    event={event}>
    {renderIcon(icon)}
  </IconBox>
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'inherit',
    'teal',
    'turquoise',
    'gray',
    'grayLight',
    'grayDark',
    'white',
  ]),
  background: PropTypes.oneOf([
    'inherit',
    'teal',
    'turquoise',
    'gray',
    'grayLight',
    'grayDark',
    'red',
    '',
  ]),
  size: PropTypes.number,
  margin: PropTypes.number,
  rotate: PropTypes.number,
  event: PropTypes.string,
};

export default Icon;
