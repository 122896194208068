import { WarningRounded } from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import Loader from 'components/elements/loading/Loader';
import { WarningBox, WarningInformation, WarningSpan } from 'components/main/calendar/style';
import { HealthRecordsClient } from 'node-api/health-records/HealthRecordsClient';
import {
  BloodPressure,
  InBody,
  Labs,
  Others,
} from 'node-api/health-records/HealthRecordsClient.types';
import { FC, ReactNode, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { PatientContext } from 'state/contexts/patient';

type VitalWithDate<T = InBody | Labs | BloodPressure | Others> = { date: string; entry: T };

type Props = {
  visible: boolean;
};

const VitalsTable: FC<Props> = ({ visible }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState(0);

  const [inBody, setInBody] = useState<VitalWithDate<InBody>[]>([]);
  const [labs, setLabs] = useState<VitalWithDate<Labs>[]>([]);
  const [bloodPressure, setBloodPressure] = useState<VitalWithDate<BloodPressure>[]>([]);
  const [others, setOthers] = useState<VitalWithDate<Others>[]>([]);

  const { patientState } = useContext(PatientContext);

  const currentPatient = patientState.data.filter((p) => !!p.current)[0];

  useEffect(() => {
    if (!currentPatient || !visible) {
      return;
    }

    setLoading(true);

    const client = new HealthRecordsClient();

    client
      .getMemberVitals(currentPatient.id)
      .then((response) => {
        const inBodyEntries: VitalWithDate<InBody>[] = [];
        const labsEntries: VitalWithDate<Labs>[] = [];
        const bloodPressureEntries: VitalWithDate<BloodPressure>[] = [];
        const othersEntries: VitalWithDate<Others>[] = [];

        for (const date of Object.keys(response)) {
          const {
            inBody: inBodyEntry,
            labs: labsEntry,
            bloodPressure: bloodPressureEntry,
            ...othersEntry
          } = response[date];

          inBodyEntries.push({ date, entry: inBodyEntry });
          labsEntries.push({ date, entry: labsEntry });
          bloodPressureEntries.push({ date, entry: bloodPressureEntry });
          othersEntries.push({ date, entry: othersEntry });
        }

        setInBody(inBodyEntries);
        setLabs(labsEntries);
        setBloodPressure(bloodPressureEntries);
        setOthers(othersEntries);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [currentPatient, visible]);

  const renderTable = <T,>(columns: string[], entries: VitalWithDate<T>[]): ReactNode => {
    return (
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((columnName) => (
                <TableCell align='center'>{columnName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(({ date, entry }) => (
              <TableRow>
                <TableCell align='center'>{date}</TableCell>
                {Object.values(entry as keyof T).map((value) => (
                  <TableCell align='center'>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (!visible) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (!loading && [...inBody, ...labs, ...bloodPressure, ...others].length === 0) {
    return (
      <WarningBox>
        <WarningInformation>
          <WarningSpan>
            <WarningRounded fontSize='large' />
          </WarningSpan>
          <span>There are no vitals recorded for this patient yet</span>
        </WarningInformation>
      </WarningBox>
    );
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={2}>
          <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
            <Tabs
              orientation='vertical'
              variant='scrollable'
              value={tabIndex}
              onChange={(_: SyntheticEvent, newIndex: number) => setTabIndex(newIndex)}
              sx={{ borderRight: 1, borderColor: 'divider' }}>
              <Tab label='InBody' />
              <Tab label='Labs' />
              <Tab label='Blood Pressure' />
              <Tab label='Others' />
            </Tabs>
          </Box>
        </Grid>
        <Grid item width='80%'>
          {tabIndex === 0 &&
            renderTable(
              [
                'Date',
                'SMM (lbs)',
                'Fat Mass (lbs)',
                'PBF (%)',
                'ECW (lbs)',
                'ICW (lbs)',
                'Visceral Fat Level (Level)',
              ],
              inBody
            )}

          {tabIndex === 1 &&
            renderTable(
              [
                'Date',
                'A1c (%)',
                'Glucose (mg/dL)',
                'Insulin (μU/mL)',
                'Triglycerides (mg/dL)',
                'Total cholesterol (mg/dL)',
                'LDL cholesterol (mg/dL)',
                'HDL cholesterol (mg/dL)',
                'HsCRP (mg/dL)',
              ],
              labs
            )}

          {tabIndex === 2 && renderTable(['Date', 'Systolic', 'Diastolic', 'mmHg'], bloodPressure)}

          {tabIndex === 3 &&
            renderTable(
              [
                'Date',
                'Pulse (bpm)',
                'Height (in)',
                'Weight (lbs)',
                'BMI (kg/m²)',
                'Waist circumference',
                'Hip circumference',
                'Neck circumference',
              ],
              others
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VitalsTable;
