import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/event';

const EventContext = createContext<{ eventState: State; eventDispatch: Dispatch<Action> }>({
  eventState: initialState,
  eventDispatch: () => null,
});

const EventProvider: FC<PropsWithChildren> = ({ children }) => {
  const [eventState, eventDispatch] = useReducer(reducer, initialState);
  return (
    <EventContext.Provider value={{ eventState, eventDispatch }}>{children}</EventContext.Provider>
  );
};

export { EventContext, EventProvider };
