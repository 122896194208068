import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;
  & h2,
  p {
    color: ${({ theme }) => theme.grayDark};
  }
`;

export const MessageContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Message = styled.div`
  & p {
    position: relative;
    top: -3px;
  }
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.teal};
`;

export const Icon = styled.div`
  & img {
    width: 50px;
    height: 50px;
    margin-right: 16px;
  }
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    width: ${({ customWidth }) => customWidth};
    height: 50px;
  }
  & button:last-of-type {
    margin-left: 16px;
    background-color: ${({ theme }) => theme.grayDarker};
  }
`;
