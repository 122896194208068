import { Moment } from 'moment';

export enum Preferences {
  UseNewVideoCall = 'use_new_video_call',
}

export interface MemberExercise {
  id: number;
  createdAt: string;
  exerciseDate: Moment;
  updatedAt: string;
  memberExercise: { label: string; value: string }[];
}

export interface GetExercisesResponse {
  data: MemberExercise[];
  success: boolean;
}

export interface IExercise {
  id: number;
  exerciseDate: string;
}

export interface ITabNotification {
  active: boolean;
  id: number;
  name: string;
  hasNoReviewEntries: boolean;
  label: string;
  newUnreviewedEntries: boolean;
  newUnreviewedData?: IExercise[];
}

export interface GetProfileNotificationsResponse {
  profileNotifications: ITabNotification[];
}

export type Weight = {
  id: number;
  recordedValue: number;
  source: string;
  sourceCreatedAt: string;
  confidence: number;
  isAnomaly: boolean;
};

export type PreferenceUpdateResponse = {
  success: true;
  data: {
    id: number;
    memberId: number;
    preferenceId: number;
    value: boolean;
    createdAt: string;
    updatedAt: string;
  };
};

type Preference = {
  id: number;
  name: string;
  label: string;
  value: boolean;
  title: string;
};

export type MemberPreference = {
  id: number;
  value: boolean;
  preference: Preference;
};
