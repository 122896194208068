import styled from 'styled-components';
import { animated } from 'react-spring';

export const Backdrop = styled(animated.div)`
  background: rgba(0, 40, 80, 0.4);
  color: ${({ theme }) => theme.gray};
  display: flex;
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
`;

export const Wrapper = styled(animated.div)`
  background: ${({ theme }) => theme.white};
  border-radius: 4px;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: auto;
`;

export const Content = styled.section`
  padding: ${(props) => (props.size === 'normal' ? '24px' : props.size === 'small' ? '12px' : '0')};
  position: relative;

  @media (min-width: ${({ theme }) => theme.md}) {
    padding: ${(props) =>
      props.size === 'normal' ? '60px 80px' : props.size === 'small' ? '30px 40px' : '0'};
  }
`;

export const Header = styled.header`
  border-bottom: 1px solid #ccc;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 16px;
  padding-bottom: 8px;
`;

export const Main = styled.main`
  flex-grow: 1;
`;

export const Footer = styled.footer`
  border-top: 1px solid #ccc;
  margin-top: 16px;
  padding-top: 8px;
`;

export const CloseButton = styled.button`
  background: ${({ theme }) => theme.white};
  border-radius: 100px;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  color: ${({ theme }) => theme.gray};
  font-size: 25px;
  height: 48px;
  position: absolute;
  right: -24px;
  top: -24px;
  width: 48px;

  &:hover {
    background: ${({ theme }) => theme.grayLight};
    cursor: pointer;
  }
`;
