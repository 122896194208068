import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import { useSpring } from 'react-spring';
import { Container, TagName, VerticalLine, CloseButton } from './style';

const Tag = ({ backgroundColor, color, children, onClose }) => {
  const wrapperAnim = useSpring({
    opacity: '1',
    from: { opacity: '0.1' },
    config: { duration: 250 },
  });
  return (
    <Container style={wrapperAnim} backgroundcolor={backgroundColor} color={color}>
      <CloseButton color={color} onClick={onClose}>
        <Icon icon='close' size={10} />
      </CloseButton>
      <VerticalLine />
      <TagName>{children}</TagName>
    </Container>
  );
};

Tag.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  // children: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

Tag.defaultProps = {
  backgroundColor: null,
  color: null,
  onClose: () => {},
};

export default Tag;
