import styled from 'styled-components';

export const AccountWrapper = styled.div``;

export const AccountHeader = styled.div`
  padding-top: 30px;
`;

export const AccountContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  margin: 0 auto;
  text-align: center;
  padding: 60px;
  width: 710px;

  label {
    font-weight: 600;
  }
  button[type='submit'] {
    width: 100%;
  }
`;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.turquoise};
  border-radius: 0 0 150% 150%;
  height: 320px;
  margin-top: 0;
  margin-left: -5%;
  width: 110%;
  position: absolute;
  z-index: -1;
`;

export const Logo = styled.img`
  display: block;
  height: 32px;
  margin: 0 auto 80px;
  width: 150px;

  @media (max-width: ${({ theme }) => theme.md}) {
    margin: 20px auto;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.grayDark};
  font: 600 42px/47px ${({ theme }) => theme.primaryFont};
  margin: 0 0 50px;
`;

export const Subtitle = styled.h3`
  color: ${({ theme }) => theme.gray};
  font: 600 28px/31px ${({ theme }) => theme.primaryFont};
  margin: 0 0 50px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.gray};
  font: 300 28px/38px ${({ theme }) => theme.primaryFont};
  margin: 0 0 50px;
`;

export const Form = styled.form`
  margin: 0 auto 40px;
  width: 390px;
`;

export const PatientMessage = styled.p`
  background: ${({ theme }) => theme.teal};
  borderradius: '8px';
  color: ${({ theme }) => theme.white};
  margin: '0 auto';
  maxwidth: '400px';
  padding: '15px 10px';
  width: '100%';
`;
