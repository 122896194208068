import { ReviewListSlugs } from 'node-api/reviewList/reviewlist.types';
import { FeaturesState } from 'state/reducers/features';
import { EFeatures } from './features.types';

export default class FeatureManager {
  public static isBulkMessagesEnabledForReviewList({
    reviewListSlug,
    featuresState,
  }: {
    reviewListSlug: string;
    featuresState: FeaturesState;
  }): boolean {
    switch (reviewListSlug) {
      case ReviewListSlugs.NotEngagedByMe:
        return true;

      case ReviewListSlugs.PatientsDueForEngagementByMe:
        return featuresState[EFeatures.ProviderBulkMessagesDueForEngagementByMe];

      case ReviewListSlugs.PatientsDueForEngagementByAny:
        return featuresState[EFeatures.ProviderBulkMessagesDueForEngagementByAny];

      case ReviewListSlugs.WeightRegainWarning:
        return featuresState[EFeatures.ProviderBulkMessagesWeightRegainWarning];

      case ReviewListSlugs.Disengaging:
        return featuresState[EFeatures.ProviderBulkMessagesDisengaging];

      default:
        return false;
    }
  }
}
