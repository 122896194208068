import React from 'react';
import { IconContent } from '../style';

const Calendar = () => (
  <IconContent fill='currentColor' viewBox='0 0 216.374 216.374'>
    <g>
      <path
        d='M207.374,30.135h-25.438V13.432c0-4.971-4.029-9-9-9s-9,4.029-9,9v16.703H52.438V13.432c0-4.971-4.029-9-9-9s-9,4.029-9,9
		v16.703H9c-4.971,0-9,4.029-9,9v163.808c0,4.971,4.029,9,9,9h198.374c4.971,0,9-4.029,9-9V39.135
		C216.374,34.164,212.345,30.135,207.374,30.135z M198.374,48.135v19.997H18V48.135H198.374z M18,193.942V86.132h180.374v107.811H18
		z'
      />
      <path
        d='M140.288,102.718L89.82,153.186l-13.734-13.734c-3.515-3.514-9.213-3.514-12.728,0c-3.515,3.515-3.514,9.214,0,12.729
		l20.098,20.098c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636l56.832-56.831c3.515-3.515,3.515-9.214,0-12.729
		C149.502,99.205,143.803,99.204,140.288,102.718z'
      />
    </g>
  </IconContent>
);

export default Calendar;
