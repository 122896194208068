import styled, { css } from 'styled-components';

export const Section = styled.div`
  background-color: ${({ theme }) => theme.white};
  min-width: 350px;
  // width:1000px;
  width: 100%;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  margin: auto;

  ${(props) =>
    props.numberOfPatients > props.slidesToShow
      ? css`
          width: 93%;
        `
      : css`
          width: 100%;
        `}

  .slick-track {
    display: flex;
    margin: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    color: ${({ theme }) => theme.teal};
  }

  .slick-prev,
  .slick-next {
    top: 50px;
  }
`;

export const Item = styled.div`
  cursor: pointer;
  margin-right: 24px;
  text-align: center;
  opacity: ${(props) => (props.reviewed ? '.2' : '1')};

  img,
  i {
    border-radius: 100px;
    border: 4px solid ${({ theme }) => theme.white};
    display: block;
    height: 80px;
    margin: 0px auto 8px;
    overflow: hidden;
    text-align: center;
    transition: all 0.1s ease-in-out;
    width: 80px;
  }

  ${(props) =>
    props.current &&
    css`
      img,
      i {
        border-color: ${({ theme }) => theme.turquoise};
      }
    `}

  p {
    color: ${({ theme }) => theme.gray};
    font: 17px ${({ theme }) => theme.primaryFont};
    margin: 0;
  }

  &:hover {
    img {
      border-color: ${({ theme }) => theme.turquoise};
    }

    p {
      color: ${({ theme }) => theme.grayDarker};
    }
  }
`;

export const Pagination = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
  margin-right: 12px;

  & > button {
    margin-left: 0.5rem;
  }
`;

export const Counter = styled.span`
  margin-right: 16px;
`;
