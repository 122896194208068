import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { createGlobalStyle } from 'styled-components';

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  html{box-sizing: border-box;line-height:1.15;-webkit-text-size-adjust:100%}
  *, *:before, *:after {box-sizing: inherit; outline:none; text-decoration:none;}
  body{margin:0; font-family: "Proxima Nova Rg";overflow-y:scroll;background-color: #fff;}
  html,body,#root{height: 100%;}
  h1{font-size:2.15em;margin:.67em0}
  hr{box-sizing:content-box;height:0;overflow:visible}
  pre{font-family:monospace,monospace;font-size:1.08em}
  a{background-color:transparent;text-decoration:none;}
  abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}
  b,strong{font-weight:bolder}
  code,kbd,samp{font-family:monospace,monospace;font-size:1.08em}
  small{font-size:87%}
  sub,sup{font-size:81%;line-height:0;position:relative;vertical-align:baseline}
  sub{bottom:-.25em}
  sup{top:-.5em}
  img{border-style:none}
  button,input,optgroup,select,textarea{font-family:inherit;line-height:1.15;margin:0;outline:none}
  button,input{overflow:visible}
  button,select{text-transform:none}
  [type=button],[type=reset],[type=submit],button{-webkit-appearance:button;cursor:pointer;}
  [type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}
  [type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}
  fieldset{padding:.35em .75em .625em}
  legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}
  progress{vertical-align:baseline}
  textarea{overflow:auto}
  [type=checkbox],[type=radio]{box-sizing:border-box;padding:0}
  [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
  [type=search]{-webkit-appearance:textfield;outline-offset:-2px}
  [type=search]::-webkit-search-decoration{-webkit-appearance:none}
  ::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
  details{display:block}
  summary{display:list-item}
  template{display:none}
  [hidden]{display:none}
  ul{padding:0;margin:0;}
  ul li{padding:0;margin:0;list-style:none;}
  
  .modal{display:flex!important;justify-content:center;flex-flow:column}
  .custom-modal {width: auto;}
  .custom-modal > .modal-content {height:100%;border:none;overflow:hidden;}
  .modalBg { filter: blur(5px); }

  .Toastify__toast--default { background: #fff; color: #aaa; }
  .Toastify__toast--info { background: #3498db; }
  .Toastify__toast--success { background: #7ebb7a; }
  .Toastify__toast--warning { background: #ffc848; }
  .Toastify__toast--error { background: #f78b8b; }

  .__react_component_tooltip.custom { background: transparent!important; }
  .__react_component_tooltip.custom:after,
  .__react_component_tooltip.custom:before {
    display: none;
  }
`;

export default GlobalStyle;
