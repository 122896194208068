import { Dispatch } from 'react';
import { Member } from 'state/reducers/patient';

export type BulkActionsContextValue = {
  bulkActionsState: BulkActionsState;
  dispatchBulkActions: Dispatch<BulkActionsActionTypes>;
};

export type BulkActionsState = {
  selectedPatients: Member[];
  shouldPerformActionPostBulkActivity: boolean;
};

export const ADD_SELECTED_PATIENT = '@enara/types/add-selected-patient';
export const SET_MANY_PATIENTS = '@enara/types/set-many-patients';
export const REMOVE_SELECTED_PATIENT = '@enara/types/remove-selected-patient';
export const CLEAR_SELECTED_PATIENTS = '@enara/types/clear-selected-patients';
export const SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY =
  '@enara/types/should-perform-action-post-bulk-activity';

export type AddSelectedPatientType = {
  type: typeof ADD_SELECTED_PATIENT;
  payload: Member;
};

export type SetManyPatientsType = {
  type: typeof SET_MANY_PATIENTS;
  payload: Member[];
};

export type RemoveSelectedPatientType = {
  type: typeof REMOVE_SELECTED_PATIENT;
  payload: string;
};

export type ClearSelectedPatientsType = {
  type: typeof CLEAR_SELECTED_PATIENTS;
};

export type ShouldPerformActionPostBulkActivityType = {
  type: typeof SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY;
  payload: boolean;
};

export type BulkActionsActionTypes =
  | AddSelectedPatientType
  | RemoveSelectedPatientType
  | ClearSelectedPatientsType
  | SetManyPatientsType
  | ShouldPerformActionPostBulkActivityType;
