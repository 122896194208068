import Box from '@mui/material/Box';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { getRandomNumber } from 'utils/math';
import '../../css/table.css';

type Props = {
  columns: GridColDef[];
  data: GridValidRowModel[];
  onChange?: (updatedRows: GridValidRowModel[]) => void;
};

export const CustomTable = ({ columns, data, onChange }: Props) => {
  const [rows, setRows] = useState<GridValidRowModel[]>(
    data.map((d) => ({ ...d, id: getRandomNumber(5, 1000) }))
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowClick = (params: GridRowParams) => {
    setRowModesModel((prev) => ({
      ...prev,
      [params.id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params) => {
    setRowModesModel((prev) => ({
      ...prev,
      [params.id]: { mode: GridRowModes.View },
    }));
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRows = rows.map((row) => (row.id === newRow.id ? { ...newRow } : row));
    setRows(updatedRows);

    if (onChange) {
      onChange(updatedRows);
    }
    return newRow;
  };

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}>
      <DataGrid
        className='custom-panel-table'
        rows={rows}
        columns={columns}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowClick={handleRowClick}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        disableColumnFilter
        disableColumnMenu
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        localeText={{ noRowsLabel: 'There is no information to show' }}
      />
    </Box>
  );
};
