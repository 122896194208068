import { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ComposeProviders from 'state/providers';
import Routes from '../routes';
import ForgotPassword from './account/forgot-password';
import Login from './account/login';
import Authentication from './authentication';
import Header from './header';
import { Main, MainContainer, MainContent } from './layout-styles';

const App: FC = () => (
  <BrowserRouter basename='/'>
    <Switch>
      <Route exact path='/login' render={(props) => <Login {...props} />} />
      <Route exact path='/forgot-password' render={(props) => <ForgotPassword {...props} />} />

      <Route
        path='/'
        render={(props) => (
          <ComposeProviders>
            <Authentication {...props}>
              <MainContainer>
                <MainContent>
                  <Header {...props} />
                  <Main>
                    <Routes />
                  </Main>
                </MainContent>
              </MainContainer>
            </Authentication>
          </ComposeProviders>
        )}
      />
    </Switch>
  </BrowserRouter>
);

export default App;
