import { ActionMap } from '../types';

enum ActionTypes {
  SET = 'SET',
}

type DataType = {
  id: number;
  slug: string;
};

type Payload = {
  [ActionTypes.SET]: DataType[];
};

export type Actions = ActionMap<Payload>[keyof ActionMap<Payload>];

export type InitialStateType = {
  data: DataType[];
};

export const initialState: InitialStateType = {
  data: [],
};

export const reducer = (state: InitialStateType, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SET:
      return {
        ...state,
        data: [...action.payload],
      };
    default:
      throw new Error('Unexpected action');
  }
};
