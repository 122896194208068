import { Avatar, Badge, Tooltip } from '@mui/material';
import { AttentionResponseEntry } from 'node-api/attention/AttentionApi.types';
import { ILegacyMyPanelResponseEntry } from 'node-api/panel/MyPanelLegacyClient.types';
import { FC } from 'react';
import { getActiveAttentionKeys, PanelSettings } from '../types';

export const PanelAvatarWithBadges: FC<{
  settings: PanelSettings;
  attention: AttentionResponseEntry[];
  member: ILegacyMyPanelResponseEntry;
}> = ({ settings, attention, member }) => {
  let avatar = <Avatar src={member.avatar?.thumb?.url || ''} />;
  const activeAttentionKeys = getActiveAttentionKeys(attention, settings);

  for (const attentionKey of activeAttentionKeys) {
    avatar = (
      <div style={{ marginRight: 6 }} className={attentionKey}>
        <Badge
          overlap='circular'
          color='primary'
          badgeContent={
            activeAttentionKeys.length > 1
              ? activeAttentionKeys.length
              : attentionKey.substring(0, 1).toUpperCase()
          }>
          {avatar}
        </Badge>
      </div>
    );
  }

  if (activeAttentionKeys?.length) {
    avatar = (
      <Tooltip placement='right-end' title={activeAttentionKeys.join(', ')}>
        {avatar}
      </Tooltip>
    );
  }
  return avatar;
};
