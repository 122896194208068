import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  background-color: ${(props) => props.theme[props.backgroundcolor]};
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme[props.color]};
  color: ${(props) => props.theme[props.color]};
  font: 15px/21px ${({ theme }) => theme.primaryFont};
  margin: 0 10px 10px 0;
  height: 30px;
  padding: 5px;
`;

export const TagName = styled(animated.div)`
  padding: 6px;
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${({ theme }) => theme.teal};
  height: 29px;
  float: right;
`;

export const CloseButton = styled.button`
  align-items: center;
  background: ${({ theme }) => theme.turquoise};
  border-radius: 100%;
  border: none;
  color: ${(props) => props.theme[props.color]};
  display: flex;
  float: right;
  font-size: 13px;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: 3px;

  &:hover {
    cursor: pointer;
  }
`;
