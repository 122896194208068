export interface IResponseCard {
  content: string;
  created_at: string;
  title: string;
  updated_at: string;
  uuid: string;
}

export interface ICard {
  id: number;
  card_id: number;
  card_uuid: string;
  checked: boolean;
  days_delay: number;
  title: string;
}

export interface ISchedule {
  locked: boolean;
  provider_fullname: string;
  provider_id: number;
  posted_by_provider_at: string;
  scheduled_for_member_at: string;
}

export interface ISubCategory {
  id: number;
  title: string;
  bcards: ICard[];
  schedule?: ISchedule;
}

export interface ICategory {
  id: number;
  title: string;
  sub_categories: ISubCategory[];
  schedule?: ISchedule;
}

export interface IResponsePostCardSequence {
  success: string;
  message: string;
}

export interface IResponseStartCurriculum {
  success: string;
  message: string;
}

export interface IOpenCategory {
  [key: number]: boolean;
}

export interface IOpenItem {
  open: number | null;
}

export interface ISubCategoryInfo {
  id: number;
  cards: ICard[];
  subCategoryTitle: string;
  forced: boolean;
  yesText: string;
  noText: string;
  message: string;
  modal: boolean;
}

export interface ICategoryInfo {
  id: number;
  subCategory: ISubCategory[];
  yesText: string;
  noText: string;
  message: string;
  modal: boolean;
}

export type CardBasicInfo = {
  originalId: number;
  originalAuthor: number;
  hasCoverImage: boolean;
  isOriginal: boolean;
  isOfficial: boolean;
  type: string;
};

export type CardTracking = {
  category: string;
  categories: string[];
  content: CardBasicInfo;
  module: unknown;
  tags: string[];
};

export type BaseCard = {
  id: number;
  uuid: string;
  title: string;
  completed: boolean;
  pinned: boolean;
  starred: string;
  taglist: string;
  tracking: CardTracking;
};

export type Card = BaseCard & {
  commentsCount: number;
  contentSnapshotUrl: string;
  dueAt: string;
  updatedAt: string;
  createdAt: string;
};

export type IRecentCard = BaseCard & {
  comments_count: number;
  content_snapshot_url: string;
  due_at: string;
  updated_at: string;
  createdAt: string;
};
export interface IRecentCards {
  success: boolean;
  cards: IRecentCard[];
}

export enum CardTabs {
  RecentCard = 'recent-card',
  PendingComments = 'card-pending-comments',
  History = 'card-history',
  PostSequence = 'post-card-sequence',
}
