import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Record = styled.div<StyledComponentsDivProps>`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  height: 30px;
  margin-bottom: 3px;
`;

export const ButtonWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-bottom: 20px;
  gap: 1em;
`;

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
`;

export const AppointmentsWrapper = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex: 2;
  flex-direction: column;
  width: -webkit-fill-available;
  min-height: 200px;
  gap: 20px;
`;

export const ShowCanceledWrapper = styled.div<StyledComponentsDivProps>`
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const CanceledAppointment = styled.div<StyledComponentsDivProps>`
  background-color: ${({ theme }) => theme.salmonDark};
  color: white;
  border-radius: 44px;
  padding: 2px 6px;
  margin-top: -2px
  width: fit-content;
  font-size: 0.6rem;
`;
