import { FC, MouseEvent, PropsWithChildren } from 'react';
import { TabData } from './Tabs';

type Props = {
  data: TabData;
  selected: boolean;
  onTabClick: (e: MouseEvent<HTMLDivElement>) => void;
};

const Tab: FC<PropsWithChildren<Props>> = ({ data, selected = false, onTabClick, children }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role='tab'
      id={`${data.id}`}
      tabIndex={0}
      key={`key-${data.id}`}
      className={`tab ${selected ? 'selected' : ''}`}
      onClick={onTabClick}>
      {children || data.name}
    </div>
  );
};

export default Tab;
