import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  Set = 'SET',
  Reload = 'RELOAD',
}

export type Tag = { id: number; name: string };

export type State = {
  data: { [key: number]: Tag[] };
  reload: number;
};

type Payload = {
  [ActionTypes.Set]: {
    patientId: number;
    data: State['data'][0];
  };
  [ActionTypes.Reload]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  reload: 0,
  data: {},
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.Set: {
      const { patientId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [patientId]: data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        },
      };
    }

    case ActionTypes.Reload: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
