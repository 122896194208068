import api from 'api';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';

import { ChartContext } from 'state/contexts/chart';

import { Loading } from 'components/elements';
import { debug } from 'utils/helpers';

import { ChartBox, ChartSection } from 'components/general-styles';
import { ActionTypes } from 'state/reducers/chart';
import Charts from './chart';

const GlucoseChart = ({ patientId, patientUuid, visible }) => {
  const { chartState, chartDispatch } = useContext(ChartContext);

  const [loading, setLoading] = useState(true);
  const [chart, setChart] = useState();

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    const getChartData = async () => {
      if (!(chartState.patientId === patientId && chartState.data.glucose.values)) {
        if (chartState.patientId !== patientId) {
          chartDispatch({ type: ActionTypes.CLEAR });
        }
        try {
          const { data } = await api().get(`/healthkit/glucose/${patientUuid}/AUTH_TOKEN`, {
            cancelToken: source.token,
          });

          chartDispatch({
            type: ActionTypes.SET,
            payload: {
              patientId,
              data,
            },
          });

          setChart(data);
          setLoading(false);
        } catch (err) {
          if (!axios.isCancel(err)) {
            debug(err);
            setLoading(false);
          }
        }
      } else {
        setChart(chartState.data);
        setLoading(false);
      }
    };

    getChartData();

    return () => {
      source.cancel();
    };
  }, [patientId]);

  if (!visible) {
    return null;
  }

  if (loading) return <Loading />;

  return (
    <>
      {chart && (
        <ChartSection>
          <ChartBox>{chart.glucose && <Charts />}</ChartBox>
        </ChartSection>
      )}
    </>
  );
};

GlucoseChart.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientUuid: PropTypes.string.isRequired,
};

GlucoseChart.defaultProps = {};

export default GlucoseChart;
