import styled from 'styled-components';

export const Field = styled.div`
  display: block;
  margin-bottom: 24px;
`;

export const AutocompleteWrapper = styled.div`
  height: 50px;
  margin-top: '0px';
  border-radius: 4px;
  background-color: ${({ theme }) => theme.inputBackground};
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 25px);
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child {
    padding-left: 20px;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
    right: -18px;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray};
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: ${({ marginbottom }) => (marginbottom ? `${marginbottom}px` : '12px')};
`;

AutocompleteWrapper.defaultProps = {
  'data-id': 'AutocompleteWrapper',
};

Label.defaultProps = {
  'data-id': 'Label',
};

Field.defaultProps = {
  'data-id': 'Field',
};
