import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { debug } from 'utils/helpers';
import AuthService from '../../../services/auth-service';

import { Link } from '../../elements';

import ResetPassword from './reset-password';
import ValidateCode from './validate-code';
import SetPassword from './set-password';

import { AccountWrapper, AccountHeader, AccountContent, Background, Logo } from '../account-styles';

import logoSrc from '../../navbar/assets/logo.png';

const ForgotPassword = ({ history }) => {
  const [step, setStep] = useState('reset');
  const [token, setToken] = useState();
  const [userEmail, setEmail] = useState();

  const submitReset = async (payload) => {
    const { email } = payload;
    try {
      await AuthService.resetPassword({ email });
      setEmail(email);
      setStep('code');
    } catch (err) {
      debug(err);
    }
  };

  const submitValidate = async (payload) => {
    const { code } = payload;
    try {
      const { data } = await AuthService.signIn({
        phone: userEmail,
        password: code,
      });
      setToken(data.auth_token);
      setStep('set');
    } catch (err) {
      debug(err);
    }
  };

  const submitSet = async (payload) => {
    const { password, password_confirmation } = payload;
    try {
      await AuthService.setPassword({
        password,
        password_confirmation,
        auth_token: token,
      });
      history.push('/login');
    } catch (err) {
      debug(err);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 'reset':
        return <ResetPassword submit={submitReset} />;
      case 'code':
        return <ValidateCode submit={submitValidate} />;
      case 'set':
        return <SetPassword submit={submitSet} />;
      default:
    }
  };

  return (
    <AccountWrapper>
      <Background />
      <AccountHeader>
        <Logo src={logoSrc} alt='Enara logo' />
      </AccountHeader>
      <AccountContent>
        {renderStep()}

        <Link to='/login' title='Login'>
          Back to login
        </Link>
      </AccountContent>
    </AccountWrapper>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default ForgotPassword;
