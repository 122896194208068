import React from 'react';
import { withStyles } from '@material-ui/core/styles';

// Material UI
import Switch from '@material-ui/core/Switch';
import Theme from 'styles/theme';

const SwitchWrapper = withStyles(() => ({
  root: {
    width: 45,
    height: 26,
    padding: 0,
  },
  switchBase: {
    padding: 0,
    '&$checked': {
      color: Theme.white,
      '& + $track': {
        backgroundColor: Theme.salmon,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: Theme.teal,
      border: '6px solid #fff',
    },
  },
  thumb: {
    boxShadow: 'none',
    height: '19px',
    margin: '3px',
    width: '19px',
  },
  track: {
    borderRadius: '50px',
    border: `1px solid ${'grayLight'}`,
    backgroundColor: Theme.grayLight,
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

export default SwitchWrapper;
