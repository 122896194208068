import { SharedEnaraNodeClient } from './common/SharedEnaraNodeClient';

export type MemberInfo = {
  startingWeight: string;
  Age: string;
  height: string;
  BFM: string;
  SMM: string;
  DLM: string;
  PBF: string;
  gender: string;
  homa_ir: string;
  ldl: string;
  hdl: string;
  hscrp: string;
  hA1c: string;
  duration: string;
  measured_ree: string;
  predicted_ree: string;
};

export type PlanPrediction = {
  lower_bound: string;
  prediction: string;
  upper_bound: string;
};

export type PlanPredictionResponse = {
  data: {
    stepwise: PlanPrediction;
    vlcd: PlanPrediction;
  };
};

export default class WeightPredictionService extends SharedEnaraNodeClient {
  constructor() {
    super('https://wlp.precisionweight.com');
  }

  async calculate(memberInfo: MemberInfo): Promise<PlanPredictionResponse | null> {
    try {
      const response = await this.axiosClient.post<PlanPredictionResponse>(
        `process_data`,
        memberInfo
      );

      return response.data;
    } catch (err) {
      console.error('[WeightPredictionService::calculate] err: ', err);
      return null;
    }
  }
}
