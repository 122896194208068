import { Badge, ButtonProps, CSSObject, IconButton, styled, Tooltip } from '@mui/material';
import { FC } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { PanelListType } from '../types';

const StyledIconButton = styled(IconButton)<{
  variant?: Exclude<ButtonProps['variant'], 'text'>;
}>(({ theme, variant, color }) => {
  const overrides: CSSObject = {};

  const colorAsVariant =
    color === undefined || color === 'inherit' || color === 'default' ? 'primary' : color;

  if (variant === 'contained') {
    overrides.backgroundColor = theme.palette[colorAsVariant].main;
    overrides.color = theme.palette[colorAsVariant].contrastText;
    overrides[':hover'] = {
      backgroundColor: theme.palette[colorAsVariant].dark,
    };
  }
  return {
    ...overrides,
  };
});

export const PanelSideBarIcon: FC<{
  badgeColor?: 'primary' | 'secondary' | 'default' | 'error' | 'info' | 'success' | 'warning';
  badgeCount: number;
  disabled?: boolean;
  icon: JSX.Element;
  info?: string;
  list: PanelListType;
  onSelectList?: (list: PanelListType) => void;
  selectedList: PanelListType;
  title: string;
}> = ({ badgeColor, badgeCount, disabled, icon, info, list, onSelectList, selectedList, title }) =>
  disabled ? (
    // Disabled produce warnings on Tooltips
    <StyledIconButton
      disabled
      variant={list === selectedList ? 'contained' : undefined}
      color='info'
      aria-label={title}>
      <Badge overlap='circular' color={badgeColor} badgeContent={badgeCount}>
        {icon}
      </Badge>
    </StyledIconButton>
  ) : (
    <Tooltip title={info ? `${title}: ${info}` : title} placement='right'>
      <StyledIconButton
        variant={list === selectedList ? 'contained' : undefined}
        color='info'
        aria-label={title}
        onClick={() => {
          MixpanelWrapperInstance.track(EventNames.PanelListSelect, {
            targetType: ClickableElement.BUTTON,
            targetValue: list,
          });
          onSelectList?.(list);
        }}>
        <Badge overlap='circular' color={badgeColor} badgeContent={badgeCount}>
          {icon}
        </Badge>
      </StyledIconButton>
    </Tooltip>
  );
