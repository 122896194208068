import api from 'api';
import { CancelToken } from 'axios';
import moment from 'moment';
import { Specialty } from 'node-api/schedule/Appointments.types';
import { Member } from 'state/reducers/patient';
import { Provider } from 'state/reducers/provider';
import { DIRECT_ROOM, TEAM_ROOM } from 'utils/constants';
import { ChatMessage, TemporaryChatMessage } from './types';

type ChatDirection =
  | { direction: 'prev'; firstMessageId: number }
  | { direction: 'next'; lastMessageId: number };

export const getChatMessages = async (
  member: Member,
  type: 'team' | 'direct',
  options: ChatDirection
): Promise<{
  messages: ChatMessage[];
  acknowledgeMessage: unknown;
  previousMessageId: number;
  nextMessageId: number;
}> => {
  if (options.direction === 'prev' && options.firstMessageId === 0) {
    return {
      messages: [],
      acknowledgeMessage: {},
      previousMessageId: 0,
      nextMessageId: 0,
    };
  }

  let url = '';

  if (type === DIRECT_ROOM) {
    url = `messages/chat/${member.uuid}/AUTH_TOKEN`;
    url +=
      options.direction === 'next'
        ? `?last_message_id=${options.lastMessageId}`
        : `?first_message_id=${options.firstMessageId}`;
  } else {
    url = `messages/provider_messages/${member.uuid}/AUTH_TOKEN`;
  }
  const { data } = await api().get<{
    provider_acknowledgement: unknown;
    prev_message_id: number;
    next_message_id: number;
    data: {
      avatar: string;
      content: string;
      date: string;
      id: number;
      position: 'right' | 'left';
      sender_name: string;
    }[];
    messages: {
      id: number;
      sender_name: string;
      sender_avatar_url: string;
      content: string;
      image_url: string;
      created_at: string;
      created_by_system: false;
      side: 'right' | 'left';
      status: 'seen';
    }[];
  }>(url);
  const acknowledgeMessage = data.provider_acknowledgement || {};

  const messages: ChatMessage[] =
    type === DIRECT_ROOM
      ? (data?.messages || []).map((message) => ({
          id: message.id,
          content: message.content,
          senderName: message.sender_name,
          senderAvatarUrl: message.sender_avatar_url,
          createdAt: message.created_at,
          direction: message.side === 'right' ? 'outgoing' : 'incoming',
        }))
      : (data.data || []).map((message) => ({
          id: message.id,
          content: message.content,
          senderName: message.sender_name,
          senderAvatarUrl: message.avatar,
          createdAt: message.date,
          direction: message.position === 'right' ? 'outgoing' : 'incoming',
        }));

  if (type === DIRECT_ROOM) {
    messages.reverse();
  }

  const nextMessageId = messages[messages.length - 1]?.id || 0;
  const previousMessageId = data.prev_message_id || 0;

  return { acknowledgeMessage, messages, nextMessageId, previousMessageId };
};

export const submitMessage = async ({
  member,
  content,
  type,
  providerIds,
  originalUser,
}: {
  member: Member;
  type: 'team' | 'direct';
  content: string;
  providerIds: number[];
  originalUser?: number;
}): Promise<TemporaryChatMessage> => {
  let url = '';

  if (type === DIRECT_ROOM) {
    url = `messages?chat_id=${member.uuid}&auth_token=AUTH_TOKEN`;
  }

  if (type === TEAM_ROOM) {
    url = `/messages/provider_messages/${member.uuid}/AUTH_TOKEN`;
  }

  const { data } = await api().post<{ message_id: number }>(url, {
    content,
    provider_ids: providerIds,
    ...(originalUser ? { original_user: originalUser } : {}),
  });

  return {
    id: data.message_id,
    content,
    senderName: '<replace with sender name>',
    senderAvatarUrl: '<replace with sender avatar url>',
    createdAt: moment().format('YYYY-MM-DD HH:mm:ss Z'),
    direction: 'outgoing-temporary',
  };
};

export const getProvidersForMember = async (member: Member, cancelToken: CancelToken) => {
  const { data } = await api().get(`users/${member.id}/providers/AUTH_TOKEN`, {
    cancelToken,
  });
  const {
    data: providers,
    partnerships,
  }: {
    data: Provider[];
    partnerships: unknown[];
  } = data;

  return { providers, partnerships };
};

export const ROLE_NAME_DIETITIAN = 'Dietitian';
export const ROLE_NAME_MEDICAL = 'Medical';
export const ROLE_NAME_EXERCISE = 'Exercise';

export const getProviderRole = (provider: Provider): Specialty => {
  if (provider?.roles.some((p: { name: string }) => p.name.includes(ROLE_NAME_DIETITIAN))) {
    return Specialty.Nutritional;
  }
  if (provider.roles.some((p: { name: string }) => p.name.includes(ROLE_NAME_MEDICAL))) {
    return Specialty.Medical;
  }
  if (provider.roles.some((p: { name: string }) => p.name.includes(ROLE_NAME_EXERCISE))) {
    return Specialty.Exercise;
  }
  return Specialty.Metabolism;
};
