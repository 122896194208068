import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke-width: 2px;
  stroke: white;
`;

export const Hidden = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Visible = styled.div`
  background: ${(props) => (props.checked ? props.theme.teal : props.theme.grayLight)}
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  margin-right: 8px;
  transition: all 150ms;
  width: 16px;

  ${Hidden}:focus + & {
    box-shadow: 0 0 0 3px teal;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')}
  }
`;
