import styled from 'styled-components';

export const MainContainer = styled.div`
  align-items: stretch;
  display: flex;
  min-height: 100%;
  width: 100%;
`;

export const MainContent = styled.div`
  background-color: ${({ theme }) => theme.mainBackground};
  flex-grow: 1;
  //padding: 32px 60px 32px 60px;
  position: relative;
  //width: 85%;
  @media (max-width: ${({ theme }) => theme.md}) {
    flex-direction: column;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
`;

MainContainer.defaultProps = {
  'data-id': 'MainContainer',
};

MainContent.defaultProps = {
  'data-id': 'MainContent',
};

Main.defaultProps = {
  'data-id': 'Main',
};
