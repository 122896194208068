import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Action = styled.div<StyledComponentsDivProps>`
  flex-shrink: 0;
`;

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 16px;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.grayDarker};
  font: 17px/24px ${({ theme }) => theme.primaryFont};
  margin: 8px 0 0;
`;

export const Details = styled.p`
  color: ${({ theme }) => theme.gray};
  font: 300 14px ${({ theme }) => theme.primaryFont};
  margin: 0 0 4px;

  strong {
    font: 600 14px ${({ theme }) => theme.primaryFont};
  }

  span {
    color: ${({ theme }) => theme.teal};
  }
`;

export const Item = styled.li`
  align-items: start;
  display: flex;
  padding: 16px 0;

  i {
    flex-shrink: 0;
  }
`;
