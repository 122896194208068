import { MouseEvent, ReactNode, useState } from 'react';
import Tab from './Tab';
import { Container, TabsList } from './style';

/**
 * Tabs:
 *
 * @param {*} data array with the entities used to fill the headers of the tab and the content.
 * @param {*} renderTabLabel function to render the tab with a specific format or
 * with a component within.
 * @param {*} renderTabContent function to render the container with a specific format
 * or with a component within.
 */

export type TabData = { id: number; name: string };

type Props<T> = {
  data: T[];
  renderTabLabel: (data: T) => ReactNode;
  renderTabContent: (data: T) => ReactNode;
};

export const Tabs = <T extends TabData>({ data, renderTabLabel, renderTabContent }: Props<T>) => {
  const [selectedTab, setSelectedTab] = useState<number>(data[0].id);
  const [selectedContainer, setSelectedContainer] = useState<number>(data[0].id);

  const handleTabClick = (e: MouseEvent<HTMLDivElement>) => {
    setSelectedTab(Number(e.currentTarget.id));
    setSelectedContainer(Number(e.currentTarget.id));
  };

  return (
    <>
      <TabsList>
        {data
          .sort((a, b) => a.id - b.id)
          .map((d) => (
            <Tab
              data={d}
              key={`tab-${d.id}`}
              onTabClick={handleTabClick}
              selected={d.id === Number(selectedTab)}>
              {renderTabLabel(d)}
            </Tab>
          ))}
      </TabsList>

      <Container>
        {data
          .filter((d) => d.id === Number(selectedContainer))
          .map((d) => renderTabContent(d) || 'No Messages')}
      </Container>
    </>
  );
};

export default Tabs;
