import React from 'react';
import { IconContent } from '../style';

const Send = () => (
  <IconContent fill='currentColor' viewBox='0 0 21 18'>
    <g transform='translate(-1194.000000, -1597.000000)'>
      <polygon points='1194.01 1615 1215 1606 1194.01 1597 1194 1604 1209 1606 1194 1608' />
    </g>
  </IconContent>
);

export default Send;
