import { Stack, Tooltip } from '@mui/material';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import { FC, useContext, useMemo, useState } from 'react';
import ApexChart from 'react-apexcharts';
import ReactTooltip from 'react-tooltip';
import { ChartContext } from 'state/contexts/chart';
import { WeightsContext } from 'state/contexts/weights';
import { ChartData, ChartStateData } from 'state/reducers/chart';
import Theme from 'styles/theme';
import { hasValues } from 'utils/helpers';
import { Annotation, getTooltipContent } from '../../common';
import { Serie, SeriesWrappers } from '../../style';
import './chart.css';
import ManageWeight from './manage-weight/manage-weight';

const SERIES = ['programs', 'events', 'weights', 'appointments'] as const;

type SeriesType = (typeof SERIES)[number]; // 'programs' | 'events' | 'weights' | 'appointments'

type Tooltips = {
  programs: JSX.Element[];
  events: JSX.Element[];
  appointments: JSX.Element[];
};

type AnnotationElement = {
  x: number;
  borderColor: string;
  label: {
    borderColor: string;
    style: { color: string; background: string; cssClass: string };
    text: string;
  };
};

type AnnotationType = {
  programs: AnnotationElement[];
  events: AnnotationElement[];
  appointments: AnnotationElement[];
};

type Marker = {
  id: number | null;
  value: string;
  date: string;
};

type WeightType = {
  date: string | Date;
  weight: string | number;
  id: number | null;
};

type Props = {
  onMarkerClick: (marker: Marker) => void;
  onMarkersSelected: (markers: ChartData[]) => void;
};

const Chart: FC<Props> = ({ onMarkerClick, onMarkersSelected }) => {
  const [showManageWeightModal, setManageWeightModal] = useState<boolean>(false);
  const [toggle, setToggle] = useState({
    programs: true,
    events: true,
    appointments: true,
    weights: true,
  });
  const [weightData, setWeightData] = useState<WeightType>({
    date: Date(),
    weight: '',
    id: null,
  });

  const { chartState } = useContext(ChartContext);
  const { weightsState } = useContext(WeightsContext);

  const chartData = useMemo(() => {
    let allSeries: ChartStateData = {
      appointments: [],
      events: [],
      programs: [],
      weights: [],
      glucose: [],
      healthkits: [],
    };

    // First load series from chartState
    if (!!chartState?.data) {
      const programs = chartState.data?.programs?.values?.map((item) => {
        return {
          display_value: item.display_value || '',
          value: item.value.toString(),
          start_date: item.start_date,
        };
      }) ?? [
        {
          display_value: '',
          value: '',
          start_date: '',
        },
      ];

      allSeries = {
        ...allSeries,
        appointments: chartState.data?.appointments?.values ?? [],
        events: chartState.data?.events?.values ?? [],
        programs,
      };
    }

    // Then load weights from weightsState
    if (!!weightsState?.weight?.weights?.length) {
      const weightsSeries = [...weightsState.weight.weights];

      // Polish weights
      if (!!chartState?.data?.programs?.values?.length) {
        const startDate = moment(chartState.data.programs.values[0].start_date).format(
          'YYYY-MM-DD'
        );

        if (moment(weightsSeries[0].date).format('YYYY-MM-DD') > startDate) {
          const dummyPoint = {
            date: startDate,
            hrId: -1,
            id: -1,
            source: 'dummy-point',
            value: weightsSeries[0].value,
          };

          // Set dummy point at the beginning of the list
          weightsSeries.unshift(dummyPoint);
        }
      }

      // Set weights
      allSeries = { ...allSeries, weights: weightsSeries };
    }

    return allSeries;
  }, [chartState, weightsState]);

  const toggleWeightModal = () => {
    setManageWeightModal((prevState) => !prevState);
  };

  const renderTooltips = () => {
    const tooltips: Tooltips = {
      programs: [],
      events: [],
      appointments: [],
    };

    SERIES.forEach((serie: SeriesType) => {
      if (serie !== 'weights') {
        tooltips[serie] = chartData[serie].map((data, i) => {
          const annotation = data as Annotation;

          return (
            <ReactTooltip
              id={`${serie}-${i}`}
              key={`${serie}-${annotation.start_date ?? ''}_${i}`}
              delayHide={200}
              className='custom'
              effect='solid'
              clickable>
              <Tooltip title={`${serie}`}>{getTooltipContent(serie, annotation) || <></>}</Tooltip>
            </ReactTooltip>
          );
        });
      }
    });

    return [...tooltips.programs, ...tooltips.events, ...tooltips.appointments];
  };

  const addTooltipAttributes = (timeout: number) => {
    setTimeout(() => {
      SERIES.forEach((serie) => {
        if (hasValues(chartData[serie]) && serie !== 'weights') {
          chartData[serie].forEach((_, i) => {
            const a = document.getElementsByClassName(`${serie}-${i}-data`)[0];
            if (a) {
              a.setAttribute('data-for', `${serie}-${i}`);
              a.setAttribute('data-tip', '');
            }
          });
        }
      });
      ReactTooltip.rebuild();
    }, timeout);
  };

  const getAnnotations = () => {
    const annotations: AnnotationType = {
      programs: [],
      events: [],
      appointments: [],
    };

    SERIES.forEach((serie) => {
      if (hasValues(chartData[serie]) && toggle[serie] && serie !== 'weights') {
        annotations[serie] = chartData[serie].map((data, i) => {
          const annotation = data as Annotation;
          return {
            x: new Date(moment(annotation.start_date).format()).getTime(),
            borderColor: Theme.series[serie],
            label: {
              borderColor: Theme.series[serie],
              style: {
                color: Theme.white,
                background: Theme.series[serie],
                cssClass: `apexchart-annotations apexcharts-yaxis-annotation-${serie} ${serie}-${i}-data`,
              },
              text: annotation.value ?? annotation.name,
            },
          };
        });
      }
    });

    return [...annotations.programs, ...annotations.events, ...annotations.appointments];
  };

  const selectSingleMarker = (
    e: { detail: number },
    opts: {
      dataPointIndex: number;
      seriesIndex: number;
      w: {
        globals: {
          series: never[][];
          seriesX: never[][];
        };
      };
    }
  ) => {
    const { seriesIndex, dataPointIndex, w } = opts;

    let date = '';
    let weight = '';
    let id = null;

    if (seriesIndex !== -1 && dataPointIndex !== -1) {
      date = moment(w.globals.seriesX[seriesIndex][dataPointIndex]).format('YYYY-MM-DD');

      weight = w.globals.series[seriesIndex][dataPointIndex];

      if (!!chartData.weights[dataPointIndex].id) {
        id = chartData.weights[dataPointIndex].id as number;
      }
    }

    if (e.detail === 1) {
      onMarkerClick({ id, value: weight, date });
    }

    // Double click
    if (e.detail === 2) {
      if (seriesIndex !== -1 && dataPointIndex !== -1) {
        setWeightData({ ...weightData, date, weight, id });

        toggleWeightModal();
      }
    }
  };

  const selectMarkersWithinZoomedArea = (opts: {
    xaxis: {
      min: number;
      max: number;
    };
  }) => {
    const minDate = moment(opts.xaxis.min);
    const maxDate = moment(opts.xaxis.max);

    const filteredWeight = chartData.weights
      .filter((weight) => moment(weight.date).isBetween(minDate, maxDate))
      .map((data) => {
        return { id: data.id, value: data.value, date: data.date };
      });

    onMarkersSelected(filteredWeight);
  };

  const memoizedApexChart = useMemo(
    () => (
      <ApexChart
        options={{
          chart: {
            id: 'weightChart',
            redrawOnWindowResize: true,
            redrawOnParentResize: true,
            fontFamily: Theme.primaryFont,
            stacked: false,
            animations: {
              enabled: true,
            },
            zoom: {
              type: 'x',
              enabled: true,
              allowMouseWheelZoom: false,
              autoScaleYaxis: true,
            },
            toolbar: {
              autoSelected: 'zoom',
            },
            events: {
              mounted(chart) {
                addTooltipAttributes(1000);
                chart.windowResizeHandler();
              },
              zoomed(chart, opts) {
                selectMarkersWithinZoomedArea(opts);
                addTooltipAttributes(0);
                chart.windowResizeHandler();
              },
              beforeResetZoom(chart) {
                onMarkersSelected([]);
                chart.windowResizeHandler();
              },
              updated() {
                addTooltipAttributes(0);
              },
              markerClick(e, _, opts) {
                selectSingleMarker(e, opts);
              },
            },
          },
          stroke: {
            width: [2],
            curve: 'smooth',
          },
          colors: [Theme.series.weights],
          markers: {
            size: [2],
            strokeWidth: 0,
            hover: {
              sizeOffset: 0,
            },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              formatter: (val) => moment(val).format('YYYY-MM-DD'),
            },
          },
          yaxis: {
            labels: {
              formatter: (val) => `${val.toFixed(0)} lbs.`,
            },
          },
          annotations: {
            xaxis: getAnnotations(),
          },
          tooltip: {
            y: {
              formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                return `${Number.parseFloat(val.toFixed(2)).toString()}${
                  (w &&
                    ` ${w.globals.initialSeries[seriesIndex].data[dataPointIndex].source}${
                      w.globals.initialSeries[seriesIndex].data[dataPointIndex].isAnomaly
                        ? ' (Anomaly)'
                        : ''
                    }`) ||
                  ''
                }`;
              },
            },
          },
        }}
        series={[
          {
            name: 'Weight',
            type: 'line',
            data: chartData.weights.map((point) => ({
              x: new Date(moment(point.date).format()).getTime(),
              y: point.value,
              confidence: point.confidence,
              isAnomaly: point.isAnomaly,
              source: point.source ? `(Added by ${point.source})` : '',
            })),
          },
        ]}
        type='line'
        height='95%'
      />
    ),
    [weightsState]
  );

  return (
    <Stack className='chart-container-profile'>
      {renderTooltips()}
      {showManageWeightModal && (
        <ManageWeight onClose={toggleWeightModal} weightData={weightData} />
      )}

      {memoizedApexChart}

      <SeriesWrappers>
        {SERIES.filter((serie) => {
          return chartData[serie] && chartData[serie].length > 0;
        }).map((serie, index) => (
          <Serie
            key={`${serie}-serie`}
            type={serie}
            visible={toggle[serie]}
            onClick={() =>
              serie !== 'weights' &&
              setToggle((prev) => ({
                ...prev,
                [serie]: !prev[serie],
              }))
            }>
            <i className={`serie_${index}`} />
            {capitalize(serie)}
          </Serie>
        ))}
      </SeriesWrappers>
    </Stack>
  );
};

export default Chart;
