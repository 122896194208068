export type CPTCode = '99212' | '99213' | '99214' | '99215';

export enum MedicalProblem {
  Obesity = 'Obesity',
  MorbidObesity = 'Morbid obesity',
  Overweight = 'Overweight',
  WeightGain = 'Weight gain',
  OtherObesity = 'Other obesity',
  ObesityUnspecified = 'Obesity, unspecified',
  HeartAttackOrHeartDisease = 'Heart attack or heart disease',
  HeartPalpitationsOrAbnormalHeartRhythm = 'Heart palpitations or abnormal heart rhythm',
  Stroke = 'Stroke',
  NeurologicalDisorders = 'Neurological (brain) disorders',
  Migraines = 'Migraines',
  HighBloodPressure = 'High blood pressure',
  HighCholesterolOrBorderlineCholesterol = 'High cholesterol or borderline cholesterol',
  Prediabetes = 'Prediabetes',
  Type1Diabetes = 'Type 1 diabetes',
  Type2Diabetes = 'Type 2 diabetes',
  LungDisease = 'Lung disease',
  LiverDisease = 'Liver disease',
  GallbladderDisease = 'Gallbladder disease',
  BowelDisease = "Bowel disease (e.g. Crohn's, irritable bowel)",
  KidneyDiseaseOrKidneyStones = 'Kidney disease/kidney stones',
  Anemia = 'Anemia',
  Cancer = 'Cancer',
  Osteoporosis = 'Osteoporosis',
  SleepApnea = 'Sleep apnea',
  PCOS = 'PCOS',
  Pancreatitis = 'Pancreatitis',
  ThyroidDisease = 'Thyroid disease',
  Glaucoma = 'Glaucoma',
  ArthritisOrChronicPain = 'Arthritis or chronic pain',
  Gout = 'Gout',
  Depression = 'Depression',
  Anxiety = 'Anxiety',
  BipolarDisorder = 'Bipolar disorder',
  EatingDisorder = 'Eating disorder',
  SubstanceAbuse = 'Substance abuse',
  OtherPsychologicalCondition = 'Other psychological condition',
}

export enum MedicalBehavioralProblem {
  LackOfExercise = 'Lack of exercise',
  ExerciseCounseling = 'Exercise counseling',
  DietaryCounseling = 'Dietary counseling',
}

export enum MedicalProcedure {
  MinimalNone = 'Minimal / None',
  ReviewingDiagnosticTests = 'Reviewing diagnostic tests/labs',
  ReviewingOutsideRecords = 'Reviewing outside medical records',
  OrderingDiagnosticTests = 'Ordering diagnostic tests/labs',
  InterpretingOutsideTests = 'Interpreting outside diagnostic tests or imaging',
  DiscussingPlanWithPhysician = 'Discussing the plan with outside physician',
  PrescribingMedication = 'Prescribing medication',
  ReviewingDietExerciseLog = 'Reviewing dietary and exercise log',
  ReviewingBloodPressure = 'Reviewing blood pressure',
  ReviewingHeartRateLog = 'Reviewing heart rate log',
  Counseling = 'Counseling',
  DocumentingVisit = 'Documenting the visit',
  ReviewingRecordsBeforeVisit = 'Reviewing records prior to the visit minutes',
  WritingPriorAuthorization = 'Writing a prior authorization',
  PlacingReferral = 'Placing a referral',
  ConsultingOutsideDoctor = 'Consulting with outside doctor',
  ConsultingPatientDietitian = "Consulting with patient's dietitian, exercise coach, or psychologist",
  TalkingWithFamily = 'Talking with family',
}

export enum MeetingDurationRange {
  TenToNineteen = '10-19 minutes',
  TwentyToTwentyNine = '20-29 minutes',
  ThirtyToThirtyNine = '30-39 minutes',
  FortyToSixtyFixe = '40-65 minutes',
}

export enum MDMCode {
  Straightforward = 'Straightforward',
  Low = 'Low',
  Moderate = 'Moderate',
  High = 'High',
}

export const MeetingCptCodeByDurationRange: { [key in MeetingDurationRange]: CPTCode } = {
  [MeetingDurationRange.TenToNineteen]: '99212',
  [MeetingDurationRange.TwentyToTwentyNine]: '99213',
  [MeetingDurationRange.ThirtyToThirtyNine]: '99214',
  [MeetingDurationRange.FortyToSixtyFixe]: '99215',
};
