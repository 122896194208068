import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Box, IconButton, Snackbar, Typography } from '@mui/material';
import Toast from 'components/toast';
import {
  AppointmentSummaryContentType,
  DiagnosisSummaryOutput,
  GetAllSummariesByDateResponse,
  MedicalProblemOutput,
  MedicalRemainingProblemsContentType,
  MeetingContextContentType,
  MeetingDurationContentType,
  MeetingSummaryStage,
  NoteDetail,
  ScribeOutputType,
  StaticTextContentType,
} from 'node-api/scribe/scribe';
import ScribeClient from 'node-api/scribe/scribe-client';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { copyToClipboard } from 'utils/clipboard';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import '../../css/notes.css';
import '../css/note-edition.css';
import '../css/note-preview.css';
import '../css/note.css';
import { NoteEditionPreview } from '../NoteEditionPreview/NoteEditionPreview';
import { prepareSectionsForCopying } from '../NotePreview/format';
import { ProblemOption } from './sections/MedicalProblemSection';
import { renderNoteEditionSectionsByOutputType } from './sections/render-sections';

const DELAY_TO_UPDATE_NOTE_IN_MILLIS = 2000;

type Props = {
  note: NoteDetail;
  fetchSummaries: () => Promise<GetAllSummariesByDateResponse>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

export const NoteEdition = ({ note, setOpenModal, fetchSummaries }: Props) => {
  const [updatedNote, setUpdatedNote] = useState<NoteDetail>(note);
  const [openCopiedToClipboardMessage, setOpenCopiedToClipboardMessage] = useState<boolean>(false);
  const [savingNote, setSavingNote] = useState<boolean>(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const copyNoteContent = () => {
    copyToClipboard(note ? prepareSectionsForCopying(updatedNote) : '');
    setOpenCopiedToClipboardMessage(true);
  };

  const updateNote = async () => {
    const client = new ScribeClient();

    let allowedSectionsToEdit = updatedNote.content;

    if (note.meetingSummary.stage === MeetingSummaryStage.PreVisit) {
      // In the pre-visit notes it is not allowed to update all the sections
      // because the initial one with the patient has not been done yet.
      const allowedOutputTypesByStage = [ScribeOutputType.MedicalProblems];

      allowedSectionsToEdit =
        updatedNote.content?.filter((section) =>
          allowedOutputTypesByStage.includes(section.outputType)
        ) || [];
    }

    const response = await client.updateSummary(note.meetingSummary.id, {
      content: allowedSectionsToEdit,
    });

    if (!response.status || !response.note) {
      Toast.show('error', 'There was an error updating the note');
      return;
    }

    await fetchSummaries();
  };

  const handleChangeDiagnosis = (value: {
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>;
    data: string[];
  }) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType !== ScribeOutputType.DiagnosisSummary) {
          return section;
        }

        return {
          ...section,
          data: {
            ...section.data,
            [value.premise]: value.data,
          },
        };
      }),
    });
  };

  const handleChangeMedicalProblem = (value: {
    sectionId: string;
    order: number;
    outputType: ScribeOutputType.MedicalProblems;
    data: MedicalProblemOutput;
    index: number;
  }) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.MedicalProblems) {
          section.data[value.index] = { ...value.data };
          return {
            ...section,
            data: section.data,
          };
        }
        return section;
      }),
    });
  };

  const handleChangeMedicalRemainingProblems = (value: MedicalRemainingProblemsContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.MedicalRemainingProblems) {
          return {
            ...section,
            data: {
              mdmCode: section.data.mdmCode,
              additionalProblem: value.data.additionalProblem,
              medicalProcedures: value.data.medicalProcedures || [],
            },
          };
        }
        return section;
      }),
    });
  };

  const handleChangeMeetingDuration = (value: MeetingDurationContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        return section.outputType === value.outputType
          ? {
              ...section,
              ...value,
            }
          : section;
      }),
    });
  };

  const handleDeleteProblem = (problemPosition: number) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.MedicalProblems) {
          const problems = [...section.data];
          problems.splice(problemPosition, 1);

          return {
            ...section,
            data: [...problems],
          };
        }

        return section;
      }),
    });
  };

  const handleAddProblem = (problemOption: ProblemOption) => {
    const newProblem: MedicalProblemOutput = {
      name: problemOption.label,
      history: '',
      dataReview: {
        labs: [],
        exams: [],
        medications: [],
        vitals: [],
      },
      icd10Code: problemOption.value,
      subjectiveUpdates: '',
      plan: '',
    };

    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType !== ScribeOutputType.MedicalProblems) {
          return section;
        }

        return {
          ...section,
          data: [...section.data, { ...newProblem }],
        };
      }),
    });
  };

  const handleDeleteDiagnosis = (
    premise: keyof Omit<DiagnosisSummaryOutput, 'sectionId'>,
    value: string
  ) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        if (section.outputType === ScribeOutputType.DiagnosisSummary) {
          const diagnoses = [...(section.data[premise] || [])];
          diagnoses.splice(section.data[premise]?.indexOf(value) || 0, 1);

          return {
            ...section,
            data: {
              ...section.data,
              [premise]: [...diagnoses],
            },
          };
        }

        return section;
      }),
    });
  };

  const handleChangeMeetingContext = (value: MeetingContextContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        return section.outputType === value.outputType
          ? {
              ...section,
              ...value,
            }
          : section;
      }),
    });
  };

  const handleChangeAppointmentSummary = (value: AppointmentSummaryContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        return section.outputType === value.outputType
          ? {
              ...section,
              ...value,
            }
          : section;
      }),
    });
  };

  const handleChangeTextSection = (value: StaticTextContentType) => {
    setUpdatedNote({
      ...updatedNote,
      content: updatedNote.content?.map((section) => {
        return section.outputType === value.outputType && section.sectionId === value.sectionId
          ? {
              ...section,
              ...value,
            }
          : section;
      }),
    });
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    } else {
      timer.current = setTimeout(() => setSavingNote(true), DELAY_TO_UPDATE_NOTE_IN_MILLIS);
    }
  }, [updatedNote]);

  useEffect(() => {
    if (savingNote) {
      (async () => {
        await updateNote();
        setSavingNote(false);
      })();
    }
  }, [savingNote]);

  return (
    <Box className='note-edition-container'>
      <Box className='note-edition-header'>
        <Box display='flex' alignItems='center'>
          <Box className='note-list-row-icon' mr={1} />
          <Typography
            mr={1}
            sx={{
              color: '#404040',
              opacity: '60%',
            }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
            note.meeting.createdAt,
            'h:mma'
          )}`}</Typography>
          <Typography
            color='#323648'
            mr={
              1
            }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>

          {savingNote ? (
            <Typography className='note-icon-disabled'>Saving note...</Typography>
          ) : (
            <Box display='flex' gap={1} className='note-icon-disabled'>
              <CheckIcon
                className='note-icon-disabled'
                sx={{
                  '&.MuiSvgIcon-root': {
                    fontSize: '20px',
                  },
                }}
              />
              <Typography>Saved</Typography>
            </Box>
          )}
        </Box>

        <IconButton size='small' onClick={() => setOpenModal(false)}>
          <Close />
        </IconButton>
      </Box>

      <Box className='note-edition-container'>
        {note.content && (
          <Box className='note-edition-content'>
            <Box className='note-preview-content-header' mr={1}>
              <Typography
                component='h4'
                fontWeight={600}
                color='#353535'
                sx={{
                  fontSize: '18px !important',
                }}>
                Edit
              </Typography>
            </Box>

            <Box mb={2} pb={3} borderBottom='1px solid #DBDBDB' id='note-edition'>
              {renderNoteEditionSectionsByOutputType(
                Object.values(ScribeOutputType),
                updatedNote.content?.sort((a, b) => a.order - b.order) || [],
                note.meetingSummary.stage,
                {
                  handleAddProblem,
                  handleChangeDiagnosis,
                  handleChangeMeetingDuration,
                  handleDeleteDiagnosis,
                  handleChangeMedicalProblem,
                  handleChangeMedicalRemainingProblems,
                  handleDeleteProblem,
                  handleChangeMeetingContext,
                  handleChangeAppointmentSummary,
                  handleChangeTextSection,
                }
              )}
            </Box>
          </Box>
        )}

        <Snackbar
          message='Copied to clipboard'
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={() => setOpenCopiedToClipboardMessage(false)}
          open={openCopiedToClipboardMessage}
        />

        <Box className='note-edition-preview'>
          <NoteEditionPreview note={updatedNote} copyNoteContent={copyNoteContent} />
        </Box>
      </Box>
    </Box>
  );
};
