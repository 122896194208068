import { CircularProgress, Popper, PopperProps } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import './compact-filter.css';
import { CompactAutocompleteWrapper, CompactFilterLabel } from './style';

const DEFAULT_MIN_WIDTH = 18;

const CustomPopper = (props: PopperProps) => {
  return <Popper {...props} style={{ width: 'fit-content' }} />;
};

export type OptionValue = {
  value: string;
  label: string;
  description?: string;
};

interface CompactFilterProps {
  id: string;
  name: string;
  errors?: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  getData: () => OptionValue[];
  onChange: (
    event: ChangeEvent<Record<never, never>>,
    value: OptionValue | OptionValue[] | null
  ) => void;
  renderOptionFn?: (option: OptionValue) => React.ReactNode;
  register?: () => unknown;
  disabled?: boolean;
  multiple?: boolean;
  width?: string;
  height?: string;
  value: OptionValue;
}

const CompactFilter: FC<CompactFilterProps> = ({
  id,
  name,
  label,
  placeholder,
  helperText,
  getData,
  onChange,
  renderOptionFn,
  register,
  disabled,
  multiple,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionValue[]>([]);
  const [automaticWidthValue, setAutomaticWidthValue] = useState(DEFAULT_MIN_WIDTH);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return;
    }

    const data = getData();
    if (active) {
      setOptions(data);
    }

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleTextChange = (
    event: ChangeEvent<Record<never, never>>,
    optionValue: OptionValue | OptionValue[] | null
  ) => {
    if (optionValue) {
      const opValue = optionValue as OptionValue;
      setAutomaticWidthValue(opValue.label ? opValue.label?.length + 1 : DEFAULT_MIN_WIDTH);
    }

    onChange(event, optionValue);
  };

  return (
    <CompactAutocompleteWrapper>
      <CompactFilterLabel>{label}</CompactFilterLabel>
      <Autocomplete
        multiple={multiple}
        id={id}
        clearOnEscape
        className='compact-filter-select'
        PopperComponent={CustomPopper}
        disabled={disabled}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={handleTextChange}
        style={{ width: `${automaticWidthValue}ch` }}
        getOptionSelected={(option: OptionValue, optionValue: OptionValue) => {
          return option.value === optionValue.value;
        }}
        getOptionLabel={(option: OptionValue) => {
          setAutomaticWidthValue(option.label ? option.label.length + 1 : DEFAULT_MIN_WIDTH);
          return option.label || '';
        }}
        options={options}
        loading={loading}
        noOptionsText='No Data'
        value={value}
        disableClearable
        size='small'
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            placeholder={placeholder || ''}
            name={name}
            inputRef={register}
            variant='standard'
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { padding: 0, resize: 'both' },
              endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={
          renderOptionFn ||
          ((option) => (
            <li className='render-option-li' key={`option-${option.value}`}>
              {option.label}
            </li>
          ))
        }
      />
    </CompactAutocompleteWrapper>
  );
};

export const CompactFilterLoading: FC<{ displayLabel: string }> = ({ displayLabel }) => (
  <CompactFilter
    id={`${displayLabel}-loading`}
    name={displayLabel}
    label={`${displayLabel} `}
    placeholder='Loading... '
    getData={() => []}
    onChange={() => {}}
    value={{ label: '', value: '' }}
  />
);

export default CompactFilter;
