import { FC, MouseEvent, PropsWithChildren, useRef } from 'react';
import { Button } from './style';

type Props = {
  disabled?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const IconButtonElement: FC<PropsWithChildren<Props>> = ({
  children,
  disabled = false,
  onClick,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(event);

    buttonRef.current?.blur();
  };

  return (
    <Button ref={buttonRef} onClick={handleClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default IconButtonElement;
