import {
  StyledComponentsButtonProps,
  StyledComponentsDivProps,
  StyledComponentsSpanProps,
} from 'components/general-styles';
import styled, { StyledComponent } from 'styled-components';
import Theme from 'styles/theme';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  & button {
    width: 300px;
  }

  & button:first-of-type {
    margin: 10px 30px;
  }
`;

export const ListSubCategoryIcon = styled.div<StyledComponentsDivProps>`
  padding-left: 50px;
`;

export const ListEmptySubCategory = styled.div<StyledComponentsDivProps>`
  display: flex;
  padding-left: 66px;
`;

export const MSGText = styled.span<StyledComponentsSpanProps>`
  color: ${({ theme }) => theme.teal};
  padding-right: 1em;

  &:hover {
    color: ${({ theme }) => theme.tealLight};
    cursor: pointer;
  }
`;

export const ListCard = styled.div<StyledComponentsDivProps>`
  text-align: start;
  padding: 15px 10px;
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.turquoise};
  overflow-y: auto;
`;

export const BoxCard = styled.div<StyledComponentsDivProps>`
  display: flex;
  padding-left: 60px;
  width: 98%;

  div:first-child {
    flex: 3 0 60%;
  }
`;

export const CardRecord: StyledComponent<
  'div',
  never,
  { customCursor?: string; customWidth?: string; backgroundColor?: string },
  never
> = styled.div`
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: ${({ theme }) => theme.teal};
  cursor: ${(props) => props.customCursor || 'auto'};
  width: ${(props) => (props.customWidth ? '5%' : '20%')};
  background-color: ${(props) => props.backgroundColor || Theme.turquoise};
`;

export const ConfirmButton = styled.button<StyledComponentsButtonProps>`
  align-items: center;
  border-radius: 4px;
  border: none;
  display: flex;
  font: 17px ${({ theme }) => theme.primaryFont};
  justify-content: center;
  padding: 8px 15px;
  transition: all 0.1s ease-in-out;
  width: 300px;
  margin: 10px 30px;

  background: ${({ theme }) => theme.teal};
  color: ${({ theme }) => theme.white};
  &:hover {
    background: ${({ theme }) => theme.tealLight};
  }
`;

export const Content = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
