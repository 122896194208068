import { StyledComponentsDivProps, StyledComponentsTextareaProps } from 'components/general-styles';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Container = styled.div<StyledComponentsDivProps>`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const Card = styled.div<StyledComponentsDivProps>`
  display: flex;
  height: auto;
  // width: 70%;
  width: auto;
  //padding: 20px;
  overflow: auto;
  justify-content: center;
  margin: 20px;
`;

export const CardContent = styled.section`
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  overflow-y: auto;
  max-height: 420px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const CardTitle = styled.div<StyledComponentsDivProps>`
  margin-top: 50px;
  margin-left: 50px;
  font-weight: bold;
  margin-right: 50px;
  font-size: 16px;
`;

export const IPhoneContent = styled.div<PropsWithChildren & { id: string; preview: string }>`
  background-image: ${(props) => props.preview};
  width: 350px;
  height: 590px;
  transform: scale(1);
  transform-origin: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Comment = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15px;
  padding: 30px;
  // width: 30%;
  width: 300px;

  min-width: 300px;
  padding-left: 0px;
`;

export const Header = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
  float: left;
  height: 50%;
  margin-bottom: 1em;
  overflow-y: auto;
`;

export const Footer = styled.div<StyledComponentsDivProps>`
  display: flex;
  position: relative;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.grayDark};
    font: 600 15px/17px ${({ theme }) => theme.primaryFont};
    height: 17px;
    margin: 5px 0px;
    width: 112px;
  }
`;

export const Comments = styled.div<StyledComponentsDivProps>`
  display: flex;
  margin-bottom: 16px;
`;

export const Avatar = styled.img`
  border-radius: 20px;
  display: block;
  height: 35px;
  overflow: hidden;
  text-align: center;
  width: 35px;
`;

export const SectionPatient = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;
export const SectionTitle = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.teal};
  font: 600 16px/18px ${({ theme }) => theme.primaryFont};
  background-color: ${({ theme }) => theme.turquoise};
  border-radius: 4px;
  padding: 7px;
  margin-bottom: 6px;
  cursor: help;
`;

export const HiddenSection = styled.div<StyledComponentsDivProps>`
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 7px 7px 0px 7px;
`;

export const SectionImage = styled.div<StyledComponentsDivProps>`
  margin-right: 12px;
`;

export const SectionName = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.grayDarker};
  font: 600 15px/17px ${({ theme }) => theme.primaryFont};
  margin-bottom: 4px;
`;

export const SectionDetails = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;
`;
export const SectionDetailsRow = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  div:first-child {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 13px/14px ${({ theme }) => theme.primaryFont};
    width: 80px;
  }

  div:last-child {
    color: ${({ theme }) => theme.gray};
    font: 300 13px/14px ${({ theme }) => theme.primaryFont};
  }
`;

export const SectionComments = styled.div<StyledComponentsDivProps>`
  display: flex;
  flex-direction: column;

  div:first-child {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 15px/17px ${({ theme }) => theme.primaryFont};
    margin-bottom: 4px;
  }

  div:last-child {
    color: ${({ theme }) => theme.gray};
    font: 300 13px/14px ${({ theme }) => theme.primaryFont};
  }
`;

export const Line = styled.hr`
  border: 0.25px solid ${({ theme }) => theme.grayLight};
  margin: 16px 0;
`;

export const Input = styled.textarea<StyledComponentsTextareaProps>`
  background-color: ${({ theme }) => theme.grayLighter};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.grayDark};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  height: 128px;
  line-height: 19px;
  margin-bottom: 5px;
  padding: 10px;
  resize: none;
  width: 100%;
`;

export const PostCommentHeader = styled.div<StyledComponentsDivProps>`
  display: flex;
`;

export const PostComment = styled.div<StyledComponentsDivProps>`
  white-space: nowrap;
`;

export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.teal};
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  height: 35px;
  text-align: center;
  width: 40%;

  &:disabled {
    background: ${({ theme }) => theme.gray};
    cursor: auto;
  }
`;

export const CenterContent = styled.div<StyledComponentsDivProps>`
  margin-left: 150px;
  margin-top: 150px;
`;
