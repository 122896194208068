import React from 'react';
import { IconContent } from '../style';

const Task = () => (
  <IconContent fill='currentColor' viewBox='0 0 18 20'>
    <path d='M16,2 L11.82,2 C11.4,0.84 10.3,0 9,0 C7.7,0 6.6,0.84 6.18,2 L2,2 C0.9,2 0,2.9 0,4 L0,18 C0,19.1 0.9,20 2,20 L16,20 C17.1,20 18,19.1 18,18 L18,4 C18,2.9 17.1,2 16,2 L16,2 Z M9,2 C9.55,2 10,2.45 10,3 C10,3.55 9.55,4 9,4 C8.45,4 8,3.55 8,3 C8,2.45 8.45,2 9,2 L9,2 Z M11,16 L4,16 L4,14 L11,14 L11,16 L11,16 Z M14,12 L4,12 L4,10 L14,10 L14,12 L14,12 Z M14,8 L4,8 L4,6 L14,6 L14,8 L14,8 Z' />
  </IconContent>
);

export default Task;
