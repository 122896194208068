/* eslint-disable eqeqeq */
import { ProviderEvent, ProviderTimeTrackerClient } from 'node-api/ProviderTimeTrackerClient';

export const sendCardCommentRPM = (patientUuid: string, cardId: number) => {
  const trackableEvent = {
    event: ProviderEvent.ProviderSendCardComment,
    patientUuid,
  };
  new ProviderTimeTrackerClient().track(trackableEvent, cardId.toString());
};

export const visitProfileRPM = (patientUuid: string) => {
  const trackableEvent = {
    event: ProviderEvent.ProviderVisitMemberProfile,
    patientUuid,
  };
  new ProviderTimeTrackerClient().track(trackableEvent);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTotalPictures = (data: any) => {
  const today = new Date().toJSON().slice(0, 10);
  const total =
    data?.foods
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((food: any) => food.date == today)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((food: any) => food.date_foods.length)[0] || 0;
  return total;
};

export const sendProviderReviewingPictureEvent = async (
  patientUuid: string,
  totalPictures: string
) => {
  const trackableEvent = {
    event: ProviderEvent.ProviderReviewPicture,
    patientUuid,
  };

  const providerTimeTrackerClient = new ProviderTimeTrackerClient();
  await providerTimeTrackerClient.track(trackableEvent, totalPictures);
};

export const getRPMEvents = async (patientUuid: string, limit: number, offset: number) => {
  const providerTimeTrackerClient = new ProviderTimeTrackerClient();
  return providerTimeTrackerClient.list({ patientUuid, limit, offset });
};
