import React from 'react';
import PropTypes from 'prop-types';

import { Field, Input, Label } from './style';
import { Errors } from '../shared-style';

const TextAreaElement = React.memo(
  ({ errors, label, name, register, type, rows, resize, ...props }) => (
    <Field error={!!errors} fullWidth>
      <Label htmlFor={`${name}-input`}>{label}</Label>
      <Input
        id={`${name}-input`}
        name={name}
        type={type}
        ref={register}
        rows={rows || 5}
        resize={resize}
        {...props}
      />

      {errors && <Errors id={`${name}-error`}>{errors.message ? errors.message : errors}</Errors>}
    </Field>
  )
);

TextAreaElement.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  rows: PropTypes.number,
  resize: PropTypes.bool,
};

TextAreaElement.defaultProps = {
  errors: null,
  rows: null,
  resize: false,
};

export default TextAreaElement;
