import { createContext, FC, ReactElement, ReactNode, useContext, useReducer } from 'react';
import {
  ADD_SELECTED_PATIENT,
  BulkActionsActionTypes,
  BulkActionsContextValue,
  BulkActionsState,
  CLEAR_SELECTED_PATIENTS,
  REMOVE_SELECTED_PATIENT,
  SET_MANY_PATIENTS,
  SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY,
} from './types';

const BulkActionsContext = createContext<BulkActionsContextValue | undefined>(undefined);

const initialState: BulkActionsState = {
  selectedPatients: [],
  shouldPerformActionPostBulkActivity: false,
};

const bulkActionsReducer = (
  prevState: BulkActionsState,
  action: BulkActionsActionTypes
): BulkActionsState => {
  const { type } = action;

  switch (type) {
    case ADD_SELECTED_PATIENT:
      return {
        ...prevState,
        selectedPatients: [...prevState.selectedPatients, action.payload],
      };

    case SET_MANY_PATIENTS:
      return {
        ...prevState,
        selectedPatients: action.payload,
      };

    case REMOVE_SELECTED_PATIENT:
      return {
        ...prevState,
        selectedPatients: prevState.selectedPatients.filter((p) => p.uuid !== action.payload),
      };

    case CLEAR_SELECTED_PATIENTS:
      return { ...prevState, selectedPatients: [] };

    case SHOULD_PERFORM_ACTION_POST_BULK_ACTIVITY:
      return { ...prevState, shouldPerformActionPostBulkActivity: action.payload };

    default:
      return prevState;
  }
};

export const BulkActionsProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [bulkActionsState, dispatchBulkActions] = useReducer(bulkActionsReducer, initialState);

  return (
    <BulkActionsContext.Provider value={{ bulkActionsState, dispatchBulkActions }}>
      {children}
    </BulkActionsContext.Provider>
  );
};

export const useBulkActions = (): BulkActionsContextValue => {
  const bulkActionsContext = useContext(BulkActionsContext);

  if (!bulkActionsContext) {
    throw new Error('useBulkActions must be used within a BulkActionsContext.Provider');
  }

  return bulkActionsContext;
};
