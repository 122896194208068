import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${Animation} 0.8s infinite linear;
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid ${(props) => props.theme[props.color]};
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  height: ${(props) => props.size}px;
  margin: 0;
  position: relative;
  transform: translateZ(0);
  width: ${(props) => props.size}px;
  border-radius: 50%;

  &:after {
    border-radius: 50%;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
  }
`;

const LoadingSpinner = ({ size, border, color }) => (
  <Spinner size={size} border={border} color={color} />
);

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  border: PropTypes.number,
  color: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  size: 40,
  border: 4,
  color: 'grayDark',
};

export default LoadingSpinner;
