import React from 'react';
import { IconContent } from '../style';

const Plus = () => (
  <IconContent fill='currentColor' viewBox='0 0 21 21'>
    <g transform='translate(-390.000000, -1596.000000)'>
      <g transform='translate(386.000000, 1592.000000)'>
        <polygon points='25 16 16 16 16 25 13 25 13 16 4 16 4 13 13 13 13 4 16 4 16 13 25 13' />
      </g>
    </g>
  </IconContent>
);

export default Plus;
