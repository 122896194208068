import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Backdrop = styled.div<StyledComponentsDivProps>`
  background: rgba(0, 40, 80, 0.4);
  color: ${({ theme }) => theme.gray};
  display: flex;
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
`;

export const Wrapper = styled.div<StyledComponentsDivProps>`
  margin: auto;
`;
