import React from 'react';
import { IconContent } from '../style';

const User = () => (
  <IconContent fill='currentColor' viewBox='0 0 19 23'>
    <g>
      <path d='M8.92 12.664h.154c1.405-.023 2.542-.498 3.381-1.406 1.846-2.002 1.54-5.433 1.506-5.76-.12-2.459-1.329-3.635-2.326-4.183-.743-.411-1.611-.632-2.58-.65h-.081c-.533 0-1.578.082-2.58.631-1.007.549-2.235 1.725-2.355 4.201-.033.328-.34 3.76 1.506 5.76.834.91 1.97 1.384 3.376 1.407zm-3.6-7.051c0-.014.004-.028.004-.037.159-3.307 2.6-3.662 3.645-3.662H9.026c1.295.028 3.496.535 3.645 3.662 0 .014 0 .028.005.037.004.032.34 3.168-1.185 4.82-.604.654-1.41.977-2.47.986h-.047c-1.055-.01-1.866-.332-2.465-.987-1.52-1.642-1.194-4.792-1.19-4.82z' />
      <path d='M18.999 18.224v-.013c0-.036-.005-.072-.005-.112-.028-.885-.088-2.955-2.095-3.617l-.047-.013c-2.086-.515-3.82-1.677-3.839-1.69a.638.638 0 0 0-.87.147.59.59 0 0 0 .153.84c.079.054 1.92 1.293 4.223 1.865 1.078.371 1.198 1.485 1.23 2.504 0 .04 0 .076.005.112.005.402-.023 1.024-.097 1.381-.75.412-3.686 1.833-8.155 1.833-4.45 0-7.405-1.426-8.16-1.837-.073-.358-.106-.98-.096-1.382 0-.036.004-.071.004-.112.033-1.019.153-2.132 1.23-2.503 2.304-.573 4.145-1.816 4.224-1.865a.59.59 0 0 0 .152-.84.638.638 0 0 0-.87-.148c-.018.013-1.743 1.176-3.838 1.69l-.047.014C.094 15.144.034 17.214.006 18.095c0 .04 0 .076-.005.111v.014c-.004.232-.009 1.426.236 2.025.046.117.13.215.24.282.14.09 3.465 2.137 9.03 2.137 5.565 0 8.89-2.052 9.03-2.137a.603.603 0 0 0 .24-.282c.231-.594.226-1.788.222-2.02z' />
    </g>
  </IconContent>
);

export default User;
