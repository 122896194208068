import { Dispatch, FC, PropsWithChildren, createContext, useReducer } from 'react';
import { Action, State, initialState, reducer } from '../reducers/program';

const ProgramContext = createContext<{ programState: State; programDispatch: Dispatch<Action> }>({
  programState: initialState,
  programDispatch: () => null,
});

const ProgramProvider: FC<PropsWithChildren> = ({ children }) => {
  const [programState, programDispatch] = useReducer(reducer, initialState);
  return (
    <ProgramContext.Provider value={{ programState, programDispatch }}>
      {children}
    </ProgramContext.Provider>
  );
};

export { ProgramContext, ProgramProvider };
