import { PanelQuickAction } from './action-drawer/PanelActionDrawer';

const DRAWER_WIDTH_COLLAPSED = '50px';

export const getDrawerWidth = (action: PanelQuickAction) => {
  switch (action) {
    case 'collapsed':
      return DRAWER_WIDTH_COLLAPSED;
    case 'direct-chat':
      return '450px';
    default:
      return '0px';
  }
};

export const isQuickActionValid = (action: PanelQuickAction | unknown) => {
  return action === 'collapsed' || action === 'direct-chat';
};
