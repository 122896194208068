import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -10px;
  margin-bottom: 32px;

  h2 {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 24px/28px ${({ theme }) => theme.primaryFont};
  }
  p {
    color: ${({ theme }) => theme.grayDarker};
    font: 17px/19px ${({ theme }) => theme.primaryFont};
  }
`;

export const Actions = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    width: 50%;
  }

  & button:first-of-type {
    margin: 8px 0;
  }
`;

export const Record = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-grow: 1;
  margin-bottom: 3px;

  img {
    display: block;
    border-radius: 100%;
    height: 34px;
    width: 34px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    position: relative;
    left: -8px;
  }
`;
