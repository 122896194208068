import { AttentionDimension } from 'node-api/attention/AttentionApi.types';

// Very dark label colors, so white text contrast is good
export const ATTENTION_DIMENSION_COLOR:
  | { [key in AttentionDimension]?: string } & { default: string } = {
  [AttentionDimension.Food]: /* very dark teal */ '#039292',
  [AttentionDimension.FoodComments]: /* very dark brick */ '#8b0505',
  [AttentionDimension.CardComments]: /* very dark purple */ '#520d97',
  [AttentionDimension.MemberChat]: /* very dark orange */ '#944a00',
  [AttentionDimension.Weights]: '#0e008b',
  [AttentionDimension.Vitals]: '#098b1f',
  default: 'rgb(104, 99, 99)',
};
