import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  TOGGLE_PANEL = 'TOGGLE_PANEL',
  SET = 'SET',
  ADD = 'ADD',
  LOAD = 'LOAD',
  REVIEW = 'REVIEW',
  REVIEW_ALL = 'REVIEW_ALL',
}

export type State = {
  panel: boolean;
  count: number;
  data: { id: number }[];
};

type Payload = {
  [ActionTypes.TOGGLE_PANEL]: undefined;
  [ActionTypes.SET]: {
    count: number;
    data: State['data'];
  };
  [ActionTypes.ADD]: { data: State['data'] };
  [ActionTypes.LOAD]: { data: State['data'] };
  [ActionTypes.REVIEW]: {
    id: number;
    status: string;
  };
  [ActionTypes.REVIEW_ALL]: undefined;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  panel: false,
  count: 0,
  data: [],
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_PANEL: {
      return {
        ...state,
        panel: !state.panel,
      };
    }

    case ActionTypes.SET: {
      const { count, data } = action.payload;
      return {
        ...state,
        count,
        data,
      };
    }

    case ActionTypes.ADD: {
      const { data } = action.payload;
      return {
        ...state,
        count: state.count + 1,
        data: [...data, ...state.data],
      };
    }

    case ActionTypes.LOAD: {
      const { data } = action.payload;
      return {
        ...state,
        data: [...state.data, ...data],
      };
    }

    case ActionTypes.REVIEW: {
      const { id, status } = action.payload;
      return {
        ...state,
        count: status === 'true' ? state.count + 1 : state.count - 1,
        data: state.data.map((notification) => {
          if (notification.id !== id) return notification;
          return {
            ...notification,
            active: status,
          };
        }),
      };
    }

    case ActionTypes.REVIEW_ALL: {
      return {
        ...state,
        count: 0,
        data: state.data.map((notification) => ({
          ...notification,
          active: 'false',
        })),
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
