import { parsePrograms } from 'helpers/charts';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  SET = 'SET',
  ADD = 'ADD',
  CLEAR = 'CLEAR',
  RELOAD = 'RELOAD',
  DELETE_PROGRAM = 'DELETE_PROGRAM',
  UPDATE_PROGRAM = 'UPDATE_PROGRAM',
}

type ChartSeries =
  | 'weights'
  | 'programs'
  | 'events'
  | 'healthkits'
  | 'glucose'
  | 'providers'
  | 'appointments';

export type ChartData = {
  value: number | string;
  start_date?: string;
  end_date?: string;
  duration?: number;
  id?: number;
  text?: string;
  user_program_id?: number;
  display_value?: string;
  date?: string;
  confidence?: number | boolean;
  isAnomaly?: boolean;
  source?: string;
};

export type ChartStateData = {
  appointments: ChartData[];
  data?: { providers: ChartData[] };
  events: ChartData[];
  glucose: ChartData[];
  healthkits: ChartData[];
  programs: { display_value: string; value: string }[];
  weights: ChartData[];
};

export type State = {
  patientId: string;
  data: {
    [serie in ChartSeries]: {
      visible: boolean;
      points?: ChartData[];
      values?: ChartData[];
    };
  };
};

type Payload = {
  [ActionTypes.SET]: {
    patientId: string;
    data: ChartStateData;
    type: ChartSeries;
  };
  [ActionTypes.ADD]: {
    type: ChartSeries;
    values: {
      value: string | number;
      start_date: string;
      user_program_id?: number;
      text?: string;
      display_value?: string;
    };
  };
  [ActionTypes.CLEAR]: never;
  [ActionTypes.RELOAD]: never;
  [ActionTypes.DELETE_PROGRAM]: {
    programAssignmentId: number;
  };
  [ActionTypes.UPDATE_PROGRAM]: {
    programAssignmentId: number;
    data: {
      programId: number;
      programName: string;
      programDisplayValue: string;
      startDate?: string;
      endDate?: string;
    };
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  patientId: '',
  data: {
    weights: {
      visible: true,
      points: [],
    },
    programs: {
      visible: true,
      points: [],
    },
    events: {
      visible: true,
      points: [],
    },
    healthkits: {
      visible: true,
      points: [],
    },
    glucose: {
      visible: true,
      points: [],
    },
    providers: {
      visible: true,
      points: [],
    },
    appointments: {
      visible: true,
      points: [],
    },
  },
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET: {
      const values = action.payload.data;

      // ----- used when programs are returned without the weights ------
      if (values.programs) {
        state.data.programs = {
          visible: true,
          values: parsePrograms(values.programs) || undefined,
        };
      }
      if (values.events) {
        state.data.events = {
          visible: true,
          values: values.events || undefined,
        };
      }

      if (values.weights) {
        return {
          ...state,
          patientId: action.payload.patientId,
          data: {
            ...state.data,
            ...(values as unknown as State['data']), // TODO: This line shouldn't be here since it's messing types. Will leave it here to not break anything
            weights: {
              visible: true,
              values: values.weights || undefined,
            },
            programs: {
              visible: true,
              values: parsePrograms(values.programs) || undefined,
            },
            events: {
              visible: true,
              values: values.events || undefined,
            },
            appointments: {
              visible: true,
              values: values.appointments || undefined,
            },
          },
        };
      }
      if (values.glucose) {
        return {
          ...state,
          patientId: action.payload.patientId,
          data: {
            ...state.data,
            glucose: {
              visible: true,
              values: values.glucose || undefined,
            },
          },
        };
      }
      if (values.healthkits) {
        return {
          ...state,
          patientId: action.payload.patientId,
          data: {
            ...state.data,
            healthkits: {
              visible: true,
              values: values.healthkits || undefined,
            },
          },
        };
      }
      if (values.data && values.data.providers) {
        return {
          ...state,
          patientId: action.payload.patientId,
          data: {
            ...state.data,
            providers: {
              visible: true,
              values: values.data.providers || undefined,
            },
          },
        };
      }

      return {
        ...state,
      };
    }

    case ActionTypes.ADD: {
      const { type, values } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [type]: {
            ...state.data[type],
            values: [...(state.data[type].values || []), values],
          },
        },
      };
    }

    case ActionTypes.CLEAR: {
      return initialState;
    }

    case ActionTypes.RELOAD: {
      return {
        ...state,
        reload: Date.now(),
      };
    }

    case ActionTypes.DELETE_PROGRAM: {
      const { programAssignmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          programs: {
            ...state.data?.programs,
            values: state.data?.programs?.values?.filter(
              ({ user_program_id }) => user_program_id !== programAssignmentId
            ),
          },
        },
      };
    }

    case ActionTypes.UPDATE_PROGRAM: {
      const { programAssignmentId, data } = action.payload;
      const { programId, programName, programDisplayValue, startDate, endDate } = data;

      const programAssignmentsUpdated = state.data?.programs?.values?.map((programAssignment) => {
        const { user_program_id } = programAssignment;
        if (user_program_id === programAssignmentId) {
          // The one we want to update
          return {
            ...programAssignment,
            ...(programId && { id: programId }),
            ...(programName && {
              text: programName,
              value: programName,
              display_value: programDisplayValue,
            }),
            ...(startDate && { start_date: startDate }),
            ...(endDate && { end_date: endDate }),
          };
        }
        return programAssignment;
      });
      return {
        ...state,
        data: {
          ...state.data,
          programs: { ...state.data?.programs, values: programAssignmentsUpdated },
        },
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
