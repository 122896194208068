import styled, { css } from 'styled-components';

export const Metadata = styled.div`
  // color: ${({ theme }) => theme.gray};
  // font: 600 14px ${({ theme }) => theme.primaryFont};
  // margin-top: 4px;
  // margin-bottom: 8px;
`;

export const Bubble = styled.div`
  align-items: flex-start;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  font: 14px/16px ${({ theme }) => theme.primaryFont};
  //font-weight: 600;
  padding: 16px 18px;
`;

export const BubbleFooter = styled.div`
  display: flex;
  color: ${({ theme }) => theme.gray};
  font: 500 14px ${({ theme }) => theme.primaryFont};
  margin-top: 6px;
  margin-bottom: 8px;

  ${(props) =>
    props.type === 'outgoing'
      ? css`
          justify-content: flex-end;
        `
      : css`
          justify-content: flex-start;
        `}
`;

export const Seen = styled.div`
  color: ${({ theme }) => theme.teal};
  font-weight: 600;
  margin-right: 20px;
  margin-left: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Sender = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 45px;
`;

export const AvatarContent = styled.div`
  display: flex;
  //flex-direction: column;
`;

export const Avatar = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50%;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.gray};
  font: 600 11px/10px ${({ theme }) => theme.primaryFont};
  margin-top: 5px;
  margin-bottom: 8px;
  word-break: keep-all;
  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
  word-break: break-word;

  ${(props) =>
    props.type === 'outgoing'
      ? css`
          justify-content: 'flex-start';

          ${Sender} {
            margin: 0 0 0 16px;
          }

          ${Bubble} {
            background-color: ${({ theme }) => theme.teal};
            border-radius: 30px 30px 1px 30px;
            //border-radius: 56px;
            color: ${({ theme }) => theme.white};
          }
        `
      : css`
          justify-content: 'flex-end';

          ${Sender} {
            margin: 0 16px 0 0;
          }

          ${Bubble} {
            background-color: ${({ theme }) => theme.grayLight};
            border-radius: 30px 30px 30px 1px;
            //border-radius: 56px;
            color: ${({ theme }) => theme.grayDarker};
          }
        `}
`;

export const Image = styled.img`
  height: 206px;
  max-width: 100%;
  border-radius: 4px;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: ${(props) => (props.hasImage ? '10px' : '0px')};

  .lp-box {
    border: solid 1px ${({ theme }) => theme.grayDarker};
    min-height: 105px;
    border-radius: 4px;
    margin-top: 6px;
    padding: 6px;
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.grayDarker};
  }

  .lp-image {
    float: right;
    img {
      width: 90px;
      height: 90px;
    }
  }

  .lp-right {
    padding: 4px;
  }

  .lp-title {
    color: ${({ theme }) => theme.teal};
    font: 18px/20px ${({ theme }) => theme.primaryFont};
    margin-bottom: 8px;

    a,
    a:visited {
      color: ${({ theme }) => theme.teal};
    }
  }

  .lp-link {
    display: none;
  }
`;
