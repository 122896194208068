import { ICard } from 'interfaces/cards';
import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  TOGGLE_MODAL = 'TOGGLE_MODAL',
}

export type State = Partial<{
  modal: boolean;
  element_id: number | string;
  element: Record<string, unknown> | ICard;
  elements: (Record<string, unknown> | ICard)[];
  callback: () => void;
}>;

type Payload = {
  [ActionTypes.TOGGLE_MODAL]: Partial<{
    element_id: number | string;
    element: Record<string, unknown> | ICard;
    elements: (Record<string, unknown> | ICard)[];
    callback: () => void;
  }>;
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  element_id: '',
  element: {},
  elements: [],
  callback: () => {},
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
        element_id: action.payload.element_id,
        element: action.payload.element,
        elements: action.payload.elements,
        callback: action.payload.callback,
      };
    }
    default:
      throw new Error('Unexpected action');
  }
};
