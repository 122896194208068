import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  width: 100%;
  background: #ffffff;
`;

export const TabsList = styled.div`
  background-color: ${({ theme }) => theme.grayLighter};
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  overflow-y: auto;

  & .tab {
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.grayLight};
    color: ${({ theme }) => theme.grayDark};
    cursor: pointer;
    display: inherit;
    font: 300 19px/22px ${({ theme }) => theme.primaryFont};
    justify-content: center;
    min-height: 48px;
    padding: 0 20px;
  }

  & .selected {
    background-color: ${({ theme }) => theme.white};
    border-bottom: 3px solid ${({ theme }) => theme.white};
    border-right: 1px solid ${({ theme }) => theme.grayLight};
    cursor: auto;
    font-weight: 400;
  }
`;

Container.defaultProps = {
  'data-id': 'Container',
};

TabsList.defaultProps = {
  'data-id': 'TabsList',
};
