import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';
import { Errors } from '../shared-style';
import { AutocompleteWrapper, Field, Label } from './style';

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
  },
}));

const AutocompleteElement = React.memo(
  ({
    id,
    name,
    placeholder,
    errors,
    label,
    getData,
    onChange,
    renderOption,
    register,
    marginbottom,
    popupIcon,
    width,
  }) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const classes = useStyles();

    React.useEffect(() => {
      let active = true;

      if (!loading) {
        return undefined;
      }

      const data = getData();
      if (active) {
        setOptions(data.map((key) => ({ ...key, name: `${key.name}` })));
      }

      return () => {
        active = false;
      };
    }, [loading]);

    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);

    return (
      <Field error={!!errors} fullWidth>
        <Label htmlFor={`${name}-input`} marginbottom={marginbottom}>
          {label}
        </Label>
        <AutocompleteWrapper>
          <Autocomplete
            id={id}
            style={{ width }}
            autoHighlight
            classes={classes}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={onChange}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            noOptionsText='No Data'
            popupIcon={popupIcon}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder={placeholder}
                name={name}
                inputRef={register}
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={renderOption}
          />
        </AutocompleteWrapper>
        {errors && (
          <Errors id={`${name}-error`}>{errors.message || errors.types?.message || errors}</Errors>
        )}
      </Field>
    );
  }
);

AutocompleteElement.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  label: PropTypes.string,
  getData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  register: PropTypes.func,
  marginbottom: PropTypes.number,
};

AutocompleteElement.defaultProps = {
  errors: null,
};

export default AutocompleteElement;
