import { animated } from 'react-spring';
import styled from 'styled-components';

export const Backdrop = styled(animated.div)`
  color: ${({ theme }) => theme.gray};
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(0, 40, 80, 0.4);
`;

export const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.height ? props.height : '80%')};
  min-height: ${({ minheight }) => minheight || '480px'};
  // max-height: 85%;
  // width: ${(props) => (props.width ? props.width : '70%')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: ${(props) => (props?.minwidth ? props.minwidth : '600px')};
  max-width: 70%;
  margin: 50px;
  // background: ${({ theme }) => theme.grayLighter};
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: none;
`;

export const Content = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  // height: calc(25vh - 10px);
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -24px;
  right: -24px;
  font-size: 25px;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.gray};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  border: none;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.grayLight};
  }
`;

export const rightArrow = styled.button`
  cursor: pointer;
`;

export const leftArrow = styled.button`
  cursor: pointer;
`;
Backdrop.defaultProps = {
  'data-id': 'Backdrop',
};

Wrapper.defaultProps = {
  'data-id': 'Wrapper',
};
Content.defaultProps = {
  'data-id': 'Content',
};
Main.defaultProps = {
  'data-id': 'Main',
};
CloseButton.defaultProps = {
  'data-id': 'CloseButton',
};
