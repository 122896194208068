import React from 'react';
import { IconContent } from '../style';

const Home = () => (
  <IconContent fill='currentColor' viewBox='0 0 25 22'>
    <g>
      <path d='M24.734 8.823L12.884.126a.648.648 0 0 0-.768 0L.266 8.823a.652.652 0 0 0-.14.91c.211.29.618.354.907.141L12.5 1.458l11.467 8.416a.645.645 0 0 0 .907-.14.652.652 0 0 0-.14-.91z' />
      <path d='M21.333 10c-.368 0-.666.284-.666.634v10.097h-5.333V15.22c0-1.75-1.496-3.172-3.334-3.172S8.666 13.47 8.666 15.22v5.511H3.333V10.634c0-.35-.298-.634-.666-.634-.368 0-.667.284-.667.634v10.732c0 .35.299.634.667.634h6.666a.654.654 0 0 0 .667-.634V15.22c0-1.05.897-1.903 2-1.903s2 .853 2 1.903v6.146a.654.654 0 0 0 .667.634h6.666c.369 0 .667-.284.667-.634V10.634c0-.35-.299-.634-.667-.634z' />
    </g>
  </IconContent>
);

export default Home;
