/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Modal } from 'components/elements';
import useLastProgram from 'hooks/useLastProgram';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment';
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChartContext } from 'state/contexts/chart';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { ProgramContext } from 'state/contexts/program';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import ActiveProgram from './components/ActiveProgram';
import AddNewProgram from './components/AddNewProgram';
import EditProgram from './components/EditProgram';
import WeightPrediction from './components/WeightPrediction';
import './manage-programs.css';

type ManageProgramsTab = 'active' | 'new' | 'edit' | 'prediction';

const ManagePrograms = ({ onClose }: { onClose: () => void }) => {
  const { programState } = useContext(ProgramContext);
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { chartState } = useContext(ChartContext);
  const { featuresState } = useContext(FeaturesContext);

  const [activeTab, setActiveTab] = useState<ManageProgramsTab>('active');

  const patient = useMemo(() => patientState.data.find((p) => p.current), [patientState]);
  const currentProgram = useLastProgram();

  const handleChange = useCallback((event: SyntheticEvent, newValue: ManageProgramsTab) => {
    setActiveTab(newValue);
  }, []);

  useEffect(() => {
    if (currentProgram && patientState?.currentProgram?.value !== currentProgram.value) {
      patientDispatch({
        type: PatientActionTypes.SET_PROGRAM,
        payload: {
          id: currentProgram.id,
          displayValue: currentProgram.displayValue as string,
          startDate: currentProgram.startDate as string,
          endDate: currentProgram.endDate as string,
          value: currentProgram.value as string,
          userProgramId: currentProgram.userProgramId,
          duration: currentProgram.duration,
        },
      });
    }
  }, [chartState.data?.programs]);

  return (
    <Modal customClass='manage-programs-modal' onClose={onClose}>
      <Box className='manage-programs-container'>
        <Box className='manage-programs-tabs'>
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab label='Active' value='active' />
            <Tab label='Start New' value='new' />
            <Tab label='Edit' value='edit' />
            {featuresState?.[EFeatures.ProviderShowWeightPrediction] && (
              <Tab label='Weight Prediction' value='prediction' />
            )}
          </Tabs>
        </Box>
        <Divider />
        {!!patient && (
          <Box className='manage-programs-content'>
            {activeTab === 'active' && <ActiveProgram onChange={() => setActiveTab('edit')} />}
            {activeTab === 'new' && (
              <AddNewProgram
                onClose={onClose}
                patient={{ id: Number(patient.id) }}
                programs={programState.data}
              />
            )}
            {activeTab === 'edit' && currentProgram?.userProgramId && (
              <EditProgram
                onClose={onClose}
                programs={programState.data}
                programAssignment={{
                  programAssignmentId: currentProgram.userProgramId,
                  programId: currentProgram.id!,
                  startDate: moment(currentProgram.startDate!, 'YYYYMMDDTHHmmss').toDate(),
                  endDate: currentProgram.endDate
                    ? moment(currentProgram.endDate, 'YYYYMMDDTHHmmss').toDate()
                    : undefined,
                }}
              />
            )}
            {activeTab === 'prediction' && <WeightPrediction />}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ManagePrograms;
