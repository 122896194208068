import { WarningRounded } from '@mui/icons-material';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  AppointmentTypeCategoryWithProviderByRole,
  AppointmentTypeWithCareTeam,
} from 'node-api/schedule/Appointments.types';
import AppointmentsClient from 'node-api/schedule/AppointmentsClient';
import { FC, useContext, useMemo, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { PatientContext } from 'state/contexts/patient';
import { SendSelfScheduleLinkButton } from '../Category/AppointmentActions';
import { AppointmentsWrapper } from '../Category/style';
import { ScheduleSelectedAppointment } from '../ScheduleAppointment/ScheduleSelectedAppointment';
import '../ScheduleTab.css';
import { WarningBox, WarningInformation, WarningSpan } from '../style';

interface CategoryProps {
  data: AppointmentTypeCategoryWithProviderByRole;
  patient: {
    id: number;
    clinic_id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    last_timezone: string;
  };
}
const CreateNewScheduleWithinCategoryTab: FC<CategoryProps> = ({ data, patient }) => {
  const { appointmentTypes, provider } = data;
  const categoryTabName = data.name;

  const [inputAppointmentType, setInputAppointmentType] = useState<string>();
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentTypeWithCareTeam>();
  const { patientState } = useContext(PatientContext);

  const providerFullName = useMemo(
    () => [provider.firstName, provider.lastName].join(' '),
    [provider]
  );

  const checkThereIsOneProviderPerRole = useMemo(() => {
    const assignedProviders = patientState.data[0]?.assignedProviders ?? [];
    const filteredProviders = assignedProviders
      .map((_provider: { role: { name: string } }) => _provider.role?.name)
      .filter((_providerRoleName: string) => _providerRoleName === categoryTabName);
    return filteredProviders.length <= 1;
  }, [categoryTabName, patientState.data]);

  return (
    <AppointmentsWrapper>
      <div className='schedule-tab__new-box__selectors'>
        <Box className='schedule-tab__new-box__selectors__column'>
          <Autocomplete
            value={providerFullName}
            inputValue={providerFullName}
            options={[providerFullName]}
            renderInput={(params) => <TextField {...params} variant='outlined' label='Provider' />}
          />
        </Box>
        <Box className='schedule-tab__new-box__selectors__column'>
          <Autocomplete
            value={selectedAppointmentType}
            onChange={(_, newValue: AppointmentTypeWithCareTeam | null) => {
              if (newValue) {
                MixpanelWrapperInstance.track('Clicked Appointment Type');
                setSelectedAppointmentType(newValue);
              } else {
                MixpanelWrapperInstance.track(EventNames.ScheduleClickAppointmentTypeNone);
              }
            }}
            inputValue={inputAppointmentType}
            onInputChange={(_, newInputValue) => {
              MixpanelWrapperInstance.track(EventNames.ScheduleTypeAppointmentType);
              setInputAppointmentType(newInputValue);
            }}
            options={appointmentTypes}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' label='Appointment Type' />
            )}
          />
          <SendSelfScheduleLinkButton
            className='schedule-tab__full-width-button'
            appointmentTypeId={selectedAppointmentType?.id}
            appointmentScheduleLink={AppointmentsClient.getMembersSchedulingLink(
              selectedAppointmentType?.scheduleLinkHash
            )}
            memberId={patient.id}
            provider={provider}
          />
        </Box>
      </div>
      {!checkThereIsOneProviderPerRole && (
        <WarningBox>
          <WarningInformation>
            <WarningSpan>
              <WarningRounded fontSize='large' />
            </WarningSpan>
            <span>
              <b>Multiple providers are assigned under the {`${categoryTabName}`} role!</b>
            </span>
          </WarningInformation>
          <Typography>
            Use the <b>Member Card {'>'} Providers Section</b> to disconnect extra{' '}
            {`${categoryTabName}(s)`}.
          </Typography>
          <Typography>
            You can use <b>More Tabs {'>'} Calendar Tab</b> to schedule appointments with any
            providers.
          </Typography>
        </WarningBox>
      )}
      {selectedAppointmentType?.id && (
        <Box>
          <ScheduleSelectedAppointment
            appointmentType={selectedAppointmentType}
            provider={provider}
            patient={patient}
            isMainProvider={checkThereIsOneProviderPerRole}
          />
        </Box>
      )}
    </AppointmentsWrapper>
  );
};

export default CreateNewScheduleWithinCategoryTab;
