import styled from 'styled-components';

export const Header = styled.header`
  margin-top: -25px;

  h2 {
    color: ${({ theme }) => theme.grayDarker};
    font: 600 24px/28px ${({ theme }) => theme.primaryFont};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;

  & button {
    width: 100%;
    height: 55px;
  }
`;

Header.defaultProps = {
  'data-id': 'Header',
};

Actions.defaultProps = {
  'data-id': 'Actions',
};
