export interface TypeformWebhookEvent {
  event_id: string;
  event_type: string;
  form_response: {
    form_id: string;
    token: string;
    landed_at: string;
    submitted_at: string;
    hidden: {
      [key: string]: string;
      email: string;
      first_name: string;
      id: string; // Member ID
      last_name: string;
      phone: string;
    };
    definition: {
      id: string;
      ref: string;
      title: string; // Form title
      fields: TypeformField[]; // Questions
    };
    answers: TypeformAnswer[]; // Answers
  };
}

export type TypeformAnswerContent =
  | {
      type: TypeformAnswerTypes.Boolean;
      boolean: boolean;
    }
  | {
      type: TypeformAnswerTypes.Choice;
      choice: {
        label: string;
        id: string;
        ref: string;
        other?: string;
      };
    }
  | {
      type: TypeformAnswerTypes.Choices;
      choices: {
        labels: string[] | null;
        ids: string[];
        refs: string[];
        other?: string;
      };
    }
  | {
      type: TypeformAnswerTypes.Date;
      date: string;
    }
  | {
      type: TypeformAnswerTypes.Email;
      email: string;
    }
  | {
      type: TypeformAnswerTypes.FileUrl;
      file_url: string;
    }
  | {
      type: TypeformAnswerTypes.Number;
      number: number;
    }
  | {
      type: TypeformAnswerTypes.Text;
      text: string;
    }
  | {
      type: TypeformAnswerTypes.Url;
      url: string;
    };

export type TypeformAnswer = {
  field: TypeformFieldRef;
} & TypeformAnswerContent;

export type TypeformFieldRef = { id: string; ref: string; type: TypeformAnswerTypes };

export type TypeformFieldDescription = TypeformFieldRef & { title: string };
export type TypeformField = TypeformFieldDescription & {
  properties?: {
    fields?: TypeformField[];
  };
};

export type TypeformDigestedAnswer = {
  field: TypeformFieldDescription;
  answer: TypeformAnswerContent;
};

export type TypeformDigestedRecordContent = {
  formResponse: TypeformDigestedAnswer[];
  formDefinition: {
    id: string;
    ref: string;
    title: string;
  };
};

export enum TypeformAnswerTypes {
  Boolean = 'boolean',
  Choice = 'choice',
  Choices = 'choices',
  Date = 'date',
  Email = 'email',
  FileUrl = 'file_url',
  Number = 'number',
  Text = 'text',
  Url = 'url',
}

export enum TypeformEventSources {
  Typeform = 'typeform',
}

export enum TypeformEventTypes {
  FormSubmitted = 'form-submitted',
}
