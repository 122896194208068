export const BULK_SENDING_CHUNKS = 5;

export type AllSelectedMembers = {
  [memberId: number]: boolean;
};

export enum BulkModalOption {
  None = 1,
  ViewList = 2,
  Reviewed = 3,
}

export interface ListAvatarProps {
  url?: string;
  altLabel?: string;
}
