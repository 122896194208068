import MyPanelList from 'components/main/MyPanel/MyPanelList';
import { Panel } from 'components/main/panel/Panel';
import { PanelVersionSelector } from 'components/main/panel/PanelVersionSelector';
import { FC } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import ContentBrowser from '../components/content/ContentBrowser';
import Dashboard from '../components/main/dashboard';
import PerformanceInsightsDashboard from '../components/main/metrics/Metrics';
import PatientPage from '../components/main/patient/patient-page';
import PatientsStatuses from '../components/main/patients-statuses';
import Review from '../components/main/review/Review';
import WeightLossStats from '../components/main/weight-loss-stats';

// TODO: All the expected props for these components can be replaced by hooks
const routes: {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: FC<RouteProps & { history: any; match: any; location: any }>;
}[] = [
  { path: '/', Component: Dashboard },
  { path: '/content', Component: ContentBrowser },
  { path: '/patients', Component: PanelVersionSelector },
  { path: '/members', Component: PanelVersionSelector },
  { path: '/members-old', Component: MyPanelList },
  { path: '/members-new', Component: Panel },
  { path: '/patient/:id', Component: PatientPage },
  { path: '/patient/:id/:selectedTab', Component: PatientPage },
  { path: '/members/:id', Component: PatientPage },
  { path: '/members/:id/:selectedTab', Component: PatientPage },
  { path: '/review/:id', Component: Review },
  { path: '/review/:id/:selectedTab', Component: Review },
  { path: '/patients_statuses', Component: PatientsStatuses },
  { path: '/wl_stats', Component: WeightLossStats },
  { path: '/dashboards/performance-insights', Component: PerformanceInsightsDashboard },
];

const Routes = () => (
  <Switch>
    {routes.map(({ path, Component }) => (
      <Route exact key={path} path={path} render={(props) => <Component {...props} />} />
    ))}
    <Route render={() => <Redirect to='/' />} />
  </Switch>
);

export default Routes;
