/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from 'prop-types';
import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

export const ImageToggleOnMouseOver = ({
  primaryImg,
  secondaryImg,
  tooltipID,
  tooltipText,
  style,
  imgStyle,
}) => {
  const imageRef = useRef(null);
  return tooltipID ? (
    <div data-tip={tooltipText} data-for={tooltipID} key={tooltipID} style={style}>
      <ReactTooltip id={tooltipID} place='top' type='dark' effect='float' />
      <img
        style={imgStyle}
        onMouseOver={() => {
          imageRef.current.src = secondaryImg;
        }}
        onMouseOut={() => {
          imageRef.current.src = primaryImg;
        }}
        src={primaryImg}
        alt=''
        ref={imageRef}
      />
    </div>
  ) : (
    <img
      style={style}
      onMouseOver={() => {
        imageRef.current.src = secondaryImg;
      }}
      onMouseOut={() => {
        imageRef.current.src = primaryImg;
      }}
      src={primaryImg}
      alt=''
      ref={imageRef}
    />
  );
};

ImageToggleOnMouseOver.propTypes = {
  primaryImg: PropTypes.string.isRequired,
  secondaryImg: PropTypes.string.isRequired,
  tooltipID: PropTypes.string,
  tooltipText: PropTypes.string,
  style: PropTypes.shape(),
  imgStyle: PropTypes.shape(),
};

export default ImageToggleOnMouseOver;
