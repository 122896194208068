import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const Header = styled.h1`
  color: ${({ theme }) => theme.teal};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const QuestionContainer = styled.div<StyledComponentsDivProps>`
  display: flex;
  width: auto;
  flex-wrap: wrap;
`;

export const PairContainer = styled.div<StyledComponentsDivProps>`
  width: 100%;
`;

export const Pair = styled.div<StyledComponentsDivProps>`
  padding-right: 10px;
  display: block;
  margin-bottom: 20px;
  width: 100%;
`;

export const SubPair = styled.div<StyledComponentsDivProps>`
  margin-left: 30px;
  display: block;
  margin-bottom: 16px;
  width: 100%;
`;

export const Question = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.teal};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
  padding-bottom: 7px;
`;

export const SubQuestion = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.teal};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 15px;
  font-weight: 600;
  line-height: 17px;
`;

export const Answer = styled.div<StyledComponentsDivProps>`
  color: ${({ theme }) => theme.gray};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 17px;
  line-height: 19px;
`;
