import { Button, Modal } from 'components/elements';
import infoOutline from 'components/icon/pngs/info-outline.png';
import { FC } from 'react';
import { Header, Icon, Message, MessageContent } from '../confirmation/style';
import { Actions, Content } from './style';

interface InformationModalProps {
  text: string;
  onClose: () => void;
}

const InformationModal: FC<InformationModalProps> = ({ onClose, text }) => {
  return (
    <Modal onClose={onClose} size='small'>
      <Content>
        <Header>
          <h2>Information</h2>
          <MessageContent>
            <Icon>
              <img src={infoOutline} alt='' />
            </Icon>
            <Message>
              <p>{text}</p>
            </Message>
          </MessageContent>
        </Header>
        <Actions>
          <Button onClick={onClose}>Ok</Button>
        </Actions>
      </Content>
    </Modal>
  );
};

export default InformationModal;
