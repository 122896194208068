import { Reducer } from 'react';
import { Action as DefaultAction } from 'state/types';

export enum ActionTypes {
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  SET = 'SET',
}

export interface Provider {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  roles: { name: string }[];
}

export type State = {
  modal: boolean;
  data: Provider[];
  partnerships: unknown[];
};

type Payload = {
  [ActionTypes.TOGGLE_MODAL]: never;
  [ActionTypes.SET]: {
    providers: {
      data: Provider[];
      partnerships: unknown[];
    };
  };
};

export type Action = DefaultAction<Payload>;

export const initialState: State = {
  modal: false,
  data: [],
  partnerships: [],
};

export const reducer: Reducer<State, Action> = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }

    case ActionTypes.SET: {
      const { providers } = action.payload;
      return {
        ...state,
        data: providers.data,
        partnerships: providers.partnerships || [],
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};
