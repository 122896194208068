import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import useLastProgram from 'hooks/useLastProgram';
import moment from 'moment';
import ProgramHistory from './ProgramHistory';

const ActiveProgram = ({ onChange }: { onChange: () => void }) => {
  const currentProgram = useLastProgram();

  return (
    <Box className='manage-programs-tab-content'>
      <ProgramHistory />
      <Divider orientation='vertical' variant='middle' flexItem />
      <Box className='manage-programs-section'>
        <Box className='title'>
          <Typography component='h5' variant='h5' marginBottom={3}>
            Active Program
          </Typography>
        </Box>
        <Box className='manage-programs-form-content'>
          {currentProgram ? (
            <>
              <TextField label='Program' defaultValue={currentProgram.displayValue} disabled />
              <TextField
                label='Program Start Date'
                defaultValue={moment(currentProgram.startDate).toISOString().slice(0, 10)}
                disabled
              />
              <TextField label='Days Active' defaultValue={currentProgram.duration} disabled />
              <Box className='actions'>
                <Button variant='outlined' onClick={onChange}>
                  Edit
                </Button>
              </Box>
            </>
          ) : (
            <Typography>There is no active program</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActiveProgram;
