import { Button, Rating, Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';

import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';

type Props = {
  label: string;
  source: string;
};

const getFeatureRatingUrl = (feature: string, rating: number) =>
  `https://docs.google.com/forms/d/e/1FAIpQLSeKPN5VgCxttTdd1gwpP3yA_btmrybhUvwN-mRGKBWURtlc6w/viewform?usp=pp_url&entry.1080152457=${rating}&entry.221230539=${encodeURIComponent(
    `[INTERNAL] ${feature} (provider-app ${process.env.REACT_APP_VERSION})`
  )}`;

export const MixpanelFeedbackClick: FC<Props & { openForm?: boolean }> = ({
  label,
  source,
  openForm,
}) => {
  const [value, setValue] = useState<number | null>(null);

  return (
    <Rating
      size='small'
      value={value}
      onChange={(_, newValue) => {
        if (newValue) {
          setValue(newValue);
          MixpanelWrapperInstance.track(EventNames.FeatureRatingClick, {
            targetLabel: label,
            targetType: ClickableElement.RATING,
            targetValue: newValue,
            source,
          });

          if (openForm) {
            const url = getFeatureRatingUrl(source, Number(newValue));
            window.open(url, '_blank');
          }
        }
      }}
    />
  );
};

export const MixpanelFeedbackComment: FC<Props> = ({ label, source }) => {
  const [comment, setComment] = useState<string | null>(null);

  return (
    <Stack>
      <TextField
        label='Feedback'
        variant='outlined'
        value={comment}
        onChange={(event) => {
          setComment(event.target.value);
        }}
      />
      <Button
        className='primary'
        variant='contained'
        sx={{ alignSelf: 'center' }}
        onClick={() => {
          if (comment) {
            MixpanelWrapperInstance.track(EventNames.FeatureRatingComment, {
              targetLabel: label,
              targetType: ClickableElement.BUTTON,
              targetValue: comment,
              source,
            });
          }
        }}>
        Submit feedback
      </Button>
    </Stack>
  );
};
