import { CardTabs } from 'interfaces/cards';

export const ENV = process.env.REACT_APP_ENV;
export const APP_NAME = `${process.env.REACT_APP_NAME}`;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const PLATFORM_ENVIRONMENT = process.env.REACT_APP_PLATFORM_ENVIRONMENT;

export const CORE_API = {
  url: process.env.REACT_APP_API_CORE_BASE_URL,
  cors: process.env.REACT_APP_CORS,
};

export const NODE_API = {
  dashboards: process.env.REACT_APP_API_DASHBOARD_BASE_URL,
  healthRecords: process.env.REACT_APP_API_HEALTH_RECORDS_BASE_URL,
  legacy_url: process.env.REACT_APP_API_NODE_BASE_URL,
  members: process.env.REACT_APP_API_MEMBERS_BASE_URL,
  onboarding: process.env.REACT_APP_API_ONBOARDING_BASE_URL,
  rl3_url: process.env.REACT_APP_API_REVIEWLIST_BASE_URL,
  rl4_url: process.env.REACT_APP_API_REVIEWLIST_BASE_URL,
  schedule: process.env.REACT_APP_API_SCHEDULE_BASE_URL,
  transcribe: process.env.REACT_APP_API_SCRIBE_BASE_URL,
};
export const SCHEDULE_API_BASE_URL = NODE_API.schedule;

export const ACUITY = {
  url: process.env.REACT_APP_ACUITY_URL,
};

export const ROR = {
  ror_new_card_url: process.env.REACT_APP_ROR_NEW_CARD_URL,
  ror_categorize_card_url: process.env.REACT_APP_ROR_CATEGORIZE_CARD_URL,
};

export const PUSHER = {
  key: process.env.REACT_APP_PUSHER_KEY,
  appId: process.env.REACT_APP_PUSHER_APPID,
  secret: process.env.REACT_APP_PUSHER_SECRET,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
};

export const MIXPANEL = {
  key: process.env.REACT_APP_MIXPANEL_KEY,
};

export const SHARED_MIXPANEL = {
  key: process.env.REACT_APP_SHARED_MIXPANEL_KEY,
};

export const CHATS = {
  tabs: [
    {
      name: 'direct',
      label: 'Patient',
    },
    {
      name: 'team',
      label: 'Care Team',
    },
  ],
  rooms: [{ name: 'direct' }, { name: 'team' }],
  pusher: {
    direct: {
      channel: 'messages',
      event: 'new-message',
    },
    team: {
      channel: 'private',
      event: 'provider-messages-update',
    },
  },
};

export const CARDS = {
  contentURL: process.env.REACT_APP_API_CONTENT_BASE_URL,
  tabs: [
    {
      name: CardTabs.PendingComments,
      label: 'Cards With Pending Comments',
    },
    {
      name: CardTabs.History,
      label: 'Card History',
    },
    {
      name: CardTabs.PostSequence,
      label: 'Post a Card Sequence',
    },
    {
      name: CardTabs.RecentCard,
      label: 'Recent cards',
    },
  ],
};

export const NOTIFICATION = {
  pusher: {
    channel: 'private',
    event: 'counter-update',
  },
};

export const VIDYO = {
  url: 'https://static.vidyo.io/latest/connector/VidyoConnector.html',
  params: '?host=prod.vidyo.io&autoJoin=1&hideConfig=1',
};

export const MEMBERS_WEB_APP_URL = process.env.REACT_APP_MEMBERS_WEB_APP_URL;
export const PVQ_TYPE_FORM_URL = process.env.REACT_APP_PVQ_TYPE_FORM_URL;

export const FEATURE = {
  url: process.env.REACT_APP_API_FEATURES_BASE_URL,
  targetEnv: process.env.REACT_APP_FEATURE_TARGET_ENV,
};

export const INBODY_DASHBOARD = {
  url: process.env.REACT_APP_INBODY_DASHBOARD_URL,
};
export const ACTIVE_ADMIN = {
  url: process.env.REACT_APP_ACTIVE_ADMIN_URL,
};

export const NOTIFICATION_SOUND = {
  url: process.env.REACT_APP_NOTIFICATION_SOUND_URL,
};

export const FEEDBACK = {
  url: process.env.REACT_APP_FEEDBACK_URL,
};

export const ENVIRONMENT = {
  isDev: process.env.REACT_APP_ENV === 'development',
  isStaging: process.env.REACT_APP_ENV === 'staging',
  isProd: process.env.REACT_APP_ENV === 'production',
};

export const REVIEW_LIST_TEAM_CHAT_AS_DEFAULT = (
  process.env.REACT_APP_REVIEW_LIST_TEAM_CHAT_AS_DEFAULT || ''
).split('|');
export const REVIEW_LIST_ACKNOWLEDGE_MESSAGES = (
  process.env.REACT_APP_REVIEW_LIST_ACKNOWLEDGE_MESSAGES || ''
).split('|');
export const NODE_REVIEW_LIST_NAMES = (process.env.REACT_APP_NODE_REVIEW_LIST_NAMES || '').split(
  '|'
);
export const CARD_REVIEW_LIST_NAMES = (process.env.REACT_APP_CARD_REVIEW_LIST_NAMES || '').split(
  '|'
);
export const CARD_PENDING_COMMENTS_REVIEW_LIST_NAMES = (
  process.env.REACT_APP_CARD_PENDING_COMMENTS_REVIEW_LIST_NAMES || ''
).split('|');
export const PICTURES_REVIEW_LIST_NAMES = (
  process.env.REACT_APP_PICTURES_REVIEW_LIST_NAMES || ''
).split('|');
export const UNREMOVABLE_PROVIDERS = (process.env.REACT_APP_UNREMOVABLE_PROVIDERS || '').split('|');
export const APP_VERSION_PROVIDERS = (process.env.REACT_APP_APP_VERSION_PROVIDERS || '').split('|');
export const ASSIGNED_AS_GHOST_PROVIDERS = (
  process.env.REACT_APP_ASSIGNED_AS_GHOST_PROVIDERS || ''
).split('|');
export const ASSIGNED_AS_REGULAR_PROVIDERS = (
  process.env.REACT_APP_ASSIGNED_AS_REGULAR_PROVIDERS || ''
).split('|');
export const RADIO_BUTTONS_TO_DROPDOWN_THRESHOLD = parseInt(
  process.env.REACT_APP_RADIO_BUTTONS_TO_DROPDOWN_THRESHOLD || '10',
  10
);
