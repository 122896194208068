import React from 'react';
import PropTypes from 'prop-types';

import { Link } from './style';

const LinkElement = React.memo(({ children, to, onClick }) => (
  <Link to={to} onClick={onClick}>
    {children}
  </Link>
));

LinkElement.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

LinkElement.defaultProps = {
  onClick: () => {},
};

export default LinkElement;
