/* eslint-disable */
import questionMark from 'components/icon/pngs/question-mark-64.png';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { ConfirmationContext } from 'state/contexts/confirmation';

import { Button, Modal } from 'components/elements';
import { Actions, Header, Icon, Message, MessageContent, Name } from './style';

const ConfirmationDialog = ({ onClose }) => {
  const [disabled, setDisabled] = useState(false);
  const { confirmationState } = useContext(ConfirmationContext);
  const customConfirmationYesText = confirmationState.yesText;
  const customConfirmationNoText = confirmationState.noText;
  const parts = confirmationState.message?.split('|');

  let part1 = '';
  let part2 = '';
  let part3 = '';

  if (parts.length === 3) {
    part1 = parts[0];
    part2 = parts[1];
    part3 = parts[2];
  }

  return (
    <Modal onClose={onClose} disableBackdropClick showClose={false} size='small'>
      <Header>
        <h2>Confirmation</h2>
        <MessageContent>
          <Icon>
            <img src={questionMark} alt='' />
          </Icon>
          <Message>
            {part3 ? (
              <p>
                {part1}
                <Name>"{part2}"</Name>
                {part3}
              </p>
            ) : confirmationState.message ? (
              <p dangerouslySetInnerHTML={{ __html: confirmationState.message }} />
            ) : (
              <p>Are you sure?</p>
            )}
          </Message>
        </MessageContent>
      </Header>

      <Actions customWidth={!!confirmationState.yesText ? 'auto' : '150px'}>
        <Button
          onClick={() => {
            setDisabled(true);
            return confirmationState.onYes(
              onClose,
              confirmationState.id,
              confirmationState.callBack,
              { ...confirmationState }
            );
          }}
          submitting={disabled}>
          {customConfirmationYesText ? customConfirmationYesText : 'Yes'}
        </Button>
        <Button
          onClick={() => {
            return confirmationState.onNo(
              onClose,
              confirmationState.id,
              confirmationState.callBack
            );
          }}>
          {customConfirmationNoText ? customConfirmationNoText : 'No'}
        </Button>
      </Actions>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
