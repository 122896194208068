import { Typography } from '@mui/material';
import { Button, Modal } from 'components/elements';
import { FC } from 'react';
import AppointmentConfirmation from '../AppointmentConfirmation';
import { Actions, Content } from './style';

interface AppointmentConfirmationModalProps {
  appointmentDateInProviderTimezone: string;
  appointmentDateInMemberTimezone: string;
  appointmentType: string;
  onClose: () => void;
  providerName: string;
}

export const AppointmentConfirmationModal: FC<AppointmentConfirmationModalProps> = ({
  appointmentDateInProviderTimezone,
  appointmentDateInMemberTimezone,
  appointmentType,
  onClose,
  providerName,
}) => {
  return (
    <Modal onClose={onClose}>
      <AppointmentConfirmation
        appointmentDateInProviderTimezone={appointmentDateInProviderTimezone}
        appointmentDateInMemberTimezone={appointmentDateInMemberTimezone}
        appointmentType={appointmentType}
        providerName={providerName}
        title='Appointment Confirmation Details:'
      />
      <Content>
        <Typography variant='body1'>
          This Appointment will be listed under Active Sessions in the main screen of Provider App
        </Typography>
      </Content>
      <Actions>
        <Button onClick={onClose}>Done</Button>
      </Actions>
    </Modal>
  );
};
